import { OrganizationType } from './organization-type';
import { PignorState } from './pignor-type';

export class OrganizationCreateRequest {
  name: string;
  code: string;
  type: OrganizationType;
  parentOrganizationId: number | null;
  codeSia: string;
  licenseId: string;
  webCode: string;
  anagId: string;
  pignorState: PignorState;
  spaceUsedPaymentPlanType: number;

  constructor(
    name: string,
    code: string,
    type: OrganizationType,
    parentOrganizationId: number | null,
    codeSia: string,
    licenseId: string,
    webCode: string,
    anagId: string,
    pignorState: PignorState
  ) {
    this.name = name;
    this.code = code;
    this.type = type;
    this.parentOrganizationId = parentOrganizationId;
    this.codeSia = codeSia;
    this.licenseId = licenseId;
    this.webCode = webCode;
    this.anagId = anagId;
    this.pignorState = pignorState;
  }
}
