<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>

<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>

<app-drawer [isOpen]="createDrawerIsOpen" (closeEventEmitter)="onCloseCreateModalForm()">
  <app-organization-create-dialog
    *ngIf="createDrawerIsOpen"
    (closeEventEmitter)="onCloseCreateModalForm()"
    (creationSubmit)="onCreateOrganization()">
  </app-organization-create-dialog>
</app-drawer>

<app-drawer [isOpen]="updateDrawerIsOpen" (closeEventEmitter)="onCloseUpdatingModalForm()">
  <app-organization-update-dialog
    *ngIf="updateDrawerIsOpen"
    [organization]="organization"
    (closeEventEmitter)="onCloseUpdatingModalForm()"
    (updatingSubmit)="onUpdateOrganization($event)">
  </app-organization-update-dialog>
</app-drawer>

<div class="page-content">
  <app-loader *ngIf="isLoading"></app-loader>
  <div *ngIf="isError" class="half-of-screen">
    <app-something-went-wrong
      description="Cannot load companies"
      [showPicture]="false"
      (retryEmitter)="fetchOrganizations(true)"></app-something-went-wrong>
  </div>
  <div *ngIf="!isLoading && !isError">
    <div class="search-panel">
      <div *ngIf="!useMobileView" class="button-group">
        <button
          mat-raised-button
          color="primary"
          class="page-block-button"
          aria-label="{{ 'createCompany' | translate }}"
          (click)="onCreateOrganizationBtnClicked()">
          <mat-icon>add</mat-icon>{{ "newCompany" | translate }}
        </button>
      </div>
      <div *ngIf="useMobileView" class="button-group">
        <button
          class="settings-mobile-button"
          mat-mini-fab
          color="primary"
          aria-label="{{ 'createCompany' | translate }}"
          (click)="onCreateOrganizationBtnClicked()">
          <mat-icon class="settings-mobile-button">add</mat-icon>
        </button>
      </div>
      <div>
        <app-search-bar
          (searchEventEmitter)="fetchOrganizations(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
    </div>

    <div *ngIf="useMobileView" class="settings-mobile-block">
      <div class="page-block">
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)">
        </mat-paginator>
      </div>
      <div class="mt-4 page-block" *ngFor="let organization of organizations">
        <mat-card>
          <!-- <mat-card-title>Name: {{organization.name}}</mat-card-title> -->
          <mat-card-content class="cursor-pointer">
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "name" | translate }}:</div>
              <div class="card-table-row-value">{{ organization.name }}</div>
            </div>
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "webCode" | translate }}:</div>
              <div class="card-table-row-value">{{ organization.webCode }}</div>
            </div>
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "licenseId" | translate }}:</div>
              <div class="card-table-row-value">{{ organization.licenseId }}</div>
            </div>
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "codeSia" | translate }}:</div>
              <div class="card-table-row-value">{{ organization.codeSia }}</div>
            </div>
            <div class="card-table-row">
              <div class="card-table-row-header">{{ "anagId" | translate }}:</div>
              <div class="card-table-row-value">{{ organization.anagId }}</div>
            </div>
            <!--            <div class="card-table-row">-->
            <!--              <div class="card-table-row-header">Type:</div>-->
            <!--              <div class="card-table-row-value">{{organizationTypes[organization.type]}}</div>-->
            <!--            </div>-->
            <!--            <div class="card-table-row">-->
            <!--              <div class="card-table-row-header">Parent organization:</div>-->
            <!--              <div class="card-table-row-value">-->
            <!--                <span *ngIf="hasParentOrganization(organization); else withoutParentOrganization">{{organization.parentOrganizationName}}</span>-->
            <!--                <ng-template #withoutParentOrganization>-->
            <!--                  <span>-</span>-->
            <!--                </ng-template>-->
            <!--              </div>-->
            <!--            </div>-->
            <!-- <div class="card-table-row">
              <div class="card-table-row-header">Accounts:</div>
              <div class="card-table-row-value">
                <span>{{0}}</span>
                <button mat-icon-button aria-label="Create mail account" (click)="openCreateMailAccountDrawer(organization.organizationId)">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div>
            <div class="card-table-row">
              <div class="card-table-row-header">Users:</div>
              <div class="card-table-row-value">
                <span>{{0}}</span>
                <button mat-icon-button aria-label="Add user in company" (click)="openCreateUserOrganizationDrawer(organization.organizationId)">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
            </div> -->
          </mat-card-content>
          <mat-card-actions>
            <div class="flex justify-end">
              <a
                mat-flat-button
                color="primary"
                aria-label="{{ 'details' | translate }}"
                (click)="openUpdateOrganizationDialog(organization)">
                <mat-icon>edit_outline</mat-icon>
              </a>
              <!-- <span class="mr-2"></span> -->
              <!-- <button mat-flat-button color="primary" aria-label="Details" (click)="deleteOrganization(organization.organizationId, organization.name)">
                <mat-icon>delete_outline</mat-icon>
              </button> -->
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
      <app-no-data *ngIf="organizations.length === 0"></app-no-data>
    </div>

    <div class="page-block" *ngIf="!useMobileView">
      <div class="table-container">
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)">
        </mat-paginator>
        <table mat-table [dataSource]="dataSource" class="w-full">
          <ng-container matColumnDef="web-code">
            <th mat-header-cell *matHeaderCellDef>{{ "webCode" | translate }}</th>
            <td mat-cell *matCellDef="let organization">{{ organization.webCode }}</td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
            <td mat-cell *matCellDef="let organization">{{ organization.name }}</td>
          </ng-container>
          <ng-container matColumnDef="license-id">
            <th mat-header-cell *matHeaderCellDef>{{ "licenseId" | translate }}</th>
            <td mat-cell *matCellDef="let organization">{{ organization.licenseId }}</td>
          </ng-container>
          <ng-container matColumnDef="code-sia">
            <th mat-header-cell *matHeaderCellDef>{{ "codeSia" | translate }}</th>
            <td mat-cell *matCellDef="let organization">{{ organization.codeSia }}</td>
          </ng-container>
          <ng-container matColumnDef="anagId">
            <th mat-header-cell *matHeaderCellDef>{{ "anagId" | translate }}</th>
            <td mat-cell *matCellDef="let organization">{{ organization.anagId }}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
            <td mat-cell *matCellDef="let organization">
              <button mat-icon-button aria-label="{{ 'details' | translate }}" (click)="openUpdateOrganizationDialog(organization)">
                <mat-icon>edit_outline</mat-icon>
              </button>
              <!-- <button mat-icon-button aria-label="Delete" (click)="deleteOrganization(organization.organizationId, organization.name)">
                <mat-icon>delete_outline</mat-icon>
              </button> -->
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <app-no-data *ngIf="organizations.length === 0"></app-no-data>
      </div>
    </div>
  </div>
</div>
