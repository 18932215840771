<div *ngIf="isLoading" class="w-full h-full">
  <app-loader height="100%"></app-loader>
</div>

<div *ngIf="cannotLoadData" class="w-full h-full">
  <app-something-went-wrong
    description="{{ 'cannotLoadData' | translate }}"
    [showPicture]="false"
    (retryEmitter)="ngOnInit()"></app-something-went-wrong>
</div>

<div *ngIf="!isLoading && !cannotLoadData" class="w-full h-full flex flex-col">
  <div class="text-2xl mb-10 font-medium">{{ "advancedSearch" | translate }}</div>
  <div class="flex-grow overflow-y-auto">
    <div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>{{ "folders" | translate }}</mat-label>
          <mat-select (selectionChange)="onSearchTypeChange(searchType)" [(value)]="searchType">
            <mat-option [value]="1">
              {{ "currSelections" | translate }}
            </mat-option>
            <mat-option [value]="2"> {{ "currSelections" | translate }} + {{ "subfolders" | translate }} </mat-option>
            <mat-option [value]="3">
              {{ "allFolders" | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- <div class="font-medium text-lg">Filters</div> -->

      <mat-form-field appearance="fill">
        <mat-label>{{ "subject" | translate }}</mat-label>
        <input id="focusSearch" matInput [(ngModel)]="searchContext.searchParams.subject" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "body" | translate }}</mat-label>
        <input matInput [(ngModel)]="searchContext.searchParams.body" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "from" | translate }}</mat-label>
        <input matInput [(ngModel)]="searchContext.searchParams.from" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "recipient" | translate }}</mat-label>
        <input matInput [(ngModel)]="searchContext.searchParams.to" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "sentDateRange" | translate }}</mat-label>
        <mat-date-range-input [formGroup]="filterForm" [rangePicker]="picker">
          <input
            [(ngModel)]="searchContext.searchParams.sentDateTime.start"
            matStartDate
            formControlName="sentStart"
            placeholder="{{ 'startDateTime' | translate }}" />
          <input matEndDate formControlName="sentEnd" placeholder="{{ 'endDateTime' | translate }}" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "attachmentName" | translate }}</mat-label>
        <input matInput [(ngModel)]="searchContext.searchParams.attachmentName" />
      </mat-form-field>
      <!-- <mat-form-field appearance="fill">
        <mat-label>FullText</mat-label>
        <input matInput [(ngModel)]="searchContext.searchParams.fullText">
      </mat-form-field> -->

      <!-- <div>
        <mat-checkbox color="primary" [disableRipple]="true" [(ngModel)]="searchContext.searchParams.deleted">Show deleted</mat-checkbox>
      </div>

      <div>
        <mat-checkbox color="primary" [disableRipple]="true" [(ngModel)]="searchContext.searchParams.isSpam">Show spam</mat-checkbox>
      </div> -->

      <!-- <mat-form-field appearance="fill">
        <mat-label>Notifications</mat-label>
        <mat-select [(value)]="selectedHideNotificationsStatus">
          <mat-option *ngFor="let item of notifications" [value]="item.value">
            {{item.text | lowercase}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field appearance="fill">
        <mat-label>{{ "attachments" | translate }}</mat-label>
        <mat-select [(value)]="selectedAttachmentsStatus">
          <mat-option *ngFor="let item of attachments" [value]="item.value">
            {{ item.text | translate | lowercase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "seen" | translate }}</mat-label>
        <mat-select [(value)]="selectedSeenStatus">
          <mat-option *ngFor="let item of seen" [value]="item.value">
            {{ item.text | translate | lowercase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field appearance="fill">
        <mat-label>Download date range</mat-label>
        <mat-date-range-input [formGroup]="filterForm" [rangePicker]="picker2">
          <input matStartDate formControlName="downloadStart" placeholder="Start date">
          <input matEndDate formControlName="downloadEnd" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-date-range-picker #picker2></mat-date-range-picker>
      </mat-form-field> -->

      <!-- <mat-form-field appearance="fill">
        <mat-label>Direction</mat-label>
        <mat-select [(value)]="searchContext.searchParams.orderDirection">
          <mat-option *ngFor="let searchDirection of searchDirections" [value]="searchDirection.id">
            {{searchDirection.name | lowercase}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <mat-form-field appearance="fill">
        <mat-label>Validation status</mat-label>
        <mat-select [(value)]="selectedValidationStatus">
          <mat-option *ngFor="let status of validated" [value]="status.value">
            {{status.text | lowercase}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <mat-form-field appearance="fill">
        <mat-label>Reassigned status</mat-label>
        <mat-select [(value)]="selectedReassignedStatus">
          <mat-option *ngFor="let status of reassigned" [value]="status.value">
            {{status.text | lowercase}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field appearance="fill">
        <mat-label>{{ "reassignedTo" | translate }}</mat-label>
        <mat-select [(value)]="selectedReassignedId">
          <mat-option [value]="0">
            {{ "showAll" | translate }}
          </mat-option>
          <mat-option *ngFor="let account of accounts" [value]="account.mailAccountId">
            {{ account.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ "source" | translate }}</mat-label>
        <mat-select [(value)]="selectedSourceStatus">
          <mat-option *ngFor="let item of source" [value]="item.value">
            {{ item.text | translate | lowercase }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <form [formGroup]="filterForm" autocomplete="off" novalidate>
        <div *ngIf="existedTags.length > 0" class="pr-2">
          <app-email-search-tag-input [existedTags]="existedTags" [selectedTags]="selectedTags"> </app-email-search-tag-input>
        </div>
      </form>
    </div>
  </div>
  <div class="flex-none py-2">
    <div class="flex justify-between">
      <button mat-flat-button (click)="resetParams()">{{ "resetFilters" | translate }}</button>
      <button mat-flat-button color="primary" (click)="search()">{{ "search" | translate }}</button>
    </div>
  </div>
</div>
