<div>
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="mat-input-without-padding">
      <mat-label>{{ "mailAccounts" | translate }}</mat-label>
      <mat-select
        formControlName="mailAccount"
        required
        [(value)]="dispatchingAction.args[0].value"
        (selectionChange)="setMailAccountValue($event.value)">
        <mat-option *ngFor="let mailAccount of mailAccounts" [(value)]="mailAccount.email">{{ mailAccount.email }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
