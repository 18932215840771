import { UserOrganizationGetOrganizationUsersResponse } from '../contracts/user-organization/user-organization.get-organization-users.response';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { UserOrganizationGetUserOrganizationsResponse } from '../contracts/user-organization/user-organization.get-user-organizations.response';
import { BaseCollectionSearchByIdRequest } from '../contracts/common/base-collection-search-by-id.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { BaseGetSearchCountByIdRequest } from '../contracts/common/base-get-search-count-by-id.request';
import { BaseValueResponse } from '../contracts/common/base-value-response';
import { UserOrganizationGetOrganizationUsersContract } from '../contracts/user-organization/user-organization.get-organization-users.contract';
import { AddUserToOrganizationRequest } from '../contracts/user-organization/add-user-to-organization.request';
import { UpdateUserRequest } from '../contracts/user-organization/update-user.request';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { ChangeRoleRequest } from '../contracts/user-organization/change-role.request';
import { ChangeStateRequest } from '../contracts/user-organization/change-state.request';
import { GetAuthenticationTypesRequest } from '../contracts/authentication/get-authentication-types.request';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class UserOrganizationClient {
  constructor(private apiRequest: ApiRequest) {}

  async getOrganizationUsers(request: BaseGetByIdRequest): Promise<UserOrganizationGetOrganizationUsersResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.userOrganization.getOrganizationUsers}?${request.format()}`);
  }

  async getUserOrganizations(): Promise<UserOrganizationGetUserOrganizationsResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.userOrganization.getUserOrganizations}`);
  }

  async getUserOrganizationsForAuth(request: GetAuthenticationTypesRequest): Promise<UserOrganizationGetUserOrganizationsResponse> {
    return this.apiRequest.get(`${environment.feUrl}${ApiRoutes.userOrganization.getUserOrganizationsForAuth}?${request.format()}`);
  }

  async deleteUserFromOrganization(request: BaseDeleteRequest): Promise<void> {
    await this.apiRequest.post<BaseDeleteRequest, void>(
      request,
      `${API_URL}${ApiRoutes.userOrganization.deleteUserFromOrganization}`,
      null
    );
  }

  async search(request: BaseCollectionSearchByIdRequest): Promise<BaseGetCollectionResponse<UserOrganizationGetOrganizationUsersContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.userOrganization.search}?${request.format()}`);
  }

  async getSearchCount(request: BaseGetSearchCountByIdRequest): Promise<BaseValueResponse<number>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.userOrganization.getSearchCount}?${request.format()}`);
  }

  async addUserToOrganization(request: AddUserToOrganizationRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post(request, `${API_URL}${ApiRoutes.userOrganization.addUserToOrganization}`);
  }

  async updateUser(request: UpdateUserRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post(request, `${API_URL}${ApiRoutes.userOrganization.updateUser}`);
  }

  async getUserIdentityTypes(request: BaseGetByIdRequest): Promise<any> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.userOrganization.getUserIdentityTypes}?${request.format()}`);
  }

  // async getIdentityOidcByUserIdentityId(request: BaseGetByIdRequest): Promise<any> {
  //   return this.apiRequest.get(`${API_URL}${ApiRoutes.userOrganization.getIdentityOidcByUserIdentityId}?${request.format()}`);
  // }

  async changeRole(request: ChangeRoleRequest): Promise<void> {
    return this.apiRequest.post(request, `${API_URL}${ApiRoutes.userOrganization.changeRole}`);
  }

  async changeState(request: ChangeStateRequest): Promise<void> {
    return this.apiRequest.post(request, `${API_URL}${ApiRoutes.userOrganization.changeState}`);
  }
}
