import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { CreateTemplateRequest } from '../contracts/template/create-template.request';
import { UpdateTemplateRequest } from '../contracts/template/update-template.request';
import { BaseCollectionSearchByIdRequest } from '../contracts/common/base-collection-search-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../contracts/common/base-get-search-count-by-id.request';
import { BaseValueResponse } from '../contracts/common/base-value-response';
import { TemplateContract } from '../contracts/template/template.contract';
import { TemplateGetByIdResponse } from '../contracts/template/template-get-by-id-response.contract';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TemplateClient {
  constructor(private http: HttpClient, private apiRequest: ApiRequest) {}

  async getForMailAccount(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<TemplateContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TemplateContract>>(
      `${API_URL}${ApiRoutes.template.getForMailAccount}?${request.format()}`
    );
  }

  async getById(request: BaseGetByIdRequest): Promise<TemplateGetByIdResponse> {
    return this.apiRequest.get<TemplateGetByIdResponse>(`${API_URL}${ApiRoutes.template.getById}?${request.format()}`);
  }

  async searchForMailAccount(request: BaseCollectionSearchByIdRequest): Promise<BaseGetCollectionResponse<TemplateContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TemplateContract>>(
      `${API_URL}${ApiRoutes.template.searchForMailAccount}?${request.format()}`
    );
  }

  async getSearchCountForMailAccount(request: BaseGetSearchCountByIdRequest): Promise<BaseValueResponse<number>> {
    return this.apiRequest.get<BaseValueResponse<number>>(
      `${API_URL}${ApiRoutes.template.getSearchCountForMailAccount}?${request.format()}`
    );
  }

  async create(request: CreateTemplateRequest): Promise<void> {
    await this.apiRequest.post<CreateTemplateRequest, void>(request, `${API_URL}${ApiRoutes.template.create}`);
  }

  async update(request: UpdateTemplateRequest): Promise<void> {
    await this.apiRequest.post<UpdateTemplateRequest, void>(request, `${API_URL}${ApiRoutes.template.update}`);
  }

  async delete(request: BaseDeleteRequest): Promise<void> {
    await this.apiRequest.post<BaseDeleteRequest, void>(request, `${API_URL}${ApiRoutes.template.delete}`);
  }
}
