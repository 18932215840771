import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { BaseIdListRequest } from '../contracts/common/base.id-list.request';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { ContactGroupCreateRequest } from '../contracts/contact-group/contactgroup.create.request';
import { ContactGroupUpdateRequest } from '../contracts/contact-group/contactgroup.update.request';
import { ContactGroupContract } from '../contracts/contact-group/contactgroup.contract';
import { ContactGroupAddContactsToGroupRequest } from '../contracts/contact-group/contactgroup.add-contacts-to-group.request';
import { ContactGroupRemoveContactsFromGroupRequest } from '../contracts/contact-group/contactgroup.remove-contacts-from-group.request';
import { BaseGetCollectionByIdRequest } from '../contracts/common/base-get-collection-by-id.request';
import { ContactContract } from '../contracts/contact/contact.contract';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ContactGroupClient {
  constructor(private apiRequest: ApiRequest) {}

  async removeContactsFromAllGroups(request: BaseIdListRequest): Promise<void> {
    return this.apiRequest.post<BaseIdListRequest, void>(request, `${API_URL}${ApiRoutes.contactGroup.removeContactsFromAllGroups}`);
  }
  async removeContactsFromGroup(request: ContactGroupRemoveContactsFromGroupRequest): Promise<void> {
    return this.apiRequest.post<ContactGroupAddContactsToGroupRequest, void>(
      request,
      `${API_URL}${ApiRoutes.contactGroup.removeContactsFromGroup}`
    );
  }

  async addContactsToGroup(request: ContactGroupAddContactsToGroupRequest): Promise<void> {
    return this.apiRequest.post<ContactGroupAddContactsToGroupRequest, void>(
      request,
      `${API_URL}${ApiRoutes.contactGroup.addContactsToGroup}`
    );
  }

  async create(request: ContactGroupCreateRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<ContactGroupCreateRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.contactGroup.create}`);
  }

  async update(request: ContactGroupUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<ContactGroupUpdateRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.contactGroup.update}`);
  }

  async delete(request: BaseIdListRequest): Promise<void> {
    return this.apiRequest.post<BaseIdListRequest, void>(request, `${API_URL}${ApiRoutes.contactGroup.delete}`);
  }

  async deleteForAccount(request: BaseDeleteRequest): Promise<void> {
    return this.apiRequest.post<BaseDeleteRequest, void>(request, `${API_URL}${ApiRoutes.contactGroup.deleteForAccount}`);
  }

  async getForAccount(request: BaseGetCollectionByIdRequest): Promise<BaseGetCollectionResponse<ContactGroupContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<ContactGroupContract>>(
      `${API_URL}${ApiRoutes.contactGroup.getForAccount}?${request.format()}`
    );
  }

  async get(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<ContactGroupContract>> {
    return this.apiRequest.get<BaseGetByIdResponse<ContactGroupContract>>(`${API_URL}${ApiRoutes.contactGroup.get}?${request.format()}`);
  }

  async getGroupContacts(request: BaseGetCollectionByIdRequest): Promise<BaseGetCollectionResponse<ContactContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<ContactContract>>(
      `${API_URL}${ApiRoutes.contactGroup.getGroupContacts}?${request.format()}`
    );
  }
}
