import { Component, Input, OnInit } from '@angular/core';
import { EmailAuditContract, isEventMetadata } from '../../../sdk/contracts/email-audit/email-audit-contract';
import { BaseGetCollectionByIdRequest } from '../../../sdk/contracts/common/base-get-collection-by-id.request';
import { EmailAuditClient } from 'src/app/sdk/clients/email-audit-client';
import { EmailAuditEventType } from 'src/app/sdk/contracts/email-audit/email-audit-event-type';
import { TranslateService } from '@ngx-translate/core';
import { isJSON } from 'src/app/shared/helpers/type-defenders';

@Component({
  selector: 'app-email-audit',
  templateUrl: './email-audit.component.html',
  styleUrls: ['./email-audit.component.scss'],
})
export class EmailAuditComponent implements OnInit {
  @Input() emailId: number;
  @Input() title: string;

  public isAuditLoadingError: boolean;
  public isAuditLoading: boolean;
  public events: EmailAuditContract[] | null = [];

  constructor(private emailAuditClient: EmailAuditClient, private translate: TranslateService) {}

  async ngOnInit(): Promise<any> {
    this.isAuditLoadingError = false;
    this.isAuditLoading = true;
    try {
      const response = await this.emailAuditClient.getByEmail(new BaseGetCollectionByIdRequest(this.emailId, 1, 100));
      this.events = response.data;
    } catch (err) {
      this.isAuditLoadingError = true;
    } finally {
      this.isAuditLoading = false;
    }
    this.localizeMetadata(this.events);
  }

  localizeMetadata(events: EmailAuditContract[]): void {
    let str = '';
    events.map((event: EmailAuditContract) => {
      if (isJSON(event.metadata)) {
        if (isEventMetadata(JSON.parse(event.metadata))) {
          let temp = JSON.parse(event.metadata);

          this.translate.stream(temp['Label'] || ' ').subscribe((res: string) => {
            str = res;
          });
          str = this.argsConfig(str, temp['Args'], temp['Label']);
          event.metadata = str;
        }
      }
    });
  }

  argsConfig(str, args, flag) {
    switch (flag) {
      case 'EmailAuditInformationForwardReplyEvent': {
        args.forEach((item, index) => {
          str = str.replace(`{${index}}`, '');
          if (item) {
            str = str + item;
            if (args[index + 1]) str = str + '; ';
          }
        });
        return str;
      }
      default: {
        args.forEach((item, index) => {
          str = str.replace(`{${index}}`, item);
        });
        return str;
      }
    }
  }

  public isAuditWithError(event: EmailAuditContract): boolean {
    return (
      event.type === EmailAuditEventType.DispatchigRuleActionError ||
      event.type === EmailAuditEventType.VirusDetected ||
      event.type === EmailAuditEventType.SentError
    );
  }
}
