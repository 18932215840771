import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganizationSettingsClient } from '../../../../../sdk/clients/organization-settings.client';
import { OrganizationSettingsContract } from '../../../../../sdk/contracts/organization/settings/organization-settings.contract';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';
import { AdfsOAuthOptions } from '../../../../../sdk/contracts/organization/settings/adfs-oauth-options';
import { OrganizationArchiveSettings } from '../../../../../sdk/contracts/organization/settings/organization-archive-settings';
import { OrganizationSettingsUpdateRequest } from '../../../../../sdk/contracts/organization/settings/organization-settings.update.request';
import { MatchError } from 'src/app/shared/error-matcher';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorCode } from 'src/app/sdk/api-errors/error-code';
import { ClaimClient } from 'src/app/sdk/clients/claim';
import { SamlClaims } from 'src/app/sdk/contracts/mail-account/settings/saml-claims.contract';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from 'src/app/components/info-dialog/info-dialog.component';
import { RoleType } from 'src/app/sdk/contracts/role/role-type';
import { LOCALE_IDS } from 'src/app/shared/constants';
import {
  SpaceUsedPaymentPlanDetailTypes,
  SpaceUsedPaymentPlanSelectTypes,
} from 'src/app/sdk/contracts/organization/settings/spaceUsedPaymentPlanTypes';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { outMaxLimit, outMinLimit } from 'src/app/sdk/validators/custom-validator';

@Component({
  selector: 'app-organization-settings-dialog',
  templateUrl: './organization-settings-dialog.component.html',
  styleUrls: ['./organization-settings-dialog.component.scss'],
})
export class OrganizationSettingsDialogComponent implements OnInit {
  @Input() organizationId: number;
  @Output() public updatingSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public autoDeleteForm: UntypedFormGroup = new UntypedFormGroup({});
  public settings: OrganizationSettingsContract;
  public settingsIsLoading = false;
  public failedSettingsLoading = false;
  public samlClaims: SamlClaims;
  public isLoading = false;
  public failedUpdate = false;
  public errorText: string | null;
  public initAuthMethods;
  public locales = LOCALE_IDS;
  public locale: UntypedFormControl = new UntypedFormControl('');
  public spaceUsedPaymentPlan: UntypedFormControl = new UntypedFormControl(1);
  public spaceUsedPaymentPlanType = SpaceUsedPaymentPlanSelectTypes;

  public handlingErrorCodes = new Map([
    [
      ErrorCode.CannotChangeTypeForOrganizationWithChildren,
      'Cannot change type from group to company if organization has children companies',
    ],
    [ErrorCode.CannotSetParentForParentOrganization, 'Cannot set parent organization for company which itself is the parent'],
    [ErrorCode.WebCodeAlreadyUsed, 'Cannot update company: web code already used'],
    [ErrorCode.LicenseIdAndCodeSiaAlreadyUsed, 'Cannot update company: LicenseID and CodeSia already used'],
  ]);
  constructor(
    private settingsService: SettingsService,
    private organizationSettingsClient: OrganizationSettingsClient,
    public claimClient: ClaimClient,
    public matchError: MatchError,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadSettings();
  }

  private initForm(): void {
    this.form = new UntypedFormGroup({
      archive: new UntypedFormControl(this.settings.archive),
      licenseNumber: new UntypedFormControl(this.settings.archiveSettings.licenseNumber),
      licenseCode: new UntypedFormControl(this.settings.archiveSettings.licenseCode),
      prefix: new UntypedFormControl(this.settings.archiveSettings.prefix, [Validators.maxLength(1)]),
      postfixInFlow: new UntypedFormControl(this.settings.archiveSettings.postfixInFlow, [Validators.maxLength(1)]),
      postfixOutFlow: new UntypedFormControl(this.settings.archiveSettings.postfixOutFlow, [Validators.maxLength(1)]),
      count: new UntypedFormControl(this.settings.archiveSettings.count, [
        Validators.pattern('^[0-9]*$'),
        Validators.min(0),
        Validators.max(200),
      ]),
      samlRtaClaim: new UntypedFormControl(this.samlClaims?.claimCode || '', []),
      // clientId: new FormControl(this.settings.adfsOAuthOptions.clientId),
      // authority: new FormControl(this.settings.adfsOAuthOptions.authority),
      // clientSecret: new FormControl(this.settings.adfsOAuthOptions.clientSecret),
      // redirectUri: new FormControl(this.settings.adfsOAuthOptions.redirectUri)
    });
    this.form.get('archive').valueChanges.subscribe((selectedValue) => {
      if (selectedValue) {
        this.form.controls['prefix'].setValidators([Validators.required, Validators.maxLength(1)]);
        this.form.controls['postfixInFlow'].setValidators([Validators.required, Validators.maxLength(1)]);
        this.form.controls['postfixOutFlow'].setValidators([Validators.required, Validators.maxLength(1)]);
        console.log(this.form);
      } else {
        this.form.controls['prefix'].setValidators([Validators.maxLength(1)]);
        this.form.controls['postfixInFlow'].setValidators([Validators.maxLength(1)]);
        this.form.controls['postfixOutFlow'].setValidators([Validators.maxLength(1)]);
      }
      this.form.controls['prefix'].markAsTouched();
      this.form.controls['postfixInFlow'].markAsTouched();
      this.form.controls['postfixOutFlow'].markAsTouched();
      this.form.controls['prefix'].updateValueAndValidity();
      this.form.controls['postfixInFlow'].updateValueAndValidity();
      this.form.controls['postfixOutFlow'].updateValueAndValidity();
    });
    if (this.settings.archive) {
      this.form.controls['prefix'].setValidators([Validators.required, Validators.maxLength(1)]);
      this.form.controls['postfixInFlow'].setValidators([Validators.required, Validators.maxLength(1)]);
      this.form.controls['postfixOutFlow'].setValidators([Validators.required, Validators.maxLength(1)]);
    } else {
      this.form.controls['prefix'].setValidators([Validators.maxLength(1)]);
      this.form.controls['postfixInFlow'].setValidators([Validators.maxLength(1)]);
      this.form.controls['postfixOutFlow'].setValidators([Validators.maxLength(1)]);
    }
    if (this.settings.samlSupport) {
      this.form.controls['samlRtaClaim'].setValidators([Validators.required, Validators.maxLength(256)]);
    } else {
      this.form.controls['samlRtaClaim'].setValidators([]);
      this.form.controls['samlRtaClaim'].disable();
    }
    this.form.controls['samlRtaClaim'].updateValueAndValidity();

    this.autoDeleteForm = new UntypedFormGroup({
      deleteEmailsFromTrash: new UntypedFormControl(this.settings.deleteEmailsFromTrash ?? false),
      delayDeleteEmailsFromTrash: new UntypedFormControl(
        this.settings.delayDeleteEmailsFromTrash ?? null,
        this.settings.deleteEmailsFromTrash ? [Validators.required, outMinLimit(30), outMaxLimit(90)] : []
      ),
      deleteEmailsFromSpam: new UntypedFormControl(this.settings.deleteEmailsFromSpam ?? false),
      delayDeleteEmailsFromSpam: new UntypedFormControl(
        this.settings.delayDeleteEmailsFromSpam ?? null,
        this.settings.deleteEmailsFromSpam ? [Validators.required, outMinLimit(30), outMaxLimit(90)] : []
      ),
    });

    this.autoDeleteForm.get('deleteEmailsFromTrash').valueChanges.subscribe((selectedValue) => {
      if (selectedValue) {
        this.autoDeleteForm.controls['delayDeleteEmailsFromTrash'].setValidators([Validators.required, outMinLimit(30), outMaxLimit(90)]);
      } else {
        this.autoDeleteForm.controls['delayDeleteEmailsFromTrash'].setValidators([]);
      }
      this.autoDeleteForm.controls['delayDeleteEmailsFromTrash'].markAsTouched();
      this.autoDeleteForm.controls['delayDeleteEmailsFromTrash'].updateValueAndValidity();
    });

    this.autoDeleteForm.get('deleteEmailsFromSpam').valueChanges.subscribe((selectedValue) => {
      if (selectedValue) {
        this.autoDeleteForm.controls['delayDeleteEmailsFromSpam'].setValidators([Validators.required, outMinLimit(30), outMaxLimit(90)]);
      } else {
        this.autoDeleteForm.controls['delayDeleteEmailsFromSpam'].setValidators([]);
      }
      this.autoDeleteForm.controls['delayDeleteEmailsFromSpam'].markAsTouched();
      this.autoDeleteForm.controls['delayDeleteEmailsFromSpam'].updateValueAndValidity();
    });
  }

  public openInfoDialog(): void {
    this.dialog.open(InfoDialogComponent, {
      width: '450px',
      data: {
        title: 'info',
        name: 'changeAuthMethodInfoPopupMessage',
      },
    });
  }

  public openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: {
        title: 'confirmation',
        isDontNeedConfirmWord: true,
        name: 'changeAuthMethodInfoPopupMessage',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onSubmit();
      }
    });
  }

  public onSamlChange(isChecked: boolean) {
    if (isChecked) {
      this.form.controls['samlRtaClaim'].setValidators([Validators.required, Validators.maxLength(256)]);
      this.form.controls['samlRtaClaim'].enable();
      this.settings.googleOAuthSupport = false;
      this.settings.oidcSupport = false;
    } else {
      this.form.controls['samlRtaClaim'].disable();
      this.form.controls['samlRtaClaim'].setValidators([]);
    }
    this.form.controls['samlRtaClaim'].markAsTouched();
    this.form.controls['samlRtaClaim'].updateValueAndValidity();
  }

  public async loadSettings(): Promise<void> {
    this.failedSettingsLoading = false;
    this.settingsIsLoading = true;
    try {
      const organizationClaimRespone = await this.claimClient.getByIdRtaClaimByOrganizationId(new BaseGetByIdRequest(this.organizationId));
      this.samlClaims = organizationClaimRespone.result;

      const response = await this.organizationSettingsClient.getByOrganization(new BaseGetByIdRequest(this.organizationId));
      this.settings = response.result;

      this.settings.alertLevelSpaceUsed = this.settings.alertLevelSpaceUsed ?? 90;
      if (this.settings.defaultNotificationLanguage) this.locale.setValue(this.settings.defaultNotificationLanguage);
      else this.locale.setValue(null);
      if (this.settings.spaceUsedPaymentPlanType) this.spaceUsedPaymentPlan.setValue(this.settings.spaceUsedPaymentPlanType);
      else this.spaceUsedPaymentPlan.setValue(null);
      this.spaceUsedPaymentPlan.disable();
      if (!this.settings.adfsOAuthOptions) {
        this.settings.adfsOAuthOptions = new AdfsOAuthOptions();
      }
      if (!this.settings.archiveSettings) {
        this.settings.archiveSettings = new OrganizationArchiveSettings();
      }

      this.initAuthMethods = [
        this.settings.adfsSupport,
        this.settings.googleOAuthSupport,
        this.settings.oidcSupport,
        this.settings.samlSupport,
      ];

      this.initForm();
    } catch (e) {
      this.failedSettingsLoading = true;
      this.matchError.logError(e);
    } finally {
      this.settingsIsLoading = false;
    }
  }

  isAuthMethodsWasChanged() {
    return (
      this.initAuthMethods[0] !== this.settings.adfsSupport ||
      this.initAuthMethods[1] !== this.settings.googleOAuthSupport ||
      this.initAuthMethods[2] !== this.settings.oidcSupport ||
      this.initAuthMethods[3] !== this.settings.samlSupport
    );
  }

  async onSubmit(): Promise<any> {
    if (this.form.valid && this.autoDeleteForm.valid) {
      this.isLoading = true;
      this.failedUpdate = false;

      const updateRequest = new OrganizationSettingsUpdateRequest();
      updateRequest.archive = this.form.controls.archive.value;
      updateRequest.organizationId = this.organizationId;

      const newSettings = new OrganizationArchiveSettings();
      newSettings.licenseNumber = this.form.controls.licenseNumber.value;
      newSettings.licenseCode = this.form.controls.licenseCode.value;
      newSettings.prefix = this.form.controls.prefix.value || null;
      newSettings.postfixInFlow = this.form.controls.postfixInFlow.value || null;
      newSettings.postfixOutFlow = this.form.controls.postfixOutFlow.value || null;
      newSettings.count = this.form.controls.count.value;

      // const adsfOptions = new AdfsOAuthOptions();
      // adsfOptions.clientId = this.form.controls.clientId.value;
      // adsfOptions.authority = this.form.controls.authority.value;
      // adsfOptions.clientSecret = this.form.controls.clientSecret.value;
      // adsfOptions.redirectUri = this.form.controls.redirectUri.value;

      updateRequest.archiveSettings = newSettings;

      updateRequest.defaultNotificationLanguage = this.locale.value;
      updateRequest.spaceUsedPaymentPlanType = this.spaceUsedPaymentPlan.value;
      updateRequest.delayDeleteEmailsFromSpam = this.autoDeleteForm.controls.delayDeleteEmailsFromSpam.value;
      updateRequest.delayDeleteEmailsFromTrash = this.autoDeleteForm.controls.delayDeleteEmailsFromTrash.value;
      updateRequest.deleteEmailsFromSpam = this.autoDeleteForm.controls.deleteEmailsFromSpam.value;
      updateRequest.deleteEmailsFromTrash = this.autoDeleteForm.controls.deleteEmailsFromTrash.value;
      updateRequest.oidcSupport = this.settings.oidcSupport;
      updateRequest.adfsSupport = this.settings.adfsSupport;
      updateRequest.googleOAuthSupport = this.settings.googleOAuthSupport;
      updateRequest.alertSpaceUsed = this.settings.alertSpaceUsed;
      updateRequest.alertLevelSpaceUsed = this.settings.alertLevelSpaceUsed;
      updateRequest.samlSupport = this.settings.samlSupport;
      try {
        await this.organizationSettingsClient.createOrUpdate(updateRequest);
        if (this.settings.samlSupport) {
          const updateRequestSamlClaims = new SamlClaims();
          updateRequestSamlClaims.claimId = this.samlClaims ? this.samlClaims.claimId : null;
          updateRequestSamlClaims.claimCode = this.form.controls.samlRtaClaim.value;
          updateRequestSamlClaims.organizationId = this.organizationId;
          updateRequestSamlClaims.role = RoleType.Rta;
          await this.claimClient.createOrUpdateRtaClaimByOrganizationId(updateRequestSamlClaims);
        }
        this.updatingSubmit.emit();
        this.settingsService.setSettingsMaxSpace(SpaceUsedPaymentPlanDetailTypes[this.spaceUsedPaymentPlan.value].value);
        this.settingsService.setSettingsAlertLevel(this.settings.alertLevelSpaceUsed);
      } catch (e) {
        this.failedUpdate = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public hasErrorAutoDelete = (controlName: string, errorName: string) => {
    return this.autoDeleteForm.controls[controlName].hasError(errorName);
  };

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }

  public get authTypeIsSelected(): boolean {
    return this.settings.oidcSupport || this.settings.adfsSupport || this.settings.googleOAuthSupport || this.settings.samlSupport;
  }

  public proccessValueSpace() {
    if (this.settings.alertLevelSpaceUsed > 100) this.settings.alertLevelSpaceUsed = 100;
    if (this.settings.alertLevelSpaceUsed < 0) this.settings.alertLevelSpaceUsed = 0;
  }

  public proccessValueDays(event, field: string) {
    let processValue = !isNaN(+event.target.value) ? Math.floor(+event.target.value) : null;
    if (processValue === 0) processValue = null;
    this.autoDeleteForm.controls[field].setValue(processValue);
  }
}
