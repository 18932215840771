export abstract class BaseGetRequest {
  public format(): string {
    const str = [];
    for (const p in this) {
      if (this.hasOwnProperty(p) && this[p]) {
        // @ts-ignore
        str.push(`${encodeURIComponent(p)}=${encodeURIComponent(this[p])}`);
      }
    }
    return str.join('&');
  }
}
