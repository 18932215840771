import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseCollectionSearchByIdRequest } from '../../../../../sdk/contracts/common/base-collection-search-by-id.request';
import { UserOrganizationGetOrganizationUsersContract } from '../../../../../sdk/contracts/user-organization/user-organization.get-organization-users.contract';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import { MailAccountGetByOrganizationIdContract } from 'src/app/sdk/contracts/mail-account/mail-account.get-by-organization-id.contract';
import { UserMailAccountClient } from 'src/app/sdk/clients/user-mail-account.client';
import { MailAccountUserRequest } from 'src/app/sdk/contracts/user-mail-account/mail-account-user.request';
import { UserOrganizationClient } from 'src/app/sdk/clients/user-organization.client';
import { BaseGetByIdRequest } from 'src/app/sdk/contracts/common/base-get-by-id.request';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { RoleType } from 'src/app/sdk/contracts/role/role-type';
import { PermissionService } from 'src/app/services/permission/permission.service';

interface UserCheckboxes extends UserOrganizationGetOrganizationUsersContract {
  _checked: boolean;
  role: number;
}

interface Roles {
  name: string;
  value: RoleType;
}

@Component({
  selector: 'app-add-mail-accounts-to-users-form',
  templateUrl: './add-mail-accounts-to-users-form.component.html',
  styleUrls: ['./add-mail-accounts-to-users-form.component.scss'],
})
export class AddMailAccountsToUsersFormComponent implements OnInit {
  @Input() public mailAccounts: MailAccountGetByOrganizationIdContract[];
  @Input() private organizationID: number;
  @Output() private close = new EventEmitter();
  @Output() private submit = new EventEmitter();

  public handlingErrorCodes = new Map<number, string>([]);

  public cannotLoadUsers: boolean = false;
  public errorText: string = '';
  public isLoading: boolean = false;
  public isLoadingUsers: boolean = false;

  public roles: Roles[] = [
    {
      name: 'User',
      value: RoleType.User,
    },
    {
      name: 'SuperUser',
      value: RoleType.SuperUser,
    },
    {
      name: 'Creator',
      value: RoleType.Creator,
    },
    {
      name: 'Validator',
      value: RoleType.Validator,
    },
    {
      name: 'LimitedUser',
      value: RoleType.LimitedUser,
    },
    {
      name: 'LimitedCreator',
      value: RoleType.LimitedCreator,
    },
  ];

  public users: UserCheckboxes[] = [];
  public filteredUsersByAdvancedSettings: UserCheckboxes[] = [];

  public search: string = '';
  public filterList = [
    { label: 'allSelect', value: -1 },
    { label: 'showOnlySelectedSelect', value: 1 },
    { label: 'showOnlyNonSelectedSelect', value: 0 },
  ];
  public filters = new UntypedFormControl(this.filterList[0].value);

  public filteredUsers: UserCheckboxes[] = [];
  public dataSource = new MatTableDataSource<UserCheckboxes>(this.filteredUsers);
  public displayedColumns: string[] = ['select', 'user', 'role'];

  constructor(
    private userOrganizationClient: UserOrganizationClient,
    private userMailAccountClient: UserMailAccountClient,
    public dialog: MatDialog,
    public permissionService: PermissionService,
    public matchError: MatchError
  ) {}

  ngOnInit(): void {
    this.fetchUsers();
  }

  public async onSubmit() {
    this.isLoading = true;
    const mailAccounts = this.mailAccounts.map((mailAccount) => mailAccount.mailAccountId);
    const usersRoles = this.users
      .filter((item) => item._checked)
      .map((item) => {
        return {
          userId: item.userId,
          role: item.role,
        };
      });

    this.filteredUsersByAdvancedSettings.forEach((e) => {
      if (e._checked) {
        const user = { userId: e.userId, role: e.role };
        usersRoles.push(user);
      }
    });

    const request: MailAccountUserRequest = {
      mailAccounts,
      usersRoles,
    };

    try {
      await this.userMailAccountClient.setMailAccountUsers(request);
      this.submit.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  resetSearch() {
    this.search = '';
    this.dataSource.filter = this.search.trim().toLowerCase();
  }

  public onClose() {
    this.close.emit();
  }

  private async fetchUsers() {
    this.isLoadingUsers = true;
    try {
      const request = new BaseCollectionSearchByIdRequest(this.organizationID, 1, 500, '');
      const result = await this.userOrganizationClient.getOrganizationUsers(request);

      if (this.mailAccounts.length == 1) {
        const mailAccountUsers = await this.fetchMailAccountUsers();

        this.users = result.organizationUsers.map((user) => {
          let currItem = mailAccountUsers.find((item) => item.userId === user.userId);
          return {
            ...user,
            role: !!currItem ? this.roles.find((item) => item.value === currItem.roleId).value : this.roles[0].value,
            _checked: !!currItem,
          };
        });
      } else {
        this.users = result.organizationUsers.map((user) => {
          return { ...user, role: this.roles[0].value, _checked: false };
        });
      }

      this.users = this.users.filter((e) => {
        if (this.permissionService.hasPermissionToSeeUserByUserEmail(e.email)) {
          return true;
        } else {
          this.filteredUsersByAdvancedSettings.push(e);
          return false;
        }
      });

      this.filteredUsers = this.users;

      this.dataSource.data = this.filteredUsers;

      this.cannotLoadUsers = false;
    } catch (e) {
      this.cannotLoadUsers = true;
      this.matchError.logError(e);
    } finally {
      this.isLoadingUsers = false;
    }
  }

  private async fetchMailAccountUsers() {
    const req = new BaseGetByIdRequest(this.mailAccounts[0].mailAccountId);
    const mailAccountUsers = await this.userMailAccountClient.getMailAccountUsers(req);

    return mailAccountUsers.userMailAccounts;
  }

  isAllSelected() {
    const numSelected = this.users.filter((mailAccount) => mailAccount._checked).length;
    const numRows = this.users.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.users.forEach((user) => (user._checked = false)) : this.users.forEach((user) => (user._checked = true));
  }

  public hasSelected() {
    let hasSelected = false;
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i]._checked) {
        hasSelected = true;
        break;
      }
    }
    return hasSelected;
  }

  onFilterSelectionChange() {
    if (this.filters.value < 0) {
      this.filteredUsers = this.users;
      this.dataSource.data = this.filteredUsers;
    }

    if (this.filters.value == 0) {
      this.filteredUsers = this.users.filter((user) => !user._checked);
      this.dataSource.data = this.filteredUsers;
    }

    if (this.filters.value > 0) {
      this.filteredUsers = this.users.filter((user) => user._checked);
      this.dataSource.data = this.filteredUsers;
    }
  }
}
