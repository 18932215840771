import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseGetSearchCountByIdRequest } from '../../../../../sdk/contracts/common/base-get-search-count-by-id.request';
import { GroupClient } from '../../../../../sdk/clients/group-settings.client';
import { UserGroupClient } from '../../../../../sdk/clients/user-group.client';
import { BaseCollectionSearchByIdRequest } from '../../../../../sdk/contracts/common/base-collection-search-by-id.request';
import { Group } from '../../../../../sdk/contracts/group/group.contract';
import { UserGroupRequest } from '../../../../../sdk/contracts/user-group/user-group.request';
import { UserOrganizationGetOrganizationUsersContract } from '../../../../../sdk/contracts/user-organization/user-organization.get-organization-users.contract';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';

interface GroupCheckboxes extends Group {
  _checked: boolean;
}

@Component({
  selector: 'app-add-users-to-groups-form',
  templateUrl: './add-users-to-groups-form.component.html',
  styleUrls: ['./add-users-to-groups-form.component.scss'],
})
export class AddUsersToGroupsFormComponent implements OnInit {
  @Input() public users: UserOrganizationGetOrganizationUsersContract[];
  @Input() private organizationID: number;
  @Output() private close = new EventEmitter();
  @Output() private submit = new EventEmitter();

  public handlingErrorCodes = new Map<number, string>([]);

  public cannotLoadGroups: boolean = false;
  public errorText: string = '';
  public isLoading: boolean = false;
  public isLoadingGroups: boolean = false;

  public search: string = '';
  public filterList = [
    { label: 'allSelect', value: -1 },
    { label: 'showOnlySelectedSelect', value: 1 },
    { label: 'showOnlyNonSelectedSelect', value: 0 },
  ];
  public filters = new UntypedFormControl(this.filterList[0].value);

  public groups: GroupCheckboxes[] = [];
  public filteredGroups: GroupCheckboxes[] = [];

  constructor(
    private groupClient: GroupClient,
    public matchError: MatchError,
    private userGroupClient: UserGroupClient,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fetchGroups();
  }

  public async onSubmit() {
    this.isLoading = true;
    const sourceIds = this.users.map((user) => user.userId);
    const toIds = this.groups.filter((group) => group._checked).map((group) => group.groupId);
    const request: UserGroupRequest = {
      sourceIds,
      toIds,
    };

    try {
      await this.userGroupClient.setUsersGroups(request);
      this.submit.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public onClose() {
    this.close.emit();
  }

  private async fetchGroups() {
    this.isLoadingGroups = true;
    try {
      const totalReq = new BaseGetSearchCountByIdRequest(this.organizationID, '');
      const total = await this.groupClient.getSearchCounter(totalReq);
      const req: BaseCollectionSearchByIdRequest = new BaseCollectionSearchByIdRequest(this.organizationID, 1, 500, '');
      const res = await this.groupClient.getAllByOrganization(req);

      if (this.users.length == 1) {
        const userGroups = await this.getUserGroups();

        this.groups = res.data.map((group) => {
          return { ...group, _checked: userGroups.includes(group.groupId) };
        });
      } else {
        this.groups = res.data.map((group) => {
          return { ...group, _checked: false };
        });
      }

      this.filteredGroups = this.groups;
      this.cannotLoadGroups = false;
    } catch (e) {
      this.cannotLoadGroups = true;
      this.matchError.logError(e);
    } finally {
      this.isLoadingGroups = false;
    }
  }

  private async getUserGroups() {
    const req = new BaseGetByIdRequest(this.users[0].userId);
    const userGroups = await this.userGroupClient.getUserGroups(req);
    return userGroups.data;
  }

  isAllSelected() {
    const numSelected = this.groups.filter((group) => group._checked).length;
    const numRows = this.groups.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.groups.forEach((group) => (group._checked = false))
      : this.groups.forEach((group) => (group._checked = true));
  }

  public hasSelected() {
    let hasSelected = false;
    for (let i = 0; i < this.groups.length; i++) {
      if (this.groups[i]._checked) {
        hasSelected = true;
        break;
      }
    }
    return hasSelected;
  }

  onFilterSelectionChange() {
    if (this.filters.value < 0) {
      this.filteredGroups = this.groups;
    }

    if (this.filters.value == 0) {
      this.filteredGroups = this.groups.filter((group) => !group._checked);
    }

    if (this.filters.value > 0) {
      this.filteredGroups = this.groups.filter((group) => group._checked);
    }
  }
}
