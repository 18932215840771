import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { MailFolderSettingsContract } from '../contracts/mail-folder/settings/mail-folder-settings.contract';
import { MailFolderSettingsGetByMailFolderRequest } from '../contracts/mail-folder/settings/mail-folder-settings.get-by-mailfolder.request';
import { MailFolderSettingsUpdateRequest } from '../contracts/mail-folder/settings/mail-folder-settings.update.request';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class MailFolderSettingsClient {
  constructor(private apiRequest: ApiRequest) {}

  async getByMailFolder(request: MailFolderSettingsGetByMailFolderRequest): Promise<BaseGetByIdResponse<MailFolderSettingsContract>> {
    return this.apiRequest.get<BaseGetByIdResponse<MailFolderSettingsContract>>(
      `${API_URL}${ApiRoutes.mailFolderSettings.getByMailFolder}?${request.format()}`
    );
  }

  async createOrUpdate(request: MailFolderSettingsUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<MailFolderSettingsUpdateRequest, BaseUpdateResponse>(
      request,
      `${API_URL}${ApiRoutes.mailFolderSettings.createOrUpdate}`
    );
  }
}
