import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ProviderContract } from '../../../sdk/contracts/provider/provider.contract';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarHelper } from '../../../shared/helpers/snackbar.helper';
import { ProviderClient } from '../../../sdk/clients/provider.client';
import { BreadcrumbItem } from '../../../shared/breadcrumb-item';
import { TabItem } from '../../../shared/tab-item';
import { PermissionNavTabHelper } from '../../../services/permission/permission-nav-tab-helper';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProviderDeleteDialogComponent } from './provider-delete-dialog/provider-delete-dialog.component';
import { MobileObserverService } from '../../../services/adaptive/mobile-observer.service';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss'],
})
export class ProviderComponent implements OnInit, AfterViewInit {
  public providers: ProviderContract[] = [];
  public dataSource = new MatTableDataSource<ProviderContract>(this.providers);
  public displayedColumns: string[] = ['name', 'smtp', 'smtp-port', 'imap', 'imap-port', 'actions'];
  public isLoading = false;
  public isError = false;
  public breadcrumbItems: BreadcrumbItem[];
  public tabItems: TabItem[] = [];
  public createDrawerIsOpen = false;
  public updateDrawerIsOpen = false;
  public updatingProvider: ProviderContract;
  public useMobileView = false;
  public webCode: string;
  public organizationId: number;

  constructor(
    public matchError: MatchError,
    protected router: Router,
    private snackbarHelper: SnackbarHelper,
    private providerClient: ProviderClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    public dialog: MatDialog,
    private mobileObserverService: MobileObserverService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));

    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.loadNavigation();
    this.tabItems = this.permissionNavTabHelper.getSettingPageTabs(this.webCode);
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    await this.loadProviders();
  }

  public loadNavigation(): void {
    this.breadcrumbItems = [
      { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
      { index: 1, label: 'providersBreadCrumbs', route: null },
    ];
  }

  ngAfterViewInit(): void {}

  async loadProviders(): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    try {
      const response = await this.providerClient.search();
      this.providers = response.providerContracts;
      this.dataSource.data = this.providers;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async deleteProvider(providerId: number, providerName: string): Promise<void> {
    const dialogRef = this.dialog.open(ProviderDeleteDialogComponent, {
      width: '350px',
      data: { providerId, providerName },
    });

    dialogRef.afterClosed().subscribe(async (x) => {
      if (x.isDeleted) {
        this.providers = this.providers.filter((p) => p.providerId !== providerId);
        this.dataSource.data = this.providers;
      }
    });
  }

  async onCreateProviderBtnClicked(): Promise<void> {
    this.createDrawerIsOpen = true;
  }

  onCloseCreateModalForm(): void {
    this.createDrawerIsOpen = false;
  }

  async onCreateProvider(): Promise<void> {
    this.createDrawerIsOpen = false;
    await this.loadProviders();
  }

  openUpdateProviderDialog(providerContract: ProviderContract): void {
    this.updateDrawerIsOpen = true;
    this.updatingProvider = providerContract;
  }

  onCloseUpdatingModalForm(): void {
    this.updateDrawerIsOpen = false;
    this.updatingProvider = null;
  }

  async onUpdateProvider(): Promise<void> {
    this.updateDrawerIsOpen = false;
    this.updatingProvider = null;
    await this.loadProviders();
  }
}
