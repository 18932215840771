<div class="drawer-content-screen flex flex-col justify-between">
  <!-- <div class="container h-full"> -->
  <div *ngIf="!settingsIsLoading && !failedSettingsLoading" class="text-2xl mb-10 font-medium">
    {{ "companySettings" | translate }}
  </div>
  <div *ngIf="settingsIsLoading" class="flex flex-col justify-center h-full">
    <app-loader></app-loader>
  </div>
  <div *ngIf="failedSettingsLoading" class="flex flex-col justify-center h-full">
    <app-something-went-wrong
      [showPicture]="false"
      description="{{ 'cannotLoadCompanySettings' | translate }}"
      (retryEmitter)="failedUpdate ? onSubmit() : loadSettings()">
    </app-something-went-wrong>
  </div>
  <!-- <div *ngIf="failedUpdate" class="flex flex-col justify-center h-full">
      <app-something-went-wrong [showPicture]="false" description="Cannot update company settings: something went wrong"
        (retryEmitter)="loadSettings()">
      </app-something-went-wrong>
    </div> -->
  <div class="overflow-auto flex flex-col h-full" *ngIf="!settingsIsLoading && !failedSettingsLoading">
    <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
      <div class="font-medium text-lg mb-3">{{ "archivingSettings" | translate }}</div>
      <div class="pb-3">
        <mat-checkbox formControlName="archive" color="primary">{{ "archive" | translate }}</mat-checkbox>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>{{ "prefix" | translate }}</mat-label>
        <input maxlength="1" matInput id="prefix" formControlName="prefix" />
        <mat-error *ngIf="hasError('prefix', 'maxlength')">{{ "moreThanOneChar" | translate }}</mat-error>
        <mat-error *ngIf="hasError('prefix', 'required')">{{ "prefixIsRequired" | translate }}</mat-error>
      </mat-form-field>
      <!--        <div class="flex w-full">-->
      <!--          <div class="w-1/2 pr-5">-->
      <!--            <mat-form-field appearance="fill">-->
      <!--              <mat-label>License number</mat-label>-->
      <!--              <input matInput id="licenseNumber" formControlName="licenseNumber">-->
      <!--            </mat-form-field>-->
      <!--          </div>-->
      <!--          <div class="w-1/2">-->
      <!--            <mat-form-field appearance="fill">-->
      <!--              <mat-label>License code</mat-label>-->
      <!--              <input matInput id="licenseCode" formControlName="licenseCode">-->
      <!--            </mat-form-field>-->
      <!--          </div>-->
      <!--        </div>-->
      <div class="flex w-full">
        <div class="w-1/2 pr-5">
          <mat-form-field appearance="fill">
            <mat-label>{{ "outFlowPostfix" | translate }}</mat-label>
            <input maxlength="1" matInput id="postfixOutFlow" formControlName="postfixOutFlow" />
            <mat-error *ngIf="hasError('postfixOutFlow', 'maxlength')">{{ "moreThanOneChar" | translate }}</mat-error>
            <mat-error *ngIf="hasError('postfixOutFlow', 'required')">{{ "outFlowPostfixIsRequired" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="w-1/2">
          <mat-form-field appearance="fill">
            <mat-label>{{ "inFlowPostfix" | translate }}</mat-label>
            <input maxlength="1" matInput id="postfixInFlow" formControlName="postfixInFlow" />
            <mat-error *ngIf="hasError('postfixInFlow', 'maxlength')">{{ "moreThanOneChar" | translate }}</mat-error>
            <mat-error *ngIf="hasError('postfixInFlow', 'required')">{{ "inFlowPostfixIsRequired" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>{{ "batchSize" | translate }}</mat-label>
        <input type="number" min="0" max="200" matInput id="count" formControlName="count" />
        <mat-error *ngIf="hasError('count', 'pattern')">Must be only integers</mat-error>
        <mat-error *ngIf="hasError('count', 'max')">{{ "lessThan200" | translate }}</mat-error>
      </mat-form-field>
      <!--        <mat-form-field appearance="fill">-->
      <!--          <mat-label>ClientId</mat-label>-->
      <!--          <input matInput id="clientid" formControlName="clientId">-->
      <!--        </mat-form-field>-->
      <!--        <mat-form-field appearance="fill">-->
      <!--          <mat-label>Authority</mat-label>-->
      <!--          <input matInput id="authority" formControlName="authority">-->
      <!--        </mat-form-field>-->
      <!--        <mat-form-field appearance="fill">-->
      <!--          <mat-label>Client Secret</mat-label>-->
      <!--          <input matInput id="clientsecret" formControlName="clientSecret">-->
      <!--        </mat-form-field>-->
      <!--        <mat-form-field appearance="fill">-->
      <!--          <mat-label>RedirectUri</mat-label>-->
      <!--          <input matInput id="redirecturi" formControlName="redirectUri">-->
      <!--        </mat-form-field>-->
      <div class="flex flex-col">
        <div class="font-medium text-lg mb-3 mt-6">{{ "authSettings" | translate }}</div>

        <div *ngIf="true">
          <div>
            <mat-checkbox
              [disabled]="settings.samlSupport"
              color="primary"
              [(ngModel)]="settings.oidcSupport"
              [ngModelOptions]="{ standalone: true }"
              >{{ "GAW" | translate }}</mat-checkbox
            >
          </div>
          <!-- <mat-checkbox color="primary" [disabled]="true" [(ngModel)]="settings.adfsSupport"
              [ngModelOptions]="{standalone: true}" [disableRipple]="true">Credem ADFS</mat-checkbox> -->
          <div>
            <mat-checkbox
              [disabled]="settings.samlSupport"
              color="primary"
              [(ngModel)]="settings.googleOAuthSupport"
              [ngModelOptions]="{ standalone: true }"
              [disableRipple]="true"
              >{{ "google" | translate }}</mat-checkbox
            >
          </div>
        </div>
        <div *ngIf="true">
          <mat-checkbox
            color="primary"
            (change)="onSamlChange($event.checked)"
            [(ngModel)]="settings.samlSupport"
            [ngModelOptions]="{ standalone: true }"
            [disableRipple]="true"
            >{{ "saml" | translate }}</mat-checkbox
          >
          <div>
            <mat-form-field appearance="fill">
              <mat-label>{{ "samlRtaClaim" | translate }}</mat-label>
              <input matInput id="samlRtaClaim" formControlName="samlRtaClaim" />
              <mat-error *ngIf="hasError('samlRtaClaim', 'required')">{{ "samlRtaClaimIsRequired" | translate }}</mat-error>
              <mat-error *ngIf="hasError('samlRtaClaim', 'maxlength')">{{ "moreThan265Char" | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="!authTypeIsSelected" class="mt-2">
          <app-error-hint errorText="{{ 'selectAtLeastOneTypeOfAuthenticationErrorHint' | translate }}" [centerAlign]="false">
          </app-error-hint>
        </div>
      </div>
    </form>
    <div class="notification-lang">
      <div class="font-medium text-lg mb-3 mt-6" >{{ "defaultNotificationLanguage" | translate }}</div>
      <div class="notification-lang__body">
        <mat-form-field>
          <mat-label>{{ "language" | translate }}</mat-label>
        <mat-select id="locale" [formControl]="locale">
          <ng-container *ngFor="let item of locales">
            <mat-option *ngIf="item.id !== 'fr' && item.id !== 'de'" [value]="item.id">{{item.language}}</mat-option>
          </ng-container>
        </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="space-alert">
      <div class="font-medium text-lg mb-3 mt-6" >{{ "alertOccupiedSpace" | translate }}</div>
      <div class="space-alert__body">
        <div class="space-alert__line">
          <span class="pt-[10px]"> {{ "totalSpaceOccupiesPerCompany" | translate }}</span>
          <div class="space-alert__plan-cell space-alert__cell">
            <mat-form-field class="space-alert__mat-form-field">
              <mat-label>{{ "spacePaymentPlan" | translate }}</mat-label>
              <mat-select id="spaceUsedPaymentPlan" [formControl]="spaceUsedPaymentPlan">
                <mat-option *ngFor="let item of spaceUsedPaymentPlanType" [value]="item.value">{{item.viewValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="space-alert__line">
          <p> {{ "definitionForAvailableSpaceWarning" | translate }}</p>
          <div class="inline-block whitespace-nowrap">
            <mat-form-field class="space-alert__number-input space-alert__cell">
              <input 
                type="number" 
                matInput 
                [(ngModel)]="settings.alertLevelSpaceUsed"
                step="1"
                min="0"
                [max]="100"
                (change)="proccessValueSpace()"
                id="myNumber"  />
            </mat-form-field>
            %
          </div>
        </div>
  <div class="space-alert__subtext"> 
    {{"thresholdForExtraGB" | translate}} 
  </div>
  <div class="space-alert__check-box">
      <mat-checkbox
        class="mat-checkbox-inner-container"
        color="primary"
        [(ngModel)]="settings.alertSpaceUsed"
        [ngModelOptions]="{ standalone: true }"
      ></mat-checkbox>
      {{"thresholdAlerting" | translate}}
    </div>   
    <div class="space-alert__subtext"> 
      {{"emailAlertToRTA" | translate}} </div>
    </div> 
    <div class="auto-delete">
      <form [formGroup]="autoDeleteForm" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
      <div class="font-medium text-lg mb-3 mt-6" >{{ "finalDeletionTitle" | translate }}</div>
      <div class="auto-delete__body relative">
        <mat-checkbox
          class="mat-checkbox-inner-container"
          color="primary"
          formControlName ="deleteEmailsFromTrash"
          [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox
        >
        {{"empty" | translate}}
        <b>{{"trashUpperCase" | translate}}</b>
        {{"folderAfter" | translate}}   
          <mat-form-field
            class="auto-delete__number-input" 
            [class.auto-delete__number-input-wrapper]="autoDeleteForm.controls['delayDeleteEmailsFromTrash'].valid">
            <input 
              class="mb-0"
              type="number" 
              matInput 
              formControlName ="delayDeleteEmailsFromTrash"
              (input)="proccessValueDays($event, 'delayDeleteEmailsFromTrash')"
              step="1"
              min="0"
              />
          </mat-form-field>
        {{"days" | translate}}
        <div class="auto-delete__error-wrapper" 
          *ngIf="autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors 
            && autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors['required']">
            {{ "fieldRequired" | translate }}
        </div>
        <div class="auto-delete__error-wrapper" 
          *ngIf="autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors 
            && !autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors['required']
            && autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors['lowerLimit']">
            {{ "valueLess" | translate }}
        </div>
        <div class="auto-delete__error-wrapper" 
          *ngIf="autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors 
            && autoDeleteForm.controls['delayDeleteEmailsFromTrash'].errors['higherLimit']">
            {{ "valueMore" | translate }}
        </div>
      </div>
      <div class="auto-delete__body relative">
        <mat-checkbox
          class="mat-checkbox-inner-container"
          color="primary"
          formControlName ="deleteEmailsFromSpam"
          [ngModelOptions]="{ standalone: true }"
          ></mat-checkbox
        >
        {{"empty" | translate}}
        <b>{{"spamUpperCase" | translate}}</b>
        {{"folderAfter" | translate}}    
          <mat-form-field
            class="auto-delete__number-input" 
            [class.auto-delete__number-input-wrapper]="autoDeleteForm.controls['delayDeleteEmailsFromSpam'].valid">
            <input
              class="mb-0" 
              type="number" 
              matInput 
              formControlName ="delayDeleteEmailsFromSpam"
              (input)="proccessValueDays($event, 'delayDeleteEmailsFromSpam')"
              step="1"
              min="0"
              />
          </mat-form-field>
        {{"days" | translate}}
        <div class="auto-delete__error-wrapper" 
          *ngIf="autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors 
            && autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors['required']">
            {{ "fieldRequired" | translate }}
        </div>
        <div class="auto-delete__error-wrapper" 
          *ngIf="autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors 
            && !autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors['required']
            && autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors['lowerLimit']">
            {{ "valueLess" | translate }}
        </div>
        <div class="auto-delete__error-wrapper" 
          *ngIf="autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors 
            && autoDeleteForm.controls['delayDeleteEmailsFromSpam'].errors['higherLimit']">
            {{ "valueMore" | translate }}
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!settingsIsLoading && !failedSettingsLoading" class="flex justify-end">
    <button
      mat-flat-button
      color="primary"
      [disabled]="isLoading || !form.valid || !autoDeleteForm.valid || !authTypeIsSelected"
      (click)="isAuthMethodsWasChanged() ? openConfirmDialog() : onSubmit()">
      <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</div>
<!-- <div *ngIf="!settingsIsLoading && !failedSettingsLoading" class="w-full"> -->
<!-- <div *ngIf="failedUpdate" class="mb-3">
      <app-error-hint errorText="Cannot update company settings: something went wrong"></app-error-hint>
    </div> -->
<!-- </div> -->
<!-- </div> -->
