import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { OrganizationSettingsContract } from '../contracts/organization/settings/organization-settings.contract';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { OrganizationSettingsUpdateRequest } from '../contracts/organization/settings/organization-settings.update.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { OrganizationAdfsUrl } from '../contracts/organization/settings/organization-adfs-url';

const API_URL = environment.apiUrl;

@Injectable()
export class OrganizationSettingsClient {
  constructor(private apiRequest: ApiRequest) {}

  async getByOrganization(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<OrganizationSettingsContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.organizationSettings.getByOrganization}?${request.format()}`);
  }

  async getOrganizationsWithAdfs(): Promise<BaseGetCollectionResponse<OrganizationAdfsUrl>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.organizationSettings.getOrganizationsWithAdfs}`);
  }

  async createOrUpdate(request: OrganizationSettingsUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<OrganizationSettingsUpdateRequest, BaseUpdateResponse>(
      request,
      `${API_URL}${ApiRoutes.organizationSettings.createOrUpdate}`
    );
  }
}
