export class UpdateTemplateRequest {
  templateId: number;
  mailAccountId: number;
  name: string;
  description: string;
  subject: string;
  body: string;

  constructor(templateId: number, mailAccountId: number, name: string, description: string, subject: string, body: string) {
    this.templateId = templateId;
    this.mailAccountId = mailAccountId;
    this.name = name;
    this.description = description;
    this.subject = subject;
    this.body = body;
  }
}
