import { Component, OnInit } from '@angular/core';
import { OrganizationContract } from '../../sdk/contracts/organization/organization.contract';

@Component({
  selector: 'app-default-page',
  templateUrl: './default-page.component.html',
  styleUrls: ['./default-page.component.scss'],
})
export class DefaultPageComponent implements OnInit {
  public links: OrganizationContract[] = [];

  constructor() {}

  ngOnInit(): void {
    this.links = JSON.parse(localStorage.getItem('organizations'));
  }

  public get currentUrl(): string {
    return document.URL;
  }
}
