import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-internal-error-page',
  templateUrl: './internal-error-page.component.html',
  styleUrls: ['./internal-error-page.component.scss'],
})
export class InternalErrorPageComponent implements OnInit {
  public webCode: string;

  constructor(private cookieService: CookieService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.webCode = this.route.snapshot.paramMap.get('webCode');
  }
}
