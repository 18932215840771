import { Component, OnInit } from '@angular/core';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { ApiException } from '../../../sdk/api-errors/api.exception';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarHelper } from '../../../shared/helpers/snackbar.helper';
import { EmailClient } from '../../../sdk/clients/email-client';
import { EmailContract } from '../../../sdk/contracts/Email/email.contract';
import { DomSanitizer } from '@angular/platform-browser';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-email-print',
  templateUrl: './email-print.component.html',
  styleUrls: ['./email-print.component.scss'],
})
export class EmailPrintComponent implements OnInit {
  public emailId: number;
  public mailAccountId: number;
  public mailFolderId: number;
  public showLoader: boolean;
  public emailDetails = new EmailContract();
  public body: string;

  constructor(
    public matchError: MatchError,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private snackbarHelper: SnackbarHelper,
    private emailClient: EmailClient,
    private sanitizer: DomSanitizer
  ) {
    this.mailAccountId = parseInt(activatedRoute.snapshot.params.mailAccountId, 10);
    this.mailFolderId = parseInt(activatedRoute.snapshot.params.mailFolderId, 10);
    this.emailId = parseInt(activatedRoute.snapshot.params.emailId, 10);
  }

  async ngOnInit(): Promise<any> {
    await this.getEmailDetail();
    await this.print();
  }

  async getEmailDetail(): Promise<void> {
    this.showLoader = true;
    const request = new BaseGetByIdRequest(this.emailId);
    try {
      const result = await this.emailClient.getById(request);
      this.emailDetails = result.email;
      this.showLoader = false;
    } catch (e) {
      if (e instanceof ApiException) {
        this.showLoader = false;
        this.snackbarHelper.openSnackBar(e.format());
      } else {
        this.showLoader = false;
        throw e;
      }
      this.matchError.logError(e);
    }
  }

  private async sleep(ms: number): Promise<any> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private async print(): Promise<any> {
    await this.sleep(3000);
    const printContent = document.getElementById('print-div-id');
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    if (WindowPrt) {
      WindowPrt.document.write(printContent.innerHTML);
      WindowPrt.document.close();
      WindowPrt.focus();
      WindowPrt.print();
      WindowPrt.close();
    }
  }
}
