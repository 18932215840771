<div class="h-screen w-screen flex justify-center flex-wrap content-center p-5">
  <div class="h-2/3 text-center">
    <div class="font-medium text-3xl mb-5">{{ "noPermission" | translate }}</div>
    <div class="text-gray-700 text-lg mb-5">
      {{ "youDontHavePermission" | translate }}
      <br />
      <div *ngIf="canRedirectToHomePage || returnUrl" class="flex justify-center">
        {{ "youCanGoBack" | translate }}&nbsp;
        <div *ngIf="canRedirectToHomePage && returnUrl">
          <a class="error__link" [routerLink]="returnUrl">{{ "previous" | translate }}</a>
          {{ "or" | translate }}
          <a class="error__link" [routerLink]="homeUrl">{{ "home" | translate }}</a>
          {{ "pages" | translate }}
        </div>
        <div *ngIf="canRedirectToHomePage && !returnUrl">
          <a class="error__link" [routerLink]="homeUrl">{{ "home" | translate }}</a>
          {{ "page" | translate }}
        </div>
        <div *ngIf="!canRedirectToHomePage && returnUrl">
          <a class="error__link" [routerLink]="returnUrl">{{ "previous" | translate }}</a>
          {{ "page" | translate }}
        </div>
      </div>
    </div>
    <img class="error-img" src="../../../../assets/403.svg" alt="access-denied" />
    <button (click)="signout()" mat-raised-button color="primary">logout</button>
  </div>
</div>
