<app-drawer
  [title]="menuTitle"
  [showBackground]="false"
  [isOpen]="createOrUpdateDrawerIsOpen"
  (closeEventEmitter)="closeCreateOrUpdateModalForm()"
  [width]="'width: 70%'">
  <app-email-create-or-update
    *ngIf="createOrUpdateDrawerIsOpen"
    [mailAccountId]="mailAccountId"
    [emailId]="updatedEmailId"
    [useMobileView]="useMobileView"
    [isDrawerClosing]="isDrawerClosing"
    (submitted)="closeCreateOrUpdateModalForm()">
  </app-email-create-or-update>
</app-drawer>

<app-drawer
  [title]="openedEmail?.subject"
  [showBackground]="false"
  [isOpen]="emailDrawerIsOpen"
  (closeEventEmitter)="closeEmailModalForm()"
  [width]="'width: 70%'">
  <app-email-detail-dialog
    *ngIf="emailDrawerIsOpen"
    [emailId]="openedEmail.emailId"
    (close)="closeEmailModalForm()"
    [useMobileView]="useMobileView">
  </app-email-detail-dialog>
</app-drawer>

<app-drawer *ngIf="useMobileView" [isOpen]="menuIsOpened" (closeEventEmitter)="closeMenu()">
  <div class="w-full h-full flex flex-col relative">
    <div class="absolute top-[-15px] right-[-15px] h-12 w-12 z-20"></div>
    <div class="w-full flex mb-2 bg-white z-20">
      <div class="flex cursor-pointer items-center" (click)="closeMenu()">
        <button mat-icon-button class="menu-close-btn pr-3 mt-[2px]">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <div class="text-xl font-medium">{{ menuHeader | translate }}</div>
      </div>
    </div>

    <div class="flex-grow w-full px-1 overflow-y-auto">
      <div *ngIf="menuContent == menuContentTypes.Attachments" class="menu__attachments">
        <div
          *ngFor="let file of emailDetails.attachmentFiles"
          [style.cursor]="!isFileDownloading(file.fileId) ? 'pointer' : 'default'"
          (click)="!isFileDownloading(file.fileId) && getAttachmentFile(file)"
          class="menu__attachment">
          <file-icon set="classic" size="md" [type]="getFileExtension(file.fileName)"></file-icon>
          <div
            [style.color]="!isFileDownloading(file.fileId) ? 'black' : 'grey'"
            [style.cursor]="!isFileDownloading(file.fileId) ? 'pointer' : 'default'"
            class="menu__filename filename-label">
            {{ file.fileName }}
          </div>
          <mat-progress-spinner
            style="width: 24px; margin-left: 3px"
            *ngIf="isFileDownloading(file.fileId)"
            mode="indeterminate"
            diameter="16"></mat-progress-spinner>
        </div>
      </div>

      <div *ngIf="menuContent == menuContentTypes.Notes" class="h-full">
        <app-email-note-list [emailId]="emailId"></app-email-note-list>
      </div>

      <div class="h-full audits" *ngIf="menuContent == menuContentTypes.Audit">
        <app-email-audit [emailId]="emailId"></app-email-audit>
      </div>

      <div *ngIf="menuContent == menuContentTypes.Notifications" class="h-full">
        <app-email-notifications-dialog [emailId]="emailId" [webCode]="webCode"> </app-email-notifications-dialog>
      </div>
    </div>
  </div>
</app-drawer>

<div (click)="checkingTheActiveElement()" class="flex justify-between h-full">
  <div *ngIf="cannotLoadEmailDetails" class="h-full w-full">
    <app-something-went-wrong
      description="{{ 'cannotLoadData' | translate }}"
      [showPicture]="false"
      (retryEmitter)="ngOnInit()"></app-something-went-wrong>
  </div>
  <div class="canvas" *ngIf="isLoading">
    <app-loader height="100%"></app-loader>
  </div>
  <div *ngIf="!isLoading && !cannotLoadEmailDetails" class="w-full h-full flex flex-col" style="overflow-x: clip">
    <div class="canvas" *ngIf="cannotLoadEmailDetails">
      <app-something-went-wrong
        [showPicture]="false"
        description="Cannot load email"
        (retryEmitter)="getEmailDetail()"></app-something-went-wrong>
    </div>
    <div class="flex relative">
      <span class="absolute custom-top">{{ "from" | translate }}</span>
      <span class="subject">{{ emailDetails.from }}</span>
    </div>
    <div class="flex relative">
      <span class="absolute custom-top">{{ "to" | translate }}</span>
      <span style="overflow-wrap: anywhere; max-height: 80px; overflow: auto" class="subject">{{ formatTo(emailDetails.to) }}</span>
    </div>
    <div *ngIf="emailDetails.cc" class="flex relative">
      <span class="absolute custom-top">{{ "cc" | translate }}</span>
      <span style="overflow-wrap: anywhere; max-height: 80px; overflow: auto" class="subject">{{ formatTo(emailDetails.cc) }}</span>
    </div>
    <div *ngIf="emailDetails.subject" class="flex relative">
      <span class="absolute custom-top">{{ "subject" | translate }}</span>
      <span style="overflow-wrap: anywhere; max-height: 80px; overflow: auto" class="subject">{{ emailDetails.subject }}</span>
    </div>
    <div class="flex relative">
      <span class="absolute custom-top">{{ "date" | translate }}</span>
      <span class="subject">{{ getDate(emailDetails) | date : "dd/MM/yyyy HH:mm:ss" }}</span>
    </div>
    <div *ngIf="haveAttachments()" class="flex relative min-h-[40px]">
      <span class="absolute custom-top">{{ "attach" | translate }}</span>
      <div class="subject attachments" type="text">
        <div class="flex flex-wrap">
          <div class="file" *ngFor="let file of emailDetails.attachmentFiles">
            <!-- <div class="main__attachment mr-1" [style.cursor]="'default'">
              <file-icon
                [style.cursor]="'default'"
                style="width: 16px"
                set="classic"
                style="font-size: '14px'"
                [type]="getFileExtension(file.fileName)"></file-icon>
            </div> -->
            <!-- <mat-progress-spinner
              *ngIf="file.fileState === attachmentState.CheckInProgress || file.fileState === attachmentState.Uploaded"
              style="width: 24px; margin-right: 3px; color: green; cursor: default"
              mode="indeterminate"
              [matTooltip]="'scaningForVirus' | translate"
              diameter="16"></mat-progress-spinner> -->
            <mat-icon
              [matTooltip]="'scaningForVirus' | translate"
              *ngIf="file.fileState === attachmentState.CheckInProgress || file.fileState === attachmentState.Uploaded"
              style="transform: scale(0.65); color: rgb(233, 162, 21); cursor: default"
              mat-icon-button>
              bolt
            </mat-icon>
            <span
              (click)="!isFileDownloading(file.fileId) && getAttachmentFile(file)"
              [style.color]="!isFileDownloading(file.fileId) ? '' : 'grey'"
              class="attach-text"
              [style.cursor]="!isFileDownloading(file.fileId) ? 'pointer' : 'default'">
              {{ file.fileName }}
            </span>
            <mat-progress-spinner
              style="width: 24px; margin-left: 3px"
              *ngIf="isFileDownloading(file.fileId)"
              mode="indeterminate"
              diameter="16"></mat-progress-spinner>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        #tags
        [ngStyle]="{ 'max-height': isCurrentElementTags ? '165px' : '60px' }"
        class="flex relative w-full tags-wrapper-detail mb-[5px]">
        <span class="absolute custom-top">{{ "tags" | translate }}</span>
        <app-tags [emailId]="[emailId]" [existedTags]="existedTags" [thisTags]="thisTags"></app-tags>
      </div>
    </div>

    <div class="canvas page-block h-full mt-[-1px] min-h-[100px]" style="overflow: auto">
      <!-- <div class="px-2">
      <div class="text-xs text-gray-600">
        <ng-container *ngIf="showAdditionalInfo">
          <div *ngIf="emailDetails.cc">Cc: {{ emailDetails.cc }}</div>
          <div *ngIf="emailDetails.bcc">Bcc: {{ emailDetails.bcc }}</div>
          <div>State: {{ emailDetails.state | emailState | lowercase }}</div>
        </ng-container>
      </div>
    </div> -->

      <ckeditor #editor disabled="{{ true }}" [editor]="Editor" data="{{ body }}" (ready)="onEditorReady($event)"></ckeditor>
      <!-- <div [innerHTML]="body | keepHtml" ></div> -->
      <!-- <ckeditor #editor [editor]="Editor" [(ngModel)]="emailDetails.body" data={{emailDetails.body}}></ckeditor> -->
    </div>
    <div
      style="overflow: initial"
      class="icon-button-group mr-2 flex justify-between items-center h-[80px] mt-2 overflow-hidden"
      *ngIf="!useMobileView"
      [appDisable]="isLoading || cannotLoadEmailDetails">
      <div class="flex icon-button-group mr-2">
        <app-loading-icon-button
          label="reply"
          icon="reply_outline"
          [showButton]="
            !isReplying &&
            showIcon([
              mailFolderType.Inbox,
              mailFolderType.Sent,
              mailFolderType.Validation,
              mailFolderType.Spam,
              mailFolderType.Trash,
              mailFolderType.Archive
            ]) &&
            emailDetails.state !== 7
          "
          [showLoading]="isReplying"
          [disabled]="isForwarding || isReplyingAll"
          (onClick)="reply()">
        </app-loading-icon-button>

        <app-loading-icon-button
          label="replyAll"
          icon="reply_all_outline"
          [showButton]="
            !isReplyingAll &&
            showIcon([
              mailFolderType.Inbox,
              mailFolderType.Sent,
              mailFolderType.Validation,
              mailFolderType.Spam,
              mailFolderType.Trash,
              mailFolderType.Archive
            ]) &&
            emailDetails.state !== 7
          "
          [showLoading]="isReplyingAll"
          [disabled]="isReplying || isForwarding"
          (onClick)="replyAll()">
        </app-loading-icon-button>
        <app-loading-icon-button
          label="forward"
          icon="shortcut"
          [showButton]="
            !isForwarding &&
            showIcon([
              mailFolderType.Inbox,
              mailFolderType.Sent,
              mailFolderType.Validation,
              mailFolderType.Spam,
              mailFolderType.Trash,
              mailFolderType.Archive
            ]) &&
            emailDetails.state !== 7
          "
          [showLoading]="isForwarding"
          [disabled]="isReplying || isReplyingAll"
          (onClick)="forward()">
        </app-loading-icon-button>
        <div *ngIf="canValidate && emailDetails.state === 7">
          <div class="button-group flex items-center">
            <button
              color="primary"
              class="page-block-button"
              *ngIf="canValidate && emailDetails.state === 7"
              mat-flat-button
              aria-label="Approve"
              (click)="approve()"
              [disabled]="approveInProgress || rejectInProgress"
              matTooltip="{{ 'approve' | translate }}">
              <mat-icon class="mb-[3px]" *ngIf="!approveInProgress">check</mat-icon>
              <app-loading-button text="{{ 'approveAndSend' | translate }}" [loading]="approveInProgress"></app-loading-button>
            </button>

            <button
              color="primary"
              class="page-block-button"
              *ngIf="canValidate && emailDetails.state === 7"
              mat-flat-button
              aria-label="Reject"
              (click)="reject()"
              [disabled]="approveInProgress || rejectInProgress"
              matTooltip="{{ 'reject' | translate }}">
              <mat-icon class="mb-[3px]" *ngIf="!rejectInProgress">clear</mat-icon>
              <app-loading-button text="{{ 'rejectApproval' | translate }}" [loading]="rejectInProgress"></app-loading-button>
            </button>
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <button mat-icon-button aria-label="Audit" (click)="audit()" matTooltip="{{ 'audit' | translate }}">
          <mat-icon>verified_outline</mat-icon>
        </button>
        <button
          *ngIf="
            (showIcon([
              mailFolderType.Inbox,
              mailFolderType.Sent,
              mailFolderType,
              mailFolderType.Draft,
              mailFolderType.Validation,
              mailFolderType.Spam,
              mailFolderType.Archive
            ]) ||
              emailDetails.state === emailStates.VirusDetected) &&
            !emailDetails.deleted
          "
          mat-icon-button
          aria-label="Delete"
          (click)="delete()"
          matTooltip="{{ 'delete' | translate }}">
          <mat-icon>delete_outline</mat-icon>
        </button>
        <button
          *ngIf="showIcon([mailFolderType.Trash]) && emailDetails.deleted"
          mat-icon-button
          aria-label="Restore"
          (click)="restoreFromTrash()"
          matTooltip="{{ 'restore' | translate }}">
          <mat-icon>restore_outline</mat-icon>
        </button>
        <button
          *ngIf="showIcon([mailFolderType.Inbox]) && !emailDetails.isSpam"
          mat-icon-button
          aria-label="Mark as spam"
          (click)="isSpam()"
          matTooltip="{{ 'markAsSpam' | translate }}">
          <mat-icon>report_outline</mat-icon>
        </button>
        <button
          *ngIf="showIcon([mailFolderType.Spam]) && emailDetails.isSpam"
          mat-icon-button
          aria-label="Mark as not spam"
          (click)="restoreMoveFolderChoose([emailId], 'restoreOneSpam')"
          matTooltip="{{ 'markAsNotSpam' | translate }}">
          <mat-icon>check_circle_outline</mat-icon>
        </button>
        <button
          *ngIf="
            showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
            emailDetails.seen
          "
          mat-icon-button
          aria-label="Unread"
          (click)="setSeen(false)"
          matTooltip="{{ 'unread' | translate }}">
          <mat-icon>visibility_off_outline</mat-icon>
        </button>
        <button
          *ngIf="
            showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
            !emailDetails.seen
          "
          mat-icon-button
          aria-label="Read"
          (click)="setSeen(true)"
          matTooltip="{{ 'read' | translate }}">
          <mat-icon>visibility_outline</mat-icon>
        </button>
        <app-loading-icon-button
          label="validate"
          icon="check_circle_outline"
          [showButton]="
            showIcon([mailFolderType.Inbox]) &&
            (emailDetails.validationState === emailValidationState.NotValidated ||
              emailDetails.validationState === emailValidationState.New) &&
            !validatingEmail
          "
          [showLoading]="validatingEmail"
          (onClick)="setValidated()">
        </app-loading-icon-button>

        <button mat-icon-button aria-label="Print" (click)="print()" matTooltip="{{ 'print' | translate }}">
          <mat-icon>print_outline</mat-icon>
        </button>

        <app-loading-icon-button
          label="downloadSingleEml"
          icon="download"
          [showButton]="
            showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
            !isDownloadingSingleEML
          "
          [showLoading]="isDownloadingSingleEML"
          (onClick)="downloadSingleEML()">
        </app-loading-icon-button>
        <app-loading-icon-button
          label="downloadEml"
          icon="download_for_offline"
          [showButton]="
            showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
            !isDownloadingEML
          "
          [showLoading]="isDownloadingEML"
          (onClick)="downloadEML()">
        </app-loading-icon-button>
        <button
          *ngIf="haveAttachments()"
          mat-icon-button
          aria-label="Open attachments"
          (click)="showAttachments()"
          matTooltip="{{ 'openAttachments' | translate }}">
          <mat-icon>attachment_outline</mat-icon>
        </button>
        <button mat-icon-button aria-label="Open notes" (click)="showNotes()" matTooltip="{{ 'openNotes' | translate }}">
          <mat-icon>note_outline</mat-icon>
        </button>
        <button
          *ngIf="showIcon([mailFolderType.Sent])"
          mat-icon-button
          aria-label="Open notifications"
          (click)="showNotifications()"
          matTooltip="{{ 'openNotifications' | translate }}">
          <mat-icon>notifications_outline</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="useMobileView" [appDisable]="isLoading || cannotLoadEmailDetails">
      <button
        *ngIf="
          showIcon([
            mailFolderType.Inbox,
            mailFolderType.Sent,
            mailFolderType.Validation,
            mailFolderType.Spam,
            mailFolderType.Trash,
            mailFolderType.Archive
          ]) && emailDetails.state !== 7
        "
        mat-icon-button
        (click)="reply()"
        matTooltip="{{ 'reply' | translate }}">
        <mat-icon>reply_outline</mat-icon>
      </button>
      <button
        *ngIf="
          showIcon([
            mailFolderType.Inbox,
            mailFolderType.Sent,
            mailFolderType.Validation,
            mailFolderType.Spam,
            mailFolderType.Trash,
            mailFolderType.Archive
          ]) && emailDetails.state !== 7
        "
        mat-icon-button
        (click)="replyAll()"
        matTooltip="{{ 'replyAll' | translate }}">
        <mat-icon>reply_all_outline</mat-icon>
      </button>
      <button
        *ngIf="haveAttachments()"
        mat-icon-button
        aria-label="Open attachments"
        (click)="showAttachments()"
        matTooltip="{{ 'openAttachments' | translate }}">
        <mat-icon>attachment_outline</mat-icon>
      </button>
      <button mat-icon-button aria-label="{{ 'openNotes' | translate }}" (click)="showNotes()" matTooltip="Open notes">
        <mat-icon>note_outline</mat-icon>
      </button>

      <button mat-icon-button aria-label="Menu" [matMenuTriggerFor]="menu" matTooltip="{{ 'menu' | translate }}">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          (click)="forward()"
          *ngIf="
            showIcon([
              mailFolderType.Inbox,
              mailFolderType.Sent,
              mailFolderType.Validation,
              mailFolderType.Spam,
              mailFolderType.Trash,
              mailFolderType.Archive
            ]) && emailDetails.state !== 7
          "
          [disableRipple]="true">
          <mat-icon>shortcut</mat-icon>
          {{ "forward" | translate }}
        </button>
        <button mat-menu-item (click)="audit()" [disableRipple]="true">
          <mat-icon>verified_outline</mat-icon>{{ "audit" | translate }}
        </button>
        <button
          *ngIf="
            showIcon([mailFolderType.Inbox]) &&
            (emailDetails.validationState === emailValidationState.NotValidated ||
              emailDetails.validationState === emailValidationState.New)
          "
          mat-menu-item
          [disableRipple]="true"
          (click)="setValidated()">
          <mat-icon>check_circle_outline</mat-icon>{{ "validate" | translate }}
        </button>
        <button
          mat-menu-item
          (click)="delete()"
          *ngIf="
            (showIcon([
              mailFolderType.Inbox,
              mailFolderType.Sent,
              mailFolderType,
              mailFolderType.Draft,
              mailFolderType.Validation,
              mailFolderType.Spam,
              mailFolderType.Archive
            ]) ||
              emailDetails.state === emailStates.VirusDetected) &&
            !emailDetails.deleted
          "
          [disableRipple]="true">
          <mat-icon>delete</mat-icon>
          {{ "delete" | translate }}
        </button>
        <button
          mat-menu-item
          (click)="restoreFromTrash()"
          *ngIf="showIcon([mailFolderType.Trash]) && emailDetails.deleted"
          [disableRipple]="true">
          <mat-icon>restore_outline</mat-icon>
          {{ "restore" | translate }}
        </button>
        <button
          mat-menu-item
          (click)="isSpam(true)"
          *ngIf="showIcon([mailFolderType.Inbox]) && !emailDetails.isSpam"
          [disableRipple]="true">
          <mat-icon>report_outline</mat-icon>
          {{ "markAsSpam" | translate }}
        </button>
        <button
          mat-menu-item
          (click)="restoreMoveFolderChoose([emailId], 'restoreOneSpam')"
          *ngIf="showIcon([mailFolderType.Spam]) && emailDetails.isSpam"
          [disableRipple]="true">
          <mat-icon>check</mat-icon>
          {{ "markAsNotSpam" | translate }}
        </button>
        <button
          mat-menu-item
          (click)="setSeen(false)"
          *ngIf="
            showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
            emailDetails.seen
          "
          [disableRipple]="true">
          <mat-icon>visibility_off_outline</mat-icon>
          {{ "markAsUnread" | translate }}
        </button>
        <button
          mat-menu-item
          (click)="setSeen(true)"
          *ngIf="
            showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive]) &&
            !emailDetails.seen
          "
          [disableRipple]="true">
          <mat-icon>visibility_outline</mat-icon>
          {{ "markAsRead" | translate }}
        </button>
        <button
          mat-menu-item
          (click)="setValidated()"
          *ngIf="
            showIcon([mailFolderType.Inbox]) &&
            (emailDetails.validationState === emailValidationState.NotValidated ||
              emailDetails.validationState === emailValidationState.New)
          "
          [disableRipple]="true">
          <mat-icon>check_circle_outline</mat-icon>
          {{ "validate" | translate }}
        </button>
        <button mat-menu-item (click)="approve()" *ngIf="canValidate && emailDetails.state === 7" [disableRipple]="true">
          <mat-icon>check</mat-icon>{{ "approve" | translate }}
        </button>
        <button mat-menu-item (click)="approve()" *ngIf="canValidate && emailDetails.state === 7" [disableRipple]="true">
          <mat-icon>clear</mat-icon>{{ "reject" | translate }}
        </button>
        <button mat-menu-item (click)="print()" [disableRipple]="true"><mat-icon>print_outline</mat-icon>{{ "print" | translate }}</button>
        <button mat-menu-item (click)="showNotifications()" [disableRipple]="true">
          <mat-icon>notifications_outline</mat-icon>{{ "openNotifications" | translate }}
        </button>
        <button
          mat-menu-item
          (click)="downloadSingleEML()"
          *ngIf="
            showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive])
          "
          [disableRipple]="true">
          <mat-icon>download</mat-icon>
          {{ "downloadSingleEml" | translate }}
        </button>
        <button
          mat-menu-item
          (click)="downloadEML()"
          *ngIf="
            showIcon([mailFolderType.Inbox, mailFolderType.Sent, mailFolderType.Validation, mailFolderType.Spam, mailFolderType.Archive])
          "
          [disableRipple]="true">
          <mat-icon>download_for_offline</mat-icon>
          {{ "downloadEml" | translate }}
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- rigth menu -->
  <div *ngIf="!useMobileView && menuIsOpened" style="max-width: 27%" class="w-full h-full relative">
    <div class="absolute top-[-15px] right-[-15px] h-12 w-12 z-20"></div>
    <div class="w-full h-full flex flex-col overflow-hidden">
      <div class="w-full flex mb-2 bg-white z-20">
        <div class="flex cursor-pointer items-center" (click)="closeMenu()">
          <button mat-icon-button class="menu-close-btn pr-3 mt-[2px]">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <div class="text-xl font-medium">{{ menuHeader | translate }}</div>
        </div>
      </div>

      <div class="flex-grow w-full px-1 overflow-y-auto">
        <div *ngIf="menuContent == menuContentTypes.Attachments" class="menu__attachments">
          <div
            *ngFor="let file of emailDetails.attachmentFiles"
            [style.cursor]="!isFileDownloading(file.fileId) ? 'pointer' : 'default'"
            (click)="!isFileDownloading(file.fileId) && getAttachmentFile(file)"
            class="menu__attachment">
            <file-icon set="classic" size="md" [type]="getFileExtension(file.fileName)"></file-icon>
            <div
              [style.color]="!isFileDownloading(file.fileId) ? 'black' : 'grey'"
              [style.cursor]="!isFileDownloading(file.fileId) ? 'pointer' : 'default'"
              class="menu__filename filename-label">
              {{ file.fileName }}
            </div>
            <mat-progress-spinner
              style="width: 24px; margin-left: 3px"
              *ngIf="isFileDownloading(file.fileId)"
              mode="indeterminate"
              diameter="16"></mat-progress-spinner>
          </div>
        </div>

        <div *ngIf="menuContent == menuContentTypes.Notes" class="h-full">
          <app-email-note-list [emailId]="emailId"></app-email-note-list>
        </div>

        <div class="h-full audits" *ngIf="menuContent == menuContentTypes.Audit">
          <app-email-audit [emailId]="emailId"></app-email-audit>
        </div>

        <div *ngIf="menuContent == menuContentTypes.Notifications" class="h-full">
          <app-email-notifications-dialog [emailId]="emailId" [webCode]="webCode" (openEmailDetails)="openEmailDetails($event)">
          </app-email-notifications-dialog>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="w-full flex-none" *ngIf="!isLoading">
  <div *ngIf="true" class="mb-3">
    <app-error-hint errorText="Cannot save data: something went wrong"></app-error-hint>
  </div>
</div> -->
