import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit {
  @Input() title: string | null;
  @Input() width: string | null;
  @Input() showBackground: boolean = true;
  @Input() isOpen: boolean;
  @Input() isOffset: boolean = false;
  @Input() contentPercentOfScreen: number | null;
  @Output() closeEventEmitter = new EventEmitter();

  ngOnInit(): void {}

  public onClose(): void {
    this.closeEventEmitter.emit();
  }
}
