import { EmailAuditEventType } from './email-audit-event-type';

interface IEventMetadata {
  label: string;
  args: string[];
}

export const isEventMetadata = (typeToCheck: any): typeToCheck is IEventMetadata => {
  if (typeToCheck && typeof typeToCheck !== 'string') {
    return true;
  }
  return;
};

export class EmailAuditContract {
  emailId: number;
  userId: number;
  userName: string;
  type: EmailAuditEventType;
  eventDateTime: Date;
  metadata: (string & IEventMetadata) | string;
}
