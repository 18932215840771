import { AttachmentState } from './attachment-state';

export class AttachmentContract {
  attachmentId: number;
  fileId: number;
  fileName: string;
  mimeType: string;
  size: number;
  fileState: AttachmentState;
}
