import { Component, OnInit } from '@angular/core';
import { SignalRService } from './services/signalR/signalR.service';
import { AuthenticationService } from './services/auth/authentication.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { RoutingState } from './services/routing/routing.state';
import { EventsService } from './services/events/events.service';
import { UserLoginResponse } from './sdk/contracts/user/user.login.response';
import { Subscription } from 'rxjs';
import { ReceiveEmailEvent } from './sdk/contracts/email-export/receive-email-event';
import { SnackbarHelper } from './shared/helpers/snackbar.helper';
import { UserMailAccountSelected } from './components/emails-sidenav/events/user-mail-account.selected';
import { UrlService } from './services/url.service';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ApiRequest } from './sdk/api.request';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public lastTokenRenewVarName = 'LastTokenRenewTime';
  public newEmailSubscription: Subscription;
  previousUrl: string = null;
  currentUrl: string = null;

  public userMailAccountSelected: UserMailAccountSelected = null;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private eventsService: EventsService,
    private signalrService: SignalRService,
    private authenticationService: AuthenticationService,
    private routingState: RoutingState,
    private snackBarHelper: SnackbarHelper,
    private apiRequest: ApiRequest,
    private urlService: UrlService,
    public translate: TranslateService
  ) {
    translate.addLangs(['it', 'en']);
  }

  async ngOnInit(): Promise<any> {
    if (!localStorage.getItem('userLocale')) {
      localStorage.setItem('userLocale', environment.language);
    }
    this.translate.use(localStorage.getItem('userLocale'));
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        this.urlService.setPreviousUrl(this.previousUrl);
      });

    this.eventsService.UserMailAccountSelected.subscribe((x) => {
      this.userMailAccountSelected = x;
    });

    this.subscribeOnEvents();

    this.newEmailSubscription = this.eventsService.receiveEmailEvent.subscribe((event) => {
      this.onNewEmail(event);
    });
  }

  private subscribeOnEvents(): void {
    this.eventsService.signInEvent.subscribe(async (item) => {
      await this.onSignIn(item);
    });

    this.eventsService.signOutEvent.subscribe(async (item) => {
      await this.onSignOut(item);
    });

    this.eventsService.apiCallEvent.subscribe(async (item) => {
      // await this.checkTokenRenew(item);
    });
  }

  private async onSignIn(tokenInfo: UserLoginResponse): Promise<any> {
    // await this.startSignalR();
  }

  private async onSignOut(tokenInfo: UserLoginResponse): Promise<any> {
    // await this.stopSignalR();
  }

  // private async startSignalR(): Promise<any> {
  //   if (this.isLogged()){
  //     await this.signalrService.startConnection();
  //     this.signalrService.addListener('FileCreatedEventMessage', (data) => {
  //
  //     });

  //     this.signalrService.addListener('ProviderCreatedEventMessage', (data) => {
  //
  //     });

  //     this.signalrService.addListener('ProviderDeletedEventMessage', (data) => {
  //
  //     });

  //     this.signalrService.addListener('EmailExportFileReadyEvent', (data) => {
  //       const event: EmailExportFileReadyEvent = JSON.parse(data);
  //       if (event.FileId > 0) {
  //         this.eventsService.emailExportFileReadyEvent.emit(event);
  //       }
  //     });

  //     this.signalrService.addListener('ReceiveEmailEvent', (data) => {
  //       const event: ReceiveEmailEvent = JSON.parse(data);
  //       if (event.EmailId > 0) {
  //         this.eventsService.receiveEmailEvent.emit(event);
  //       }
  //     });

  //     this.signalrService.addListener('EmailSetSeenEvent', (data) => {
  //       const event: EmailSetSeenEvent = JSON.parse(data);
  //       if (event.EmailIds && event.EmailIds.length > 0) {
  //         this.eventsService.emailSetSeenEvent.emit(event);
  //       }
  //     });

  //     this.signalrService.addListener('EmailMoveToFolderEvent', (data) => {
  //       const event: EmailMoveToFolderEvent = JSON.parse(data);
  //       if (event.EmailIds && event.EmailIds.length > 0) {
  //         this.eventsService.emailMoveToFolderEvent.emit(event);
  //       }
  //     });

  //     this.signalrService.addListener('EmailReassignEvent', (data) => {
  //       const event: EmailReassignEvent = JSON.parse(data);
  //       if (event.EmailIds && event.EmailIds.length > 0) {
  //         this.eventsService.emailReassignEvent.emit(event);
  //       }
  //     });
  //   }
  // }

  private async stopSignalR(): Promise<any> {
    await this.signalrService.stopConnection();
  }

  onNewEmail(event: ReceiveEmailEvent): void {
    // this.snackBarHelper.openSnackBar(`New email from: ${event.From} with subject ${event.Subject}`);
  }

  wait(ms): void {
    const start = new Date().getTime();
    let end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }
}
