<div class="drawer-content-screen flex flex-col justify-between">
  <div class="w-full h-full mb-2">
    <div *ngIf="!cannotLoadAvailableOrganizationUsers && !availableOrganizationUsersIsLoading" class="text-2xl mb-10 font-medium">
      {{ "addUserToMailAccount" | translate }}
    </div>
    <div class="flex justify-center flex-col h-full" *ngIf="cannotLoadAvailableOrganizationUsers">
      <app-something-went-wrong
        description="{{ 'cannotLoadOrgUsers' | translate }}"
        [showPicture]="false"
        (retryEmitter)="loadAvailableOrganizationUsers()"></app-something-went-wrong>
    </div>
    <app-loader class="flex justify-center flex-col h-full" *ngIf="availableOrganizationUsersIsLoading"></app-loader>
    <div *ngIf="!cannotLoadAvailableOrganizationUsers && !availableOrganizationUsersIsLoading">
      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill">
          <mat-label>{{ "user" | translate }}</mat-label>
          <mat-select id="user" [(value)]="selectedUser" required>
            <mat-option *ngFor="let user of availableUsers" [value]="user">{{ user.firstName + " " + user.lastName }}</mat-option>
          </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field appearance="fill">
          <mat-label>{{ "role" | translate }}</mat-label>
          <mat-select id="role" [(value)]="selectedRole" required>
            <mat-option *ngFor="let role of availableRoles" [value]="role">{{ roleTypes[role] | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div *ngIf="!cannotLoadAvailableOrganizationUsers && !availableOrganizationUsersIsLoading" class="w-full">
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !formIsValid()" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
