<div class="conditions-box">
  <div class="conditions-header-box mb-2">
    <div class="conditions-header">
      <div>
        <span class="font-normal lowercase" *ngIf="header !== 'subject'">{{ "and" | translate }}</span>
        {{ header | translate }}
      </div>
    </div>
  </div>

  <div class="conditions-content">
    <div class="w-[125px] pr-4">
      <mat-form-field appearance="fill" class="mat-input-without-padding">
        <mat-select name="action" [(value)]="emailCondition.operation" required>
          <mat-option *ngFor="let operation of availableOperations" [value]="operation">
            {{ operationToText(operation) | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="condition-patterns">
      <form [formGroup]="form">
        <div *ngFor="let pattern of emailCondition.patterns; let i = index; trackBy: trackByFn">
          <div class="w-full flex flex-1">
            <mat-form-field appearance="fill" class="mat-input-without-padding">
              <div *ngIf="i > 0" class="italic" style="position: absolute; top: 2px; left: -22px">{{ "or" | translate }}</div>
              <input
                matInput
                [id]="paramName + '-pattern-' + i"
                [formControlName]="paramName + '-pattern-' + i"
                (input)="onChangePattern(i, $event)" />
              <mat-error *ngIf="hasError(paramName + '-pattern-' + i, 'maxlength')">{{ "moreThan256Char" | translate }} </mat-error>
            </mat-form-field>
            <div class="flex flex-wrap content-center justify-center">
              <button
                [disabled]="emailCondition.patterns.length === 1"
                mat-icon-button
                matTooltip="{{ 'deleteCondition' | translate }}"
                aria-label="Delete condition"
                (click)="onDelete(i)"
                class="flex flex-wrap content-center">
                <mat-icon color="primary">remove_circle_outline</mat-icon>
              </button>
            </div>
            <div class="flex flex-wrap content-center justify-center">
              <button
                mat-icon-button
                matTooltip="{{ 'addConditionOr' | translate }}"
                aria-label="{{ 'addConditionOr' | translate }}"
                (click)="onCreate()"
                class="flex flex-wrap content-center">
                <mat-icon color="primary">add_circle_outline</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="mb-4"></div>
    </div>
  </div>
</div>
