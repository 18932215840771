<div *ngIf="settingsIsLoading" class="flex flex-col justify-center h-full">
  <app-loader></app-loader>
</div>
<div *ngIf="failedSettingsLoading" class="flex flex-col justify-center h-full">
  <app-something-went-wrong
    [showPicture]="false"
    description="{{ 'cannotLoadCompanySettings' | translate }}"
    (retryEmitter)="failedUpdate ? onSubmit() : loadAuthTypes()">
  </app-something-went-wrong>
</div>
<div *ngIf="!failedSettingsLoading && !settingsIsLoading" class="drawer-content-screen flex flex-col justify-between">
  <div class="text-2xl mb-10 font-medium">
    {{ "updateUser" | translate }}
  </div>
  <div class="h-full w-full flex-grow overflow-y-auto">
    <form [formGroup]="form" autocomplete="off" novalidate>
      <mat-form-field appearance="fill">
        <mat-label>{{ "firstName" | translate }}</mat-label>
        <input matInput id="firstName" formControlName="firstName" required />
        <mat-error *ngIf="hasError('firstName', 'required')">{{ "firstNameIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('firstName', 'maxlength')">{{ "moreThan128Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "lastName" | translate }}</mat-label>
        <input matInput id="lastName" formControlName="lastName" required />
        <mat-error *ngIf="hasError('lastName', 'required')">{{ "lastNameIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('lastName', 'maxlength')">{{ "moreThan128Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "email" | translate }}</mat-label>
        <input matInput id="email" formControlName="email" required />
        <mat-error *ngIf="hasError('email', 'required')">{{ "emailIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('email', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
        <mat-error *ngIf="hasError('email', 'email')">{{ "incorrectEmail" | translate }}</mat-error>
      </mat-form-field>
      <div class="my-3">
        <mat-checkbox color="primary" [checked]="gawOidcAuthType" (change)="changeGawOidcAuthType($event.checked)">{{
          "GAW" | translate
        }}</mat-checkbox>
        <mat-form-field appearance="fill">
          <mat-label>{{ "nickname" | translate }}</mat-label>
          <input matInput id="nickName" formControlName="nickName" required />
          <mat-error *ngIf="hasError('nickName', 'required')">{{ "nicknameIsRequired" | translate }}</mat-error>
          <mat-error *ngIf="hasError('nickName', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="!isUserHasOidc" appearance="fill">
          <mat-label>{{ "password" | translate }}</mat-label>
          <input type="password" matInput id="password" formControlName="password" required />
          <mat-error *ngIf="hasError('password', 'required')">{{ "passIsRequired" | translate }}</mat-error>
          <mat-error *ngIf="hasError('password', 'minlength')">{{ "lessThanEightChar" | translate }}</mat-error>
          <mat-error *ngIf="hasError('password', 'maxlength')">{{ "moreThanFifteenChar" | translate }}</mat-error>
          <mat-error *ngIf="hasError('password', 'hasAtLeastOneUpperCharacter')">{{ "atLeastOneUpperLetter" | translate }}</mat-error>
          <mat-error *ngIf="hasError('password', 'hasAtLeastOneLowerCharacter')">{{ "atLeastOneLowerLetter" | translate }}</mat-error>
          <mat-error *ngIf="hasError('password', 'hasAtLeastOneDigit')">{{ "atLeastOneDigit" | translate }}</mat-error>
          <mat-error *ngIf="hasError('password', 'hasAtLeastOneSpecialSymbol')">{{ "atLeastOneSpecialSym" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <mat-checkbox color="primary" [checked]="googleAuthType" (change)="changeGoogleAuthType($event.checked)">{{
        "google" | translate
      }}</mat-checkbox>
      <mat-error class="mt-3" *ngIf="!(this.googleAuthType || this.gawOidcAuthType)">{{
        "selectAtLeast1AuthenticationType" | translate
      }}</mat-error>
    </form>
  </div>
  <div class="w-full">
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button
        mat-flat-button
        color="primary"
        [disabled]="isLoading || !form.valid || !(this.googleAuthType || this.gawOidcAuthType)"
        (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
