import { Injectable, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../auth/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthGuard } from '../auth/auth.guard';
import { LoggingHandler } from '../logging/logging.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  constructor(
    public router: Router,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private activateRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private dialogRef: MatDialog,
    private authGuard: AuthGuard,
    protected loggingHandler: LoggingHandler,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });
    return next.handle(req).pipe(catchError((error) => this.handleError(error)));
  }

  handleError(httpErrorResponse: HttpErrorResponse): Observable<never> {
    const webCode = this.activateRoute.snapshot.firstChild.params.webCode;

    if (httpErrorResponse.status === 401 && webCode) {
      this.loggingHandler
        .create('error', `401 webCode:${webCode} Response: ${JSON.stringify(httpErrorResponse)}`);
      this.dialogRef.closeAll();
      this.authenticationService.logout().then();
      document.location.href = `${environment.feUrl}signout?webCode=${webCode}`;
    } else if (httpErrorResponse.status === 403 && webCode) {
      this.dialogRef.closeAll();
      this.router.navigate([`${webCode}/403`], { state: { returnUrl: this.router.url } }).then();
    } else {
      const message = this.getErrorMessage(httpErrorResponse);
      // this.openSnackBar(message);
    }

    return throwError(() => httpErrorResponse);
  }

  getErrorMessage(httpErrorResponse: HttpErrorResponse): string {
    let message;
    if (httpErrorResponse.error == null || httpErrorResponse.error.description == null) {
      message = httpErrorResponse.statusText;
    } else {
      message = httpErrorResponse.error.description;
      if (httpErrorResponse.error.meta && httpErrorResponse.error.meta.length > 0 && httpErrorResponse.error.meta[0].errorMessage) {
        message += `(${httpErrorResponse.error.meta[0].errorMessage})`;
      }
    }
    return message;
  }

  openSnackBar(message: string): void {
    const messageText = message === 'Unknown Error' ? 'Fatal error' : message;
    this.zone.run(() => {
      const snackBar = this.snackBar.open(messageText, 'OK', {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
      });
      snackBar.onAction().subscribe(() => {
        snackBar.dismiss();
      });
    });
  }
}
