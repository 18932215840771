export class UserUpdateRequest {
  firstName: string;
  lastName: string;
  localizationCode: string;

  constructor(firstName: string, lastName: string, localizationCode: string) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.localizationCode = localizationCode;
  }
}
