import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseIdListRequest } from '../contracts/common/base.id-list.request';
import { EmailGroupsRequest } from '../contracts/email-groups/email-groups.request';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class EmailGroupClient {
  constructor(private apiRequest: ApiRequest) {}

  async removeGroupsFromEmails(request: EmailGroupsRequest): Promise<void> {
    return this.apiRequest.post<EmailGroupsRequest, void>(request, `${API_URL}${ApiRoutes.emailGroup.removeEmailsFromGroups}`);
  }

  async addGroupsToEmails(request: EmailGroupsRequest): Promise<void> {
    return this.apiRequest.post<EmailGroupsRequest, void>(request, `${API_URL}${ApiRoutes.emailGroup.addEmailsToGroups}`);
  }
  async getGroupsForEmails(request: BaseIdListRequest): Promise<string[]> {
    return this.apiRequest.post<BaseIdListRequest, string[]>(request, `${API_URL}${ApiRoutes.emailGroup.getGroupsForEmails}`);
  }
}
