export class UpdateUserRequest {
  userOrganizationId: number;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  nickname: string;
  password: string;
  oidcState: number;
  googleState: number;

  public constructor(
    userOrganizationId: number,
    userId: number,
    firstName: string,
    lastName: string,
    email: string,
    nickname: string,
    password: string,
    oidcState: number,
    googleState: number
  ) {
    this.userOrganizationId = userOrganizationId;
    this.userId = userId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.nickname = nickname;
    this.password = password;
    this.oidcState = oidcState;
    this.googleState = googleState;
  }
}
