import { BaseGetRequest } from '../common/base-get.request';

export class UserMailAccountGetListRequest extends BaseGetRequest {
  userId: number;

  constructor(id: number) {
    super();
    this.userId = id;
  }
}
