import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserContract } from '../../../sdk/contracts/user/user.contract';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserClient } from '../../../sdk/clients/user.client';
import { UserUpdateRequest } from '../../../sdk/contracts/user/user-update.request';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-account-update-dialog',
  templateUrl: './account-update-dialog.component.html',
  styleUrls: ['./account-update-dialog.component.scss'],
})
export class AccountUpdateDialogComponent implements OnInit {
  @Input() user: UserContract;
  //@Input() userLocale: string;
  @Input() locales: Array<{ language: string; id: string }>;
  @Output() public updateEventEmitter = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();

  public isLoading = false;
  public isError = false;
  public form = new UntypedFormGroup({});

  constructor(private userClient: UserClient, public matchError: MatchError) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl(this.user.firstName, [Validators.required, Validators.maxLength(256)]),
      lastName: new UntypedFormControl(this.user.lastName, [Validators.required, Validators.maxLength(256)]),
      email: new UntypedFormControl(this.user.email),
      locale: new UntypedFormControl(this.user.localizationCode),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public cancel(): void {
    this.closeEventEmitter.emit();
  }

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.isError = false;
      const firstName = this.form.get('firstName').value;
      const lastName = this.form.get('lastName').value;
      const localizationCode = this.form.get('locale').value;
      const request = new UserUpdateRequest(firstName, lastName, localizationCode);
      try {
        await this.userClient.update(request);
        this.updateEventEmitter.emit({ firstName, lastName, localizationCode });
      } catch (e) {
        this.isError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
