<form class="mat-form-tags-wrapper" [formGroup]="form">
  <button></button>

  <mat-form-field
    (keydown)="moveCell($event)"
    [class.cc-open]="showCc"
    [class.tags-wrapper-no-margin]="!isNeedMarginLeft"
    class="example-full-width flex tags-wrapper"
    appearance="fill">
    <div class="flex min-h-[40px] flex-wrap">
      <button
        *ngFor="let tagTo of toList"
        (keydown)="onTagKeyDown($event, tagTo)"
        (focus)="setFocusedTag(tagTo)"
        (click)="$event.stopPropagation()"
        style="border-radius: 100px"
        [style.height]="tagTo === clickedTagValueTo ? '40px' : '100%'"
        (click)="onTagToClick(tagTo)"
        (mousedown)="isTagClickedEmit()">
        <div
          class="tag-wrapper"
          [style.background]="isTagShiftSelected(tagTo) ? '#0000004d' : !isValidTag(tagTo) ? '#ff0b0b57' : ''"
          *ngIf="tagTo !== clickedTagValueTo"
          class="chip">
          <span class="tags-wrapper-span">
            {{ tagTo }}
          </span>
          <mat-icon class="chip-close" (click)="deleteTagTo(tagTo)">cancel</mat-icon>
        </div>
        <div style="height: 36px; line-height: 22px; margin-top: 3px; margin-right: 5px" *ngIf="tagTo === clickedTagValueTo" class="chip">
          <label class="input-sizer input-label tags-wrapper-span">
            <input
              (focusout)="onSubmitTagTo($event)"
              style="height: 18px"
              (keydown.enter)="onSubmitTagTo($event)"
              #tagInputTo
              type="text"
              onInput="this.parentNode.dataset.value = this.value"
              onfocus="this.parentNode.dataset.value = this.value"
              size="1"
              [value]="tagTo" />
          </label>
        </div>
      </button>
      <input
        type="text"
        [placeholder]="'+ ' + (placeHolder | translate)"
        aria-label="Number"
        matInput
        (keydown.enter)="selectedTo($event.target.value)"
        (focusout)="selectedTo($event.target.value, true)"
        style="height: 40px; flex: 1 0 100px"
        #tagInputSelectTo
        formControlName="to"
        [matAutocomplete]="auto" />
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTo($event.option.value)">
      <mat-option *ngFor="let option of filteredToList()" [value]="option">
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
