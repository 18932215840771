import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { OrganizationClient } from '../../../../sdk/clients/organization.client';
import { UserOrganizationClient } from '../../../../sdk/clients/user-organization.client';
import { BaseCollectionSearchByIdRequest } from '../../../../sdk/contracts/common/base-collection-search-by-id.request';
import { BaseGetByIdRequest } from '../../../../sdk/contracts/common/base-get-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../../../../sdk/contracts/common/base-get-search-count-by-id.request';
import { RoleType } from '../../../../sdk/contracts/role/role-type';
import { UserOrganizationGetOrganizationUsersContract } from '../../../../sdk/contracts/user-organization/user-organization.get-organization-users.contract';
import { UserOrganizationState } from '../../../../sdk/contracts/user-organization/user-organization-state.enum';
import { MobileObserverService } from '../../../../services/adaptive/mobile-observer.service';
import { PermissionNavTabHelper } from '../../../../services/permission/permission-nav-tab-helper';
import { PermissionService } from '../../../../services/permission/permission.service';
import { BreadcrumbItem } from '../../../../shared/breadcrumb-item';
import { TabItem } from '../../../../shared/tab-item';
import { OrganizationUserDeleteDialogComponent } from './organization-user-delete-dialog/organization-user-delete-dialog.component';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { MatchError } from 'src/app/shared/error-matcher';
import { OrganizationSettingsClient } from 'src/app/sdk/clients/organization-settings.client';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-organization-users',
  templateUrl: './organization-users.component.html',
  styleUrls: ['./organization-users.component.scss'],
})
export class OrganizationUsersComponent implements OnInit, AfterViewInit {
  public previousRoute: string;
  public organizationId: number;
  public organizationName: string;

  public organizationUsers: UserOrganizationGetOrganizationUsersContract[] = [];
  public dataSource = new MatTableDataSource<UserOrganizationGetOrganizationUsersContract>(this.organizationUsers);
  public displayedColumns: string[] = [
    'select',
    'firstName',
    'lastName',
    'googleIdentity',
    'oidcIdentity',
    'adfsIdentity',
    'email',
    'role',
    'state',
    'actions',
  ];
  public breadcrumbItems: BreadcrumbItem[] = [];
  public formatUserStateEnum(order: UserOrganizationState): string {
    switch (order) {
      case UserOrganizationState.Active:
        return 'activated';
      case UserOrganizationState.Disabled:
        return 'diactivated';
      default:
        return 'Not implemented';
    }
  }
  public tabItems: TabItem[] = [];
  public cannotLoadNavigation = false;
  public isLoading = false;
  public hasError = false;
  public isSamlSupport: boolean = true;
  public roleTypes = RoleType;

  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;

  public searchValue = '';
  public useMobileView = false;
  public webCode: string;

  public isDrawerOpen: boolean = false;
  public onDrawerClose: Function = () => {};

  public createDrawerIsOpen = false;
  public updateDrawerIsOpen = false;
  public showAddUsersToGroupsForm = false;
  public showAddUsersToMailAccountsForm = false;

  public selection = new SelectionModel<UserOrganizationGetOrganizationUsersContract>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private route: ActivatedRoute,
    private userOrganizationClient: UserOrganizationClient,
    private permissionService: PermissionService,
    private organizationClient: OrganizationClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private matchError: MatchError,
    private organizationSettingsClient: OrganizationSettingsClient,
    public dialog: MatDialog,
    private mobileObserverService: MobileObserverService
  ) {}

  async ngOnInit(): Promise<void> {
    this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));

    this.pageSize = JSON.parse(localStorage.getItem('pageSizeSettings')) || 25;
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.breadcrumbItems = [
      { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
      { index: 1, label: 'usersBreadCrumbs', route: null },
    ];
    await this.loadNavigation();
    await this.loadOrganizationUsers(true);
  }

  ngAfterViewInit(): void {}

  public async loadNavigation(): Promise<void> {
    try {
      this.loadTabItems();
    } catch (e) {
      this.cannotLoadNavigation = true;
      this.matchError.logError(e);
    }
  }

  private loadTabItems(): void {
    this.tabItems = this.permissionNavTabHelper.getOrganizationPageTabs(this.organizationId, this.webCode);
  }

  public async changeState(userOrganizationId: number, state: UserOrganizationState) {
    try {
      if (state === UserOrganizationState.Disabled) {
        await this.userOrganizationClient.changeState({ userOrganizationId, state: UserOrganizationState.Active });
      } else {
        await this.userOrganizationClient.changeState({ userOrganizationId, state: UserOrganizationState.Disabled });
      }
      await this.loadOrganizationUsers(false);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  public openConfirmDialog(
    userOrganizationId: number,
    state: UserOrganizationState,
    user: UserOrganizationGetOrganizationUsersContract
  ): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: {
        name: 'stateChange',
        isDontNeedConfirmWord: true,
        title: state === UserOrganizationState.Active ? 'stateActiveTitle' : 'stateDisabledTitle',
        itemName: `${user.firstName} ${user.lastName}`,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.changeState(userOrganizationId, state);
      }
    });
  }

  public async loadOrganizationUsers(fetchSearchCounter): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    if (fetchSearchCounter) {
      this.pageIndex = 0;
    }

    const nullableSearchValue = this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchByIdRequest(this.organizationId, this.pageIndex + 1, this.pageSize, nullableSearchValue);
    try {
      const organizationSettings = await this.organizationSettingsClient.getByOrganization(new BaseGetByIdRequest(this.organizationId));
      this.isSamlSupport = organizationSettings.result.samlSupport;

      const response = await this.userOrganizationClient.search(request);
      this.selection.clear();
      this.organizationUsers = response.data.filter((e) => this.permissionService.hasPermissionToSeeUserByUserEmail(e.email));
      //filter root
      this.organizationUsers = this.organizationUsers.filter((e) => e.roleId !== 1);
      this.dataSource.data = this.organizationUsers;
      if (fetchSearchCounter) {
        const countRequest = new BaseGetSearchCountByIdRequest(this.organizationId, nullableSearchValue);
        const counterResponse = await this.userOrganizationClient.getSearchCount(countRequest);
        this.totalSize = counterResponse.result;
      }
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async handlePage(e: any): Promise<void> {
    localStorage.setItem('pageSizeSettings', JSON.stringify(e.pageSize));
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadOrganizationUsers(false);
  }

  public onSearchValueChanged(newSearchValue): void {
    this.searchValue = newSearchValue;
  }

  async onCreateOrganizationUserBtnClicked(): Promise<void> {
    this.openDrawer(this.onCloseCreateModalForm);
    this.createDrawerIsOpen = true;
  }

  async onUpdateOrganizationUserBtnClicked(user: UserOrganizationGetOrganizationUsersContract): Promise<void> {
    this.selection.clear();
    this.selection.select(user);
    this.openDrawer(this.onCloseUpdateModalForm);
    this.updateDrawerIsOpen = true;
  }

  onCloseCreateModalForm(): void {
    this.closeDrawer();
    this.createDrawerIsOpen = false;
  }

  onCloseUpdateModalForm(): void {
    this.closeDrawer();
    this.updateDrawerIsOpen = false;
    this.selection.clear();
  }

  async onCreateOrganizationUser(): Promise<void> {
    this.onCloseCreateModalForm();
    this.totalSize += 1;
    await this.loadOrganizationUsers(false);
  }

  async onUpdateOrganizationUser(): Promise<void> {
    this.onCloseUpdateModalForm();
    this.totalSize += 1;
    await this.loadOrganizationUsers(false);
  }

  async onDeleteUserFromOrganizationBtnClicked(userOrganizationId: number, userName: string): Promise<void> {
    const dialogRef = this.dialog.open(OrganizationUserDeleteDialogComponent, {
      width: '450px',
      data: { userOrganizationId, userName, organizationName: this.organizationName },
    });

    dialogRef.afterClosed().subscribe(async (x) => {
      if (x.isDeleted) {
        this.totalSize -= 1;
        await this.loadOrganizationUsers(false);
      }
    });
  }

  public isRta(organizationUserRole: RoleType): boolean {
    return organizationUserRole === RoleType.Rta;
  }

  //#region Drawer
  public openDrawer(onClose = () => {}) {
    this.isDrawerOpen = true;
    this.onDrawerClose = onClose;
  }

  public closeDrawer() {
    this.isDrawerOpen = false;
    this.onDrawerClose = () => {};
  }
  //#endregion

  //#region Table multi select
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
  //#endregion

  //#region Add users to groups event
  public onAddUsersToGroupsBtnClicked(): void {
    this.openDrawer(this.onHideAddUsersToGroupForm);
    this.showAddUsersToGroupsForm = true;
  }

  public onAddUserToGroupsBtnClicked(user: UserOrganizationGetOrganizationUsersContract): void {
    this.selection.clear();
    this.selection.select(user);
    this.openDrawer(this.onHideAddUsersToGroupForm);
    this.showAddUsersToGroupsForm = true;
  }

  public onHideAddUsersToGroupForm(): void {
    this.closeDrawer();
    this.showAddUsersToGroupsForm = false;
    this.selection.clear();
  }

  public onAddUsersToGroups(): void {
    this.onHideAddUsersToGroupForm();
  }

  //#endregion

  //#region Add users to mail accounts event
  public onAddUsersToMailAccountsBtnClicked(): void {
    this.openDrawer(this.onHideAddUsersToMailAccountsForm);
    this.showAddUsersToMailAccountsForm = true;
  }

  public onAddUserToMailAccountsBtnClicked(user: UserOrganizationGetOrganizationUsersContract): void {
    this.selection.clear();
    this.selection.select(user);
    this.openDrawer(this.onHideAddUsersToMailAccountsForm);
    this.showAddUsersToMailAccountsForm = true;
  }

  public onHideAddUsersToMailAccountsForm(): void {
    this.closeDrawer();
    this.showAddUsersToMailAccountsForm = false;
    this.selection.clear();
  }

  public onAddUsersToMailAccounts(): void {
    this.onHideAddUsersToMailAccountsForm();
  }
  //#endregion
}
