import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FoldersTypeCheck {
  constructor() {}
  public isInbox$ = new Subject<boolean>();
  public isAnyFolder$ = new Subject<boolean>();
  public isAnyFolder = true;
  public isInboxFolderOrSubfolder(mailAccountFolders, currentFolder): void {
    const inboxFolders = mailAccountFolders.data.find((e) => e.data.folderType === 2);
    const ids = [];
    function findIds(folder) {
      if (folder.children) {
        folder.children.forEach((e) => findIds(e));
      }
      ids.push(folder.data.mailFolderId);
    }
    findIds(inboxFolders);
    const isInbox = ids.includes(currentFolder.data.mailFolderId);
    this.isInbox$.next(isInbox);
  }
  public setIsInboxTrue(): void {
    this.isInbox$.next(true);
  }
  public setIsAnyFolder(isAny: boolean): void {
    this.isAnyFolder$.next(isAny);
    this.isAnyFolder = isAny;
  }
}
