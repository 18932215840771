import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../services/auth/authentication.service';

@Component({
  selector: 'app-error401-page',
  templateUrl: './error401-page.component.html',
  styleUrls: ['./error401-page.component.scss'],
})
export class Error401PageComponent implements OnInit {
  public returnUrl: string;
  public canRedirectToHomePage: boolean;
  public homeUrl: string | null;

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const webCode = this.activatedRoute.snapshot.paramMap.get('webCode');
    if (webCode) {
      this.homeUrl = `/${webCode}`;
      this.canRedirectToHomePage = true;
    } else {
      this.canRedirectToHomePage = false;
    }
    this.returnUrl = this.router.getCurrentNavigation()?.extras?.state?.returnUrl;
  }

  async signout(): Promise<void> {
    const webCode = this.activatedRoute.snapshot.paramMap.get('webCode');

    if (webCode) {
      await this.authenticationService.logout();
      document.location.href = `${environment.feUrl}signout?webCode=${webCode}`;
    }
  }
}
