import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EventsService } from 'src/app/services/events/events.service';
import { MatchError } from 'src/app/shared/error-matcher';
import { MailAccountClient } from '../../sdk/clients/mail-account.client';
import { TagClient } from '../../sdk/clients/tag.client';
import { BaseGetByIdRequest } from '../../sdk/contracts/common/base-get-by-id.request';
import { OrderDirection } from '../../sdk/contracts/common/order-direction';
import { GetOrganizationMailAccountsRequest } from '../../sdk/contracts/mail-account/get-organization-mail-accounts.request';
import { MailAccountContract } from '../../sdk/contracts/mail-account/mail-account.contract';
import { FoldersUtilityService } from '../../services/folders-utility/folders-utility.service';
import { SearchContext } from '../../services/search/search-context';
import { SearchContextStorageService } from '../../services/search/search-context-storage.service';

@Component({
  selector: 'app-email-search-advanced-dialog',
  templateUrl: './email-search-advanced-dialog.component.html',
  styleUrls: ['./email-search-advanced-dialog.component.scss'],
})
export class EmailSearchAdvancedDialogComponent implements OnInit, OnDestroy {
  @Output() closeEventEmitter = new EventEmitter();
  public webCode: string;
  public isLoading = true;
  public cannotLoadData = false;
  public searchContext: SearchContext;
  private searchContextSubscription: Subscription;
  public searchDirections = Object.keys(OrderDirection)
    .filter((k) => !(parseInt(k, 10) >= 0))
    .map((key) => ({
      id: OrderDirection[key],
      name: key,
    }));

  public existedTags: string[] = [];
  public selectedTags: string[] = [];
  public reassignId: number;
  public seen = [
    { value: 0, text: 'showAll' },
    { value: 2, text: 'onlySeen' },
    { value: 1, text: 'onlyUnseen' },
  ];
  public attachments = [
    { value: 0, text: 'showAll' },
    { value: 2, text: 'onlyWithAttachments' },
    { value: 1, text: 'onlyWithoutAttachments' },
  ];
  public source = [
    { value: 0, text: 'showAll' },
    { value: 1, text: 'mailboxPEC' },
    { value: 2, text: 'reassignment' },
  ];
  public notifications = [
    { value: 0, text: 'show all' },
    { value: 1, text: 'only without notifications' },
  ];
  public reassigned = [
    { value: 1, text: 'not reassigned' },
    { value: 2, text: 'reassigned' },
    { value: 0, text: 'all' },
  ];
  public validated = [
    { value: 1, text: 'New' },
    { value: 2, text: 'Validated' },
    { value: 3, text: 'Not validated' },
    { value: 0, text: 'all' },
  ];

  public selectedAttachmentsStatus;
  public selectedSourceStatus;
  public selectedHideNotificationsStatus;
  public selectedSeenStatus;
  public selectedReassignedId;
  public selectedReassignedStatus;
  public selectedValidationStatus;
  public accounts: MailAccountContract[] = [];
  public searchType: number = 1;

  public filterForm: UntypedFormGroup = new UntypedFormGroup({
    sentStart: new UntypedFormControl(),
    sentEnd: new UntypedFormControl(),
    downloadStart: new UntypedFormControl(),
    downloadEnd: new UntypedFormControl(),
  });

  constructor(
    public matchError: MatchError,
    private searchContextStorageService: SearchContextStorageService,
    private eventsService: EventsService,
    private tagClient: TagClient,
    private mailAccountClient: MailAccountClient,
    private route: ActivatedRoute,
    private router: Router,
    private foldersUtilityService: FoldersUtilityService
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.searchContextSubscription = this.searchContextStorageService.searchContext$
      .pipe(filter((searchContext) => !!searchContext.selectedMailFolderIds))
      .subscribe((searchContext) => {
        this.searchContext = searchContext;
      });
    if (this.searchContext.searchParams.reassignedId === null) {
      this.selectedReassignedId = 0;
    } else {
      this.selectedReassignedId = this.searchContext.searchParams.reassignedId;
    }
    this.searchType = JSON.parse(localStorage.getItem('searchType')) || 1;
    if (this.searchContext.searchParams.reassigned === null) {
      this.selectedReassignedStatus = 0;
    } else if (this.searchContext.searchParams.reassigned === false) {
      this.selectedReassignedStatus = 1;
    } else {
      this.selectedReassignedStatus = 2;
    }
    if (this.searchContext.searchParams.seen === null) {
      this.selectedSeenStatus = 0;
    } else if (this.searchContext.searchParams.seen === false) {
      this.selectedSeenStatus = 1;
    } else {
      this.selectedSeenStatus = 2;
    }
    if (this.searchContext.searchParams.withAttachments === null) {
      this.selectedAttachmentsStatus = 0;
    } else if (this.searchContext.searchParams.withAttachments === false) {
      this.selectedAttachmentsStatus = 1;
    } else {
      this.selectedAttachmentsStatus = 2;
    }
    if (this.searchContext.searchParams.source === null) {
      this.selectedSourceStatus = 0;
    } else if (this.searchContext.searchParams.source === false) {
      this.selectedSourceStatus = 1;
    } else {
      this.selectedSourceStatus = 2;
    }
    if (this.searchContext.searchParams.hideNotifications === false) {
      this.selectedHideNotificationsStatus = 0;
    } else {
      this.selectedHideNotificationsStatus = 1;
    }

    this.filterForm.setValue({
      sentStart: this.searchContext.searchParams.sentDateTime.start || null,
      sentEnd: this.searchContext.searchParams.sentDateTime.end
        ? this.substractDays(this.searchContext.searchParams.sentDateTime.end, 1)
        : null,
      downloadStart: this.searchContext.searchParams.downloadDateTime.start || null,
      downloadEnd: this.searchContext.searchParams.downloadDateTime.end || null,
    });
    // console.log(123123, this.filterForm)
    await this.loadData();
    setTimeout(() => {
      document.getElementById('focusSearch').focus();
    }, 33);
  }

  onSearchTypeChange(value) {
    localStorage.setItem('searchType', JSON.stringify(value));
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  substractDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  ngOnDestroy(): void {
    this.searchContextSubscription?.unsubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    }
    if (event.key === 'Escape') {
      this.closeEventEmitter.emit();
    }
  }

  async loadData(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadData = false;
    try {
      const tagResponse = await this.tagClient.getForMailAccount(
        new BaseGetByIdRequest(this.searchContextStorageService.currentMailAccountId)
      );
      this.existedTags = tagResponse.data.map((e) => e.tag);
      this.selectedTags = this.searchContext.searchParams.tags;

      const mailAccountRequest = await this.mailAccountClient.getOrganizationMailAccounts(
        new GetOrganizationMailAccountsRequest(this.webCode)
      );
      this.accounts = mailAccountRequest.data;
    } catch (e) {
      this.cannotLoadData = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async search(): Promise<void> {
    this.selectedReassignedId === 0
      ? (this.searchContext.searchParams.reassignedId = null)
      : (this.searchContext.searchParams.reassignedId = this.selectedReassignedId);
    this.searchContext.searchParams.sentDateTime.start = this.filterForm.controls.sentStart?.value;
    this.searchContext.searchParams.sentDateTime.end =
      this.filterForm.controls.sentEnd?.value && this.addDays(this.filterForm.controls.sentEnd?.value, 1);
    this.searchContext.searchParams.downloadDateTime.start = this.filterForm.controls.downloadStart?.value;
    this.searchContext.searchParams.downloadDateTime.end = this.filterForm.controls.downloadEnd?.value;

    if (this.selectedValidationStatus && this.selectedValidationStatus != 0) {
      this.searchContext.searchParams.validated = [this.selectedValidationStatus];
    } else {
      this.searchContext.searchParams.validated = null;
    }

    if (this.selectedSourceStatus === 0) {
      this.searchContext.searchParams.source = null;
    } else if (this.selectedSourceStatus === 1) {
      this.searchContext.searchParams.source = false;
    } else {
      this.searchContext.searchParams.source = true;
    }

    if (this.selectedReassignedStatus === 0) this.searchContext.searchParams.reassigned = null;
    else if (this.selectedReassignedStatus === 1) this.searchContext.searchParams.reassigned = false;
    else this.searchContext.searchParams.reassigned = true;

    if (this.selectedSeenStatus === 0) this.searchContext.searchParams.seen = null;
    else if (this.selectedSeenStatus === 1) this.searchContext.searchParams.seen = false;
    else this.searchContext.searchParams.seen = true;

    if (this.selectedAttachmentsStatus === 0) this.searchContext.searchParams.withAttachments = null;
    else if (this.selectedAttachmentsStatus === 1) this.searchContext.searchParams.withAttachments = false;
    else this.searchContext.searchParams.withAttachments = true;

    if (this.selectedHideNotificationsStatus === 0) this.searchContext.searchParams.hideNotifications = false;
    else this.searchContext.searchParams.hideNotifications = true;

    this.searchContext.searchParams.tags = this.selectedTags;
    // 1) for each selectedMailFolderIds find mail account
    // 2) for each found mail account get all folders -> search

    let selectedMailFolders = this.searchContext.selectedMailFolderIds;
    let currentFolder = this.searchContextStorageService.currentFolderId;

    if (this.searchType === 1) {
      this.searchContextStorageService.setSearchContext([currentFolder], this.searchContext.searchParams, 1);
    }

    if (this.searchType === 2) {
      const foldersIds = this.foldersUtilityService.getFoldersAndSubfoldersId(selectedMailFolders);
      this.searchContextStorageService.setSearchContext(foldersIds, this.searchContext.searchParams, 1);
    }

    if (this.searchType === 3) {
      const foldersIds = this.foldersUtilityService.getAllFoldersId(selectedMailFolders);
      this.searchContextStorageService.setSearchContext(foldersIds, this.searchContext.searchParams, 1);
    }
    await this.router.navigate([this.webCode, 'emails']);
    // this.searchContext.searchParams.sentDateTime.end = this.filterForm.controls.sentEnd?.value && this.filterForm.controls.sentEnd?.value;
    this.closeEventEmitter.emit();
  }

  resetParams(): void {
    this.searchType = 1;
    this.selectedReassignedId = 0;
    this.selectedAttachmentsStatus = 0;
    this.selectedSeenStatus = 0;
    this.selectedTags = [];
    this.searchContextStorageService.resetParams();
    this.filterForm.setValue({
      sentStart: this.searchContext.searchParams.sentDateTime.start || null,
      sentEnd: this.searchContext.searchParams.sentDateTime.end
        ? this.substractDays(this.searchContext.searchParams.sentDateTime.end, 1)
        : null,
      downloadStart: this.searchContext.searchParams.downloadDateTime.start || null,
      downloadEnd: this.searchContext.searchParams.downloadDateTime.end || null,
    });
  }
}
