import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ProviderGetAllResponse } from '../contracts/provider/provider.get-all.response';
import { ProviderCreateRequest } from '../contracts/provider/provider.create.request';
import { ProviderCreateResponse } from '../contracts/provider/provider.create.response';
import { ProviderUpdateRequest } from '../contracts/provider/provider.update.request';
import { ProviderGetByIdResponse } from '../contracts/provider/provider.get-by-id.response';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseDeleteResponse } from '../contracts/common/base-delete.response';

const API_URL = environment.apiUrl;

@Injectable()
export class ProviderClient {
  constructor(private apiRequest: ApiRequest) {}

  async getAll(): Promise<ProviderGetAllResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.provider.getAll}`);
  }

  async getByOrganization(request: BaseGetByIdRequest): Promise<ProviderGetAllResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.provider.getByOrganization}?${request.format()}`);
  }

  async search(): Promise<ProviderGetAllResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.provider.search}`);
  }

  async create(request: ProviderCreateRequest): Promise<ProviderCreateResponse> {
    return this.apiRequest.post<ProviderCreateRequest, ProviderCreateResponse>(request, `${API_URL}${ApiRoutes.provider.create}`);
  }

  async update(request: ProviderUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<ProviderCreateRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.provider.update}`);
  }

  async delete(request: BaseDeleteRequest): Promise<BaseDeleteResponse> {
    return this.apiRequest.post<BaseDeleteRequest, BaseDeleteResponse>(request, `${API_URL}${ApiRoutes.provider.delete}`);
  }

  async getById(request: BaseGetByIdRequest): Promise<ProviderGetByIdResponse> {
    return this.apiRequest.get<ProviderGetByIdResponse>(`${API_URL}${ApiRoutes.provider.get}?${request.format()}`);
  }
}
