import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganizationContract } from '../../../../sdk/contracts/organization/organization.contract';
import { OrganizationClient } from '../../../../sdk/clients/organization.client';
import { OrganizationType } from '../../../../sdk/contracts/organization/organization-type';
import { OrganizationCreateRequest } from '../../../../sdk/contracts/organization/organization.create.request';
import { OrganizationSettingsContract } from '../../../../sdk/contracts/organization/settings/organization-settings.contract';
import { ErrorCode } from '../../../../sdk/api-errors/error-code';
import { MatchError } from '../../../../shared/error-matcher';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PignorState } from 'src/app/sdk/contracts/organization/pignor-type';
import { ClaimClient } from 'src/app/sdk/clients/claim';
import { OrganizationSettingsUpdateRequest } from 'src/app/sdk/contracts/organization/settings/organization-settings.update.request';
import { OrganizationSettingsClient } from 'src/app/sdk/clients/organization-settings.client';
import { SamlClaims } from 'src/app/sdk/contracts/mail-account/settings/saml-claims.contract';
import { RoleType } from 'src/app/sdk/contracts/role/role-type';
import { SpaceUsedPaymentPlanSelectTypesWithNull } from 'src/app/sdk/contracts/organization/settings/spaceUsedPaymentPlanTypes';

@Component({
  selector: 'app-organization-create-dialog',
  templateUrl: './organization-create-dialog.component.html',
  styleUrls: ['./organization-create-dialog.component.scss'],
})
export class OrganizationCreateDialogComponent implements OnInit {
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public loadingParentOrganizations = false;
  public cannotLoadParentOrganizations = false;
  public availableParentOrganizations: OrganizationContract[];
  public isError: boolean;
  public errorText: string;
  public handlingErrorCodes = new Map<number, string>([
    [ErrorCode.WebCodeAlreadyUsed, 'Cannot create company: web code already used'],
    [ErrorCode.LicenseIdAndCodeSiaAlreadyUsed, 'Cannot create company: LicenseID and CodeSia already used'],
  ]);
  public isLoading: boolean;
  public form: UntypedFormGroup = new UntypedFormGroup({});
  // public mailAccountSamlClaimsForm: FormGroup = new FormGroup({});
  public organizationTypes = OrganizationType;
  public availableOrganizationTypes = [OrganizationType.Company, OrganizationType.Group];
  public parentOrganization: OrganizationContract;
  public oidcSupport = true;
  public adfsSupport = false;
  public googleOAuthSupport = true;
  public samlSupport: boolean = false;
  public pignor: boolean = false;
  public spaceUsedPaymentPlanType = SpaceUsedPaymentPlanSelectTypesWithNull;

  constructor(
    private organizationClient: OrganizationClient,
    public claimClient: ClaimClient,
    public matchError: MatchError,
    public organizationSettingsClient: OrganizationSettingsClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.maxLength(256)]),
      code: new UntypedFormControl('', [Validators.required, Validators.maxLength(256)]),
      samlRtaClaim: new UntypedFormControl('', []),
      type: new UntypedFormControl(OrganizationType.Company, [Validators.required]),
      codeSia: new UntypedFormControl('', [Validators.required, Validators.maxLength(15)]),
      licenseId: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
      webCode: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern('^([a-z][a-z0-9_]*)(-[a-z0-9]+)*$'),
      ]),
      anagId: new UntypedFormControl('', [
        Validators.required,
        // Validators.maxLength(10),
        // Validators.minLength(10),
        // Validators.pattern('^[0-9]*$'),
      ]),
      spaceUsedPaymentPlanType: new UntypedFormControl(null, [Validators.required]),
    });
    // this.mailAccountSamlClaimsForm = new FormGroup({
    //   claimId: new FormControl('', [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(1)]),
    //   claimCode: new FormControl('', [Validators.maxLength(256), Validators.required]),
    //   organizationId: new FormControl('', [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(1)]),
    //   mailAccountId: new FormControl('', [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(1)]),
    //   role: new FormControl('', [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(1)]),
    // });
    this.form.controls['samlRtaClaim'].disable();
    await this.loadAvailableParentOrganization();
  }

  public async loadAvailableParentOrganization(): Promise<void> {
    this.loadingParentOrganizations = true;
    this.cannotLoadParentOrganizations = false;
    try {
      const response = await this.organizationClient.getAvailableParentOrganizations();
      this.availableParentOrganizations = response.data;
    } catch (e) {
      this.cannotLoadParentOrganizations = true;
      this.matchError.logError(e);
    } finally {
      this.loadingParentOrganizations = false;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  // public hasErrorSamlForm = (controlName: string, errorName: string) => {
  //   return this.mailAccountSamlClaimsForm.controls[controlName].hasError(errorName);
  // };

  public onSamlChange(isChecked: boolean) {
    if (isChecked) {
      this.form.controls['samlRtaClaim'].setValidators([Validators.required, Validators.maxLength(256)]);
      this.form.controls['samlRtaClaim'].enable();
      this.googleOAuthSupport = false;
      this.oidcSupport = false;
    } else {
      this.form.controls['samlRtaClaim'].disable();
      this.form.controls['samlRtaClaim'].setValidators([]);
    }
    this.form.controls['samlRtaClaim'].markAsTouched();
    this.form.controls['samlRtaClaim'].updateValueAndValidity();
  }

  async onSubmit(organizationContract: OrganizationContract): Promise<void> {
    if (this.form.valid && this.authTypeIsSelected) {
      this.isLoading = true;
      this.isError = false;
      const settings = new OrganizationSettingsContract();
      settings.samlSupport = this.samlSupport;
      settings.oidcSupport = this.oidcSupport;
      settings.adfsSupport = this.adfsSupport;
      settings.googleOAuthSupport = this.googleOAuthSupport;
      settings.samlRtaClaim = this.form.get('samlRtaClaim').value;
      const request = new OrganizationCreateRequest(
        organizationContract.name,
        organizationContract.code,
        organizationContract.type,
        this.parentOrganization?.organizationId,
        organizationContract.codeSia,
        organizationContract.licenseId,
        organizationContract.webCode,
        organizationContract.anagId,
        this.pignor ? PignorState.Active : PignorState.Disabled
      );
      try {
        const response = await this.organizationClient.create(request);
        const organizationId = response.organizationId;
        const updateRequest = new OrganizationSettingsUpdateRequest();
        updateRequest.organizationId = organizationId;
        updateRequest.samlSupport = this.samlSupport;
        updateRequest.googleOAuthSupport = this.googleOAuthSupport;
        updateRequest.adfsSupport = this.adfsSupport;
        updateRequest.oidcSupport = this.oidcSupport;
        updateRequest.spaceUsedPaymentPlanType = organizationContract.spaceUsedPaymentPlanType;
        await this.organizationSettingsClient.createOrUpdate(updateRequest);
        if (this.samlSupport) {
          const updateRequestSamlClaims = new SamlClaims();
          updateRequestSamlClaims.claimId = null;
          updateRequestSamlClaims.claimCode = this.form.get('samlRtaClaim').value;
          updateRequestSamlClaims.organizationId = organizationId;
          updateRequestSamlClaims.role = RoleType.Rta;
          await this.claimClient.createOrUpdateRtaClaimByOrganizationId(updateRequestSamlClaims);
        }
        this.creationSubmit.emit();
        this.form.reset();
      } catch (e) {
        this.isError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }

  public get authTypeIsSelected(): boolean {
    return this.oidcSupport || this.adfsSupport || this.googleOAuthSupport || this.samlSupport;
  }
}
