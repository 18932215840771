import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationType } from '../../sdk/contracts/authentication/authentication-type';
import { MatchError } from '../../shared/error-matcher';
import { ErrorCode } from '../../sdk/api-errors/error-code';
import { environment } from '../../../environments/environment';
import { AuthGuard } from '../../services/auth/auth.guard';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { LoggingHandler } from '../../services/logging/logging.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public isLoading = true;
  public webCode: string;
  public authTypes: AuthenticationType[] = [];
  public handlingErrorCodes = new Map<number, string>([[ErrorCode.OrganizationDontExist, `Organization doesn't exist`]]);
  public selectedAuthType: AuthenticationType;

  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    public matchError: MatchError,
    private authGuard: AuthGuard,
    protected authenticationService: AuthenticationService,
    private loggingHandler: LoggingHandler,
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.snapshot.paramMap.get('webCode');
    await this.loggingHandler.create('information', 'SignInComponent ngOnInit');
    if (this.authenticationService.isLoggedIn()) {
      await this.loggingHandler.create('information', 'redirect SignInComponent ngOnInit');
      this.router.navigateByUrl(`/${this.webCode}`);
      return;
    }
    const currentLoginType = JSON.parse(localStorage.getItem('authType'));
    const params = this.route.snapshot.queryParams?.otherData;
    this.authTypes = params
      ? JSON.parse(this.route.snapshot.queryParams?.otherData)
      : await this.authGuard.loadAuthTypes(this.webCode);
    this.selectedAuthType = currentLoginType || null;
    this.isLoading = false;
  }

  public getAuthTypeDescription(authType: AuthenticationType): string {
    switch (authType) {
      case AuthenticationType.Adfs:
        return 'ADFS';
      case AuthenticationType.Google:
        return 'google';
      case AuthenticationType.Oidc:
        return 'GAW';
    }
  }

  public onLogin(): Promise<void> {
    localStorage.setItem(`authType`, JSON.stringify(this.selectedAuthType));
    switch (this.selectedAuthType) {
      case AuthenticationType.Oidc:
        this.loggingHandler.create('information', 'redirect onLogin Oidc');
        document.location.href = `${environment.oidcSignInUri}?webCode=${this.webCode}`;
        return;
      case AuthenticationType.Adfs:
        alert('todo');
        return;
      case AuthenticationType.Google:
        this.loggingHandler.create('information', 'redirect onLogin Google');
        document.location.href = `${environment.googleSignInUri}?webCode=${this.webCode}`;
        return;
    }
  }
}
