<div class="drawer-content-screen flex flex-col justify-between h-full">
  <div *ngIf="cannotLoadSetting" class="h-full flex justify-center flex-col">
    <app-something-went-wrong description="cannotLoadData" [showPicture]="false" (retryEmitter)="ngOnInit()"></app-something-went-wrong>
  </div>
  <app-loader class="h-full flex justify-center flex-col" *ngIf="isLoading"></app-loader>
  <div class="w-full" *ngIf="!isLoading && !cannotLoadSetting">
    <div>
      <div class="text-2xl mb-4 font-medium" style="overflow-wrap: anywhere">
        {{ "folderSettingsFor" | translate }} {{ mailFolderName }}
      </div>
      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <div *ngIf="isShowPignorCheckbox && folderType === mailFolderType.Inbox" class="flex flex-col">
          <div class="font-medium text-lg mb-3 mt-6">{{ "pignor" | translate }}</div>
          <mat-checkbox color="primary" [(ngModel)]="pignor" [ngModelOptions]="{ standalone: true }">{{
            "pignor" | translate
          }}</mat-checkbox>
        </div>
        <!-- <mat-form-field appearance="fill">
          <mat-label>Code</mat-label>
          <input matInput id="code" formControlName="code">
        </mat-form-field> -->
        <div class="flex flex-col">
          <div class="font-medium text-lg mb-3 mt-6">{{ "archive" | translate }}</div>
          <mat-checkbox color="primary" [disableRipple]="true" formControlName="archive">{{ "archive" | translate }}</mat-checkbox>
          <!-- <mat-checkbox color="primary" [disableRipple]="true" formControlName="canValidate">Can validate</mat-checkbox>
          <mat-checkbox color="primary" [disableRipple]="true" formControlName="canReassign">Can reassign</mat-checkbox> -->
        </div>
      </form>
    </div>
  </div>
  <div class="w-full" *ngIf="!isLoading && !cannotLoadSetting">
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isSaving">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isSaving || !form.valid" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isSaving"></app-loading-button>
      </button>
    </div>
  </div>
</div>
