import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { TagContract } from '../contracts/tag/tag.contract';
import { BaseIdListRequest } from '../contracts/common/base.id-list.request';
import { BaseCollectionSearchByIdRequest } from '../contracts/common/base-collection-search-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../contracts/common/base-get-search-count-by-id.request';
import { BaseValueResponse } from '../contracts/common/base-value-response';
import { InsertTagRequest } from '../contracts/tag/insert-tag.request';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class TagClient {
  constructor(private http: HttpClient, private apiRequest: ApiRequest) {}

  async getForMailAccount(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<TagContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TagContract>>(`${API_URL}${ApiRoutes.tag.getForMailAccount}?${request.format()}`);
  }

  async getList(request: BaseIdListRequest): Promise<BaseGetCollectionResponse<TagContract>> {
    return this.apiRequest.post<BaseIdListRequest, BaseGetCollectionResponse<TagContract>>(request, `${API_URL}${ApiRoutes.tag.getList}`);
  }

  async searchForMailAccount(request: BaseCollectionSearchByIdRequest): Promise<BaseGetCollectionResponse<TagContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TagContract>>(
      `${API_URL}${ApiRoutes.tag.searchForMailAccount}?${request.format()}`
    );
  }

  async getSearchCountForMailAccount(request: BaseGetSearchCountByIdRequest): Promise<BaseValueResponse<number>> {
    return this.apiRequest.get<BaseValueResponse<number>>(`${API_URL}${ApiRoutes.tag.getSearchCountForMailAccount}?${request.format()}`);
  }

  async insert(request: InsertTagRequest): Promise<void> {
    await this.apiRequest.post<InsertTagRequest, void>(request, `${API_URL}${ApiRoutes.tag.insert}`);
  }

  async delete(request: BaseDeleteRequest): Promise<void> {
    await this.apiRequest.post<BaseDeleteRequest, void>(request, `${API_URL}${ApiRoutes.tag.delete}`);
  }

  async getAll(): Promise<BaseGetCollectionResponse<string>> {
    return await this.apiRequest.get<BaseGetCollectionResponse<string>>(`${API_URL}${ApiRoutes.tag.getAll}`);
  }
}
