import { PignorState } from "../../organization/pignor-type";

export class MailFolderSettingsUpdateRequest {
  mailFolderId: number;
  archive: boolean;
  canValidate: boolean;
  canReassign: boolean;
  archiveSettings: string;
  code: string;
  pignorState: PignorState;
}
