import { Component, OnInit } from '@angular/core';
import { LoggingHandler } from 'src/app/services/logging/logging.service';

@Component({
  selector: 'app-waf-page',
  templateUrl: './waf-page.component.html',
  styleUrls: ['./waf-page.component.scss'],
})
export class WafPageComponent implements OnInit {
  public webCode: string;

  constructor(public loggingHandler: LoggingHandler) {}

  async ngOnInit(): Promise<void> {
    await this.loggingHandler.create('error', 'waf-page-opened');
  }
}
