import { Pipe, PipeTransform } from '@angular/core';
import { EmailAuditEventType } from '../../../sdk/contracts/email-audit/email-audit-event-type';

@Pipe({
  name: 'emailAuditEventType',
})
export class EmailAuditEventTypePipe implements PipeTransform {
  transform(value: number): string {
    return 'emailAuditEventType' + EmailAuditEventType[value];
  }
}
