import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { AuthGuard } from './auth.guard';
import { PermissionType } from '../../sdk/contracts/permission/permission-type';
import { PermissionService } from '../permission/permission.service';
import { AuthenticationClient } from '../../sdk/clients/authentication.client';
import { MatchError } from '../../shared/error-matcher';
import { LoggingHandler } from '../logging/logging.service';

@Injectable({ providedIn: 'root' })
export class RootAuthGuard extends AuthGuard {
  constructor(
    protected router: Router,
    protected authenticationService: AuthenticationService,
    private permissionService: PermissionService,
    protected authenticationClient: AuthenticationClient,
    protected matchError: MatchError,
    protected loggingHandler: LoggingHandler,
  ) {
    super(router, authenticationService, authenticationClient, matchError, loggingHandler);
  }

  override async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const superActivationResult = await super.canActivate(route, state);

    if (!superActivationResult) {
      return false;
    } else {
      return await this.permissionService.hasPermissionOver(
        null,
        null,
        PermissionType.CanEverything
      );
    }
  }
}
