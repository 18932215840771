<div class="relative">
  <h1 mat-dialog-title>{{ "reassignEmails" | translate }}</h1>
  <button tabindex="-1" style="position: absolute; top: -20px; right: -17px" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div *ngIf="isLoading">
    <app-loader height="100%"></app-loader>
  </div>

  <div *ngIf="cannotLoad">
    <app-something-went-wrong
      [showPicture]="false"
      description="{{ 'cannotLoadData' | translate }}"
      (retryEmitter)="loadData()"></app-something-went-wrong>
  </div>

  <div *ngIf="!isLoading && !cannotLoad">
    <mat-form-field *ngIf="selectedMailAccountId" appearance="fill">
      <mat-label>{{ "accountForReassign" | translate }}</mat-label>
      <mat-select [(value)]="selectedMailAccountId" (selectionChange)="onAccountChange()">
        <mat-option *ngFor="let account of accounts" [value]="account.mailAccountId">
          {{ account.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-error-hint
      *ngIf="cannotSave"
      errorText="{{ 'cannotReassignErrorPopupSubtitle' | translate }}"
      [centerAlign]="false"></app-error-hint>
  </div>
</div>
<div mat-dialog-actions>
  <div class="w-full flex justify-end">
    <button mat-button mat-flat-button color="primary" (click)="confirm()" [disabled]="saving || isLoading || cannotLoad || isLoadingFolders">
      <app-loading-button text="{{ 'moveReassign' | translate }}" [loading]="saving"></app-loading-button>
    </button>
  </div>
</div>
