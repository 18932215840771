import { Component, OnInit } from '@angular/core';
import { BreadcrumbItem } from '../../shared/breadcrumb-item';
import { TabItem } from '../../shared/tab-item';
import { UserContract } from '../../sdk/contracts/user/user.contract';
import { UserClient } from '../../sdk/clients/user.client';
import { PermissionService } from '../../services/permission/permission.service';
import { PermissionType } from '../../sdk/contracts/permission/permission-type';
import { UserOrganizationClient } from '../../sdk/clients/user-organization.client';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { OrganizationSettingsClient } from 'src/app/sdk/clients/organization-settings.client';
import { BaseGetByIdRequest } from 'src/app/sdk/contracts/common/base-get-by-id.request';
import { MatchError } from 'src/app/shared/error-matcher';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LOCALE_IDS } from 'src/app/shared/constants';
import { ListOfPossibleLanguages } from 'src/app/shared/list-of-possible-languages';
import { FormatAccountLanguage } from 'src/app/shared/pipes/format-account-language.pipe';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
  public breadcrumbItems: BreadcrumbItem[] = [{ index: 0, label: 'accountSettingsBreadCrumbs', route: null }];
  public tabItems: TabItem[] = [];
  public isLoading = false;
  public isError = false;
  public isSamlSupport = false;
  public user: UserContract;
  public updateDrawerIsOpen = false;
  public changePasswordDrawerIsOpen = false;
  public canChangePassword = false;
  public webCode: string;
  public userLocale: string;
  public localeLanguage: string;
  public organizationId: number;
  public locales = LOCALE_IDS;
  public possibleLanguages = ListOfPossibleLanguages;

  constructor(
    private formatAccountLanguage: FormatAccountLanguage,
    private userClient: UserClient,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    public matchError: MatchError,
    private organizationSettingsClient: OrganizationSettingsClient,
    private userOrganizationClient: UserOrganizationClient,
    public translate: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    await this.loadUser();
    if (!this.user.localizationCode) this.setCorrectLanguage(this.user.localizationCode);
    this.translate.use(localStorage.getItem('userLocale'));
  }

  private setCorrectLanguage(code) {
    if (code) {
      localStorage.setItem('userLocale', code);
    } else {
      let elem = navigator.language.split('-')[0];
      if (this.possibleLanguages.indexOf(elem) !== -1) {
        code = elem;
      } else {
        code = environment.language;
      }
    }
  }

  public async loadUser(): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    try {
      const organizations = (await this.userOrganizationClient.getUserOrganizations()).organizations;
      const organizationId = organizations.find((e) => e.webCode === this.webCode)?.organizationId;
      this.organizationId = organizationId;
      if (organizationId) {
        const organizationSettings = await this.organizationSettingsClient.getByOrganization(new BaseGetByIdRequest(organizationId));
        this.isSamlSupport = organizationSettings.result.samlSupport;
      }
      const response = await this.userClient.getUser();
      this.user = response.result;
      await this.loadCanChangePassword();
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
  public onChangePassword() {
    const fullURL = location.href;
    window.location.href = `${environment.apiOauthUrl}?returnUrl=${fullURL}`;
  }
  private async loadCanChangePassword(): Promise<void> {
    if (await this.permissionService.hasPermissionOver(null, null, PermissionType.CanEverything)) {
      this.canChangePassword = false;
      return;
    }

    const organizations = (await this.userOrganizationClient.getUserOrganizations()).organizations;
    if (!organizations || organizations.length === 0) {
      this.canChangePassword = false;
      return;
    }
    const organizationId = organizations.find((e) => e.webCode === this.webCode)?.organizationId;
    // if (await this.permissionService.hasPermissionOver(organizationId, null, PermissionType.CanManageOrganization)) {
    //   this.canChangePassword = false;
    //   return;
    // }

    this.canChangePassword = true;
  }

  openUpdateUserDialog(): void {
    this.updateDrawerIsOpen = true;
  }

  async onUpdateUser(name): Promise<void> {
    this.updateDrawerIsOpen = false;
    this.user.firstName = name.firstName;
    this.user.lastName = name.lastName;
    this.user.localizationCode = name.localizationCode;
    //this.userLocale$.next(name.localizationCode);
    localStorage.setItem('userLocale', this.user.localizationCode);
    this.translate.use(localStorage.getItem('userLocale'));
  }

  onCloseUpdatingModalForm(): void {
    this.updateDrawerIsOpen = false;
  }

  openChangePasswordUserDialog(): void {
    this.changePasswordDrawerIsOpen = true;
  }

  // async onChangePassword(): Promise<void> {
  //   this.changePasswordDrawerIsOpen = false;
  // }

  onCloseChangePasswordModalForm(): void {
    this.changePasswordDrawerIsOpen = false;
  }
}
