import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErrorCode } from '../../../../../../sdk/api-errors/error-code';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MailAccountContract } from '../../../../../../sdk/contracts/mail-account/mail-account.contract';
import { MailAccountClient } from '../../../../../../sdk/clients/mail-account.client';
import { MailAccountChangePasswordRequest } from '../../../../../../sdk/contracts/mail-account/mail-account.change-password.request';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss'],
})
export class MailAccountChangePasswordDialogComponent implements OnInit {
  @Input() public mailAccount: MailAccountContract;
  @Output() public submitEventEmitter = new EventEmitter<MailAccountContract>();
  @Output() public closeEventEmitter = new EventEmitter();
  public isLoading = false;
  public errorText: string;
  public errorCode: ErrorCode | null = null;
  public form = new UntypedFormGroup({});

  public handlingErrorCodes = new Map<number, string>([]);

  constructor(private mailAccountClient: MailAccountClient, public matchError: MatchError, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.mailAccount.name),
      providerName: new UntypedFormControl(this.mailAccount.providerName),
      email: new UntypedFormControl(this.mailAccount.email),
      newPassword: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.errorCode = null;

      const request = new MailAccountChangePasswordRequest(this.mailAccount.mailAccountId, this.form.get('newPassword').value);
      try {
        await this.mailAccountClient.changePassword(request);
        this.submitEventEmitter.emit();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
