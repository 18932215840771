<h1 mat-dialog-title>{{ "confirmUserDeletion" | translate }}</h1>
<div mat-dialog-content>
  <p>
    {{ "deleteUser" | translate }} <span class="font-bold italic">{{ data.userName }}</span> {{ "fromCompany" | translate }}
    <span class="font-bold italic">{{ data.organizationName }}</span> ?
  </p>
  <!-- <app-error-hint *ngIf="hasError"
                  errorText="Cannot delete user from company: something went wrong"
                  [centerAlign]="false">
  </app-error-hint> -->
</div>
<div mat-dialog-actions>
  <div class="w-full flex justify-between">
    <button mat-button (click)="onCancelBtnClicked()">{{ "no" | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onDeleteBtnClicked()">
      <app-loading-button text="{{ 'yes' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</div>
