import { Injectable } from '@angular/core';
import { UserLoginResponse } from '../../sdk/contracts/user/user.login.response';
import { AuthenticationClient } from '../../sdk/clients/authentication.client';
import { EventsService } from '../events/events.service';
import { PermissionService } from '../permission/permission.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { LoggingHandler } from '../logging/logging.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public storageTokenName = 'auth_token';

  constructor(
    private authenticationClient: AuthenticationClient,
    private eventService: EventsService,
    private permissionService: PermissionService,
    private cookieService: CookieService,
    protected loggingHandler: LoggingHandler,
  ) {}

  async logout(): Promise<void> {
    this.eventService.signOutEvent.emit();
    this.permissionService.clearPermissionsOverResources();
    await this.loggingHandler.create('information', 'logout');
  }

  async login(tokenInfo: UserLoginResponse): Promise<void> {
    // localStorage.setItem(this.storageTokenName, JSON.stringify(tokenInfo));
    // this.eventService.signInEvent.emit(tokenInfo);
  }

  getJwtTokenFromCookie(): boolean {
    const cookies = this.cookieService.get(environment.cookieAnchor);
    return cookies ? JSON.parse(cookies) : false;
  }

  isLoggedIn(): boolean {
    return this.getJwtTokenFromCookie();
  }
}
