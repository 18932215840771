import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MailFolderSettingsGetByMailFolderRequest } from '../../../sdk/contracts/mail-folder/settings/mail-folder-settings.get-by-mailfolder.request';
import { MailFolderSettingsUpdateRequest } from '../../../sdk/contracts/mail-folder/settings/mail-folder-settings.update.request';
import { MailFolderSettingsClient } from '../../../sdk/clients/mail-folder-settings.client';
import { MailFolderSettingsContract } from '../../../sdk/contracts/mail-folder/settings/mail-folder-settings.contract';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import { BaseGetByIdRequest } from 'src/app/sdk/contracts/common/base-get-by-id.request';
import { OrganizationClient } from 'src/app/sdk/clients/organization.client';
import { MailAccountSettingsClient } from 'src/app/sdk/clients/mail-account-settings.client';
import { PignorState } from 'src/app/sdk/contracts/organization/pignor-type';
import { MailFolderType } from 'src/app/sdk/contracts/mail-folder/mail-folder.type';

@Component({
  selector: 'app-mail-folder-settings-dialog',
  templateUrl: './mail-folder-settings-dialog.component.html',
  styleUrls: ['./mail-folder-settings-dialog.component.scss'],
})
export class MailFolderSettingsDialogComponent implements OnInit {
  @Input() mailAccountId: number;
  @Input() organizationId: number;
  @Input() folderType: MailFolderType;
  @Input() mailFolderId: number;
  @Input() mailFolderName: string;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();

  public mailFolderType = MailFolderType;
  public settings: MailFolderSettingsContract;
  public form: UntypedFormGroup;
  public isLoading = false;
  public cannotLoadSetting = false;
  public isSaving = false;
  public cannotSave = false;
  public errorText: string;
  public pignor: boolean;
  public isShowPignorCheckbox: boolean;

  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    private mailAccountSettingsClient: MailAccountSettingsClient,
    private mailFolderSettingsClient: MailFolderSettingsClient,
    private organizationClient: OrganizationClient,
    public matchError: MatchError,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadData();

    this.form = new UntypedFormGroup({
      archive: new UntypedFormControl(this.settings.archive),
      canValidate: new UntypedFormControl(this.settings.canValidate),
      canReassign: new UntypedFormControl(this.settings.canReassign),
      code: new UntypedFormControl(this.settings.code),
    });
  }

  async loadData(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadSetting = false;
    try {
      const organizationResponse = await this.organizationClient.getById(new BaseGetByIdRequest(this.organizationId));
      const isOrganizationPignorActive = organizationResponse.organization.pignorState === PignorState.Active ? true : false;
      if (isOrganizationPignorActive) {
        const mailAccountResponse = await this.mailAccountSettingsClient.getByMailAccount(new BaseGetByIdRequest(this.mailAccountId));
        this.isShowPignorCheckbox = mailAccountResponse.result.pignorState === PignorState.Active ? true : false;
      }

      const response = await this.mailFolderSettingsClient.getByMailFolder(
        new MailFolderSettingsGetByMailFolderRequest(this.mailAccountId, this.mailFolderId)
      );
      this.pignor = response.result.pignorState === PignorState.Active ? true : false;
      this.settings = response.result;
    } catch (e) {
      this.cannotLoadSetting = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isSaving = true;
      const updateRequest = new MailFolderSettingsUpdateRequest();
      updateRequest.archive = this.form.controls.archive.value;
      updateRequest.canValidate = this.form.controls.canValidate.value;
      updateRequest.canReassign = this.form.controls.canReassign.value;
      updateRequest.code = this.form.controls.code.value;
      updateRequest.mailFolderId = this.mailFolderId;
      updateRequest.pignorState = this.pignor ? PignorState.Active : PignorState.Disabled;
      try {
        await this.mailFolderSettingsClient.createOrUpdate(updateRequest);
        this.creationSubmit.emit();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isSaving = false;
      }
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public cancel(): void {
    this.closeEventEmitter.emit();
  }
}
