import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import { UserGroupClient } from '../../../../../sdk/clients/user-group.client';
import { UserOrganizationClient } from '../../../../../sdk/clients/user-organization.client';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';
import { Group } from '../../../../../sdk/contracts/group/group.contract';
import { UserGroupRequest } from '../../../../../sdk/contracts/user-group/user-group.request';
import { UserOrganizationGetOrganizationUsersContract } from '../../../../../sdk/contracts/user-organization/user-organization.get-organization-users.contract';
import { PermissionService } from 'src/app/services/permission/permission.service';

interface UserCheckboxes extends UserOrganizationGetOrganizationUsersContract {
  _checked: boolean;
}

@Component({
  selector: 'app-add-groups-to-users-form',
  templateUrl: './add-groups-to-users-form.component.html',
  styleUrls: ['./add-groups-to-users-form.component.scss'],
})
export class AddGroupsToUsersFormComponent implements OnInit {
  @Input() public groups: Group[];
  @Input() private organizationID: number;
  @Output() private close = new EventEmitter();
  @Output() private submit = new EventEmitter();

  public cannotLoadUsers: boolean = false;
  public errorText: string = '';
  public isLoading: boolean = false;
  public isLoadingUsers: boolean = false;

  public handlingErrorCodes = new Map<number, string>([]);

  public search: string = '';
  public filterList = [
    { label: 'allSelect', value: -1 },
    { label: 'showOnlySelectedSelect', value: 1 },
    { label: 'showOnlyNonSelectedSelect', value: 0 },
  ];
  public filters = new UntypedFormControl(this.filterList[0].value);

  public users: UserCheckboxes[] = [];
  public filteredUsersByAdvancedSettings: UserCheckboxes[] = [];
  public filteredUsers: UserCheckboxes[] = [];

  constructor(
    private userOrganizationClient: UserOrganizationClient,
    private permissionService: PermissionService,
    public matchError: MatchError,
    private userGroupClient: UserGroupClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    await this.fetchUsers();
  }

  public async onSubmit() {
    this.isLoading = true;
    const sourceIds = this.groups.map((group) => group.groupId);
    const toIds = this.users.filter((user) => user._checked).map((user) => user.userId);
    this.filteredUsersByAdvancedSettings.forEach((e) => {
      if (e._checked) {
        toIds.push(e.userId);
      }
    });
    const request: UserGroupRequest = {
      sourceIds,
      toIds,
    };
    try {
      await this.userGroupClient.setGroupsUsers(request);
      this.submit.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public onClose() {
    this.close.emit();
  }

  private async fetchUsers() {
    this.cannotLoadUsers = false;
    this.isLoadingUsers = true;
    const request = new BaseGetByIdRequest(this.organizationID);
    try {
      const res = await this.userOrganizationClient.getOrganizationUsers(request);

      if (this.groups.length == 1) {
        const groupUsers = await this.getGroupUsers();
        this.users = res.organizationUsers.map((user) => {
          return { ...user, _checked: groupUsers.includes(user.userId), name: user.firstName + ' ' + user.lastName };
        });
      } else {
        this.users = res.organizationUsers.map((user) => {
          return { ...user, _checked: false, name: user.firstName + ' ' + user.lastName };
        });
      }

      this.users = this.users.filter((e) => {
        if (this.permissionService.hasPermissionToSeeUserByUserEmail(e.email)) {
          return true;
        } else {
          this.filteredUsersByAdvancedSettings.push(e);
          return false;
        }
      });

      this.filteredUsers = this.users;
    } catch (e) {
      this.cannotLoadUsers = true;
      this.matchError.logError(e);
    } finally {
      this.isLoadingUsers = false;
    }
  }

  private async getGroupUsers() {
    const req = new BaseGetByIdRequest(this.groups[0].groupId);
    const groupUsers = await this.userGroupClient.getGroupUsers(req);
    return groupUsers.data;
  }

  isAllSelected() {
    const numSelected = this.users.filter((user) => user._checked).length;
    const numRows = this.users.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.users.forEach((user) => (user._checked = false)) : this.users.forEach((user) => (user._checked = true));
  }

  public hasSelected() {
    let hasSelected = false;
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i]._checked) {
        hasSelected = true;
        break;
      }
    }
    return hasSelected;
  }

  onFilterSelectionChange() {
    if (this.filters.value < 0) {
      this.filteredUsers = this.users;
    }

    if (this.filters.value == 0) {
      this.filteredUsers = this.users.filter((user) => !user._checked);
    }

    if (this.filters.value > 0) {
      this.filteredUsers = this.users.filter((user) => user._checked);
    }
  }
}
