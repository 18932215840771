import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MailAccountGetByOrganizationIdContract } from '../../../../sdk/contracts/mail-account/mail-account.get-by-organization-id.contract';
import { MailAccountClient } from '../../../../sdk/clients/mail-account.client';
import { TabItem } from '../../../../shared/tab-item';
import { PermissionService } from '../../../../services/permission/permission.service';
import { PermissionType } from '../../../../sdk/contracts/permission/permission-type';
import { PermissionNavTabHelper } from '../../../../services/permission/permission-nav-tab-helper';
import { BreadcrumbItem } from '../../../../shared/breadcrumb-item';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { BaseCollectionSearchByIdRequest } from '../../../../sdk/contracts/common/base-collection-search-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../../../../sdk/contracts/common/base-get-search-count-by-id.request';
import { OrganizationClient } from '../../../../sdk/clients/organization.client';
import { BaseGetByIdRequest } from '../../../../sdk/contracts/common/base-get-by-id.request';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MailAccountDeleteDialogComponent } from './mail-account-delete-dialog/mail-account-delete-dialog.component';
import { MobileObserverService } from '../../../../services/adaptive/mobile-observer.service';
import { SelectionModel } from '@angular/cdk/collections';
import { OrganizationSettingsClient } from 'src/app/sdk/clients/organization-settings.client';
import { MatchError } from 'src/app/shared/error-matcher';
import { EmailSearchOrder } from 'src/app/sdk/contracts/Email/email.search-order';
import { FormatSearchOrders } from 'src/app/shared/pipes/format-search-orders.pipe';
import { MailAccountSearchOrder } from 'src/app/sdk/contracts/mail-account/MailAccountSearchOrder';
import { FormatMailAccountSearchOrders } from 'src/app/shared/pipes/format-mail-account-search-orders.pipe';
import { ProviderClient } from 'src/app/sdk/clients/provider.client';
import { FilterFields, defaultFilterFields } from 'src/app/sdk/contracts/common/FilterFields';
import { BaseCollectionFilteredSearchByIdRequest } from 'src/app/sdk/contracts/common/base-collection-filtered-search-by-id.request';

@Component({
  selector: 'app-mail-account',
  templateUrl: './mail-account.component.html',
  styleUrls: ['./mail-account.component.scss'],
})
export class MailAccountComponent implements OnInit, AfterViewInit {
  public organizationId: number;
  public isLoading = false;
  public mailAccounts: MailAccountGetByOrganizationIdContract[] = [];
  public dataSource = new MatTableDataSource<MailAccountGetByOrganizationIdContract>(this.mailAccounts);
  public displayedColumns: string[] = [
    'select',
    'name',
    'license',
    'codeSia',
    'anagId',
    'email',
    'provider-name',
    'is-active',
    'archive',
    'space',
    'actions',
  ];
  public hasError = false;
  public tabItems: TabItem[] = [];
  public breadcrumbItems: BreadcrumbItem[] = [];
  public searchValue = '';
  public pageNumber: number = 1;
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;
  public navigationIsLoading = false;
  public cannotLoadNavigation = false;
  public createDrawerIsOpen = false;
  public canManageMailAccounts = false;
  public useMobileView = false;
  public useTabletView = false;
  public webCode: string;
  public isSamlSupport: boolean = true;
  public spaceUsed: number = 0;

  public isDrawerOpen: boolean = false;
  public onDrawerClose: Function = () => {};
  public searchOrder: number = 0;
  public searchOrders;
  public showAddMailAccountsToUsersForm = false;
  public provider = -1;
  public filter: FilterFields = { ...defaultFilterFields };
  public providers = [];
  public selection = new SelectionModel<MailAccountGetByOrganizationIdContract>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private formatSearchOrders: FormatSearchOrders,
    private formatMailAccountSearchOrders: FormatMailAccountSearchOrders,
    public matchError: MatchError,
    protected router: Router,
    protected route: ActivatedRoute,
    private mailAccountClient: MailAccountClient,
    private permissionService: PermissionService,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private organizationSettingsClient: OrganizationSettingsClient,
    private organizationClient: OrganizationClient,
    private providerClient: ProviderClient,
    public dialog: MatDialog,
    private mobileObserverService: MobileObserverService
  ) {}

  async ngOnInit(): Promise<void> {
    this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));
    this.mobileObserverService.tabletObserver().subscribe((isTablet) => (this.useTabletView = isTablet));

    this.pageSize = JSON.parse(localStorage.getItem('pageSizeSettings')) || 25;
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.breadcrumbItems = [
      { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
      { index: 1, label: 'mailAccountsBreadCrumbs', route: null },
    ];

    let searchOrders = Object.keys(MailAccountSearchOrder)
      .filter((k) => !(parseInt(k, 10) >= 0))
      .map((key) => ({
        id: MailAccountSearchOrder[key],
        name: this.formatMailAccountSearchOrders.transform(MailAccountSearchOrder[key]),
      }));

    this.searchOrders = searchOrders.filter((e) => e.id !== MailAccountSearchOrder.OrderByMailAccountId);

    await this.loadNavigation();
    await this.loadMailAccounts(true);
  }

  ngAfterViewInit(): void {}

  public async loadNavigation(): Promise<void> {
    this.isLoading = true;
    try {
      await Promise.all([this.loadNavTabs(), this.loadCanManageMailAccounts()]);
    } catch (e) {
      this.cannotLoadNavigation = true;
      this.matchError.logError(e);
    }
  }

  private async loadCanManageMailAccounts(): Promise<void> {
    this.canManageMailAccounts = await this.permissionService.hasPermissionOver(
      this.organizationId,
      null,
      PermissionType.CanManageMailAccounts
    );
  }

  async reload() {
    this.filter = { ...defaultFilterFields };
    this.searchOrder = 0;

    await this.loadMailAccounts(true);
  }

  async changeOrder() {
    if (this.filter.orderDirection === 1) {
      this.filter.orderDirection = 2;
    } else this.filter.orderDirection = 1;
    await this.loadMailAccounts(true);
  }

  public async retry(): Promise<void> {
    await this.loadNavigation();
    await this.loadMailAccounts(true);
  }

  private async loadNavTabs(): Promise<void> {
    this.tabItems = await this.permissionNavTabHelper.getTabsForMailAccountsPage(this.organizationId, this.webCode);
  }

  public async loadMailAccounts(fetchSearchCounter): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    const nullableSearchValue = this.searchValue.length > 0 ? this.searchValue : null;
    this.filter.search = this.searchValue.length > 0 ? this.searchValue : null;

    if (fetchSearchCounter) {
      this.pageIndex = 0;
    }
    try {
      const organizationSettings = await this.organizationSettingsClient.getByOrganization(new BaseGetByIdRequest(this.organizationId));
      this.isSamlSupport = organizationSettings.result.samlSupport;
      const request = new BaseCollectionFilteredSearchByIdRequest(this.organizationId, this.pageIndex + 1, this.pageSize, this.filter);
      const result = await this.mailAccountClient.getSearch(request);

      this.mailAccounts = result.data;
      this.spaceUsed = result.spaceUsed ?? 0;
      if (this.spaceUsed === undefined || this.spaceUsed === null) this.spaceUsed = 0; //if something goes wrong

      this.dataSource.data = this.mailAccounts;
      if (fetchSearchCounter) {
        const counterRequest = new BaseGetSearchCountByIdRequest(this.organizationId, nullableSearchValue);
        const counterResponse = await this.mailAccountClient.getSearchCount(counterRequest);
        this.totalSize = counterResponse.result;
      }

      this.providers = (await this.providerClient.getByOrganization(new BaseGetByIdRequest(this.organizationId))).providerContracts.map(
        (elem) => {
          return {
            val: elem.providerId,
            name: elem.name,
          };
        }
      );
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async deleteMailAccount(mailAccountId: number, mailAccountName: string): Promise<void> {
    const dialogRef = this.dialog.open(MailAccountDeleteDialogComponent, {
      width: '450px',
      data: { mailAccountId, mailAccountName },
    });

    dialogRef.afterClosed().subscribe(async (x) => {
      if (x.isDeleted) {
        this.totalSize -= 1;
        await this.loadMailAccounts(false);
      }
    });
  }

  public onSearchValueChanged(newSearchValue): void {
    this.searchValue = newSearchValue;
  }

  public getDetailsLink(mailAccountId: number): string {
    return `/${this.webCode}/settings/organization/${this.organizationId}/mail-account/${mailAccountId}/general`;
  }

  public async handlePage(e: any): Promise<void> {
    localStorage.setItem('pageSizeSettings', JSON.stringify(e.pageSize));
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadMailAccounts(false);
  }

  async onCreateMailAccountBtnClicked(): Promise<void> {
    this.isDrawerOpen = true;
    this.createDrawerIsOpen = true;
  }

  onCloseCreateModalForm(): void {
    this.isDrawerOpen = false;
    this.createDrawerIsOpen = false;
  }

  public async searchOrderChange(): Promise<void> {
    this.filter.searchOrder = this.searchOrder;
    this.filter.orderDirection = 1;
    await this.loadMailAccounts(true);
  }

  public async searchProviderChange(provider: { val: number; name: string }): Promise<void> {
    this.filter.ProviderId = provider.val;
    await this.loadMailAccounts(true);
  }

  public async setFilterChange(name: string, val: boolean): Promise<void> {
    this.filter[name] = val;
    await this.loadMailAccounts(true);
  }

  async onCreateMailAccount(): Promise<void> {
    this.isDrawerOpen = false;
    this.createDrawerIsOpen = false;
    this.totalSize += 1;
    await this.loadMailAccounts(false);
  }

  public async redirectToDetails(mailAccountId: number): Promise<void> {
    await this.router.navigateByUrl(this.getDetailsLink(mailAccountId));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  //#region Drawer
  public openDrawer(onClose = () => {}) {
    this.isDrawerOpen = true;
    this.onDrawerClose = onClose;
  }

  public closeDrawer() {
    this.isDrawerOpen = false;
    this.createDrawerIsOpen = false;
    this.onDrawerClose = () => {};
  }
  //#endregion

  //#region Add mail accounts to users event
  public onAddMailAccountsToUsersBtnClicked(): void {
    this.openDrawer(this.onHideAddMailAccountsToUsersForm);
    this.showAddMailAccountsToUsersForm = true;
  }

  public onAddMailAccountToUsersBtnClicked(mailAccount: MailAccountGetByOrganizationIdContract): void {
    this.selection.clear();
    this.selection.select(mailAccount);
    this.openDrawer(this.onHideAddMailAccountsToUsersForm);
    this.showAddMailAccountsToUsersForm = true;
  }

  public onHideAddMailAccountsToUsersForm(): void {
    this.closeDrawer();
    this.showAddMailAccountsToUsersForm = false;
    this.selection.clear();
  }

  public onAddMailAccountsToUsers(): void {
    this.onHideAddMailAccountsToUsersForm();
  }
}
