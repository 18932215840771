export const LOCALE_IDS = [
  {
    language: 'English',
    id: 'en',
  },
  {
    language: 'Deutsch',
    id: 'de',
  },
  {
    language: 'Italiano',
    id: 'it',
  },
  {
    language: 'Français',
    id: 'fr',
  },
];
