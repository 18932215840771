<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>

<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>

<app-drawer [isOpen]="settingsDrawerIsOpen" (closeEventEmitter)="onCloseSettingsModalForm()">
  <app-organization-settings-dialog
    *ngIf="settingsDrawerIsOpen"
    [organizationId]="organizationId"
    (closeEventEmitter)="onCloseSettingsModalForm()"
    (updatingSubmit)="onUpdateOrganizationSettings()">
  </app-organization-settings-dialog>
</app-drawer>

<ng-container>
  <div class="page-content">
    <app-loader *ngIf="isLoading"></app-loader>

    <div *ngIf="hasError" class="half-of-screen">
      <app-something-went-wrong description="Cannot load company" [showPicture]="false" (retryEmitter)="retry()"></app-something-went-wrong>
    </div>

    <div *ngIf="!isLoading && !hasError" class="page-block box-container relative">
      <div class="header-container">
        <h1>{{ "generalInfo" | translate }}</h1>
        <div class="settings-icon">
          <button
            mat-icon-button
            aria-label="Settings"
            matTooltip="{{ 'settings' | translate }}"
            (click)="openOrganizationSettingsDialog()">
            <mat-icon>settings</mat-icon>
          </button>
        </div>
      </div>
      <table *ngIf="!isLoading && !hasError">
        <tr>
          <td class="item-header">{{ "name" | translate }}:</td>
          <td>{{ organization.name }}</td>
        </tr>
        <!-- <tr>
          <td class="item-header">Code:</td>
          <td>{{organization.code}}</td>
        </tr> -->
        <tr>
          <td class="item-header">{{ "webCode" | translate }}:</td>
          <td>{{ organization.webCode }}</td>
        </tr>
        <tr>
          <td class="item-header">{{ "licenseId" | translate }}:</td>
          <td>{{ organization.licenseId }}</td>
        </tr>
        <tr>
          <td class="item-header">{{ "codeSia" | translate }}:</td>
          <td>{{ organization.codeSia }}</td>
        </tr>
        <tr>
          <td class="item-header">{{ "anagId" | translate }}:</td>
          <td>{{ organization.anagId }}</td>
        </tr>
        <!--          <tr>-->
        <!--            <td class="item-header">Organization type:</td>-->
        <!--            <td>{{organizationTypes[organization.type]}}</td>-->
        <!--          </tr>-->
        <!--          <tr *ngIf="organization.type === organizationTypes.Company">-->
        <!--            <td class="item-header">Parent organization:</td>-->
        <!--            <td>-->
        <!--              <span>{{hasParentOrganization() ? organization.parentOrganizationName : '-'}}</span>-->
        <!--            </td>-->
        <!--          </tr>-->
      </table>
      <app-loader *ngIf="isLoading" height="5rem"></app-loader>
      <div *ngIf="hasError" class="h-32">
        <app-something-went-wrong description="Cannot load company" [showPicture]="false" (retryEmitter)="loadOrganization()">
        </app-something-went-wrong>
      </div>
    </div>

    <!--    <div class="grid grid-rows-3 grid-cols-1 lg:grid-cols-2 gap-4">-->

    <!--      <div class="row-span-3 page-block box-container">-->
    <!--        <h1>Mail accounts statistic</h1>-->
    <!--        <div *ngIf="!statisticIsLoading && !statisticHasError">-->
    <!--          <div *ngIf="!statistic.mailAccountsStatistic || statistic.mailAccountsStatistic.length === 0">No mail accounts</div>-->
    <!--          <mat-accordion>-->
    <!--            <mat-expansion-panel *ngFor="let account of statistic.mailAccountsStatistic">-->
    <!--              <mat-expansion-panel-header>-->
    <!--                <mat-panel-title>-->
    <!--                  {{account.email}}-->
    <!--                </mat-panel-title>-->
    <!--              </mat-expansion-panel-header>-->
    <!--              <div class="max-h-48 overflow-y-scroll">-->
    <!--                <table class="box-table">-->
    <!--                  <tr>-->
    <!--                    <td class="item-header">Users:</td>-->
    <!--                    <td>{{account.usersCount}}</td>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <td class="item-header">Disk space:</td>-->
    <!--                    <td>{{account.diskSpace}}</td>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <td class="item-header">Emails:</td>-->
    <!--                    <td>{{account.emailsCount}}</td>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <td class="item-header">Massive emails:</td>-->
    <!--                    <td>{{account.massiveEmailsCount}}</td>-->
    <!--                  </tr>-->
    <!--                  <tr>-->
    <!--                    <td class="item-header">Folders:</td>-->
    <!--                    <td>-->
    <!--                      <div *ngFor="let folder of account.foldersStatistic">-->
    <!--                        <div class="italic">{{folder.name}}</div>-->
    <!--                        <table class="box-table ml-5">-->
    <!--                          <tr>-->
    <!--                            <td class="item-header">Disk space:</td>-->
    <!--                            <td>{{folder.diskSpace}}</td>-->
    <!--                          </tr>-->
    <!--                          <tr>-->
    <!--                            <td class="item-header">Emails:</td>-->
    <!--                            <td>{{folder.emailsCount}}</td>-->
    <!--                          </tr>-->
    <!--                          <tr>-->
    <!--                            <td class="item-header">Massive emails:</td>-->
    <!--                            <td>{{folder.massiveEmailsCount}}</td>-->
    <!--                          </tr>-->
    <!--                        </table>-->
    <!--                      </div>-->
    <!--                    </td>-->
    <!--                  </tr>-->
    <!--                </table>-->
    <!--              </div>-->
    <!--            </mat-expansion-panel>-->
    <!--          </mat-accordion>-->
    <!--        </div>-->
    <!--        <app-loader *ngIf="statisticIsLoading" height="5rem"></app-loader>-->
    <!--        <div *ngIf="statisticHasError" class="h-32">-->
    <!--          <app-something-went-wrong-->
    <!--            description="Cannot load mail accounts statistic"-->
    <!--            [showPicture]="false"-->
    <!--            (retryEmitter)="loadStatistic()"-->
    <!--          >-->
    <!--          </app-something-went-wrong>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="col-span-1 page-block box-container">-->
    <!--        <h1>General statistic</h1>-->
    <!--        <table *ngIf="!statisticIsLoading && !statisticHasError" class="box-table">-->
    <!--          <tr>-->
    <!--            <td class="item-header">Disk space:</td>-->
    <!--            <td>{{statistic.diskSpace}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td class="item-header">Users:</td>-->
    <!--            <td>{{statistic.usersCount}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td class="item-header">Mail accounts:</td>-->
    <!--            <td>{{statistic.mailAccountsCount}}</td>-->
    <!--          </tr>-->
    <!--        </table>-->
    <!--        <app-loader *ngIf="statisticIsLoading" height="5rem"></app-loader>-->
    <!--        <div *ngIf="statisticHasError" class="h-32">-->
    <!--          <app-something-went-wrong description="Cannot load general statistic"-->
    <!--                                    [showPicture]="false"-->
    <!--                                    (retryEmitter)="loadStatistic()"-->
    <!--          >-->
    <!--          </app-something-went-wrong>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--      <div class="col-span-1 page-block box-container">-->
    <!--        <h1>Emails statistic</h1>-->
    <!--        <table *ngIf="!statisticIsLoading && !statisticHasError" class="box-table">-->
    <!--          <tr>-->
    <!--            <td class="item-header">Emails:</td>-->
    <!--            <td>{{statistic.emailsCount}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td class="item-header">Massive emails:</td>-->
    <!--            <td>{{statistic.massiveEmailsCount}}</td>-->
    <!--          </tr>-->
    <!--        </table>-->
    <!--        <app-loader *ngIf="statisticIsLoading" height="5rem"></app-loader>-->
    <!--        <div *ngIf="statisticHasError" class="h-32">-->
    <!--          <app-something-went-wrong description="Cannot load emails statistic"-->
    <!--                                    [showPicture]="false"-->
    <!--                                    (retryEmitter)="loadStatistic()"-->
    <!--          >-->
    <!--          </app-something-went-wrong>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </div>-->
  </div>
</ng-container>
