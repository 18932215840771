<app-drawer title="Create folder" [isOpen]="createDrawerIsOpen" (closeEventEmitter)="onCloseCreateModalForm()">
  <app-mail-folder-create-dialog
    *ngIf="createDrawerIsOpen"
    (closeEventEmitter)="onCloseCreateModalForm()"
    (creationSubmit)="onSubmitCreation($event)"
    [parentMailFolderId]="parentMailFolderIdForCreation"
    [mailAccountId]="mailAccountId"
    [parentMailFolderType]="parentMailFolderTypeForCreation"
    [isSettings]="false">
  </app-mail-folder-create-dialog>
</app-drawer>

<app-drawer title="Rename folder" [isOpen]="renameDrawerIsOpen" (closeEventEmitter)="onCloseRenameModalForm()">
  <app-mail-folder-rename-dialog
    *ngIf="renameDrawerIsOpen"
    [mailFolderId]="editingFolder.mailFolderId"
    [mailFolderName]="editingFolder.name"
    (closeEventEmitter)="onCloseRenameModalForm()"
    [providerFolderName]="editingFolder.providerFolderName"
    (creationSubmit)="onSubmitRename()"
    [mailAccountId]="mailAccountId"
    [parentMailFolderType]="parentMailFolderTypeForCreation"
    [isSettings]="false">
  </app-mail-folder-rename-dialog>
</app-drawer>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="rightMenu"></div>

<mat-menu #rightMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button mat-menu-item (click)="createFolder(item)">{{ "createSubfolder" | translate }}</button>
    <button *ngIf="item && item.parentId !== null" mat-menu-item (click)="renameFolder(item)">{{ "modify" | translate }}</button>
    <button *ngIf="item && item.parentId !== null" mat-menu-item (click)="deleteFolder(item)">{{ "delete" | translate }}</button>
  </ng-template>
</mat-menu>

<ng-template *ngIf="showLoader; else content">
  <mat-progress-spinner mode="indeterminate" diameter="30"></mat-progress-spinner>
</ng-template>

<ng-template #content>
  <ng-template *ngIf="loadError; else mainContent">
    {{ apiError }}
  </ng-template>

  <ng-template #mainContent>
    <div>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="mail-folder-tree -ml-4">
        <mat-tree-node
          style="min-height: 40px"
          [class.btn-dark]="item?.data?.mailFolderId === searchContextStorageService?.currentFolderId"
          *matTreeNodeDef="let item">
          <div class="mat-tree-node">
            <button style="height: 20px" mat-icon-button disabled></button>
            <button
              style="text-align: left; border-radius: 0; padding-left: 0; min-width: 0; overflow-wrap: anywhere; padding: 10px 16px 10px 0"
              [class.btn-dark]="item?.data?.mailFolderId === searchContextStorageService?.currentFolderId"
              [disableRipple]="true"
              (contextmenu)="onRightClick($event, item.data)"
              (click)="selectFolder(item)">
              {{ item.data.creationType === 1 ? ("folderType" + item.data.folderType | translate) : item.data.name }}
            </button>
            <span 
            *ngIf="getCountForFolder(item.data.mailFolderId)" 
            [class.btn-dark]="item?.data?.mailFolderId === searchContextStorageService?.currentFolderId">{{
              getCountForFolder(item.data.mailFolderId)
            }}</span>
          </div>
        </mat-tree-node>
        <mat-nested-tree-node *matTreeNodeDef="let item; when: hasChild">
          <div>
            <div
              style="overflow: hidden"
              class="mat-tree-node"
              [class.btn-dark]="item?.data?.mailFolderId === searchContextStorageService?.currentFolderId">
              <button
                (click)="expandCollapseTree(item)"
                mat-icon-button
                [disableRipple]="true"
                [attr.aria-label]="'toggle ' + item.data.name">
                <mat-icon style="font-size: 20px" class="mat-icon-rtl-mirror">
                  {{ isOpenedFolder(item.data.mailFolderId) ? "add" : "remove" }}
                </mat-icon>
              </button>
              <button
                style="
                  border-radius: 0;
                  padding: 10px 16px 10px 0;
                  min-width: 0;
                  overflow-wrap: anywhere;
                  line-height: normal;
                  font-weight: 500;
                  text-align: left;
                  min-height: 40px;
                "
                [class.btn-dark]="item?.data?.mailFolderId === searchContextStorageService?.currentFolderId"
                [disableRipple]="true"
                (click)="selectFolder(item)"
                (contextmenu)="onRightClick($event, item.data)">
                {{ item.data.creationType === 1 ? ("folderType" + item.data.folderType | translate) : item.data.name }}
              </button>
              <span>{{ getCountForFolder(item.data.mailFolderId) }}</span>
            </div>
            <!-- <div [class.mail-folder-tree-invisible]="!treeControl.isExpanded(item)" role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div> -->
            <div [class.mail-folder-tree-invisible]="isOpenedFolder(item.data.mailFolderId)" role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
  </ng-template>
</ng-template>
