import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() height = '15rem';
  @Input() diameter = '64';
  @Input() padding = '1.25rem';

  constructor() {}

  ngOnInit(): void {}
}
