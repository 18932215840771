import { Component, Input, OnInit } from '@angular/core';
import { DispatchingAction } from '../../../../../../../sdk/contracts/dispatching/dispatching.action';
import { MailFolderContract } from '../../../../../../../sdk/contracts/mail-folder/mail-folder.contract';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-move-to-folder-action',
  templateUrl: './move-to-folder-action.component.html',
  styleUrls: ['./move-to-folder-action.component.scss'],
})
export class MoveToFolderActionComponent implements OnInit {
  @Input() dispatchingAction: DispatchingAction;
  @Input() availableLocalFolders: MailFolderContract[];
  public form: UntypedFormGroup;
  constructor() {}

  ngOnInit(): void {
    this.setFolderValue(this.dispatchingAction.args[0]?.value || this.availableLocalFolders[0].mailFolderId.toString());

    this.form = new UntypedFormGroup({
      folder: new UntypedFormControl(parseInt(this.dispatchingAction.args[0].value, 10)),
    });
  }

  setFolderValue(folder: string): void {
    this.dispatchingAction.args = [];
    this.dispatchingAction.args[0] = {
      name: 'FolderId',
      value: folder.toString(),
    };
  }
}
