import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ProviderContract } from '../../../../sdk/contracts/provider/provider.contract';
import { Router } from '@angular/router';
import { ProviderClient } from '../../../../sdk/clients/provider.client';
import { ProviderConfiguration } from '../../../../sdk/contracts/provider/provider.configuration';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-provider-create-dialog',
  templateUrl: './provider-create-dialog.component.html',
  styleUrls: ['./provider-create-dialog.component.scss'],
})
export class ProviderCreateDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isError = false;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();

  constructor(public matchError: MatchError, private router: Router, private providerClient: ProviderClient) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(128)]),
      smtp: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
      smtpPort: new UntypedFormControl(null, [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(1), Validators.max(65535)]),
      imap: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
      imapPort: new UntypedFormControl(null, [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(1), Validators.max(65535)]),
      code: new UntypedFormControl(null, [Validators.maxLength(512)]),
      inboxName: new UntypedFormControl(null, [Validators.maxLength(512)]),
      outboxName: new UntypedFormControl(null, [Validators.maxLength(512)]),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(providerContract: ProviderContract): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.isError = false;
      try {
        if (this.form.controls.inboxName.value || this.form.controls.outboxName.value) {
          providerContract.configuration = new ProviderConfiguration(
            this.form.controls.inboxName.value,
            this.form.controls.outboxName.value
          );
        }
        await this.providerClient.create(providerContract);
        this.creationSubmit.emit();
        this.form.reset();
      } catch (e) {
        this.isError = true;
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  cancel(): void {
    this.closeEventEmitter.emit();
  }
}
