import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { TemplateClient } from '../../../sdk/clients/template.client';
import { TemplateContract } from '../../../sdk/contracts/template/template.contract';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
})
export class EmailTemplatesComponent implements OnInit {
  @Input() mailAccountId: number;
  @Output() templateSelected = new EventEmitter();
  public isAnyTemplateDownloading = false;
  public downloadingTemplateId: number;
  public isLoading = false;
  public isError = false;
  public searchValue = '';
  public templates: TemplateContract[] = [];
  public searchedTemplates: TemplateContract[] = [];
  public useMobileView = false;

  constructor(public matchError: MatchError, private templateClient: TemplateClient, public dialog: MatDialog) {}

  async ngOnInit(): Promise<void> {
    await this.loadTemplates();
  }

  public searchTemplates() {
    if (this.searchValue)
      this.searchedTemplates = this.templates.filter((e) => e.name.includes(this.searchValue) || e.description.includes(this.searchValue));
    else {
      this.searchedTemplates = this.templates;
    }
  }

  public async loadTemplates(): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    const request = new BaseGetByIdRequest(this.mailAccountId);
    try {
      const response = await this.templateClient.getForMailAccount(request);
      this.templates = response.data;
      this.searchedTemplates = response.data;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public async loadTemplate(templateId: number): Promise<void> {
    this.downloadingTemplateId = templateId;
    this.isAnyTemplateDownloading = true;
    const request = new BaseGetByIdRequest(templateId);
    try {
      const response = await this.templateClient.getById(request);
      this.templateSelected.emit(response.template);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.downloadingTemplateId = null;
      this.isAnyTemplateDownloading = false;
    }
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }
}
