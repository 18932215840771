import { Component, Input, OnInit } from '@angular/core';
import { DispatchingAction } from '../../../../../../../sdk/contracts/dispatching/dispatching.action';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forward-action',
  templateUrl: './forward-action.component.html',
  styleUrls: ['./forward-action.component.scss'],
})
export class ForwardActionComponent implements OnInit {
  @Input() dispatchingAction: DispatchingAction;
  public form: UntypedFormGroup;
  public list: string[];
  constructor() {}

  ngOnInit(): void {
    this.setToValue([this.dispatchingAction.args[0]?.value] || []);

    this.form = new UntypedFormGroup({
      forwardTo: new UntypedFormControl(this.dispatchingAction.args[0]?.value, [Validators.required]),
    });
    this.list = this.dispatchingAction.args[0].value ? this.dispatchingAction.args[0].value.split(';') : [];
  }
  public isValidTag(value: string) {
    return !!String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }
  public isValidEmails() {
    let isAllValid = this.dispatchingAction.args[0].value
      .replace(/\s+/g, '')
      .split(';')
      .every((e) => this.isValidTag(e));
    return !!isAllValid;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName]?.hasError(errorName);
  };

  setToValue(event: string[]): void {
    let emailsString = event.join(';');
    this.dispatchingAction.args[0] = {
      name: 'to',
      value: emailsString.replace(/\s+/g, ''),
    };
  }
}
