import { ApiException } from './api-errors/api.exception';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LoggingHandler } from '../services/logging/logging.service';

@Injectable({ providedIn: 'root' })
export class ApiRequest {
  constructor(
    private http: HttpClient,
    protected loggingHandler: LoggingHandler,
  ) {}

  public async post<TRequest, TResponse>(request: TRequest, url: string, headers: any = null): Promise<TResponse> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const response: TResponse = await this.http
        .post<TResponse>(url, request, { headers: { ...headers, ClientId }, withCredentials: true })
        .toPromise();
      return response;
    } catch (e) {
      this.throwApiException(e, { url }, { request }, { headers });
    }
  }

  public async get<TResponse>(url: string, headers: any = null): Promise<TResponse> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const response: TResponse = await this.http
        .get<TResponse>(url, { headers: { ...headers, ClientId }, withCredentials: true })
        .toPromise();
      return response;
    } catch (e) {
      this.throwApiException(e, { url }, { headers });
    }
  }

  public async download(url: string): Promise<ArrayBuffer> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const response = await this.http
        .get(url, { headers: { ClientId }, responseType: 'arraybuffer', withCredentials: true })
        .toPromise();
      return response;
    } catch (e) {
      this.throwApiException(e, { url });
    }
  }

  public async downloadPost<TRequest>(url: string, request: TRequest): Promise<ArrayBuffer> {
    try {
      const ClientId = this.loggingHandler.getClientId();
      const response = await this.http
        .post(url, request, { headers: { ClientId }, responseType: 'arraybuffer', withCredentials: true })
        .toPromise();
      return response;
    } catch (e) {
      this.throwApiException(e, { url }, { request });
    }
  }

  public upload<TRequest, TResponse>(request: TRequest, url: string, headers: any = null): Observable<any> {
    const ClientId = this.loggingHandler.getClientId();
    return this.http
      .post(url, request, { headers: { ...headers, ClientId }, observe: 'events', reportProgress: true, withCredentials: true })
      .pipe(catchError((err) => throwError(err))) as Observable<TResponse>;
  }

  private throwApiException(e, ...args): void {
    const errorResponse = JSON.stringify(e?.error);
    const errorInformation = [];
    args.forEach((e) => errorInformation.push(e));
    const message = JSON.stringify(errorInformation);
    throw new ApiException(
      `${e.status} ${e.message} ${message}, Response: ${errorResponse}`,
      e.error?.code,
      e.error?.description,
      e.error?.meta,
      e.status
    );
    // switch (e.status) {
    // case 400:
    //   throw new ApiException(
    //     `${e.status} ${e.message} ${message}, Response: ${errorResponse}`,
    //     e.error?.code,
    //     e.error?.description,
    //     e.error?.meta
    //   );
    // case 500:
    //   throw new ApiException(`error: 500 ${message}, Response: ${errorResponse}`, 500, 'on server', null);
    //   default:
    //     throw new ApiException(
    //       `${e.status} ${e.message} ${message}, Response: ${errorResponse}`,
    //       e.error?.code,
    //       e.error?.description,
    //       e.error?.meta
    //     );
    // }
  }
}
