import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserOrganizationClient } from '../../../../../sdk/clients/user-organization.client';
import { AddUserToOrganizationRequest } from '../../../../../sdk/contracts/user-organization/add-user-to-organization.request';
import { ErrorCode } from '../../../../../sdk/api-errors/error-code';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import {
  hasAtLeastOneDigit,
  hasAtLeastOneLowerCharacter,
  hasAtLeastOneSpecialSymbol,
  hasAtLeastOneUpperCharacter,
} from '../../../../../shared/validators/custom-validators';
import { OrganizationSettingsClient } from '../../../../../sdk/clients/organization-settings.client';
import { OrganizationSettingsContract } from '../../../../../sdk/contracts/organization/settings/organization-settings.contract';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';

@Component({
  selector: 'app-organization-user-create-dialog',
  templateUrl: './organization-user-create-dialog.component.html',
  styleUrls: ['./organization-user-create-dialog.component.scss'],
})
export class OrganizationUserCreateDialogComponent implements OnInit {
  public isLoading = false;
  @Input() public organizationId: number;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public isLoadingAuthTypes: boolean = false;
  public gawOidcAuthType: boolean = false;
  public googleAuthType: boolean = false;
  public passwordValidators = [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(15),
    hasAtLeastOneUpperCharacter(),
    hasAtLeastOneLowerCharacter(),
    hasAtLeastOneDigit(),
    hasAtLeastOneSpecialSymbol(),
  ];
  public failedSettingsLoading = false;
  public settingsIsLoading = false;
  public settings: OrganizationSettingsContract;

  public errorText: string;
  public handlingErrorCodes = new Map<number, string>([
    [ErrorCode.UserAlreadyExists, 'Cannot add user to company: email already registered'],
  ]);
  public form: UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
    email: new UntypedFormControl('', [Validators.required, Validators.maxLength(256), Validators.email]),
    nickName: new UntypedFormControl('', [Validators.required, Validators.maxLength(256)]),
    password: new UntypedFormControl('', this.passwordValidators),
  });

  constructor(
    private userOrganizationClient: UserOrganizationClient,
    private organizationSettingsClient: OrganizationSettingsClient,
    public matchError: MatchError,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadSettings();
  }

  public changeGawOidcAuthType(value: boolean) {
    this.gawOidcAuthType = value;
    if (!value) {
      this.form.controls['password'].disable();
      this.form.controls['nickName'].disable();
    } else {
      this.form.controls['password'].enable();
      this.form.controls['nickName'].enable();
    }
  }
  public changeGoogleAuthType(value: boolean) {
    this.googleAuthType = value;
  }

  public async loadSettings(): Promise<void> {
    this.failedSettingsLoading = false;
    this.settingsIsLoading = true;
    try {
      this.form.controls['password'].disable();
      this.form.controls['nickName'].disable();
      const response = await this.organizationSettingsClient.getByOrganization(new BaseGetByIdRequest(this.organizationId));
      this.settings = response.result;
      // if (!this.settings.adfsOAuthOptions) {
      //   this.settings.adfsOAuthOptions = new AdfsOAuthOptions();
      // }
      // if (!this.settings.archiveSettings) {
      //   this.settings.archiveSettings = new OrganizationArchiveSettings();
      // }

      // this.initForm();
    } catch (e) {
      this.failedSettingsLoading = true;
      this.matchError.logError(e);
    } finally {
      this.settingsIsLoading = false;
    }
  }

  checkIsValid() {
    return !(this.googleAuthType || this.gawOidcAuthType);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      let nickName = null;
      let password = null;
      if (this.gawOidcAuthType) {
        nickName = this.form.get('nickName').value;
        password = this.form.get('password').value;
      }
      const request = new AddUserToOrganizationRequest(
        this.form.get('firstName').value,
        this.form.get('lastName').value,
        this.form.get('email').value,
        nickName,
        this.organizationId,
        password,
        this.gawOidcAuthType,
        this.googleAuthType
      );
      try {
        await this.userOrganizationClient.addUserToOrganization(request);
        this.creationSubmit.emit();
        this.form.reset();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
