import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { MailAccountSamlClaims } from '../contracts/mail-account/settings/mail-account-saml-claims.contract';
import { SamlClaims } from '../contracts/mail-account/settings/saml-claims.contract';

const API_URL = environment.apiUrl;
@Injectable()
export class ClaimClient {
  constructor(private apiRequest: ApiRequest) {}

  async createOrUpdate(request: MailAccountSamlClaims): Promise<any> {
    return this.apiRequest.post<any, any>(request, `${API_URL}${ApiRoutes.claim.createOrUpdate}`);
  }

  async createOrUpdateRtaClaimByOrganizationId(request: SamlClaims): Promise<any> {
    return this.apiRequest.post<any, any>(request, `${API_URL}${ApiRoutes.claim.createOrUpdateRtaClaimByOrganizationId}`);
  }

  async getByIdForAccount(request: BaseGetByIdRequest): Promise<{ data: SamlClaims[] }> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.claim.getForAccount}?${request.format()}`);
  }

  async getByIdRtaClaimByOrganizationId(request: BaseGetByIdRequest): Promise<{ result: SamlClaims }> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.claim.getRtaClaimByOrganizationId}?${request.format()}`);
  }
}
