import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MailAccountGetBadgeRequest } from 'src/app/sdk/contracts/mail-account/mail-account.get-badges.request';
import { MailAccountGetBadgeResponse } from 'src/app/sdk/contracts/mail-account/mail-account.get-badges.response';
import { PermissionType } from 'src/app/sdk/contracts/permission/permission-type';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { MailAccountClient } from '../../sdk/clients/mail-account.client';
import { UserMailAccountClient } from '../../sdk/clients/user-mail-account.client';
import { UserMailAccountContract } from '../../sdk/contracts/user-mail-account/user-mail-account.contract';
import { EventsService } from '../../services/events/events.service';
import { FoldersTypeCheck } from '../../services/folders-utility/folders-type-check.service';
import { SearchContextStorageService } from '../../services/search/search-context-storage.service';
import { UserMailAccountSelected } from './events/user-mail-account.selected';

@Component({
  selector: 'app-emails-sidenav',
  templateUrl: './emails-side-nav.component.html',
  styleUrls: ['./emails-side-nav.component.scss'],
})
export class EmailsSideNavComponent implements OnInit, OnDestroy {
  public showLoader = false;
  public mailAccounts: UserMailAccountContract[] = [];
  public dataSource = new MatTableDataSource<UserMailAccountContract>(this.mailAccounts);
  public displayedColumns: string[] = ['name', 'email', 'action'];
  public apiError: string;
  public mailAccountId: number;
  public mailAccountIds: number[] = [];
  public mailAccountBadges: MailAccountGetBadgeResponse[] = [];
  public webCode: string;
  public createDrawerIsOpen = false;
  public reloadFoldersSubject: Subject<void> = new Subject<void>();
  public mailAccountIdRightClick: number | null;
  public isTreeLoaded = false;
  public hideNotification = true;
  public badgesRefreshInterval;
  public defaultMailAccountId: number;
  menuTopLeftPosition = { x: '0', y: '0' };
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  reassignedAccountIds;

  constructor(
    private userMailAccountClient: UserMailAccountClient,
    private mailAccountClient: MailAccountClient,
    protected route: ActivatedRoute,
    private router: Router,
    private eventsService: EventsService,
    private permissionService: PermissionService,
    private searchContextStorageService: SearchContextStorageService,
    private readonly foldersTypeCheck: FoldersTypeCheck
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    await this.getMailAccounts();
    this.getBadgesForMailAccounts(this.mailAccountIds);
    const defaultMailAccount = this.searchContextStorageService.getDefaultMailAccount();
    if (defaultMailAccount) {
      this.searchContextStorageService.selectMailAccount(defaultMailAccount.mailAccountId);
    }
    this.searchContextStorageService.isTreeLoaded$.subscribe((isTreeLoaded) => {
      this.isTreeLoaded = isTreeLoaded;
    });

    this.eventsService.unreadCountEvent.subscribe(() => {
      if (this.hideNotification !== !this.eventsService.unreadCountValue) {
        this.hideNotification = !this.eventsService.unreadCountValue;
        this.getBadgesForMailAccounts(this.mailAccountIds);
      } else {
        this.hideNotification = !this.eventsService.unreadCountValue;
      }
    });

    this.reassignedAccountIds = this.eventsService.reassignedAccountIds$.subscribe((data) => {
      if (!!data?.length) {
        this.getBadgesForMailAccounts(this.mailAccountIds);
      }
    });

    this.badgesRefreshInterval = setInterval(() => {
      this.eventsService.intervalUpdateBadges.emit();
      this.getBadgesForMailAccounts(this.mailAccountIds);
    }, 120000);
  }

  ngOnDestroy(): void {
    this.reassignedAccountIds?.unsubscribe();
    clearInterval(this.badgesRefreshInterval);
  }

  async getMailAccounts(): Promise<void> {
    this.showLoader = true;
    this.apiError = '';

    try {
      if (await this.permissionService.hasPermissionOver(null, null, PermissionType.CanEverything)) {
        return;
      }
      const response = await this.userMailAccountClient.getLoggedUserAccounts();
      this.mailAccounts = response.userMailAccounts;
      this.mailAccountIds = response.userMailAccounts.map((e) => e.mailAccountId);

      this.mailAccounts.length ? this.foldersTypeCheck.setIsAnyFolder(true) : this.foldersTypeCheck.setIsAnyFolder(false);
      this.dataSource.data = this.mailAccounts;
      this.showLoader = false;
    } catch (e) {
      this.showLoader = false;
      this.apiError = `Code:${e.error.Code}, description:${e.error.description}`;
    }
  }

  public async getBadgesForMailAccounts(mailAccountIds: number[]): Promise<void> {
    if (mailAccountIds.length) {
      const badgesForMailAccount = await this.mailAccountClient.getMailAccountsBadges(
        new MailAccountGetBadgeRequest(mailAccountIds, this.hideNotification)
      );

      badgesForMailAccount.data.forEach((elem) => {
        this.mailAccounts.map((x) => {
          if (x.mailAccountId === elem.mailAccountId) { x.spaceUsed = elem.spaceUsed ? elem.spaceUsed : 0; }
        });
      });

      badgesForMailAccount.data.forEach((e) =>
        this.mailAccountBadges.some((x) => x.mailAccountId === e.mailAccountId)
          ? (this.mailAccountBadges = this.mailAccountBadges.map((x) => (x.mailAccountId === e.mailAccountId ? e : x)))
          : this.mailAccountBadges.push(e)
      );
      const mailAccountBadgesNeedsToBeRemoved = mailAccountIds.filter((e) => !badgesForMailAccount.data.some((x) => x.mailAccountId === e));
      if (mailAccountBadgesNeedsToBeRemoved.length) {
        this.mailAccountBadges = this.mailAccountBadges.filter((e) => !mailAccountBadgesNeedsToBeRemoved.includes(e.mailAccountId));
      }
    }
  }

  public getBadge(mailAccount: UserMailAccountContract): number {
    return this.mailAccountBadges.find((e) => e.mailAccountId === mailAccount.mailAccountId)?.badge || null;
  }

  public async closed(event: any, mailAccount: UserMailAccountContract): Promise<void> {}

  public async opened(event: any, mailAccount: UserMailAccountContract): Promise<void> {
    this.eventsService.UserMailAccountSelected.emit({
      MailAccountId: mailAccount.mailAccountId,
      MailAccountRole: mailAccount.roleId,
      MailAccountName: mailAccount.mailAccountName,
    } as UserMailAccountSelected);
    this.searchContextStorageService.selectMailAccount(mailAccount.mailAccountId);
    await this.router.navigate([this.webCode, `emails`]);
    this.foldersTypeCheck.setIsInboxTrue();
  }

  public getTitle(mailAccount: UserMailAccountContract): string {
    return mailAccount.mailAccountName !== '' ? mailAccount.mailAccountName : mailAccount.mailAccountEmail;
  }

  onRightClick(event: MouseEvent, item): void {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item };

    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  public isDefault(mailAccount: UserMailAccountContract): any {
    const defaultMailAccount = this.searchContextStorageService.getDefaultMailAccount();
    this.defaultMailAccountId = defaultMailAccount?.mailAccountId;
    return mailAccount && defaultMailAccount && mailAccount?.mailAccountId === defaultMailAccount?.mailAccountId;
  }
}
