import { Component, Input, OnInit } from '@angular/core';
import { DispatchingAction } from '../../../../../../../sdk/contracts/dispatching/dispatching.action';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MailAccountContract } from '../../../../../../../sdk/contracts/mail-account/mail-account.contract';
import { Group } from '../../../../../../../sdk/contracts/group/group.contract';
import { CheckboxItem } from '../../../../../../../shared/checkbox.item';


@Component({
  selector: 'app-assign-to-group-action',
  templateUrl: './assign-to-group-action.component.html',
  styleUrls: ['./assign-to-group-action.component.scss'],
})
export class AssignGroupsActionComponent implements OnInit {
  @Input() dispatchingAction: DispatchingAction;
  @Input() checkBoxGroups: CheckboxItem<Group>[] = [];
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public selected = [];
  constructor() {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      groups: new UntypedFormControl('', Validators.required),
    });
    this.selected = this.checkBoxGroups.filter((c) => c.checked).map(c => c.value.groupId);
  }

  onChange(group): void {
    let currGroup = this.checkBoxGroups.find((item) => item.label === group.label);
    currGroup.checked = !currGroup.checked;
	
	this.checkBoxGroups.filter((c) => !c.checked).forEach((c) => {		
			let index = this.dispatchingAction.args.findIndex(y => y.value === c.value.groupId.toString());		
			this.dispatchingAction.args.splice(index, 1)
		});
	
	this.checkBoxGroups
      .filter((c) => c.checked).forEach((c) => {
		this.dispatchingAction.args.push(
			{
				name: 'GroupId',
				value: c.value.groupId.toString()
			})
		})
	}
}

