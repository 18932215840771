import { OrganizationArchiveSettings } from './organization-archive-settings';
import { AdfsOAuthOptions } from './adfs-oauth-options';

export class OrganizationSettingsContract {
  alertSpaceUsed: boolean;
  alertLevelSpaceUsed: number | null;
  organizationId: number;
  archive: boolean;
  archiveSettings: OrganizationArchiveSettings;
  adfsOAuthOptions: AdfsOAuthOptions;
  oidcSupport: boolean;
  adfsSupport: boolean;
  deleteEmailsFromTrash: boolean;
  deleteEmailsFromSpam: boolean;
  delayDeleteEmailsFromTrash: number;
  delayDeleteEmailsFromSpam: number;
  googleOAuthSupport: boolean;
  samlSupport: boolean;
  samlRtaClaim: string;
  spaceUsedPaymentPlanType: number;
  defaultNotificationLanguage: string | null;
}
