<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>
<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>
<app-loader *ngIf="navigationIsLoading"></app-loader>

<!-- <div *ngIf="cannotLoadNavigation" class="navigation-error-container">
  <app-something-went-wrong description="Cannot load navigation" (retryEmitter)="loadNavigation()" [showPicture]="false"></app-something-went-wrong>
</div> -->

<app-drawer [isOpen]="updateDrawerIsOpen" (closeEventEmitter)="onCloseUpdatingModalForm()">
  <app-mail-account-update-dialog
    *ngIf="updateDrawerIsOpen"
    [mailAccount]="mailAccount"
    (closeEventEmitter)="onCloseUpdatingModalForm()"
    (submitEventEmitter)="onUpdateMailAccount($event)">
  </app-mail-account-update-dialog>
</app-drawer>

<app-drawer [isOpen]="changePasswordDrawerIsOpen" (closeEventEmitter)="onCloseChangePasswordModalForm()">
  <password-dialog
    *ngIf="changePasswordDrawerIsOpen"
    [mailAccount]="mailAccount"
    (closeEventEmitter)="onCloseChangePasswordModalForm()"
    (submitEventEmitter)="onChangedPassword()">
  </password-dialog>
</app-drawer>

<app-drawer [isOpen]="settingsDrawerIsOpen" (closeEventEmitter)="onCloseSettingsModalForm()">
  <app-mail-account-settings-dialog
    *ngIf="settingsDrawerIsOpen"
    [mailAccountId]="mailAccountId"
    [organizationId]="organizationId"
    (closeEventEmitter)="onCloseSettingsModalForm()"
    (submitEventEmitter)="onUpdateSettings($event)">
  </app-mail-account-settings-dialog>
</app-drawer>

<app-drawer [isOpen]="quickSetupDrawerIsOpen" (closeEventEmitter)="onCloseQuickSetupForm()">
  <app-mail-account-quick-setup-dialog
    *ngIf="quickSetupDrawerIsOpen"
    [mailAccountId]="mailAccountId"
    (closeEventEmitter)="onCloseQuickSetupForm()"
    (submitEventEmitter)="onCloseQuickSetupForm()">
  </app-mail-account-quick-setup-dialog>
</app-drawer>

<ng-container>
  <div class="page-content">
    <app-loader *ngIf="isLoading"></app-loader>

    <div *ngIf="hasError && cannotLoadNavigation" class="half-of-screen">
      <app-something-went-wrong
        description="{{ 'cannotLoadMailAccount' | translate }}"
        [showPicture]="false"
        (retryEmitter)="ngOnInit()"></app-something-went-wrong>
    </div>

    <div *ngIf="!isLoading && !hasError && !cannotLoadNavigation && !navigationIsLoading">
      <div class="page-block box-container relative">
        <div class="title-container flex gap-1 items-center">
          <h1>{{ "generalSettings" | translate }}</h1>
          <div class="buttons">
            <button mat-icon-button aria-label="Edit" (click)="onOpenUpdatingModalForm()">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button aria-label="Settings" (click)="openSettingsModalForm()">
              <mat-icon>settings</mat-icon>
            </button>
          </div>
        </div>
        <table>
          <!-- <tr>
            <td class="item-header">Code:</td>
            <td>{{mailAccount.code}}</td>
          </tr> -->
          <tr>
            <td class="item-header">{{ "name" | translate }}:</td>
            <td>{{ mailAccount.name }}</td>
          </tr>
          <tr>
            <td class="item-header">{{ "license" | translate }}:</td>
            <td>{{ mailAccount.license }}</td>
          </tr>
          <tr>
            <td class="item-header">{{ "codeSia" | translate }}:</td>
            <td>{{ mailAccount.codeSia }}</td>
          </tr>
          <tr>
            <td class="item-header">{{ "anagId" | translate }}:</td>
            <td>{{ mailAccount.anagId }}</td>
          </tr>
          <tr>
            <td class="item-header">{{ "email" | translate }}:</td>
            <td>{{ mailAccount.email }}</td>
          </tr>
          <tr>
            <td class="item-header">{{ "providerLabel" | translate }}:</td>
            <td>{{ mailAccount.providerName }}</td>
          </tr>
          <tr>
            <td class="item-header">{{ "state" | translate }}:</td>
            <td>{{ isActive ? ("active" | translate) : ("notActive" | translate) }}</td>
          </tr>
        </table>
      </div>
      <div class="page-block box-container relative mt-5">
        <h1>{{ "additionalSettings" | translate }}</h1>
        <div class="buttons-panel">
          <button style="margin-bottom: 0.5rem; width: 175px" color="primary" mat-raised-button (click)="openChangePasswordModalForm()">
            {{ "changePass" | translate }}
            <mat-icon>password</mat-icon>
          </button>
          <button style="width: 175px" color="primary" mat-raised-button (click)="onOpenQuickSetupForm()">
            {{ "quickSetup" | translate }}
            <mat-icon>bolt</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
