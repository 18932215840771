<div>
  <form [formGroup]="form" autocomplete="off" novalidate>
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>{{ "selectGroups" | translate }}</mat-label>
      <mat-select [(ngModel)]="selected" formControlName="groups" #select multiple>
        <mat-option
          *ngFor="let group of checkBoxGroups"
          color="primary"
		  [checked]="true"
          [disableRipple]="true"
          (click)="onChange(group)"
          [value]="group.value.groupId">
          {{ group.value.group }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

