import { Component, Input, OnInit, Output, EventEmitter, ViewChild, HostListener } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorDialogComponent } from '../../error/error-dialog/error-dialog.component';
import { EmailClient } from '../../../sdk/clients/email-client';
import { Clipboard } from '@angular/cdk/clipboard';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, fromEvent, tap } from 'rxjs';

@Component({
  selector: 'app-email-tags',
  templateUrl: './email-tags.component.html',
  styleUrls: ['./email-tags.component.scss'],
})
export class EmailTagsComponent implements OnInit {
  @Input() toList: string[];
  @Input() placeHolder: string;
  @Input() existedContacts: string[];
  @Input() initContacts: string[];
  @Input() showCc: boolean = true;
  @Input() isNeedMarginLeft: boolean = true;
  @Output() public changeList = new EventEmitter();
  @Output() public dynamicSearchValue = new EventEmitter();
  @Output() public setCurrentTagsElement = new EventEmitter();
  public isLoading = false;
  public cannotLoadMeta = false;
  public meta: string;
  public clickedTagValueTo: string;
  public form: UntypedFormGroup;
  @ViewChild('tagInputSelectTo') tagInputSelectTo;
  public classClicked: string;
  public isTagClicked: boolean = false;
  public shiftedTagsTo = [];
  public focusedTag: string;

  constructor(public dialog: MatDialog, private emailClient: EmailClient, private clipboard: Clipboard) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      to: new UntypedFormControl(''),
    });
  }

  ngAfterViewInit() {
    // server-side search
    if (this.dynamicSearchValue) {
      fromEvent(this.tagInputSelectTo.nativeElement, 'keyup')
        .pipe(
          filter(
            (e: KeyboardEvent) =>
              e.code !== 'ArrowLeft' && e.code !== 'ArrowRight' && e.code !== 'ArrowDown' && e.code !== 'ArrowUp' && e.code !== 'Enter'
          ),
          debounceTime(300),
          distinctUntilChanged(),
          tap((text) => {
            this.dynamicSearchValue.emit(this.tagInputSelectTo.nativeElement.value);
          })
        )
        .subscribe();
    }
  }

  public isTagClickedEmit() {
    this.isTagClicked = true;
  }

  public onTagToClick(value) {
    this.setCurrentTagsElement.emit();
    this.clickedTagValueTo = value;
    const activeEle = document.activeElement;
    (activeEle as HTMLElement)?.focus();
    setTimeout(() => {
      const activeEle = document.activeElement;
      (activeEle.getElementsByClassName('input-label')[0] as HTMLElement)?.focus();
      this.isTagClicked = false;
    }, 0);
  }

  openErrorDialog(title: string, description: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { title: title, description: description },
    });
  }

  public filteredToList() {
    let filteredList = this.existedContacts.filter((e) => !this.toList.includes(e));
    if (this.tagInputSelectTo?.nativeElement?.value && !this.dynamicSearchValue) {
      return filteredList.filter((e) => e.toLowerCase().includes(this.tagInputSelectTo.nativeElement.value.toLowerCase()));
    } else {
      return filteredList;
    }
  }

  public onSubmitTagTo(event) {
    if (!event.target.value) {
      this.deleteTagTo(this.clickedTagValueTo);
    } else {
      this.toList = this.toList.map((e) => (e === this.clickedTagValueTo ? (e = event.target.value) : e));
      this.toList = this.toList.filter((e, i, arr) => arr.lastIndexOf(e) === i);
      this.clickedTagValueTo = null;
      if (!this.isTagClicked) {
        this.tagInputSelectTo.nativeElement.focus();
      }
    }
    this.changeList.emit(this.toList);
  }

  @HostListener('window:mousedown', ['$event'])
  keyEvent(e: MouseEvent) {
    this.classClicked = (e.target as Element).className;
  }

  public selectedTo(value: string, isFocusOut = false) {
    if (isFocusOut && this.classClicked === 'mat-option-text') {
      return;
    }
    if (value) {
      let emails = value
        .split(';')
        .map((e) => e.replace(/\s/g, ''))
        .filter((e) => e.length);
      let toList = [...this.toList, ...emails];
      if (toList.length >= 100) {
        this.openErrorDialog('error', 'maximumNumberOfRecipients');
      } else {
        this.toList = toList;
        this.toList = this.toList.filter((e, i, arr) => arr.lastIndexOf(e) === i);
      }
      this.shiftedTagsTo = [];
      this.tagInputSelectTo.nativeElement.value = '';
      // this.tagInputSelectTo.nativeElement.focus();
      this.changeList.emit(this.toList);
      if (this.initContacts) {
        this.existedContacts = this.initContacts;
      }
    }
  }

  public deleteTagTo(value: string) {
    this.shiftedTagsTo = [];
    this.toList = this.toList.filter((e) => e !== value);
    this.tagInputSelectTo.nativeElement.blur();
    this.changeList.emit(this.toList);
  }

  public isValidTag(value: string) {
    return !!String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  public isTagShiftSelected(value: string) {
    return this.shiftedTagsTo.includes(value);
  }

  public setFocusedTag(value: string) {
    this.focusedTag = value;
  }

  moveCell(e) {
    if (!this.toList.length) {
      return;
    }
    const activeEle = document.activeElement;
    if (e.key == 'ArrowRight' && !this.tagInputSelectTo.nativeElement.value && activeEle.parentElement.tagName === 'DIV') {
      if (e.shiftKey) {
        this.shiftedTagsTo.push(this.focusedTag);
        (activeEle.nextElementSibling as HTMLElement)?.focus();
        this.shiftedTagsTo.push(this.focusedTag);
        this.shiftedTagsTo = this.shiftedTagsTo.filter((e, i, arr) => arr.indexOf(e) === i);
        if (this.focusedTag === this.toList[this.toList.length - 1]) {
          this.focusedTag = '';
        }
      } else {
        this.shiftedTagsTo = [];
        (activeEle.nextElementSibling as HTMLElement)?.focus();
      }
    }

    if (
      e.key == 'ArrowLeft' &&
      (!this.tagInputSelectTo.nativeElement.value || e.target.selectionStart === 0) &&
      activeEle.parentElement.tagName === 'DIV'
    ) {
      if (e.shiftKey) {
        this.shiftedTagsTo.push(this.focusedTag);
        (activeEle.previousElementSibling as HTMLElement)?.focus();
        this.shiftedTagsTo.push(this.focusedTag);
        this.shiftedTagsTo = this.shiftedTagsTo.filter((e, i, arr) => arr.indexOf(e) === i);
      } else {
        this.shiftedTagsTo = [];
        (activeEle.previousElementSibling as HTMLElement)?.focus();
      }
    }
    if (e.key == 'End') {
      if (e.shiftKey) {
        this.shiftedTagsTo = this.toList.filter((e, i, arr) => arr.indexOf(this.focusedTag) <= i);
        (activeEle.parentNode.lastChild.previousSibling.previousSibling.previousSibling as HTMLElement)?.focus();
      } else {
        this.shiftedTagsTo = [];
        (activeEle.parentNode.lastChild.previousSibling as HTMLElement)?.focus();
      }
    }
    if (e.key == 'Home') {
      if (e.shiftKey) {
        if (!this.focusedTag) {
          this.shiftedTagsTo = this.toList;
        } else {
          this.shiftedTagsTo = this.toList.filter((e, i, arr) => arr.indexOf(this.focusedTag) >= i);
        }
        (activeEle.parentNode.firstChild as HTMLElement)?.focus();
      } else {
        this.shiftedTagsTo = [];
        (activeEle.parentNode.firstChild as HTMLElement)?.focus();
      }
    }
    if (e.key == 'c' && e.ctrlKey) {
      if (this.shiftedTagsTo.length) {
        this.clipboard.copy(this.toList.filter((e) => this.shiftedTagsTo.includes(e)).join(';'));
      } else {
        this.clipboard.copy(this.focusedTag);
      }
    }
    if (e.key == 'a' && e.ctrlKey) {
      this.shiftedTagsTo = this.toList;
    }
    if (e.key == 'Backspace' && activeEle.parentElement.tagName === 'DIV' && activeEle.tagName === 'INPUT') {
      if (!this.tagInputSelectTo.nativeElement.value) {
        this.deleteTagTo(this.toList[this.toList.length - 1]);
        this.tagInputSelectTo.nativeElement.focus();
      }
    }
    this.changeList.emit(this.toList);
  }

  onTagKeyDown(e, value: string) {
    const activeEle = document.activeElement;
    if (activeEle.tagName === 'INPUT') {
      return;
    }
    if (e.key == 'Backspace') {
      if (this.shiftedTagsTo.length) {
        this.toList = this.toList.filter((e) => !this.shiftedTagsTo.includes(e));
        this.tagInputSelectTo.nativeElement.blur();
        this.shiftedTagsTo = [];
      } else {
        this.deleteTagTo(value);
      }
      if (activeEle.previousElementSibling) {
        (activeEle.previousElementSibling as HTMLElement)?.focus();
      } else if (activeEle.nextElementSibling) {
        (activeEle.nextElementSibling as HTMLElement)?.focus();
      } else {
        this.tagInputSelectTo.nativeElement.focus();
      }
    }
    this.changeList.emit(this.toList);
  }
}
