import { Component, OnInit } from '@angular/core';
import { BreadcrumbItem } from '../../../../../shared/breadcrumb-item';
import { TabItem } from '../../../../../shared/tab-item';
import { PermissionType } from '../../../../../sdk/contracts/permission/permission-type';
import { PermissionService } from '../../../../../services/permission/permission.service';
import { MailAccountContract } from '../../../../../sdk/contracts/mail-account/mail-account.contract';
import { OrganizationClient } from '../../../../../sdk/clients/organization.client';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';
import { PermissionNavTabHelper } from '../../../../../services/permission/permission-nav-tab-helper';
import { ActivatedRoute } from '@angular/router';
import { MailAccountClient } from '../../../../../sdk/clients/mail-account.client';
import { MailAccountSettingsClient } from '../../../../../sdk/clients/mail-account-settings.client';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-mail-account-general-settings',
  templateUrl: './mail-account-general-settings.component.html',
  styleUrls: ['./mail-account-general-settings.component.scss'],
})
export class MailAccountGeneralSettingsComponent implements OnInit {
  public organizationId: number;
  public mailAccountId: number;
  public mailAccount: MailAccountContract;
  public webCode: string;

  public isLoading = false;
  public hasError = false;

  public navigationIsLoading = false;
  public cannotLoadNavigation = false;
  public breadcrumbItems: BreadcrumbItem[] = [];
  public tabItems: TabItem[] = [];
  public updateDrawerIsOpen = false;
  public changePasswordDrawerIsOpen = false;
  public settingsDrawerIsOpen = false;
  public quickSetupDrawerIsOpen = false;
  public isActive: boolean;

  constructor(
    public matchError: MatchError,
    private permissionService: PermissionService,
    private organizationClient: OrganizationClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private route: ActivatedRoute,
    private mailAccountSettingsClient: MailAccountSettingsClient,
    private mailAccountClient: MailAccountClient
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.mailAccountId = parseInt(this.route.snapshot.paramMap.get('mailAccountId'), 10);
    this.loadTabs();
    await this.loadMailAccount();
    await this.loadNavigation();
  }

  async loadNavigation(): Promise<void> {
    this.cannotLoadNavigation = false;
    this.navigationIsLoading = true;
    try {
      await this.loadBreadcrumbItems();
    } catch (e) {
      this.cannotLoadNavigation = true;
      this.matchError.logError(e);
    } finally {
      this.navigationIsLoading = false;
    }
  }

  private loadTabs(): void {
    this.tabItems = this.permissionNavTabHelper.getTabsForMailAccountPage(this.organizationId, this.mailAccountId, this.webCode);
  }

  private async loadBreadcrumbItems(): Promise<void> {
    const organizationName = (await this.organizationClient.getOrganizationName(new BaseGetByIdRequest(this.organizationId))).result;
    if (await this.permissionService.hasPermissionOver(null, null, PermissionType.CanEverything)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: this.mailAccount.name, route: null },
        { index: 3, label: 'generalBreadCrumbs', route: null },
      ];
    } else if (await this.permissionService.hasPermissionOver(this.organizationId, null, PermissionType.CanManageOrganization)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: this.mailAccount.name, route: null },
        { index: 3, label: 'generalBreadCrumbs', route: null },
      ];
    } else {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: this.mailAccount.name, route: null },
        { index: 3, label: 'generalBreadCrumbs', route: null },
      ];
    }
  }

  public async loadMailAccount(): Promise<void> {
    this.hasError = false;
    this.isLoading = true;
    try {
      const response = await this.mailAccountClient.getById(new BaseGetByIdRequest(this.mailAccountId));
      const responseSettings = await this.mailAccountSettingsClient.getByMailAccount(new BaseGetByIdRequest(this.mailAccountId));
      this.isActive = responseSettings.result.active;
      this.mailAccount = response.mailAccount;
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public onOpenUpdatingModalForm(): void {
    this.updateDrawerIsOpen = true;
  }

  public onCloseUpdatingModalForm(): void {
    this.updateDrawerIsOpen = false;
  }

  public onUpdateMailAccount({ name, codeSia, license, anagId }): void {
    this.updateDrawerIsOpen = false;
    this.mailAccount.name = name;
    this.mailAccount.codeSia = codeSia;
    this.mailAccount.license = license;
    this.mailAccount.anagId = anagId;
  }

  public openChangePasswordModalForm(): void {
    this.changePasswordDrawerIsOpen = true;
  }

  public onCloseChangePasswordModalForm(): void {
    this.changePasswordDrawerIsOpen = false;
  }

  public onChangedPassword(): void {
    this.changePasswordDrawerIsOpen = false;
  }

  public openSettingsModalForm(): void {
    this.settingsDrawerIsOpen = true;
  }

  public onCloseSettingsModalForm(): void {
    this.settingsDrawerIsOpen = false;
  }

  public onUpdateSettings({ isActive }): void {
    this.isActive = isActive;
    this.settingsDrawerIsOpen = false;
  }

  public onOpenQuickSetupForm(): void {
    this.quickSetupDrawerIsOpen = true;
  }

  public onCloseQuickSetupForm(): void {
    this.quickSetupDrawerIsOpen = false;
  }
}
