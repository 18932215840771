import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseDeleteResponse } from '../contracts/common/base-delete.response';
import { EmailNoteContract } from '../contracts/email-note/email-note.contract';
import { EmailNoteCreateRequest } from '../contracts/email-note/email-note.create-request';
import { EmailNoteUpdateRequest } from '../contracts/email-note/email-note.update-request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';

const API_URL = environment.apiUrl;

@Injectable()
export class EmailNoteClient {
  constructor(private apiRequest: ApiRequest) {}

  async get(request: BaseGetByIdRequest): Promise<EmailNoteContract> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.emailNote.get}?${request.format()}`);
  }

  async getForEmail(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<EmailNoteContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.emailNote.getForEmail}?${request.format()}`);
  }

  async delete(request: BaseDeleteRequest): Promise<BaseDeleteResponse> {
    return this.apiRequest.post<BaseDeleteRequest, BaseDeleteResponse>(request, `${API_URL}${ApiRoutes.emailNote.delete}`);
  }

  async create(request: EmailNoteCreateRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<EmailNoteCreateRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.emailNote.create}`);
  }

  async update(request: EmailNoteUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<EmailNoteUpdateRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.emailNote.update}`);
  }
}
