<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>

<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>

<!-- <div *ngIf="cannotLoadNavigation" class="navigation-error-container">
  <app-something-went-wrong
    description="Cannot load navigation"
    (retryEmitter)="loadNavigation()"
    [showPicture]="false"></app-something-went-wrong>
</div> -->

<app-drawer [isOpen]="createDrawerIsOpen" (closeEventEmitter)="onDrawerClose()">
  <app-organization-group-create-dialog
    *ngIf="createDrawerIsOpen"
    (closeEventEmitter)="onCloseCreateForm()"
    (createEventEmitter)="onGroupCreated()"
    [organizationId]="organizationId">
  </app-organization-group-create-dialog>
</app-drawer>

<app-drawer [isOpen]="updateDrawerIsOpen" (closeEventEmitter)="onDrawerClose()">
  <app-organization-group-update-dialog
    *ngIf="updateDrawerIsOpen"
    (closeEventEmitter)="onCloseUpdateForm()"
    (updateEventEmitter)="onGroupUpdated()"
    [group]="editingGroup">
  </app-organization-group-update-dialog>
</app-drawer>

<app-drawer newTitle="Add groups to users" [isOpen]="showAddGroupsToUsersForm" (closeEventEmitter)="onDrawerClose()">
  <app-add-groups-to-users-form
    *ngIf="showAddGroupsToUsersForm"
    (close)="onHideAddGroupsToUsersForm()"
    (submit)="onAddGroupsToUsers()"
    [groups]="selection.selected"
    [organizationID]="organizationId">
  </app-add-groups-to-users-form>
</app-drawer>

<ng-container>
  <div class="page-content">
    <div class="search-panel flex-wrap">
      <div class="button-group">
        <div class="button-group mr-5">
          <button
            mat-raised-button
            color="primary"
            class="page-block-button"
            aria-label="Create provider"
            (click)="onCreateGroupBtnClicked()">
            <mat-icon>add</mat-icon>{{ "newGroup" | translate }}
          </button>
        </div>

        <div class="button-group" *ngIf="!selection.isEmpty()">
          <button
            color="white"
            mat-mini-fab
            class="page-block-button"
            mat-icon-button
            aria-label="Add groups to users"
            matTooltip="{{ 'addGroupsToUsers' | translate }}"
            (click)="onAddGroupsToUsersBtnClicked()">
            <mat-icon>groups</mat-icon>
          </button>
        </div>
      </div>
      <div class="mobile-view search-bar">
        <app-search-bar
          [fullWidth]="true"
          (searchEventEmitter)="fetchGroups(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
      <div class="not-mobile-view search-bar">
        <app-search-bar
          [fullWidth]="false"
          (searchEventEmitter)="fetchGroups(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
    </div>
    <div class="mobile-view">
      <div class="mobile">
        <div class="buttons-mobile-container">
          <mat-checkbox
            color="primary"
            class="mb-2 mt-4"
            [checked]="selection.hasValue() && isAllSelected()"
            (change)="$event ? masterToggle() : null"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
            {{ "selectAll" | translate }}
          </mat-checkbox>
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)"></mat-paginator>
        </div>

        <div class="page-block" *ngFor="let el of organizationGroups">
          <div class="card">
            <div class="checkbox-col">
              <mat-checkbox
                color="primary"
                class="mobile-card-title-checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(el) : null"
                [checked]="selection.isSelected(el)">
              </mat-checkbox>
            </div>

            <mat-card class="cursor-pointer mobile-row">
              <!-- <div class="w-full flex flex-wrap" (click)="emailDetails(email)">
                
              </div> -->

              <mat-card-content class="flex flex-1" (click)="onUpdateGroupBtnClicked(el)">
                <div class="card-table-row flex-start">
                  <div class="card-table-row-header">Name:</div>
                  <div class="card-table-row-value">{{ el.group }}</div>
                </div>
                <div class="card-table-row flex-start">
                  <div class="card-table-row-header">Created At:</div>
                  <div class="card-table-row-value">{{ el.creationDateTime | date }}</div>
                </div>
              </mat-card-content>

              <div style="width: 40px; margin-left: 10px">
                <button
                  matTooltip="{{ 'addGroupToUsers' | translate }}"
                  mat-icon-button
                  color="primary"
                  aria-label="Add Group To Users"
                  (click)="onAddGroupToUsersBtnClicked(el)">
                  <mat-icon>groups</mat-icon>
                </button>
                <button matTooltip="Update" mat-icon-button color="primary" aria-label="Update" (click)="onUpdateGroupBtnClicked(el)">
                  <mat-icon>edit_outline</mat-icon>
                </button>
                <button matTooltip="Delete" mat-icon-button color="primary" aria-label="Delete" (click)="onDeleteGroupBtnClicked(el)">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </mat-card>
          </div>
        </div>
        <app-no-data *ngIf="organizationGroups.length === 0 && !isLoading && !hasError"></app-no-data>
      </div>
    </div>

    <div class="page-block" *ngIf="!useMobileView">
      <div class="table-container">
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)">
        </mat-paginator>
        <table *ngIf="!isLoading && !hasError" mat-table [dataSource]="dataSource">
          <!-- Select checkbox column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                color="primary"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
            <td mat-cell *matCellDef="let el">{{ el.group }}</td>
          </ng-container>

          <!-- Created At Column -->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>{{ "createdAt" | translate }}</th>
            <td mat-cell *matCellDef="let el">{{ el.creationDateTime | date }}</td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th class="actions-col" mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
            <td mat-cell *matCellDef="let el">
              <button
                mat-icon-button
                aria-label="{{ 'addGroupsToUsers' | translate }}"
                matTooltip="{{ 'addGroupsToUsers' | translate }}"
                (click)="onAddGroupToUsersBtnClicked(el)">
                <mat-icon>groups</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="{{ 'updateGroup' | translate }}"
                matTooltip="{{ 'updateGroup' | translate }}"
                (click)="onUpdateGroupBtnClicked(el)">
                <mat-icon>edit_outline</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="{{ 'delete' | translate }}"
                matTooltip="{{ 'delete' | translate }}"
                (click)="onDeleteGroupBtnClicked(el)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
        </table>
        <app-no-data *ngIf="organizationGroups.length === 0 && !isLoading && !hasError"></app-no-data>
      </div>
    </div>
  </div>

  <app-loader *ngIf="isLoading && !hasError"></app-loader>

  <div *ngIf="hasError" class="half-of-screen">
    <app-something-went-wrong
      [showPicture]="false"
      description="{{ 'cannotLoadGroups' | translate }}"
      (retryEmitter)="fetchGroups() && loadNavigation()"></app-something-went-wrong>
  </div>
</ng-container>
