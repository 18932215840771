import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { TagClient } from '../../../sdk/clients/tag.client';

import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subscription, Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { ContactContract } from '../../../sdk/contracts/contact/contact.contract';
import { filter } from 'rxjs/operators';
import { ContactClient } from '../../../sdk/clients/contact.client';
import { BaseGetCollectionByIdRequest } from '../../../sdk/contracts/common/base-get-collection-by-id.request';
import { BaseCollectionSearchByIdRequest } from '../../../sdk/contracts/common/base-collection-search-by-id.request';
import { BaseIdListRequest } from '../../../sdk/contracts/common/base.id-list.request';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactGroupClient } from '../../../sdk/clients/contact-group.client';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'email-contacts-dialog',
  templateUrl: './email-contacts-dialog.component.html',
  styleUrls: ['./email-contacts-dialog.component.scss'],
})
export class EmailContactsDialogComponent {
  public isLoading = true;
  @ViewChild('input', { read: ElementRef }) input: ElementRef;
  public mailAccountId: number;
  public contacts: ContactContract[] = null;
  public dataSource = new MatTableDataSource<ContactContract>(this.contacts);
  public displayedColumns: string[] = ['select', 'email', 'action'];
  private pageNumber: number;
  public pageSize: number;
  private subscription: Subscription;
  public selection = new SelectionModel<ContactContract>(true, []);
  public webCode: string;
  public contactToDelete: ContactContract | null = null;
  public myControl = new UntypedFormControl();
  public options: string[] = [];
  public filteredOptions: Observable<string[]>;
  public isError = false;
  public search: string = null;

  constructor(
    public dialogRef: MatDialogRef<EmailContactsDialogComponent>,
    public tagClient: TagClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private matchError: MatchError,
    private contactClient: ContactClient,
    private contactGroupClient: ContactGroupClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.close();
      }
    });
    this.mailAccountId = this.data.mailAccountId;
    this.pageNumber = 1;
    this.pageSize = 25;
    try {
      const response = await this.contactClient.getForAccount(new BaseGetCollectionByIdRequest(this.mailAccountId, this.pageNumber, 100));
      this.options = response.data.map((e) => e.email);
      await this.getContacts();
      this.isLoading = false;
      this.isError = false;
    } catch (e) {
      this.isError = true;
      this.isLoading = false;
      this.matchError.logError(e);
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(300),
        distinctUntilChanged(),
        tap((text) => {
          this.search = this.input?.nativeElement?.value;
          this.getContacts();
        })
      )
      .subscribe();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  canNavigateBack(): boolean {
    if (this.contacts) {
      return this.pageNumber > 1;
    }
  }

  async navigateBack(): Promise<void> {
    this.pageNumber = this.pageNumber - 1;
    await this.getContacts();
    this.selection.clear();
  }

  canNavigateForward(): boolean {
    if (this.contacts) {
      return this.contacts.length === this.pageSize;
    }
  }

  async navigateForward(): Promise<void> {
    this.pageNumber = this.pageNumber + 1;
    await this.getContacts();
    this.selection.clear();
  }

  async deleteContacts(): Promise<any> {
    if (!this.contactToDelete && this.selection && this.selection.selected.length > 0) {
      const request = new BaseIdListRequest();
      request.list = this.selection.selected.map((value) => value.contactId);
      await this.contactClient.delete(request);
      await this.getContacts();
    }
    if (this.contactToDelete) {
      const request = new BaseIdListRequest();
      request.list = [this.contactToDelete.contactId];
      await this.contactClient.delete(request);
      await this.getContacts();
      this.contactToDelete = null;
      this.selection.clear();
    }
  }

  public deleteContact(contact: ContactContract): void {
    this.contactToDelete = contact;
    this.openConfirmDialog();
  }

  public openConfirmDialog(isCreate = false): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: {
        title: 'deleteContactTitle',
        isDontNeedConfirmWord: true,
        name: 'deleteContactSubtitle',
        isCreate,
        mailAccountId: this.mailAccountId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteContacts();
      }
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.search = event.option.viewValue;
    this.getContacts();
  }

  public async pageSizeChange(pageSize: number): Promise<void> {
    this.pageSize = pageSize;
    this.pageNumber = 1;

    await this.getContacts();
  }

  private async getContacts(): Promise<any> {
    this.isLoading = true;
    let response;
    try {
      if (!this.search) {
        response = await this.contactClient.getForAccount(
          new BaseGetCollectionByIdRequest(this.mailAccountId, this.pageNumber, this.pageSize)
        );
      } else {
        response = await this.contactClient.searchForAccount(
          new BaseCollectionSearchByIdRequest(this.mailAccountId, this.pageNumber, this.pageSize, this.search)
        );
      }
      this.contacts = response.data;
      this.dataSource.data = this.contacts;
      this.isError = false;
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  public addContact(email: string): void {
    this.dialogRef.close([email]);
  }

  public addSelectedContacts(): void {
    const emails = this.selection.selected.map((e) => e.email);
    //
    this.dialogRef.close(emails);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
