import { UntypedFormGroup, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

// To validate password and confirm password
export const comparePasswordValidator: ValidatorFn = (formGroup: UntypedFormGroup): ValidationErrors | null => {
  const control = formGroup.controls.password;
  const matchingControl = formGroup.controls.passwordConfirm;
  if (control.value !== matchingControl.value) {
    matchingControl.setErrors({ mustMatch: true });
  } else {
    matchingControl.setErrors(null);
  }
  return control.value !== matchingControl.value ? { comparePasswordValidator: true } : null;
};

export function hasAtLeastOneUpperCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return /[A-Z]+/.test(control.value) ? null : { hasAtLeastOneUpperCharacter: control.value };
  };
}

export function hasAtLeastOneLowerCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return /[a-z]+/.test(control.value) ? null : { hasAtLeastOneLowerCharacter: control.value };
  };
}

export function hasAtLeastOneDigit(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return /\d+/.test(control.value) ? null : { hasAtLeastOneDigit: control.value };
  };
}

export function hasAtLeastOneSpecialSymbol(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return /[$&+,:;=?@#|'<>.^*()%!-]+/.test(control.value) ? null : { hasAtLeastOneSpecialSymbol: control.value };
  };
}
