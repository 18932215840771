import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { map, startWith } from 'rxjs/operators';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

@Component({
  selector: 'app-email-search-tag-input',
  templateUrl: './email-search-tag-input.component.html',
  styleUrls: ['./email-search-tag-input.component.scss'],
})
export class EmailSearchTagInputComponent implements OnInit {
  @Input() existedTags: string[];
  @Input() selectedTags: string[];
  tagCtrl = new UntypedFormControl();
  filteredTags: Observable<string[]>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  constructor() {
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.existedTags.slice()))
    );
  }

  ngOnInit(): void {}

  removeTag(tag: string): void {
    const index = this.selectedTags.indexOf(tag);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }
    this.tagCtrl.setValue(null);
  }

  selectedTag(event: MatAutocompleteSelectedEvent): void {
    this.selectedTags.push(event.option.value);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.existedTags.filter((tag) => tag.toLowerCase().indexOf(filterValue) === 0);
    } else {
      const filterValue = value.toLowerCase();
      return this.existedTags.filter((tag) => tag.toLowerCase().indexOf(filterValue) === 0);
    }
  }
}
