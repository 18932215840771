<div class="drawer-content-screen flex flex-col justify-between">
  <div class="text-2xl mb-10 font-medium">
    {{ "createNewProvider" | translate }}
  </div>
  <div class="h-full w-full flex-grow overflow-y-auto">
    <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit(form.value)">
      <mat-form-field appearance="fill">
        <mat-label>{{ "code" | translate }}</mat-label>
        <input matInput id="code" formControlName="code" />
        <mat-error *ngIf="hasError('code', 'maxlength')">You have more than 512 characters</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "name" | translate }}</mat-label>
        <input matInput id="name" formControlName="name" required />
        <mat-error *ngIf="hasError('name', 'required')">{{ "nameIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('name', 'maxlength')">{{ "moreThan128Char" | translate }}</mat-error>
      </mat-form-field>
      <div class="flex w-full">
        <div class="w-2/3 mr-5">
          <mat-form-field appearance="fill">
            <mat-label>{{ "smtp" | translate }}</mat-label>
            <input matInput id="smtp" formControlName="smtp" required />
            <mat-error *ngIf="hasError('smtp', 'required')">{{ "nameIsRequired" | translate }}</mat-error>
            <mat-error *ngIf="hasError('smtp', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="w-1/3">
          <mat-form-field appearance="fill">
            <mat-label>{{ "smtpPort" | translate }}</mat-label>
            <input type="number" matInput id="smtpPort" formControlName="smtpPort" required />
            <mat-error *ngIf="hasError('smtpPort', 'min')">{{ "portValueMustBe" | translate }}</mat-error>
            <mat-error *ngIf="hasError('smtpPort', 'max')">{{ "portValueMustBe" | translate }}</mat-error>
            <mat-error *ngIf="hasError('smtpPort', 'pattern')">{{ "portValueMustInt" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="flex w-full">
        <div class="w-2/3 mr-5">
          <mat-form-field appearance="fill">
            <mat-label>{{ "imap" | translate }}</mat-label>
            <input matInput id="imap" formControlName="imap" required />
            <mat-error *ngIf="hasError('imap', 'required')">{{ "nameIsRequired" | translate }}</mat-error>
            <mat-error *ngIf="hasError('imap', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="w-1/3">
          <mat-form-field appearance="fill">
            <mat-label>{{ "imapPort" | translate }}</mat-label>
            <input type="number" matInput id="imapPort" formControlName="imapPort" required />
            <mat-error *ngIf="hasError('imapPort', 'min')">{{ "portValueMustBe" | translate }}</mat-error>
            <mat-error *ngIf="hasError('imapPort', 'max')">{{ "portValueMustBe" | translate }}</mat-error>
            <mat-error *ngIf="hasError('imapPort', 'pattern')">{{ "portValueMustInt" | translate }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-form-field appearance="fill">
        <mat-label>{{ "inboxFolderName" | translate }}</mat-label>
        <input type="text" matInput id="inboxName" formControlName="inboxName" />
        <mat-error *ngIf="hasError('inboxName', 'maxlength')">You have more than 512 characters</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "outboxFolderName" | translate }}</mat-label>
        <input type="text" matInput id="outboxName" formControlName="outboxName" />
        <mat-error *ngIf="hasError('outboxName', 'maxlength')">You have more than 512 characters</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="w-full">
    <!-- <div *ngIf="isError" class="mb-3">
      <app-error-hint errorText="Cannot create provider: something went wrong"></app-error-hint>
    </div> -->
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit(form.value)">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
