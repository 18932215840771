import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MailAccountGetByOrganizationIdResponse } from '../contracts/mail-account/mail-account.get-by-organization-id.response';
import { MailAccountCreateRequest } from '../contracts/mail-account/mail-account.create.request';
import { MailAccountUpdateRequest } from '../contracts/mail-account/mail-account.update.request';
import { MailAccountGetByIdResponse } from '../contracts/mail-account/mail-account.get-by-id.response';
import { MailAccountChangePasswordRequest } from '../contracts/mail-account/mail-account.change-password.request';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseDeleteResponse } from '../contracts/common/base-delete.response';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseCollectionSearchByIdRequest } from '../contracts/common/base-collection-search-by-id.request';
import { BaseValueResponse } from '../contracts/common/base-value-response';
import { BaseGetSearchCountByIdRequest } from '../contracts/common/base-get-search-count-by-id.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { GetOrganizationMailAccountsRequest } from '../contracts/mail-account/get-organization-mail-accounts.request';
import { MailAccountContract } from '../contracts/mail-account/mail-account.contract';
import { MailAccountGetBadgeRequest } from '../contracts/mail-account/mail-account.get-badges.request';
import { MailAccountGetBadgeResponse } from '../contracts/mail-account/mail-account.get-badges.response';
import { BaseCollectionFilteredSearchByIdRequest } from '../contracts/common/base-collection-filtered-search-by-id.request';

const API_URL = environment.apiUrl;

@Injectable()
export class MailAccountClient {
  constructor(private apiRequest: ApiRequest) {}

  async getSearch(request: BaseCollectionFilteredSearchByIdRequest): Promise<MailAccountGetByOrganizationIdResponse> {
    return this.apiRequest.post(request, `${API_URL}${ApiRoutes.mailAccount.getSearch}`);
  }

  async getForReassign(request: BaseCollectionSearchByIdRequest): Promise<MailAccountGetByOrganizationIdResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.mailAccount.getForReassign}?${request.format()}`);
  }

  async create(request: MailAccountCreateRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<MailAccountCreateRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.mailAccount.create}`);
  }

  async update(request: MailAccountUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<MailAccountUpdateRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.mailAccount.update}`);
  }

  async delete(request: BaseDeleteRequest): Promise<BaseDeleteResponse> {
    return this.apiRequest.post<BaseDeleteRequest, BaseDeleteResponse>(request, `${API_URL}${ApiRoutes.mailAccount.delete}`);
  }

  async getById(request: BaseGetByIdRequest): Promise<MailAccountGetByIdResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.mailAccount.get}?${request.format()}`);
  }

  async getMailAccountsBadges(request: MailAccountGetBadgeRequest): Promise<BaseGetCollectionResponse<MailAccountGetBadgeResponse>> {
    return this.apiRequest.post<MailAccountGetBadgeRequest, BaseGetCollectionResponse<MailAccountGetBadgeResponse>>(
      request,
      `${API_URL}${ApiRoutes.mailAccount.getMailAccountsBadges}`
    );
  }

  async dispatchForAccount(request: BaseGetByIdRequest): Promise<any> {
    return this.apiRequest.get(`${API_URL}dispatching/dispatchforaccount?${request.format()}`);
  }

  async changePassword(request: MailAccountChangePasswordRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<MailAccountChangePasswordRequest, BaseUpdateResponse>(
      request,
      `${API_URL}${ApiRoutes.mailAccount.updatePassword}`
    );
  }

  async getSearchCount(request: BaseGetSearchCountByIdRequest): Promise<BaseValueResponse<number>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.mailAccount.getSearchCount}?${request.format()}`);
  }

  async getOrganizationMailAccounts(request: GetOrganizationMailAccountsRequest): Promise<BaseGetCollectionResponse<MailAccountContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.mailAccount.getOrganizationMailAccounts}?${request.format()}`);
  }
}
