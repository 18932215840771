import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { GroupUpdateRequest } from '../../../../../sdk/contracts/group/group.requets';
import { GroupClient } from '../../../../../sdk/clients/group-settings.client';
import { Group } from '../../../../../sdk/contracts/group/group.contract';
import { MatchError } from 'src/app/shared/error-matcher';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-organization-group-update-dialog',
  templateUrl: './organization-group-update-dialog.component.html',
  styleUrls: ['./organization-group-update-dialog.component.scss'],
})
export class OrganizationGroupUpdateDialogComponent implements OnInit {
  @Output() private closeEventEmitter = new EventEmitter();
  @Output() private updateEventEmitter = new EventEmitter();
  @Input() public group: Group;

  public handlingErrorCodes = new Map<number, string>([]);

  public errorText: string;

  public form = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(256)]],
  });

  public isLoading = false;
  public hasError = false;

  constructor(private fb: UntypedFormBuilder, private groupClient: GroupClient, public matchError: MatchError, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.form.patchValue({
      name: this.group.group,
    });
  }

  public cancel() {
    this.form.reset();
    this.closeEventEmitter.emit();
  }

  public async save() {
    if (this.form.valid) {
      const req: GroupUpdateRequest = {
        group: this.form.get('name').value,
        groupId: this.group.groupId,
      };
      try {
        this.isLoading = true;
        await this.groupClient.update(req);
        this.updateEventEmitter.emit();
        this.form.reset();
      } catch (e) {
        this.hasError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
