import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MailFolderCreateRequest } from '../../../sdk/contracts/mail-folder/mail-folder.create.request';
import { MailFolderType } from '../../../sdk/contracts/mail-folder/mail-folder.type';
import { MailFolderClient } from '../../../sdk/clients/mail-folder.client';
import { MatchError } from 'src/app/shared/error-matcher';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { RemoteClient } from '../../../sdk/clients/remote.client';
@Component({
  selector: 'app-mail-folder-create-dialog',
  templateUrl: './mail-folder-create-dialog.component.html',
  styleUrls: ['./mail-folder-create-dialog.component.scss'],
})
export class MailFolderCreateDialogComponent implements OnInit {
  @Input() parentMailFolderId: number | null;
  @Input() parentMailFolderType: number;
  @Input() mailAccountId: number;
  @Input() isSettings: boolean;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public loadingAvailableData = false;
  public cannotLoadAvailableData = false;
  public isLoading = false;
  public errorText: string;
  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.maxLength(256)]),
  });
  public providerFolders: any[] = null;
  public selectedProviderFolder: string | null = null;
  constructor(private mailFolderClient: MailFolderClient, public matchError: MatchError, private remoteClient: RemoteClient) {}

  async ngOnInit(): Promise<any> {
    if ((this.parentMailFolderType === 2 || this.parentMailFolderType === 3) && this.isSettings) {
      await this.loadData();
    }
  }
  async loadData(): Promise<any> {
    this.loadingAvailableData = true;
    this.cannotLoadAvailableData = false;
    try {
      const response = await this.remoteClient.fetchRemoteFolders(new BaseGetByIdRequest(this.mailAccountId));
      this.providerFolders = response.data.map((e) => e.name);
    } catch (e) {
      this.cannotLoadAvailableData = true;
      this.matchError.logError(e);
    } finally {
      this.loadingAvailableData = false;
    }
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public cancel(): void {
    this.closeEventEmitter.emit();
  }

  public async onSubmit(formValue: any): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;

      const request = new MailFolderCreateRequest();
      request.name = formValue.name;
      request.mailAccountId = this.mailAccountId;
      request.providerFolderName = this.selectedProviderFolder;

      if (this.parentMailFolderId) {
        request.parentId = this.parentMailFolderId;
      }
      request.folderType = MailFolderType.Custom;
      try {
        const response = await this.mailFolderClient.create(request);
        this.creationSubmit.emit(response);
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
