import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { EventsService } from '../../services/events/events.service';
import { MatDrawer } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
})
export class EmailsComponent implements OnInit, OnDestroy, AfterViewInit {
  private userMailAccountMenuStateSubscription: Subscription;
  public hasBackdrop: boolean;

  @ViewChild(MatDrawer) matDriver!: MatDrawer;

  constructor(private eventsService: EventsService, private observer: BreakpointObserver) {}

  ngOnInit(): void {
    this.userMailAccountMenuStateSubscription = this.eventsService.leftMenuClicked.subscribe(async () => {
      if (this.matDriver) {
        await this.matDriver.toggle();
      }
    });
  }

  ngOnDestroy(): void {
    this.userMailAccountMenuStateSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.observer
      .observe(['(max-width: 800px)'])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          this.matDriver.mode = 'over';
          this.hasBackdrop = true;
        } else {
          this.matDriver.mode = 'side';
          this.hasBackdrop = false;
        }
      });
  }
}
