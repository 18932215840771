import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiRoutes } from '../routes/api.routes';
import { AuthenticationSignInResponse } from '../contracts/authentication/authentication-sign-in.response';
import { ApiRequest } from '../api.request';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { AuthenticationAddByCodeIdentityRequest } from '../contracts/authentication/authentication-add-bycode-identity.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { AuthenticationType } from '../contracts/authentication/authentication-type';
import { GetAuthenticationTypesRequest } from '../contracts/authentication/get-authentication-types.request';
import { FeRoutes } from '../routes/fe.routes';

const API_URL = environment.apiUrl;
const FE_URL = environment.feUrl;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationClient {
  constructor(private http: HttpClient, private apiRequest: ApiRequest) {}

  async logOut(): Promise<void> {
    this.http.post(`${FE_URL}${FeRoutes.user.logout}`, null).subscribe();
  }

  async logOutAll(): Promise<void> {
    this.http.post(`${FE_URL}${FeRoutes.user.logoutAll}`, null).subscribe();
  }

  async renew(): Promise<AuthenticationSignInResponse> {
    return this.apiRequest.post<any, AuthenticationSignInResponse>(null, `${API_URL}${ApiRoutes.authentication.renew}`);
  }

  async authenticationAddGoogleIdentity(request: AuthenticationAddByCodeIdentityRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<AuthenticationAddByCodeIdentityRequest, BaseCreateResponse>(
      request,
      `${API_URL}${ApiRoutes.authentication.addGoogleIdentity}`
    );
  }

  async authenticationAddAdfsIdentity(request: AuthenticationAddByCodeIdentityRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<AuthenticationAddByCodeIdentityRequest, BaseCreateResponse>(
      request,
      `${API_URL}${ApiRoutes.authentication.addAdfsIdentity}`
    );
  }

  async getAdfsRedirectUrl(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<string>> {
    return this.apiRequest.get<BaseGetByIdResponse<string>>(`${API_URL}${ApiRoutes.authentication.getAdfsRedirectUrl}?${request.format()}`);
  }

  async getAuthenticationTypes(request: GetAuthenticationTypesRequest): Promise<BaseGetCollectionResponse<AuthenticationType>> {
    return this.apiRequest.get<BaseGetCollectionResponse<AuthenticationType>>(
      `${API_URL}${ApiRoutes.authentication.getAuthenticationTypes}?${request.format()}`
    );
  }
}
