<div class="drawer-content-screen flex flex-col justify-between">
  <div class="text-2xl mb-10 font-medium">{{ "addGroupToUsers" | translate }}</div>
  <div class="h-full w-full mb-2 overflow-auto">
    <!-- <div *ngIf="!cannotLoadUsers && !isLoadingUsers" class="text-2xl mb-10 font-medium">Add {{ groups.length > 1 ? "Groups" : "Group" }} to</div> -->

    <div class="flex flex-col justify-center h-full" *ngIf="cannotLoadUsers">
      <app-something-went-wrong
        description="Cannot load the users. Something went wrong"
        (retryEmitter)="fetchUsers()"
        [showPicture]="false"></app-something-went-wrong>
    </div>

    <div *ngIf="!cannotLoadUsers && !isLoadingUsers" class="filters">
      <mat-form-field appearance="fill" class="filters-search mr-1">
        <mat-label class="filters-search-label">{{ "search" | translate }}</mat-label>
        <input matInput [(ngModel)]="search" class="filters-search-input" />
        <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search = ''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="fill" class="filters-select">
        <mat-label>{{ "filter" | translate }}</mat-label>
        <mat-select [formControl]="filters" [value]="filterList[0].value" (selectionChange)="onFilterSelectionChange()">
          <mat-option *ngFor="let filter of filterList" [value]="filter.value">{{ filter.label | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-loader class="flex flex-col justify-center h-full" *ngIf="isLoadingUsers"></app-loader>
    <div *ngIf="!cannotLoadUsers && !isLoadingUsers">
      <mat-checkbox
        color="primary"
        class="mb-2"
        [checked]="hasSelected() && isAllSelected()"
        [indeterminate]="hasSelected() && !isAllSelected()"
        (change)="$event ? masterToggle() : null">
        {{ "selectAll" | translate }}
      </mat-checkbox>
      <ul>
        <li *ngFor="let user of filteredUsers | filter : search : 'name'">
          <mat-checkbox color="primary" class="mb-1" [(ngModel)]="user._checked">
            {{ user.name }}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="!cannotLoadUsers && !isLoadingUsers" class="w-full">
    <div class="flex justify-end">
      <!-- <button (click)="onClose()" mat-flat-button>{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
