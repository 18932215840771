import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { DispatchingRule } from '../../../../../sdk/contracts/dispatching/dispatching.rule';
import { DispatchingRuleClient } from '../../../../../sdk/clients/dispatchingrule.client';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';
import { BreadcrumbItem } from '../../../../../shared/breadcrumb-item';
import { TabItem } from '../../../../../shared/tab-item';
import { PermissionNavTabHelper } from '../../../../../services/permission/permission-nav-tab-helper';
import { PermissionType } from '../../../../../sdk/contracts/permission/permission-type';
import { OrganizationClient } from '../../../../../sdk/clients/organization.client';
import { PermissionService } from '../../../../../services/permission/permission.service';
import { MailAccountContract } from '../../../../../sdk/contracts/mail-account/mail-account.contract';
import { MailAccountClient } from '../../../../../sdk/clients/mail-account.client';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DispatchingRuleDeleteDialogComponent } from './dispatching-rule-delete-dialog/dispatching-rule-delete-dialog.component';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { BaseCollectionSearchByIdRequest } from '../../../../../sdk/contracts/common/base-collection-search-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../../../../../sdk/contracts/common/base-get-search-count-by-id.request';
import { MobileObserverService } from '../../../../../services/adaptive/mobile-observer.service';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-dispatching-rule',
  templateUrl: './dispatching-rule.component.html',
  styleUrls: ['./dispatching-rule.component.scss'],
})
export class DispatchingRuleComponent implements OnInit, AfterViewInit {
  public navigationIsLoading = false;
  public cannotLoadNavigation = false;
  public breadcrumbItems: BreadcrumbItem[] = [];
  public tabItems: TabItem[] = [];
  public organizationId: number;
  public mailAccountId: number;
  public isLoading = false;
  public isError = false;
  public dispatchingRules: DispatchingRule[] = [];
  public dataSource = new MatTableDataSource<DispatchingRule>(this.dispatchingRules);
  public displayedColumns: string[] = ['name', 'priority', 'actions'];
  public mailAccount: MailAccountContract;
  public searchValue = '';
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;
  public webCode: string;

  public createDrawerIsOpen = false;
  public editDrawerIsOpen = false;
  public editingDispatchingRule: DispatchingRule | null = null;
  public useMobileView = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    public matchError: MatchError,
    protected route: ActivatedRoute,
    private dispatchingRuleClient: DispatchingRuleClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private organizationClient: OrganizationClient,
    private permissionService: PermissionService,
    private mailAccountClient: MailAccountClient,
    public dialog: MatDialog,
    private mobileObserverService: MobileObserverService
  ) {}

  async ngOnInit(): Promise<void> {
    this.pageSize = JSON.parse(localStorage.getItem('pageSizeSettings')) || 25;
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.mailAccountId = parseInt(this.route.snapshot.paramMap.get('mailAccountId'), 10);
    await this.loadNavigation();
    await this.loadDispatchingRules(true);
  }

  ngAfterViewInit(): void {
    this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));
  }

  public async loadNavigation(): Promise<void> {
    this.navigationIsLoading = true;
    try {
      this.loadTabs();
      await this.loadBreadcrumbItems();
    } catch (e) {
      this.cannotLoadNavigation = true;
      this.matchError.logError(e);
    } finally {
      this.navigationIsLoading = false;
    }
  }

  private loadTabs(): void {
    this.tabItems = this.permissionNavTabHelper.getTabsForMailAccountPage(this.organizationId, this.mailAccountId, this.webCode);
  }

  private async loadBreadcrumbItems(): Promise<void> {
    this.mailAccount = (await this.mailAccountClient.getById(new BaseGetByIdRequest(this.mailAccountId))).mailAccount;
    if (await this.permissionService.hasPermissionOver(null, null, PermissionType.CanEverything)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: this.mailAccount.name, route: null },
        { index: 3, label: 'dispatchingRulesBreadCrumbs', route: null },
      ];
    } else if (await this.permissionService.hasPermissionOver(this.organizationId, null, PermissionType.CanManageOrganization)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: this.mailAccount.name, route: null },
        { index: 3, label: 'dispatchingRulesBreadCrumbs', route: null },
      ];
    } else {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: this.mailAccount.name, route: null },
        { index: 3, label: 'dispatchingRulesBreadCrumbs', route: null },
      ];
    }
  }

  async loadDispatchingRules(fetchSearchCount: boolean): Promise<any> {
    this.isLoading = true;
    this.isError = false;
    if (fetchSearchCount) {
      this.pageIndex = 0;
    }
    const nullableSearchValue = this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchByIdRequest(this.mailAccountId, this.pageIndex + 1, this.pageSize, nullableSearchValue);
    try {
      const response = await this.dispatchingRuleClient.searchForAccount(request);
      this.dispatchingRules = response.data;
      this.dataSource.data = this.dispatchingRules;
      if (fetchSearchCount) {
        const countRequest = new BaseGetSearchCountByIdRequest(this.mailAccountId, nullableSearchValue);
        const counterResponse = await this.dispatchingRuleClient.getSearchForAccountCount(countRequest);
        this.totalSize = counterResponse.result;
      }
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  ruleIsDefault(dispatchingRule: DispatchingRule): boolean {
    return (
      !dispatchingRule.dispatchingRule.matchExpression.conditions || dispatchingRule.dispatchingRule.matchExpression.conditions.length === 0
    );
  }

  async deleteDispatchingRule(dispatchingRuleId: number, dispatchingRuleName: string): Promise<void> {
    const dialogRef = this.dialog.open(DispatchingRuleDeleteDialogComponent, {
      width: '450px',
      data: { dispatchingRuleId, dispatchingRuleName },
    });

    dialogRef.afterClosed().subscribe(async (x) => {
      if (x.isDeleted) {
        this.totalSize -= 1;
        await this.loadDispatchingRules(false);
      }
    });
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  public async handlePage(e: any): Promise<void> {
    localStorage.setItem('pageSizeSettings', JSON.stringify(e.pageSize));
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadDispatchingRules(false);
  }

  public openCreateModalForm(): void {
    this.createDrawerIsOpen = true;
  }

  public onCloseCreateModalForm(): void {
    this.createDrawerIsOpen = false;
  }

  public async onCreateDispatchingRule(): Promise<void> {
    this.createDrawerIsOpen = false;
    this.totalSize += 1;
    await this.loadDispatchingRules(false);
  }

  public openEditModalForm(editingDispatchingRule: DispatchingRule): void {
    this.editingDispatchingRule = editingDispatchingRule;
    this.editDrawerIsOpen = true;
  }

  public onCloseEditModalForm(): void {
    this.editDrawerIsOpen = false;
    this.editingDispatchingRule = null;
  }

  public async onEditDispatchingRule(): Promise<void> {
    this.editDrawerIsOpen = false;
    this.editingDispatchingRule = null;
    await this.loadDispatchingRules(false);
  }
}
