import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { MatchError } from 'src/app/shared/error-matcher';
import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { GroupClient } from '../../../../sdk/clients/group-settings.client';
import { OrganizationClient } from '../../../../sdk/clients/organization.client';
import { BaseCollectionSearchByIdRequest } from '../../../../sdk/contracts/common/base-collection-search-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../../../../sdk/contracts/common/base-get-search-count-by-id.request';
import { Group } from '../../../../sdk/contracts/group/group.contract';
import { MobileObserverService } from '../../../../services/adaptive/mobile-observer.service';
import { PermissionNavTabHelper } from '../../../../services/permission/permission-nav-tab-helper';
import { PermissionService } from '../../../../services/permission/permission.service';
import { BreadcrumbItem } from '../../../../shared/breadcrumb-item';
import { TabItem } from '../../../../shared/tab-item';

@Component({
  selector: 'app-organization-groups',
  templateUrl: './organization-groups.component.html',
  styleUrls: ['./organization-groups.component.scss'],
})
export class OrganizationGroupsComponent implements OnInit {
  public breadcrumbItems: BreadcrumbItem[] = [];
  public navigationIsLoading = false;
  public tabItems: TabItem[] = [];
  public cannotLoadNavigation = false;

  public organizationName: string;
  public organizationId: number;
  public webCode: string;

  public isLoading = true;
  public hasError = false;
  public errorText: string;

  public useMobileView = false;

  public searchValue = '';
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;

  public organizationGroups: Group[] = [];
  public displayedColumns: string[] = ['select', 'name', 'createdAt', 'actions'];

  public isDrawerOpen: boolean = false;
  public onDrawerClose: Function = () => {};

  public createDrawerIsOpen: boolean = false;
  public updateDrawerIsOpen: boolean = false;
  public showAddGroupsToUsersForm: boolean = false;

  public editingGroup: Group;

  public dataSource = new MatTableDataSource<Group>();
  public selection = new SelectionModel<Group>(true, []);
  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    private organizationClient: OrganizationClient,
    private permissionService: PermissionService,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private route: ActivatedRoute,
    private mobileObserverService: MobileObserverService,
    private groupClient: GroupClient,
    private matchError: MatchError,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));

    this.pageSize = JSON.parse(localStorage.getItem('pageSizeSettings')) || 25;
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.breadcrumbItems = [
      { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
      { index: 1, label: 'groupsBreadCrumbs', route: null },
    ];
    await this.loadNavigation();
    await this.fetchGroups();
  }

  ngAfterViewInit(): void {
  }

  public async loadNavigation(): Promise<void> {
    try {
      this.loadTabItems();
    } catch (e) {
      this.cannotLoadNavigation = true;
      this.matchError.logError(e);
    } finally {
    }
  }

  private loadTabItems(): void {
    this.tabItems = this.permissionNavTabHelper.getOrganizationPageTabs(this.organizationId, this.webCode);
  }

  public async fetchGroups(fetchSearch = false): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    if (fetchSearch) {
      this.pageIndex = 0;
    }
    const req = new BaseCollectionSearchByIdRequest(this.organizationId, this.pageIndex + 1, this.pageSize, this.searchValue);
    try {
      const searchCountReq = new BaseGetSearchCountByIdRequest(this.organizationId, this.searchValue);
      const totalSize = await this.groupClient.getSearchCounter(searchCountReq);
      this.totalSize = totalSize.result;

      const res = await this.groupClient.getAllByOrganization(req);
      this.organizationGroups = res.data;
      this.selection.clear();
      this.dataSource.data = this.organizationGroups;
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public handlePage(e) {
    localStorage.setItem('pageSizeSettings', JSON.stringify(e.pageSize));
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.fetchGroups();
  }

  public onSearchValueChanged(newSearchValue): void {
    this.searchValue = newSearchValue;
  }

  private closeDrawer(): void {
    this.isDrawerOpen = false;
    this.onDrawerClose = () => {};
  }

  private openDrawer(onClose: Function = () => {}): void {
    this.isDrawerOpen = true;
    this.onDrawerClose = onClose;
  }

  //#region Table multi select
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }
  //#endregion

  //#region create events
  public onCreateGroupBtnClicked(): void {
    this.openDrawer(this.onCloseCreateForm);
    this.createDrawerIsOpen = true;
  }

  public onCloseCreateForm(): void {
    this.closeDrawer();
    this.createDrawerIsOpen = false;
  }

  public onGroupCreated(): void {
    this.fetchGroups();
    this.closeDrawer();
    this.createDrawerIsOpen = false;
  }
  //#endregion create events

  //#region update events
  public onUpdateGroupBtnClicked(group: Group): void {
    this.editingGroup = group;
    this.openDrawer(this.onCloseUpdateForm);
    this.updateDrawerIsOpen = true;
  }

  public onCloseUpdateForm(): void {
    this.editingGroup = null;
    this.closeDrawer();
    this.updateDrawerIsOpen = false;
  }

  public onGroupUpdated(): void {
    this.fetchGroups();
    this.onCloseUpdateForm();
  }
  //#endregion update events

  //#region delete events
  public onDeleteGroupBtnClicked(group: Group): void {
    this.openConfirmDeleteDialog(group);
  }
  //#endregion delete events

  //#region add groups to users
  public onAddGroupsToUsersBtnClicked(): void {
    this.openDrawer(this.onHideAddGroupsToUsersForm);
    this.showAddGroupsToUsersForm = true;
  }

  public onAddGroupToUsersBtnClicked(group: Group): void {
    this.selection.clear();
    this.selection.select(group);
    this.openDrawer(this.onHideAddGroupsToUsersForm);
    this.showAddGroupsToUsersForm = true;
  }

  public onHideAddGroupsToUsersForm(): void {
    this.closeDrawer();
    this.showAddGroupsToUsersForm = false;
    this.selection.clear();
  }

  public onAddGroupsToUsers(): void {
    this.onHideAddGroupsToUsersForm();
  }

  //#endregion

  private openConfirmDeleteDialog(group: Group) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { name: 'groupDelete', isDontNeedConfirmWord: true, itemName: group.group, title: 'groupDeleteTitle' },
    });

    dialogRef.beforeClosed().subscribe(async (res) => {
      if (res) {
        try {
          await this.groupClient.delete({ ids: [group.groupId] });
          this.fetchGroups();
        } catch (e) {
          this.matchError.errorHandler(e);
          this.matchError.logError(e);
        }
      }
    });
  }
}
