<div class="flex flex-col mt-8">
  <div class="font-medium mb-2 text-lg">{{ "webMailFolders" | translate }} *</div>

  <form [formGroup]="form" autocomplete="off" novalidate>
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>{{ "selectMailFolders" | translate }}</mat-label>
      <mat-select [(ngModel)]="selected" formControlName="mailFolders" #select multiple>
        <mat-option
          *ngFor="let folder of checkBoxFolders"
          color="primary"
          [disableRipple]="true"
          (click)="onChange(folder)"
          [value]="folder.value.name">
          {{ folder.value.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.controls.mailFolders.hasError('required')">{{ "selectAtLeastOneFolder" | translate }}</mat-error>
    </mat-form-field>
  </form>
</div>
