import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { EmailTagSaveRequest } from '../contracts/email-tags/email-tag-save.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { TagContract } from '../contracts/tag/tag.contract';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';

const API_URL = environment.apiUrl;

@Injectable()
export class EmailTagClient {
  constructor(private apiRequest: ApiRequest) {}

  async saveTags(request: EmailTagSaveRequest): Promise<void> {
    await this.apiRequest.post(request, `${API_URL}${ApiRoutes.emailTag.saveTags}`);
  }

  async getEmailTags(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<TagContract>> {
    return await this.apiRequest.get(`${API_URL}${ApiRoutes.emailTag.getEmailTags}?${request.format()}`);
  }
}
