<div *ngIf="mailAccounts.length === 0" class="flex h-full">
  <p style="margin: auto" class="text-center">{{ "noMailAccountIsAvailableYet" | translate }}</p>
</div>
<mat-accordion>
  <mat-expansion-panel
    hideToggle
    matRipple
    *ngFor="let mailAccount of mailAccounts"
    (afterCollapse)="closed(this, mailAccount)"
    (afterExpand)="opened(this, mailAccount)"
    [expanded]="isDefault(mailAccount)">
    <mat-expansion-panel-header matRipple class="account-name">
      <mat-panel-title
        matTooltip="{{ getTitle(mailAccount) }}"
        style="width: 100%; display: block; display: flex; justify-content: space-between; margin: 0">
        <span style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; margin-right: 15px">
          {{ getTitle(mailAccount) }}
        </span>
        <span class="default-theme-color text-end"> {{ getBadge(mailAccount) }} </span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-mail-account-folders
      #folders
      *ngIf="isTreeLoaded"
      (getBadgesForMailAccounts)="getBadgesForMailAccounts($event)"
      [defaultMailAccountId]="defaultMailAccountId"
      [mailAccountId]="mailAccount.mailAccountId"
      [reloadEvent]="reloadFoldersSubject.asObservable()"></app-mail-account-folders>
    <div style="display: flex; justify-content: space-between; margin-top: 16px;"> {{"occupiedSpace" | translate}} <span class="space-text"> {{mailAccount.spaceUsed}} GB</span></div>
    
  </mat-expansion-panel>
</mat-accordion>
