import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function outMinLimit(minLimit: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value < minLimit ? { lowerLimit: { value: control.value } } : null;
  };
}

export function outMaxLimit(maxLimit: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value > maxLimit ? { higherLimit: { value: control.value } } : null;
  };
}
