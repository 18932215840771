<div class="w-full h-full" *ngIf="isLoading">
  <app-loader height="100%"></app-loader>
</div>

<div class="w-full h-full" *ngIf="cannotLoadNotes">
  <app-something-went-wrong
    [showPicture]="false"
    description="Cannot load notes"
    (retryEmitter)="loadAvailableData()"></app-something-went-wrong>
</div>

<div class="w-full h-full flex flex-col notes" *ngIf="!isLoading && !cannotLoadNotes">
  <div *ngIf="title" class="text-2xl mb-10 font-medium">{{ title | translate }}</div>
  <div class="flex-none pl-1 pr-2 note mb-3">
    <div class="note-header justify-between">
      <div>
        <div class="p-1.5 flex flex-col text-gray-600">
          <div class="text-sm font-medium">{{ user.firstName + " " + user.lastName }}</div>
        </div>
      </div>
    </div>
    <mat-form-field class="notes-wrapper" appearance="fill">
      <mat-label>{{ "newNote" | translate }}</mat-label>
      <textarea matInput #box></textarea>
    </mat-form-field>
    <div class="flex justify-end p-1.5 note-border">
      <button mat-flat-button color="primary" (click)="createNewNote(box.value); box.value = ''" matTooltip="{{ 'save' | translate }}">
        {{ "save" | translate }}
      </button>
    </div>
  </div>

  <div class="notes">
    <div
      *ngFor="let note of notes"
      class="note"
      [class.mat-elevation-z4]="noteIsBeingEdited(note.emailNoteId)"
      [class.animate-pulse]="noteIsUpdating(note.emailNoteId) || noteIsDeleting(note.emailNoteId)">
      <div class="note-header" [class.justify-between]="canManageNote(note.userId)">
        <div class="p-1.5 flex flex-col text-gray-600">
          <div class="text-sm font-medium">{{ note.userFirstName }} {{ note.userLastName }}</div>
          <div class="text-xs">{{ note.noteDateTime | date: "dd/MM/yyyy HH:mm:ss" }}</div>
        </div>
        <!-- <div *ngIf="canManageNote(note.userId)">
          <button (click)="editNote(note.emailNoteId)" *ngIf="!noteIsBeingEdited(note.emailNoteId)">
            <mat-icon class="note-action-icon">edit</mat-icon>
          </button>
          <button (click)="updateNote(note)" *ngIf="noteIsBeingEdited(note.emailNoteId)" matTooltip="Save" [disabled]="noteIsUpdating(note.emailNoteId) || noteIsDeleting(note.emailNoteId)">
            <mat-icon class="note-action-icon">save_alt</mat-icon>
          </button>         
        </div> -->
      </div>
      <div class="note-border p-1.5">{{ note.note }}</div>
      <!-- <mat-form-field appearance="fill">
        <textarea matInput [disabled]="!noteIsBeingEdited(note.emailNoteId)" [(ngModel)]="note.note"></textarea>
      </mat-form-field> -->
      <!-- <div class="flex justify-end" >
        <button mat-flat-button color="primary" (click)="deleteNote(note)"  [disabled]="noteIsUpdating(note.emailNoteId) || noteIsDeleting(note.emailNoteId)">
          Delete
        </button>

      </div> -->
    </div>
  </div>
</div>
