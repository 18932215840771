import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TagClient } from '../../../../../../sdk/clients/tag.client';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InsertTagRequest } from '../../../../../../sdk/contracts/tag/insert-tag.request';
import { ErrorCode } from '../../../../../../sdk/api-errors/error-code';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-mail-account-tag-create-dialog',
  templateUrl: './mail-account-tag-create-dialog.component.html',
  styleUrls: ['./mail-account-tag-create-dialog.component.scss'],
})
export class MailAccountTagCreateDialogComponent implements OnInit {
  @Input() mailAccountId: number;
  @Output() submitEventEmitter = new EventEmitter();
  @Output() closeEventEmitter = new EventEmitter();

  public isLoading = false;
  public errorText: string;

  public handlingErrorCodes = new Map<number, string>([[ErrorCode.TagAlreadyExists, 'This tag already exists']]);

  public form = new UntypedFormGroup({
    tag: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
  });

  constructor(private tagClient: TagClient, public dialog: MatDialog, public matchError: MatchError) {}

  ngOnInit(): void {}

  public async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      const request = new InsertTagRequest(this.mailAccountId, this.form.get('tag').value);
      try {
        await this.tagClient.insert(request);
        this.submitEventEmitter.emit();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  public cancel(): void {
    this.closeEventEmitter.emit();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };
}
