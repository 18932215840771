<div class="h-screen w-screen flex justify-center flex-wrap content-center p-5">
  <div class="h-2/3 text-center">
    <div class="font-medium text-3xl mb-5">{{ "noSuchPageExists" | translate }}</div>
    <div class="text-gray-700 text-lg mb-5 text-center" *ngIf="previousRoute">
      {{ "youCanGoBack" | translate }} <a class="error__link" [routerLink]="previousRoute">{{ "previous" | translate }}</a>
      {{ "page" | translate }}
    </div>
    <img class="error-img" src="../../../../assets/404.svg" alt="not-found" />
  </div>
</div>
