import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { ReportCreateRequest, ReportResponse } from '../contracts/report/report.create.request';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ReportClient {
  constructor(private apiRequest: ApiRequest) {}

  async create(request: ReportCreateRequest): Promise<ReportResponse> {
    return this.apiRequest.post<ReportCreateRequest, ReportResponse>(request, `${API_URL}${ApiRoutes.report.exportExcel}`);
  }
}
