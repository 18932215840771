<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>
<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>

<app-drawer [isOpen]="createDrawerIsOpen" (closeEventEmitter)="onCloseCreateModalForm()">
  <app-dispatching-rule-create-or-update-dialog
    *ngIf="createDrawerIsOpen"
    (closeEventEmitter)="onCloseCreateModalForm()"
    (submitEventEmitter)="onCreateDispatchingRule()"
    [mailAccountId]="mailAccountId">
  </app-dispatching-rule-create-or-update-dialog>
</app-drawer>

<app-drawer [isOpen]="editDrawerIsOpen" (closeEventEmitter)="onCloseEditModalForm()">
  <app-dispatching-rule-create-or-update-dialog
    *ngIf="editDrawerIsOpen"
    (closeEventEmitter)="onCloseEditModalForm()"
    (submitEventEmitter)="onEditDispatchingRule()"
    [updatingDispatchingRule]="editingDispatchingRule"
    [mailAccountId]="mailAccountId">
  </app-dispatching-rule-create-or-update-dialog>
</app-drawer>

<div class="page-content">
  <div class="search-panel flex-wrap">
    <div class="button-group">
      <button
        mat-raised-button
        color="primary"
        class="page-block-button"
        aria-label="Create dispatching rule"
        (click)="openCreateModalForm()">
        <mat-icon>add</mat-icon>{{ "newDispatchingRule" | translate }}
      </button>
    </div>

    <div class="mobile-view search-bar">
      <app-search-bar
        [fullWidth]="true"
        (searchEventEmitter)="loadDispatchingRules(true)"
        (valueChangedEventEmitter)="onSearchValueChanged($event)"
        [search]="searchValue"></app-search-bar>
    </div>
    <div class="not-mobile-view search-bar">
      <app-search-bar
        [fullWidth]="false"
        (searchEventEmitter)="loadDispatchingRules(true)"
        (valueChangedEventEmitter)="onSearchValueChanged($event)"
        [search]="searchValue"></app-search-bar>
    </div>
  </div>
  <div *ngIf="!navigationIsLoading && !isError">
    <div *ngIf="useMobileView">
      <div class="page-block">
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)">
        </mat-paginator>
      </div>
      <div *ngIf="!isLoading && !isError">
        <div class="page-block" *ngFor="let dispatchingRule of dispatchingRules">
          <div class="card"></div>
          <mat-card class="mobile-row">
            <mat-card-content (click)="openEditModalForm(dispatchingRule)" class="cursor-default flex flex-1">
              <div class="card-table-row">
                <div class="card-table-row-header">{{ "name" | translate }}:</div>
                <div class="card-table-row-value">{{ dispatchingRule.dispatchingRule.name }}</div>
              </div>
              <div class="card-table-row">
                <div class="card-table-row-header">{{ "priority" | translate }}:</div>
                <div class="card-table-row-value">{{ dispatchingRule.dispatchingRule.priority }}</div>
              </div>
            </mat-card-content>
            <div style="width: 40px; margin-left: 10px">
              <button
                mat-icon-button
                matTooltip="{{ 'editDispatchingRuleLabel' | translate }}"
                color="primary"
                aria-label="{{ 'editDispatchingRuleLabel' | translate }}"
                (click)="openEditModalForm(dispatchingRule)">
                <mat-icon>edit_outline</mat-icon>
              </button>
              <span class="mr-2"></span>
              <button
                mat-icon-button
                matTooltip="{{ 'delete' | translate }}"
                color="primary"
                aria-label="Delete"
                (click)="deleteDispatchingRule(dispatchingRule.dispatchingRuleId, dispatchingRule.dispatchingRule.name)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </div>
          </mat-card>
        </div>
      </div>
      <app-no-data *ngIf="dispatchingRules.length === 0 && !isLoading && !navigationIsLoading && !isError"></app-no-data>
    </div>

    <div class="page-block" *ngIf="!useMobileView">
      <div class="table-container">
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)">
        </mat-paginator>
        <table *ngIf="!isLoading && !isError" mat-table [dataSource]="dataSource" class="w-full">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
            <td mat-cell *matCellDef="let dispatchingRules">{{ dispatchingRules.dispatchingRule.name }}</td>
          </ng-container>
          <ng-container matColumnDef="priority">
            <th mat-header-cell *matHeaderCellDef>{{ "priority" | translate }}</th>
            <td mat-cell *matCellDef="let dispatchingRules">{{ dispatchingRules.dispatchingRule.priority }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
            <td mat-cell *matCellDef="let dispatchingRules">
              <button
                mat-icon-button
                aria-label="{{ 'editDispatchingRuleLabel' | translate }}"
                matTooltip="{{ 'editDispatchingRuleLabel' | translate }}"
                (click)="openEditModalForm(dispatchingRules)">
                <mat-icon>edit_outline</mat-icon>
              </button>
              <span class="mr-2"></span>
              <button
                mat-icon-button
                aria-label="Delete"
                matTooltip="{{ 'delete' | translate }}"
                (click)="deleteDispatchingRule(dispatchingRules.dispatchingRuleId, dispatchingRules.dispatchingRule.name)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
        </table>
        <app-no-data *ngIf="dispatchingRules.length === 0 && !isLoading && !navigationIsLoading && !isError"></app-no-data>
      </div>
    </div>
  </div>
  <app-loader *ngIf="isLoading || navigationIsLoading"></app-loader>
  <div *ngIf="isError" class="half-of-screen">
    <app-something-went-wrong
      description="{{ 'cannotLoadDispatchingRules' | translate }}"
      [showPicture]="false"
      (retryEmitter)="loadDispatchingRules(true) && loadNavigation()"></app-something-went-wrong>
  </div>
</div>
