import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EmailClient } from '../../sdk/clients/email-client';
import { EmailTagClient } from '../../sdk/clients/email-tag.client';
import { MailFolderClient } from '../../sdk/clients/mail-folder.client';
import { BaseGetByFileIdRequest } from '../../sdk/contracts/common/base-get-by-file-id.request';
import { BaseGetByIdRequest } from '../../sdk/contracts/common/base-get-by-id.request';
import { EmailIcons } from '../../sdk/contracts/Email/email-icons';
import { EmailState } from '../../sdk/contracts/Email/email-state';
import { EmailType } from '../../sdk/contracts/Email/email-type';
import { EmailContract } from '../../sdk/contracts/Email/email.contract';
import { EmailMultipleForwardRequest } from '../../sdk/contracts/Email/email.forward-multiple.request';
import { EmailListItemContract } from '../../sdk/contracts/Email/email.list-item.contract';
import { EmailReplyRequest } from '../../sdk/contracts/Email/email.reply.request';
import { EmailRetrySendRequest } from '../../sdk/contracts/Email/email.retry-send.request';
import { EmailSearchByWidgetRequest } from '../../sdk/contracts/Email/email.search-by-widget.request';
import { EmailSearchRequest } from '../../sdk/contracts/Email/email.search.request';
import { EmailValidationState } from '../../sdk/contracts/Email/email.validation-state';
import { MailFolderType } from '../../sdk/contracts/mail-folder/mail-folder.type';
import { MobileObserverService } from '../../services/adaptive/mobile-observer.service';
import { SearchContextStorageService } from '../../services/search/search-context-storage.service';
import { SearchParams } from '../../services/search/search-params';
import { SnackbarHelper } from '../../shared/helpers/snackbar.helper';
import { RoleType } from '../../sdk/contracts/role/role-type';
import { EmailAddGroupsDialogComponent } from '../email/email-add-groups-dialog/email-add-groups-dialog.component';
import { EmailAddTagsDialogComponent } from '../email/email-add-tags-dialog/email-add-tags-dialog.component';
import { ErrorDialogComponent } from '../error/error-dialog/error-dialog.component';
import { ConfirmFinalDeletionDialogComponent } from '../final-deletion-confirm-dialog/final-deletion-confirm-dialog.component';
import { MailFolderSelectDialogComponent } from '../mail-account-folders/mail-folder-select-dialog/mail-folder-select-dialog.component';
import { ReassignDialogComponent } from '../reassign-dialog/reassign-dialog.component';

import { BaseGetByEmailFileIdRequest } from 'src/app/sdk/contracts/common/base-get-by-email-file-id.request';
import { EmailForwardRequest } from 'src/app/sdk/contracts/Email/email.forward.request';
import { MatchError } from 'src/app/shared/error-matcher';
import { FormatEmailIconsByFolderType } from 'src/app/shared/pipes/format-email-icons-by-folder-type.pipe';
import { FormatSearchOrders } from 'src/app/shared/pipes/format-search-orders.pipe';
import { EmailExportClient } from '../../sdk/clients/email-export-client';
import { FileClient } from '../../sdk/clients/file.client';
import { TempFileClient } from '../../sdk/clients/temp-file.client';
import { EmailSearchOrder } from '../../sdk/contracts/Email/email.search-order';
import { EventsService } from '../../services/events/events.service';
import { SearchContext } from '../../services/search/search-context';
import { TranslateService } from '@ngx-translate/core';
import { EmailMoveToDraftRequest } from 'src/app/sdk/contracts/Email/email.move-to-draft.request';

import { delay } from 'rxjs/operators';
import { PermissionType } from 'src/app/sdk/contracts/permission/permission-type';
import { PermissionService } from 'src/app/services/permission/permission.service';
@Component({
  selector: 'app-mails-content',
  templateUrl: './emails-content.component.html',
  styleUrls: ['./emails-content.component.scss'],
})
export class EmailsContentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() validated: EmailValidationState[] = [];
  @Input() reassigned: boolean;
  @Input() isAllFoldersSelected: boolean;

  public emailIcons = EmailIcons;
  public emailValidationState = EmailValidationState;
  public webCode: string;
  public showLoader = true;
  public isError = false;
  public emails: EmailListItemContract[] = [];
  public dataSource = new MatTableDataSource<EmailListItemContract>(this.emails);
  public displayedColumns: string[] = [
    'select',
    'attachment',
    'from',
    'to',
    'subject',
    'date',
    'source',
    'state',
    'meta',
    'notes',
    'folder',
  ];
  public pageNumber = 1;
  public pageSize = 25;
  private subscription: Subscription;
  public selection = new SelectionModel<number>(true, []);
  public searchWidgetId: number;
  public canNavigateBack = false;
  public canNavigateForward = false;
  public useMobileView = false;
  private useMobileViewSubscription: Subscription;
  public createOrUpdateDrawerIsOpen = false;
  public updatedEmail: EmailContract | EmailListItemContract | null;
  public menuTitle: string | null;
  public emailDrawerIsOpen = false;
  public openedEmail: EmailListItemContract | null;
  public emailStates = EmailState;
  private selectedMailFolderIds: number[] = [];
  public selectedMailAccountId: number | null;
  private searchContextSubscription: Subscription;
  public searchParams: SearchParams;
  private selectedMailFolderId: number | null;
  public menuTopLeftPosition = { x: '0', y: '0' };
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  public openedEmailInfo;
  public mailFolderType = MailFolderType;
  public notificationMailId: number;
  public notesMailId: number;
  public auditMailId: number;
  public metaMailId: number;
  public notificationDrawerIsOpen: boolean;
  public notesDrawerIsOpen: boolean;
  public auditDrawerIsOpen: boolean;
  public metaDrawerIsOpen: boolean;
  public searchContext: SearchContext;
  public searchOrder: number;
  public isShowFolderColumn: boolean;
  public isExporting = false;
  public isDownloadingEML = false;
  public isDownloadingSingleEML = false;
  public validatingEmailIds: number[] = [];
  public downloadingEMLEmailId: number;
  public downloadingSingleEMLEmailId: number;
  public folderCount: number;
  public hasAttachments = false;
  public searchOrders;
  public mouseCoordinatesX: number;
  public mouseCoordinatesY: number;
  public currentFolderType: number;
  public mailIconsList: string[];
  public isDrawerClosing = false;
  public replyingEmailId = null;
  public replyingAllEmailId = null;
  public forwardingEmailId = null;
  public organizationRole;
  public mailAccountRole;
  public roleTypes = RoleType;
  public canFinalDelete = false;

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private emailClient: EmailClient,
    private snackbarHelper: SnackbarHelper,
    private mailFolderClient: MailFolderClient,
    public dialog: MatDialog,
    private translate: TranslateService,
    private mobileObserverService: MobileObserverService,
    private emailExportClient: EmailExportClient,
    private fileClient: FileClient,
    private formatSearchOrders: FormatSearchOrders,
    private formatEmailIconsByFolderType: FormatEmailIconsByFolderType,
    private tempFileClient: TempFileClient,
    private eventsService: EventsService,
    private matchError: MatchError,
    private searchContextStorageService: SearchContextStorageService,
    private emailTagClient: EmailTagClient,
    private permissionService: PermissionService
  ) {}

  async ngOnInit(): Promise<void> {
    this.useMobileViewSubscription = this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));
    this.webCode = this.activateRoute.parent.snapshot.paramMap.get('webCode');
    // delay 1ms because when change searchContext and component will be destroyed will not send new search request
    this.searchContextSubscription = this.searchContextStorageService.searchContext$
      .pipe(filter((searchContext) => !!searchContext.selectedMailFolderIds))
      .pipe(delay(1))
      .subscribe(async (searchContext) => {
        this.searchContext = searchContext;
        this.pageNumber = searchContext.page;
        this.selectedMailFolderIds = searchContext.selectedMailFolderIds;
        this.searchParams = searchContext.searchParams;
        this.searchParams.validated = this.validated;
        this.searchParams.reassigned = this.searchParams.reassignedId && this.isAllFoldersSelected ? true : this.reassigned;
        this.searchOrder = searchContext.searchParams.searchOrder;

        // clear data
        this.selection.clear();
        this.selectedMailAccountId = null;
        this.selectedMailFolderId = null;

        if (this.selectedMailFolderIds.length) {
          this.selectedMailAccountId = this.searchContextStorageService.userFolders.find(
            (f) => f.mailFolderId === this.selectedMailFolderIds[0]
          ).mailAccountId;
          this.selectedMailFolderId = this.selectedMailFolderIds[0];
        }

        this.canFinalDelete = await this.permissionService.hasPermissionOver(
          null,
          this.selectedMailAccountId,
          PermissionType.CanFinalDelete
        );

        const folderType = this.searchContextStorageService.userFolders.find(
          (e) => e.mailFolderId === this.selectedMailFolderId
        )?.folderType;

        if (folderType === this.mailFolderType.Spam) {
          this.searchParams.isSpam = true;
        }
        if (folderType === this.mailFolderType.Archive) {
          this.searchParams.isArchive = true;
        }
        if (folderType === this.mailFolderType.Trash) {
          this.searchParams.deleted = true;
        }

        this.eventsService.UserMailAccountSelected.subscribe((x) => {
          this.mailAccountRole = x.MailAccountRole;
        });

        this.isShowFolderColumn = this.selectedMailFolderIds.length > 1;
        this.pageSize = JSON.parse(localStorage.getItem('pageSize')) || 25;
        await this.loadData();
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription?.unsubscribe();
      this.subscription = null;
    }
    this.useMobileViewSubscription?.unsubscribe();
    this.searchContextSubscription?.unsubscribe();
  }

  public showIconById(mailFolderId: number, iconType: EmailIcons) {
    let emailFolderType = this.searchContextStorageService?.userFolders?.find((e) => e.mailFolderId === mailFolderId).folderType;
    return this.formatEmailIconsByFolderType.transform(emailFolderType, iconType);
  }

  public showIconByIdSelected(iconType: EmailIcons) {
    let selectedMailFolderIds = this.dataSource?.data
      ?.filter((e) => this.selection.selected.includes(e.emailId))
      .map((e) => e.mailFolderId);
    return selectedMailFolderIds?.every((e) => this.showIconById(e, iconType));
  }

  public isSelectedRetrySendingNeededState() {
    let selectedMailFolderState = this.dataSource?.data?.filter((e) => this.selection.selected.includes(e.emailId)).map((e) => e.state);
    return selectedMailFolderState.every((e) => e === 5);
  }

  public folderName(mailFolderId: number) {
    let emailFolder = this.searchContextStorageService.userFolders.find((e) => e.mailFolderId === mailFolderId);
    return emailFolder.creationType === 1 ? 'folderType' + emailFolder.folderType : emailFolder.mailFolderName;
  }

  onRightClick(event: PointerEvent, item) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item: item };

    // we open the menu
    this.matMenuTrigger.openMenu();
  }

  sourceRaw(email: EmailListItemContract) {
    if (this.currentFolderType === MailFolderType.Archive || this.currentFolderType === MailFolderType.Trash) {
      return 'folderType' + email.sourceFolderType;
    } else {
      return null;
    }
  }
  public isRowSelected(id: number): boolean {
    return this.selection.selected.includes(id);
  }
  public isRowWithError(email: EmailListItemContract): boolean {
    const emailState = email.state;
    const emailType = email.type;
    return (
      emailState === EmailState.DeliveryError ||
      emailState === EmailState.VirusDetected ||
      emailState === EmailState.DispatchError ||
      emailState === EmailState.NotDispatched ||
      emailState === EmailState.NotAccepted ||
      emailState === EmailState.SendError ||
      emailType === EmailType.DeliveryError
    );
  }
  public isSentStates(emailState: number) {
    return (
      emailState === EmailState.Sent ||
      emailState === EmailState.Delivered ||
      emailState === EmailState.DeliveryError ||
      emailState === EmailState.Sending ||
      emailState === EmailState.Downloaded ||
      emailState === EmailState.NotAccepted ||
      emailState === EmailState.Accepted
    );
  }

  public getInfoAboutOpenedEmail(openedEmail: any) {
    this.openedEmailInfo = openedEmail;
  }

  public setSeenOpened(obj): void {
    this.dataSource.data = this.dataSource.data.map((e) => (e.emailId === obj.emailId ? { ...e, seen: obj.b } : e));
    this.eventsService.unreadCountFolderValue(obj.folderId);
  }

  public setValidationStateForEmail(emailId: number): void {
    this.dataSource.data = this.dataSource.data.map((e) =>
      e.emailId === emailId ? { ...e, validationState: EmailValidationState.Validated } : e
    );
  }

  async setSeen(b: boolean, email: EmailListItemContract): Promise<any> {
    try {
      await this.emailClient.setSeen({ emailIds: [email.emailId], seen: b });
      this.dataSource.data = this.dataSource.data.map((e) => (e.emailId === email.emailId ? { ...e, seen: b } : e));
      this.eventsService.unreadCountFolderValue(email.mailFolderId);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async setSelectedSeen(b: boolean): Promise<any> {
    const ids = this.selection.selected;
    try {
      await this.emailClient.setSeen({ emailIds: ids, seen: b });
      this.dataSource.data = this.dataSource.data.map((e) => (ids.includes(e.emailId) ? { ...e, seen: b } : e));
      this.eventsService.unreadCountEvent.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async restoreMoveFolderChoose(ids: number[], operationType: string): Promise<any> {
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        data: {
          mailAccountId: this.selectedMailAccountId,
          ids: ids,
          isRestore: true,
          folderType: this.currentFolderType,
          title: 'Select the folder to restore',
        },
      });
      dialogRef.afterClosed().subscribe((e) => {
        if (e?.confirmed) {
          if (operationType === 'restoreOneDeleted') {
            this.restore(ids, e.folderId);
          }
          if (operationType === 'restoreOneSpam') {
            this.setNotSpam(ids, e.folderId);
          }
          if (operationType === 'restoreSelectedSpam') {
            this.setSelectedNotSpam(e.folderId);
          }
          if (operationType === 'restoreSelectedDeleted') {
            this.restoreAllSelected(e.folderId);
          }
        }
      });
    }
  }

  async restore(id: number[], folderId: number): Promise<any> {
    try {
      await this.emailClient.restore({ emailIds: id, mailFolderId: folderId });
      this.dataSource.data = this.dataSource.data.filter((e) => e.emailId !== id[0]);
      this.selection.deselect(id[0]);
      this.eventsService.unreadCountEvent.emit();
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async restoreAllSelected(folderId: number): Promise<any> {
    const ids = this.selection.selected;
    try {
      await this.emailClient.restore({ emailIds: ids, mailFolderId: folderId });
      this.dataSource.data = this.dataSource.data.filter((e) => !ids.includes(e.emailId));
      this.selection.clear();
      this.eventsService.unreadCountEvent.emit();
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async setSpam(id: number): Promise<any> {
    try {
      await this.emailClient.setIsSpam({ emailIds: [id] });
      this.dataSource.data = this.dataSource.data.filter((e) => e.emailId !== id);
      this.selection.deselect(id);
      this.eventsService.unreadCountEvent.emit();
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  public async retrySend(id: number) {
    try {
      await this.emailClient.retrySend(new EmailRetrySendRequest([id]));
      this.dataSource.data = this.dataSource.data.map((e) => (e.emailId === id ? { ...e, state: 3 } : e));
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  public async moveToDraft(id: number) {
    try {
      await this.emailClient.moveToDraft(new EmailMoveToDraftRequest(id));
      this.dataSource.data = this.dataSource.data.filter((e) => e.emailId !== id);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  public async retrySendSelected() {
    const ids = this.selection.selected;
    try {
      await this.emailClient.retrySend(new EmailRetrySendRequest(ids));
      this.dataSource.data = this.dataSource.data.map((e) => (ids.includes(e.emailId) ? { ...e, state: 3 } : e));
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async reload() {
    this.showLoader = true;
    this.isError = false;
    try {
      this.selection.clear();
      await this.getEmails(this.pageNumber, this.pageSize);
      this.eventsService.unreadCountEvent.emit();
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.showLoader = false;
    }
  }

  async setValidated(newStatus: EmailValidationState, emailId: number) {
    this.validatingEmailIds = [emailId];
    try {
      await this.emailClient.setValidated({ emailsId: [emailId], validated: newStatus });
      if (this.validated[0] === EmailValidationState.NotValidated) {
        this.dataSource.data = this.dataSource.data.filter((e) => e.emailId !== emailId);
        this.reloadDataOnDemand();
      } else {
        this.setValidationStateForEmail(emailId);
      }
      this.snackbarHelper.openSnackBar(this.translate.instant('PECWasValidatedSuccessfully'));
      this.selection.deselect(emailId);
      this.eventsService.unreadCountEvent.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.validatingEmailIds = [];
    }
  }

  async setSelectedValidated(newStatus: EmailValidationState) {
    const ids = this.selection.selected;
    this.validatingEmailIds = ids;
    try {
      await this.emailClient.setValidated({ emailsId: ids, validated: newStatus });
      this.dataSource.data = this.dataSource.data.filter((e) => !ids.includes(e.emailId));
      this.snackbarHelper.openSnackBar(this.translate.instant('PECWasValidatedSuccessfully'));
      this.selection.clear();
      this.eventsService.unreadCountEvent.emit();
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.validatingEmailIds = [];
    }
  }

  public isEmailValidating(emailId: number) {
    return this.validatingEmailIds.includes(emailId);
  }

  async setNotSpam(id: number[], folderId: number): Promise<any> {
    try {
      await this.emailClient.setNotSpam({ emailIds: id, mailFolderId: folderId });
      this.dataSource.data = this.dataSource.data.filter((e) => e.emailId !== id[0]);
      this.selection.deselect(id[0]);
      this.eventsService.unreadCountEvent.emit();
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async restoreFromArchive(id: number = null): Promise<any> {
    let ids = id ? [id] : this.selection.selected;
    const emails = this.dataSource.data.filter((e) => ids.includes(e.emailId));
    if (!emails.every((e, i, arr) => arr[0].sourceFolderType === e.sourceFolderType)) {
      this.openErrorDialog('errorPopupTitle', 'sourceFoldersMustBeTheSameErrorPopupSubtitle');
      return null;
    }
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        data: {
          mailAccountId: this.selectedMailAccountId,
          ids: ids,
          isRestore: true,
          folderType: this.currentFolderType,
          isArchive: true,
          sourceFoldersType: emails[0].sourceFolderType,
          title: 'restoreFromArchive',
        },
      });

      dialogRef.afterClosed().subscribe(async (e) => {
        if (e?.confirmed) {
          try {
            await this.emailClient.restoreFromArchive({ emailIds: ids, mailFolderId: e.folderId });
            this.dataSource.data = this.dataSource.data.filter((e) => !ids.includes(e.emailId));
            this.selection.clear();
            this.eventsService.unreadCountEvent.emit();
            this.reloadDataOnDemand();
          } catch (e) {
            this.matchError.errorHandler(e);
            this.matchError.logError(e);
          }
        }
      });
    }
  }

  async restoreFromTrash(emailId?: number): Promise<any> {
    const ids = emailId ? [emailId] : this.selection.selected;
    const emails = this.dataSource.data.filter((e) => ids.includes(e.emailId));
    if (!emails.every((e, i, arr) => arr[0].sourceFolderType === e.sourceFolderType)) {
      this.openErrorDialog('error', 'sourceFoldersMustBeTheSameErrorPopupSubtitle');
      return null;
    }
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        data: {
          mailAccountId: this.selectedMailAccountId,
          ids: ids,
          isRestore: true,
          folderType: this.currentFolderType,
          isTrash: true,
          sourceFoldersType: emails[0].sourceFolderType,
          title: 'restoreEmail',
        },
      });

      dialogRef.afterClosed().subscribe(async (e) => {
        if (e?.confirmed) {
          try {
            await this.emailClient.restore({ emailIds: ids, mailFolderId: e.folderId });
            this.dataSource.data = this.dataSource.data.filter((e) => !ids.includes(e.emailId));
            this.selection.clear();
            this.eventsService.unreadCountEvent.emit();
            this.reloadDataOnDemand();
          } catch (e) {
            this.matchError.errorHandler(e);
            this.matchError.logError(e);
          }
        }
      });
    }
  }

  public reloadDataOnDemand() {
    if (!this.dataSource.data.length) {
      this.reload();
    }
  }

  async setSelectedSpam(): Promise<any> {
    const ids = this.selection.selected;
    try {
      await this.emailClient.setIsSpam({ emailIds: ids });
      this.dataSource.data = this.dataSource.data.filter((e) => !ids.includes(e.emailId));
      this.selection.clear();
      this.eventsService.unreadCountEvent.emit();
      this.reloadDataOnDemand();
    } catch (e) {
      this.openErrorDialog('errorPopupTitle', e.Description || 'cannotSetSpamSelectedErrorPopupSubtitle');
      this.matchError.logError(e);
    }
  }

  async setSelectedArchive(id: number = null): Promise<any> {
    let ids = id ? [id] : this.selection.selected;
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        data: {
          mailAccountId: this.selectedMailAccountId,
          ids: ids,
          isRestore: true,
          folderType: this.currentFolderType,
          isArchive: true,
          sourceFoldersType: MailFolderType.Archive,
          title: 'moveToArchive',
        },
      });

      dialogRef.afterClosed().subscribe(async (e) => {
        if (e?.confirmed) {
          try {
            await this.emailClient.archive({ emailIds: ids, mailFolderId: e.folderId });
            this.dataSource.data = this.dataSource.data.filter((e) => !ids.includes(e.emailId));
            this.selection.clear();
            this.eventsService.unreadCountEvent.emit();
            this.reloadDataOnDemand();
          } catch (e) {
            this.matchError.errorHandler(e);
            this.matchError.logError(e);
          }
        }
      });
    }
  }

  async setSelectedNotSpam(folderId: number): Promise<any> {
    const ids = this.selection.selected;
    try {
      await this.emailClient.setNotSpam({ emailIds: ids, mailFolderId: folderId });
      this.dataSource.data = this.dataSource.data.filter((e) => !ids.includes(e.emailId));
      this.selection.clear();
      this.eventsService.unreadCountEvent.emit();
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async delete(id: number): Promise<any> {
    try {
      await this.emailClient.delete({ emailIds: [id] });
      this.dataSource.data = this.dataSource.data.filter((e) => e.emailId !== id);
      this.selection.deselect(id);
      this.eventsService.unreadCountEvent.emit();
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async deleteAllSelected(): Promise<any> {
    const ids = this.selection.selected;
    try {
      await this.emailClient.delete({ emailIds: ids });
      this.dataSource.data = this.dataSource.data.filter((e) => !ids.includes(e.emailId));
      this.selection.clear();
      this.eventsService.unreadCountEvent.emit();
      this.reloadDataOnDemand();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async finalDelete(id: number): Promise<any> {
    const dialogRef = this.dialog.open(ConfirmFinalDeletionDialogComponent, {
      width: '450px',
      data: {
        ids: [id],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.length && result.length > 0) {
        this.dataSource.data = this.dataSource.data.filter((e) => !result.includes(e.emailId));
        this.selection.clear();
        this.eventsService.unreadCountEvent.emit();
        this.reloadDataOnDemand();
      }
    });
  }

  async finalDeleteAllSelected(): Promise<any> {
    let ids = this.selection.selected;
    const dialogRef = this.dialog.open(ConfirmFinalDeletionDialogComponent, {
      width: '450px',
      data: {
        ids: ids,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.length && result.length > 0) {
        this.dataSource.data = this.dataSource.data.filter((e) => !result.includes(e.emailId));
        this.selection.clear();
        this.eventsService.unreadCountEvent.emit();
        this.reloadDataOnDemand();
      }
    });
  }

  async reply(emails: EmailListItemContract): Promise<any> {
    emails = emails.emailId === undefined ? this.dataSource.data.filter((e) => e.emailId === emails[0])[0] : emails;

    try {
      this.replyingEmailId = emails.emailId;
      const newEmail = await this.emailClient.reply(new EmailReplyRequest(emails.emailId));
      const replyEmail = { ...emails };
      replyEmail.emailId = newEmail.id;

      this.openCreateOrUpdateModalForm(replyEmail, 'Reply');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.replyingEmailId = null;
    }
  }

  async replyAll(emails: EmailListItemContract): Promise<any> {
    emails = emails.emailId === undefined ? this.dataSource.data.filter((e) => e.emailId === emails[0])[0] : emails;
    try {
      this.replyingAllEmailId = emails.emailId;
      const newEmail = await this.emailClient.replyAll(new EmailReplyRequest(emails.emailId));
      const replyEmail = { ...emails };
      replyEmail.emailId = newEmail.id;

      this.openCreateOrUpdateModalForm(replyEmail, 'Reply all');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.replyingAllEmailId = null;
    }
  }

  async exportExcel(): Promise<void> {
    this.isExporting = true;
    try {
      this.searchParams.validated = this.validated;
      this.searchParams.reassigned = this.searchParams.reassignedId && this.isAllFoldersSelected ? true : this.reassigned;
      const request = new EmailSearchRequest(1, 1, this.searchParams, this.selectedMailFolderIds);

      const fileResponse = await this.emailExportClient.exportExcel(request);
      const downloadRequest = new BaseGetByFileIdRequest(fileResponse.result.fileId);
      await this.tempFileClient.download(downloadRequest);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isExporting = false;
    }
  }

  onStartRecording(e: MouseEvent) {
    if (e.button === 0) {
      this.mouseCoordinatesX = e.x;
      this.mouseCoordinatesY = e.y;
    }
  }

  onFinishRecording(e: MouseEvent, row: EmailListItemContract) {
    if (e.button === 0) {
      this.mouseCoordinatesX === e.x && this.mouseCoordinatesY === e.y && this.emailDetails(row);
    }
  }

  async loadData(): Promise<void> {
    this.showLoader = true;
    this.isError = false;
    try {
      await this.getFolderType();
      await this.getEmails(this.pageNumber, this.pageSize);
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.showLoader = false;
    }
  }

  public async getFolderType(): Promise<void> {
    const res = this.searchContextStorageService.userFolders.find((e) => e.mailFolderId === this.selectedMailFolderId);
    this.currentFolderType = res.folderType;
    let searchOrders = Object.keys(EmailSearchOrder)
      .filter((k) => !(parseInt(k, 10) >= 0))
      .map((key) => ({
        id: EmailSearchOrder[key],
        name: this.formatSearchOrders.transform(EmailSearchOrder[key]),
      }));
    if (this.currentFolderType === this.mailFolderType.Inbox || this.currentFolderType === this.mailFolderType.Spam) {
      searchOrders = searchOrders.filter((e) => e.id !== EmailSearchOrder.OrderByTo);
    }
    if (
      this.currentFolderType === this.mailFolderType.Validation ||
      this.currentFolderType === this.mailFolderType.Draft ||
      this.currentFolderType === this.mailFolderType.Outbox
    ) {
      if (this.searchOrder === EmailSearchOrder.OrderBySentDateTime || this.searchOrder === EmailSearchOrder.OrderByStateDateTime) {
        this.searchOrder = EmailSearchOrder.OrderByStateDateTime;
      }
      searchOrders = searchOrders.filter((e) => e.id !== EmailSearchOrder.OrderBySentDateTime);
    } else {
      searchOrders = searchOrders.filter((e) => e.id !== EmailSearchOrder.OrderByStateDateTime);
    }
    if (this.currentFolderType >= this.mailFolderType.Sent && this.currentFolderType <= this.mailFolderType.Validation) {
      searchOrders = searchOrders.filter((e) => e.id !== EmailSearchOrder.OrderByFrom);
    }
    searchOrders = searchOrders.filter((e) => e.id !== EmailSearchOrder.OrderBySentDateTimeOrStateDateTimeIfSentDateTimeIsNull);
    if (this.currentFolderType === this.mailFolderType.Trash) {
      searchOrders = searchOrders.map((e) =>
        e.id === EmailSearchOrder.OrderBySentDateTime
          ? { ...e, id: EmailSearchOrder.OrderBySentDateTimeOrStateDateTimeIfSentDateTimeIsNull }
          : e
      );
      if (this.searchOrder === EmailSearchOrder.OrderBySentDateTime) {
        this.searchOrder = EmailSearchOrder.OrderBySentDateTimeOrStateDateTimeIfSentDateTimeIsNull;
      }
    }
    this.searchOrders = searchOrders;
  }

  public showIcon(iconTypes: number[]) {
    return iconTypes.includes(this.currentFolderType);
  }

  public isColumnShow(isTo: boolean, isClass = false) {
    // if (this.currentFolderType === this.mailFolderType.Inbox && this.reassigned) {
    //   if (isClass) {
    //     return true;
    //   }
    //   return false;
    // }
    if (this.currentFolderType === this.mailFolderType.Inbox || this.currentFolderType === this.mailFolderType.Spam) {
      return isTo ? true : false;
    }
    if (this.currentFolderType >= this.mailFolderType.Inbox && this.currentFolderType <= this.mailFolderType.Validation) {
      return isTo ? false : true;
    } else {
      if (isClass) {
        return true;
      } else {
        return false;
      }
    }
  }

  public countFrom() {
    return (this.folderCount ? (this.pageNumber === 1 ? 1 : this.pageSize * (this.pageNumber - 1) + 1) : '1').toString();
  }

  public countTo() {
    return (
      this.folderCount ? (this.pageSize * this.pageNumber < this.folderCount ? this.pageSize * this.pageNumber : this.folderCount) : '0'
    ).toString();
  }

  public countTotal() {
    return this.folderCount ? this.folderCount : '0';
  }

  async getEmails(pageNumber: number, pageSize: number): Promise<void> {
    this.showLoader = true;
    this.isError = false;
    this.canNavigateBack = false;
    this.canNavigateForward = false;
    this.emails = [];
    this.dataSource.data = null;
    try {
      if (this.searchWidgetId && this.searchWidgetId > 0) {
        await this.getEmailsByWidget(pageNumber, pageSize);
      } else {
        await this.getEmailsByFilter(pageNumber, pageSize);
      }
      this.dataSource.data = this.emails;
      this.canNavigateBack = this.emails && this.pageNumber > 1;
      this.canNavigateForward = this.emails && this.emails.length === this.pageSize;
      this.hasAttachments = this.dataSource.data.some((e) => e.hasAttachments === true);
      this.selection.clear();
    } catch (e) {
      this.isError = true;
      if (this.subscription) {
        this.subscription?.unsubscribe();
        this.subscription = null;
      }
      this.matchError.logError(e);
    }
    this.showLoader = false;
  }

  private async getEmailsByFilter(pageNumber: number, pageSize: number): Promise<any> {
    this.searchParams.validated = this.validated;
    this.searchParams.reassigned = this.searchParams.reassignedId && this.isAllFoldersSelected ? true : this.reassigned;
    this.searchParams.searchOrder = this.searchOrder;
    const request = new EmailSearchRequest(pageNumber, pageSize, this.searchParams, this.selectedMailFolderIds);
    const result = await this.emailClient.search(request);
    this.folderCount = this.pageNumber === 1 ? result.count : this.folderCount;
    this.emails = result.data;
  }

  private async getEmailsByWidget(pageNumber: number, pageSize: number): Promise<any> {
    const request = new EmailSearchByWidgetRequest(pageNumber, pageSize, this.searchWidgetId, this.selectedMailFolderIds);
    const result = await this.emailClient.searchByWidget(request);
    this.emails = result.data;
  }

  async emailDetails(row: EmailListItemContract): Promise<void> {
    if (row.state === EmailState.Draft || row.state === EmailState.ValidationRejected || this.currentFolderType === MailFolderType.Draft) {
      this.editEmail(row);
      return;
    }
    this.openEmailDetails(row);
  }

  public closeNotification() {
    this.notificationDrawerIsOpen = false;
  }

  public closeNotes() {
    this.notesDrawerIsOpen = false;
  }

  public closeAudit() {
    this.auditDrawerIsOpen = false;
  }

  public closeMeta() {
    this.metaDrawerIsOpen = false;
  }

  public openNotifications(id: number) {
    this.notificationMailId = id;
    this.notificationDrawerIsOpen = true;
  }

  public refreshNotes() {
    this.dataSource.data = this.dataSource.data.map((e) => (e.emailId === this.notesMailId ? { ...e, notesCount: e.notesCount + 1 } : e));
  }

  public openNotes(id: number) {
    this.notesMailId = id;
    this.notesDrawerIsOpen = true;
  }

  public openAudit(auditMailId: number = null) {
    if (auditMailId) {
      this.auditMailId = auditMailId;
    } else {
      this.auditMailId = this.selection.selected[0];
    }
    this.auditDrawerIsOpen = true;
  }

  public openMeta(id: number) {
    this.metaMailId = id;
    this.metaDrawerIsOpen = true;
  }

  async navigateBack(): Promise<void> {
    this.pageNumber = this.pageNumber - 1;
    this.showLoader = true;
    this.isError = false;
    try {
      await this.getEmails(this.pageNumber, this.pageSize);
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.showLoader = false;
    }
  }

  async navigate(): Promise<void> {
    this.searchContextStorageService.setPage(this.pageNumber);
  }

  async navigateForward(): Promise<void> {
    this.pageNumber = this.pageNumber + 1;
    this.showLoader = true;
    this.isError = false;
    try {
      await this.getEmails(this.pageNumber, this.pageSize);
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.showLoader = false;
    }
  }

  isAllSelected(): boolean {
    if (!this.selection?.selected?.length || !this.dataSource?.data?.length) return false;
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row.emailId));
  }

  openAddGroupDialog(emails): void {
    const dialogRef = this.dialog.open(EmailAddGroupsDialogComponent, {
      width: '600px',
      data: { ids: [emails.emailId], emails: [emails], mailAccountId: this.selectedMailAccountId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        emails.groups = result;
      }
    });
  }

  openAddGroupDialogSelected(): void {
    const ids = this.selection.selected;
    const emails = this.dataSource.data.filter((e) => ids.includes(e.emailId));
    const dialogRef = this.dialog.open(EmailAddGroupsDialogComponent, {
      width: '600px',
      data: { ids: ids, emails: emails, mailAccountId: this.selectedMailAccountId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.data = this.dataSource.data.map((e) => (ids.includes(e.emailId) ? { ...e, groups: result } : e));
      }
    });
  }

  openAddTagDialog(emails): void {
    const dialogRef = this.dialog.open(EmailAddTagsDialogComponent, {
      width: '600px',
      data: { ids: [emails.emailId], emails: [emails], mailAccountId: this.selectedMailAccountId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        emails.tags = result.tags;
      }
    });
  }

  openAddTagDialogSelected(): void {
    const ids = this.selection.selected;
    const emails = this.dataSource.data.filter((e) => ids.includes(e.emailId));
    const dialogRef = this.dialog.open(EmailAddTagsDialogComponent, {
      width: '600px',
      data: { ids: ids, emails: emails, mailAccountId: this.selectedMailAccountId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.dataSource.data = this.dataSource.data.map((e) => (result.ids.includes(e.emailId) ? { ...e, tags: result.tags } : e));
      }
    });
  }

  openErrorDialog(title: string, description: string, value?: number): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { title: title, description: description, value: value },
    });
  }

  async forward(id: number = null): Promise<any> {
    let ids = id ? [id] : this.selection.selected;
    if (ids.length > 0) {
      try {
        let createResponse;
        if (ids.length === 1) {
          this.forwardingEmailId = ids[0];
          createResponse = await this.emailClient.forward(new EmailForwardRequest(ids[0]));
        } else {
          this.forwardingEmailId = ids[0];
          createResponse = await this.emailClient.forwardMultiple(new EmailMultipleForwardRequest(ids, this.selectedMailAccountId));
        }
        const newEmailResponse = await this.emailClient.getById(new BaseGetByIdRequest(createResponse.id));
        this.openCreateOrUpdateModalForm(newEmailResponse.email, 'Forward');
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.forwardingEmailId = null;
      }
    }
  }

  async move(id: number = null): Promise<any> {
    let ids = id ? [id] : this.selection.selected;
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        data: {
          mailAccountId: this.selectedMailAccountId,
          ids: ids,
          isRestore: false,
          folderType: this.currentFolderType,
        },
      });

      dialogRef.afterClosed().subscribe(async (e) => {
        if (e?.confirmed) {
          await this.getEmails(this.pageNumber, this.pageSize);
          this.eventsService.unreadCountEvent.emit();
        }
      });
    }
  }

  async reassign(emailId: number): Promise<any> {
    const dialogRef = this.dialog.open(ReassignDialogComponent, {
      width: '350px',
      data: {
        mailAccountId: this.selectedMailAccountId,
        ids: [emailId],
      },
    });

    dialogRef.afterClosed().subscribe(async (e) => {
      if (e?.confirmed) {
        await this.getEmails(this.pageNumber, this.pageSize);
      }
    });
  }

  async reassignSelected(): Promise<any> {
    const ids = this.selection.selected;
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(ReassignDialogComponent, {
        width: '350px',
        data: {
          mailAccountId: this.selectedMailAccountId,
          ids: ids,
        },
      });

      dialogRef.afterClosed().subscribe(async (e) => {
        if (e?.confirmed) {
          await this.getEmails(this.pageNumber, this.pageSize);
        }
      });
    }
  }

  public async pageSizeChange(pageSize: number): Promise<void> {
    localStorage.setItem(`pageSize`, JSON.stringify(pageSize));
    this.pageSize = pageSize;
    this.pageNumber = 1;
    await this.getEmails(this.pageNumber, this.pageSize);
  }

  public async searchOrderChange(): Promise<void> {
    this.pageNumber = 1;
    await this.loadData();
  }

  public async createEmail(): Promise<any> {
    this.openCreateOrUpdateModalForm(null, 'Create email');
  }

  public editEmail(email: EmailListItemContract): void {
    this.openCreateOrUpdateModalForm(email, 'Edit email');
  }

  public getDate(email: EmailListItemContract) {
    let emailFolderType = this.searchContextStorageService.userFolders.find((e) => e.mailFolderId === email.mailFolderId).folderType;
    return emailFolderType === MailFolderType.Outbox ||
      emailFolderType === MailFolderType.Draft ||
      emailFolderType === MailFolderType.Validation
      ? email.stateDateTime
      : email.sourceFolderType === MailFolderType.Draft || email.sourceFolderType === MailFolderType.Outbox
      ? email.stateDateTime
      : email.sentDateTime;
  }

  public formatTo(to: string | null): string {
    if (!to) return '';
    let formatedEmails = to.split(';').join('; ');
    return formatedEmails;
  }

  public selectedOneEmail(): boolean {
    return this.selection.selected.length === 1;
  }

  public openCreateOrUpdateModalForm(email: EmailContract | EmailListItemContract | null, title: string): void {
    this.updatedEmail = email;
    this.menuTitle = title;
    this.createOrUpdateDrawerIsOpen = true;
  }

  public async isClosingCreateOrUpdateModalForm() {
    this.isDrawerClosing = true;
  }

  public async closeCreateOrUpdateModalForm(): Promise<any> {
    this.isDrawerClosing = false;
    this.createOrUpdateDrawerIsOpen = false;
    if (this.currentFolderType === this.mailFolderType.Draft) {
      this.reload();
    }
    if (this.updatedEmail && this.currentFolderType !== this.mailFolderType.Draft) {
      this.getTags(true, this.updatedEmail.emailId);
    }
    this.updatedEmail = null;
    this.menuTitle = null;
  }

  public openEmailDetails(email: EmailListItemContract) {
    this.openedEmail = email;
    this.emailDrawerIsOpen = true;
  }

  public async getTags(isSeen: boolean, emailId: number) {
    try {
      let tagsResponse = await this.emailTagClient.getEmailTags(new BaseGetByIdRequest(emailId));
      this.dataSource.data = this.dataSource.data.map((e) => (e.emailId === emailId ? { ...e, seen: isSeen, tags: tagsResponse.data } : e));
    } catch (e) {
      this.matchError.logError(e);
    }
  }

  public deleteFromData(id: number) {
    this.dataSource.data = this.dataSource.data.filter((e) => e.emailId !== id);
  }

  public changeDraftData(data) {
    if (this.currentFolderType === this.mailFolderType.Draft) {
      this.dataSource.data = this.dataSource.data.map((e) =>
        e.emailId === data.emailId
          ? {
              ...e,
              to: data.updateRequest.to,
              subject: data.updateRequest.subject,
              notesCount: data.isNotesChanged ? 1 : e.notesCount,
              hasAttachments: data.updateRequest.attachmentFiles.length ? true : false,
            }
          : e
      );
    }
  }

  public async closeEmailModalForm(): Promise<any> {
    this.emailDrawerIsOpen = false;
    const isDeleted = this.openedEmailInfo?.deleted === this.openedEmail?.deleted;
    const isSpam = this.openedEmailInfo?.isSpam === this.openedEmail?.isSpam;
    const isSeen = this.openedEmailInfo?.seen;

    if (isDeleted && isSpam) {
      this.getTags(isSeen, this.openedEmail.emailId);
    } else {
      this.eventsService.unreadCountEvent.emit();
      if (this.openedEmailInfo) {
        this.dataSource.data = this.dataSource.data.filter((e) => e.emailId !== this.openedEmail.emailId);
        this.reloadDataOnDemand();
      }
    }
    this.openedEmail = null;
    this.openedEmailInfo = null;
  }

  public async downloadEML(id: number) {
    this.downloadingEMLEmailId = id;
    try {
      let fileResponse = await this.emailClient.download({ emailIds: [id] });
      const request = new BaseGetByFileIdRequest(fileResponse.fileId);
      await this.tempFileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.downloadingEMLEmailId = null;
    }
  }

  public async downloadSingleEML(id: number) {
    this.downloadingSingleEMLEmailId = id;
    try {
      let fileResponse = await this.emailClient.singleDownload({ emailIds: [id] });
      const request = new BaseGetByEmailFileIdRequest(fileResponse.fileId, id);
      await this.fileClient.download(request);
    } catch (error) {
      this.matchError.errorHandler(error);
    } finally {
      this.downloadingSingleEMLEmailId = null;
    }
  }

  public async downloadEMLSelection() {
    this.isDownloadingEML = true;
    try {
      const ids = this.selection.selected;
      let fileResponse = await this.emailClient.download({ emailIds: ids });
      const request = new BaseGetByFileIdRequest(fileResponse.fileId);
      await this.tempFileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isDownloadingEML = false;
    }
  }

  public async downloadSingleEMLSelection() {
    this.isDownloadingSingleEML = true;
    try {
      const ids = this.selection.selected;
      let fileResponse = await this.emailClient.singleDownload({ emailIds: ids });
      if (ids.length === 1) {
        const request = new BaseGetByEmailFileIdRequest(fileResponse.fileId, ids[0]);
        await this.fileClient.download(request);
      } else {
        const request = new BaseGetByFileIdRequest(fileResponse.fileId);
        await this.tempFileClient.download(request);
      }
    } catch (error) {
      this.matchError.errorHandler(error);
    } finally {
      this.isDownloadingSingleEML = false;
    }
  }

  public selectedOneFolder(): boolean {
    return this.selectedMailFolderIds.length === 1;
  }

  public getMailAccountName(email: EmailListItemContract): string {
    return this.searchContextStorageService.userFolders.find((x) => x.mailFolderId === email.mailFolderId)?.mailAccountName;
  }

  public getMailFolderName(email: EmailListItemContract): string {
    return this.searchContextStorageService.userFolders.find((x) => x.mailFolderId === email.mailFolderId)?.mailFolderName;
  }

  public get multipleReassignIsAvailable(): boolean {
    return !!this.selectedMailFolderId && this.currentFolderType === 2;
  }
  public validateIsAvailable(): boolean {
    const emails = this.dataSource.data.filter((e) => this.selection.selected.includes(e.emailId));
    const isAvailable = emails.every(
      (e) => e.validationState === EmailValidationState.New || e.validationState === EmailValidationState.NotValidated
    );
    return isAvailable;
  }
}
