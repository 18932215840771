import { Component, Input, OnInit } from '@angular/core';
import { DispatchingAction } from '../../../../../../../sdk/contracts/dispatching/dispatching.action';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MailAccountGetByOrganizationIdContract } from 'src/app/sdk/contracts/mail-account/mail-account.get-by-organization-id.contract';

@Component({
  selector: 'app-reassign-action',
  templateUrl: './reassign-action.component.html',
  styleUrls: ['./reassign-action.component.scss'],
})
export class ReassignActionComponent implements OnInit {
  @Input() dispatchingAction: DispatchingAction;
  @Input() mailAccounts: MailAccountGetByOrganizationIdContract[];
  public form: UntypedFormGroup;
  constructor() {}

  ngOnInit(): void {
    this.setMailAccountValue(this.dispatchingAction.args[0]?.value || this.mailAccounts[0].email);
    this.form = new UntypedFormGroup({
      mailAccount: new UntypedFormControl(this.dispatchingAction.args[0].value),
    });
  }

  setMailAccountValue(email: string): void {
    this.dispatchingAction.args = [];
    this.dispatchingAction.args[0] = {
      name: 'MailAccountToReassign',
      value: email,
    };
  }
}
