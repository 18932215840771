export enum ExpressionOperation {
  Like = 1,
  NotLike = 2,
  Equal = 3,
  NotEqual = 4
}

export function toText(operation: ExpressionOperation): string {
  switch (operation) {
    case ExpressionOperation.Like:
      return 'contains';
    case ExpressionOperation.NotLike:
      return 'notContains';
	case ExpressionOperation.Equal:
      return 'equal';
	case ExpressionOperation.NotEqual:
      return 'notEqual';
    default:
      throw new Error('Not implemented ExpressionOperation: ' + operation);
  }
}
