import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { EmailFerreroCreateRequest } from '../contracts/plugins/email-ferrero.create-request';
const API_URL = environment.apiUrl;

@Injectable()
export class PluginsClient {
  constructor(private apiRequest: ApiRequest) {}

  async create(request: EmailFerreroCreateRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<EmailFerreroCreateRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.ferreroPlugin.create}`);
  }
}
