<div class="drawer-content-screen flex flex-col justify-between">
  <div *ngIf="!cannotLoadSettings && !settingsIsLoading" class="text-2xl mb-10 font-medium">
    {{ "mailAccountSettings" | translate }}
  </div>
  <div *ngIf="cannotLoadSettings" class="flex flex-col justify-center h-full">
    <app-something-went-wrong [showPicture]="false" description="Cannot load settings" (retryEmitter)="ngOnInit()">
    </app-something-went-wrong>
  </div>
  <div *ngIf="settingsIsLoading" class="flex flex-col justify-center h-full">
    <app-loader></app-loader>
  </div>
  <div class="h-full w-full flex-grow overflow-y-auto" *ngIf="!settingsIsLoading && !cannotLoadSettings">
    <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
      <div class="font-medium text-lg mb-3">{{ "downloadSetting" | translate }}</div>

      <mat-form-field appearance="fill">
        <mat-label>{{ "downloadEach" | translate }}</mat-label>
        <input type="number" matInput id="downloadCron" formControlName="downloadCron" required />
        <mat-error *ngIf="hasError('downloadCron', 'required')">{{ "downloadEachIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('downloadCron', 'maxlength')">{{ "moreThan50Char" | translate }}</mat-error>
        <mat-error *ngIf="hasError('downloadCron', 'min')"> {{ "greaterThan0" | translate }}</mat-error>
        <mat-error *ngIf="hasError('downloadCron', 'max')"> {{ "lessThan31" | translate }}</mat-error>
        <mat-error *ngIf="hasError('downloadCron', 'pattern')">{{ "mustBeInt" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "downloadBatchNumber" | translate }}</mat-label>
        <input type="number" matInput id="downloadBatchNumber" formControlName="downloadBatchNumber" required />
        <mat-error *ngIf="hasError('downloadBatchNumber', 'min')">{{ "greaterThan0" | translate }}</mat-error>
        <mat-error *ngIf="hasError('downloadBatchNumber', 'pattern')">{{ "mustBeInt" | translate }}</mat-error>
      </mat-form-field>

      <div>
        <mat-checkbox color="primary" [ngModelOptions]="{ standalone: true }" [(ngModel)]="settings.active"
          >{{ "active" | translate }}
        </mat-checkbox>
      </div>
      <!-- <div>
          <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}" [(ngModel)]="settings.dryRun">Dry Run</mat-checkbox>
        </div> -->
      <div>
        <mat-checkbox color="primary" [ngModelOptions]="{ standalone: true }" [(ngModel)]="settings.download"
          >{{ "download" | translate }}
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox color="primary" [ngModelOptions]="{ standalone: true }" [(ngModel)]="settings.send"
          >{{ "send" | translate }}
        </mat-checkbox>
      </div>
      <!-- <div>
        <mat-checkbox color="primary" [ngModelOptions]="{ standalone: true }" [(ngModel)]="settings.archive"
          >{{ "archive" | translate }}
        </mat-checkbox>
      </div> -->
      <!--        <div>-->
      <!--          <mat-checkbox color="primary" [ngModelOptions]="{standalone: true}" [(ngModel)]="settings.leaveMessagesOnServer">Leave Messages On Server</mat-checkbox>-->
      <!--        </div>-->
      <div class="mt-3">
        <mat-form-field appearance="fill">
          <mat-label>{{ "autoContactSaving" | translate }}</mat-label>
          <mat-select [(value)]="settings.autoContactSavingType">
            <mat-option *ngFor="let source of autoContactSavingSources" [value]="source.id">
              {{ source.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="font-medium text-lg mb-3 mt-6">{{ "archivingSettingsMailAccountDialog" | translate }}</div>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>Code</mat-label>
          <input matInput id="code" formControlName="code" />
          <mat-error *ngIf="hasError('code', 'required')">Code is required</mat-error>
          <mat-error *ngIf="hasError('code', 'maxlength')">You have more than 256 characters</mat-error>
        </mat-form-field>
        <mat-checkbox color="primary" [ngModelOptions]="{ standalone: true }" [(ngModel)]="settings.archive" (change)="setCodeValidation()"
          >{{ "archive" | translate }}
        </mat-checkbox>
      </div>
      <div *ngIf="isShowAdvancedSettings" class="flex flex-col mt-10 -mb-4">
        <div class="font-medium text-lg my-3">Sleep</div>
        <mat-form-field appearance="fill">
          <mat-label>Milliseconds</mat-label>
          <input type="number" matInput [(ngModel)]="threadSleepForSend" [ngModelOptions]="{ standalone: true }" />
        </mat-form-field>
      </div>
      <div *ngIf="isShowPignor" class="flex flex-col mb-3 mt-10">
        <div class="font-medium text-lg my-3">{{ "pignor" | translate }}</div>
        <mat-checkbox color="primary" [(ngModel)]="pignor" [ngModelOptions]="{ standalone: true }">{{ "pignor" | translate }}</mat-checkbox>
        <div *ngIf="isShowAdvancedSettings" class="mt-3">
          <mat-form-field appearance="fill">
            <mat-label>Pignor path</mat-label>
            <input matInput [(ngModel)]="settings.pignorPath" formControlName="pignorPath" />
            <mat-error *ngIf="hasError('pignorPath', 'maxlength')">You have more than 256 characters</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-col mb-3 mt-10">
        <div class="font-medium text-lg">{{ "validationSettingsTitle" | translate }}</div>
        <div class="my-3">
          <mat-checkbox
            color="primary"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="settings.validation"
            (change)="checkValidation()"
            >{{ "validation" | translate }}</mat-checkbox
          >
        </div>
        <div>
          <mat-form-field appearance="fill">
            <mat-label>{{ "validationHours" | translate }}</mat-label>
            <input type="number" matInput id="validationHours" formControlName="validationHours" required />
            <mat-error *ngIf="hasError('validationHours', 'min')">{{ "greaterThan0" | translate }}</mat-error>
            <mat-error *ngIf="hasError('validationHours', 'pattern')">{{ "mustBeInt" | translate }}</mat-error>
            <mat-error *ngIf="hasError('validationHours', 'required')">{{ "isRequired" | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" style="overflow: hidden">
            <mat-label>{{ "validationStart" | translate }}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="validateStart" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="hasError('validateStart', 'required')">{{ "isRequired" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-checkbox class="mb-2" color="primary" [ngModelOptions]="{ standalone: true }" [(ngModel)]="settings.sendNotifyOfValidation"
            >{{ "sendNotifyOfValidation" | translate }}
          </mat-checkbox>
          <app-email-tags
            (changeList)="setNotificationEmails($event)"
            style="width: 100%"
            [toList]="notificationEmails"
            [placeHolder]="'emailAddAllert'"
            [isNeedMarginLeft]="false"
            [existedContacts]="[]"></app-email-tags>
          <mat-error *ngIf="!isAllValid()">{{ "incorrectEmailDispatchingRules" | translate }}</mat-error>
        </div>
      </div>
    </form>
    <form *ngIf="isSamlSupport" [formGroup]="mailAccountSamlClaimsForm" autocomplete="off" novalidate>
      <div class="flex flex-col mb-3 mt-6">
        <div class="font-medium text-lg mb-3">{{ "mailAccountSamlClaims" | translate }}</div>
        <mat-form-field appearance="fill">
          <mat-label>{{ "superuserRoleClaim" | translate }}</mat-label>
          <input matInput id="superuserRoleClaim" formControlName="superuserRoleClaim" required />
          <mat-error *ngIf="hasErrorSamlForm('superuserRoleClaim', 'required')"
            >{{ "superuserRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error
          >
          <mat-error *ngIf="hasErrorSamlForm('superuserRoleClaim', 'maxlength')">{{ "moreThan265Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "userRoleClaim" | translate }}</mat-label>
          <input matInput id="userRoleClaim" formControlName="userRoleClaim" required />
          <mat-error *ngIf="hasErrorSamlForm('userRoleClaim', 'required')"
            >{{ "userRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error
          >
          <mat-error *ngIf="hasErrorSamlForm('userRoleClaim', 'maxlength')">{{ "moreThan265Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "validatorRoleClaim" | translate }}</mat-label>
          <input matInput id="validatorRoleClaim" formControlName="validatorRoleClaim" required />
          <mat-error *ngIf="hasErrorSamlForm('validatorRoleClaim', 'required')"
            >{{ "validatorRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error
          >
          <mat-error *ngIf="hasErrorSamlForm('validatorRoleClaim', 'maxlength')">{{ "moreThan265Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "creatorRoleClaim" | translate }}</mat-label>
          <input matInput id="creatorRoleClaim" formControlName="creatorRoleClaim" required />
          <mat-error *ngIf="hasErrorSamlForm('creatorRoleClaim', 'required')"
            >{{ "creatorRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error
          >
          <mat-error *ngIf="hasErrorSamlForm('creatorRoleClaim', 'maxlength')">{{ "moreThan265Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "limitedUserRoleClaim" | translate }}</mat-label>
          <input matInput id="limitedUserRoleClaim" formControlName="limitedUserRoleClaim" required />
          <mat-error *ngIf="hasErrorSamlForm('limitedUserRoleClaim', 'required')"
            >{{ "limitedUserRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error
          >
          <mat-error *ngIf="hasErrorSamlForm('limitedUserRoleClaim', 'maxlength')">{{ "moreThan265Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "limitedCreatorRoleClaim" | translate }}</mat-label>
          <input matInput id="limitedCreatorRoleClaim" formControlName="limitedCreatorRoleClaim" required />
          <mat-error *ngIf="hasErrorSamlForm('limitedCreatorRoleClaim', 'required')"
            >{{ "limitedCreatorRoleClaim" | translate }}{{ "isRequired" | translate }}</mat-error
          >
          <mat-error *ngIf="hasErrorSamlForm('limitedCreatorRoleClaim', 'maxlength')">{{ "moreThan265Char" | translate }}</mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div *ngIf="!settingsIsLoading && !cannotLoadSettings" class="w-full mt-6">
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button
        mat-flat-button
        color="primary"
        [disabled]="isLoading || !form.valid || !isAllValid() || (isSamlSupport ? !mailAccountSamlClaimsForm.valid : false)"
        (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
