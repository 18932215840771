import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserOrganizationClient } from '../../../../../sdk/clients/user-organization.client';
import { UpdateUserRequest } from '../../../../../sdk/contracts/user-organization/update-user.request';
import { ErrorCode } from '../../../../../sdk/api-errors/error-code';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import {
  hasAtLeastOneDigit,
  hasAtLeastOneLowerCharacter,
  hasAtLeastOneSpecialSymbol,
  hasAtLeastOneUpperCharacter,
} from '../../../../../shared/validators/custom-validators';
import { AuthenticationType } from '../../../../../sdk/contracts/authentication/authentication-type';
import { OrganizationSettingsClient } from '../../../../../sdk/clients/organization-settings.client';
import { OrganizationSettingsContract } from '../../../../../sdk/contracts/organization/settings/organization-settings.contract';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';
import { UserOrganizationGetOrganizationUsersContract } from '../../../../../sdk/contracts/user-organization/user-organization.get-organization-users.contract';
import { UserOrganizationState } from '../../../../../sdk/contracts/user-organization/user-organization-state.enum';

@Component({
  selector: 'app-organization-user-update-dialog',
  templateUrl: './organization-user-update-dialog.component.html',
  styleUrls: ['./organization-user-update-dialog.component.scss'],
})
export class OrganizationUserUpdateDialogComponent implements OnInit {
  public isLoading = false;
  @Input() public organizationId: number;
  @Input() public user: UserOrganizationGetOrganizationUsersContract;
  @Output() public onUpdateOrganizationUser = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public gawOidcAuthType: boolean = false;
  public googleAuthType: boolean = false;

  public passwordValidators = [
    Validators.required,
    Validators.minLength(8),
    Validators.maxLength(15),
    hasAtLeastOneUpperCharacter(),
    hasAtLeastOneLowerCharacter(),
    hasAtLeastOneDigit(),
    hasAtLeastOneSpecialSymbol(),
  ];
  public failedSettingsLoading = false;
  public settingsIsLoading = false;
  public settings: OrganizationSettingsContract;
  public userIdentityTypes;
  public isUserHasOidc: boolean = false;

  public errorText: string;
  public handlingErrorCodes = new Map<number, string>([
    [ErrorCode.UserAlreadyExists, 'Cannot add user to company: email already registered'],
  ]);
  public form: UntypedFormGroup = new UntypedFormGroup({
    firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
    lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(128)]),
    email: new UntypedFormControl('', [Validators.required, Validators.maxLength(256), Validators.email]),
    nickName: new UntypedFormControl('', [Validators.required, Validators.maxLength(256)]),
    password: new UntypedFormControl('', this.passwordValidators),
  });

  constructor(
    private userOrganizationClient: UserOrganizationClient,
    private organizationSettingsClient: OrganizationSettingsClient,
    public matchError: MatchError,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form.controls['email'].setValue(this.user.email);
    this.form.controls['firstName'].setValue(this.user.firstName);
    this.form.controls['lastName'].setValue(this.user.lastName);

    this.loadAuthTypes();
  }

  public changeGawOidcAuthType(value: boolean) {
    this.gawOidcAuthType = value;
    if (!this.isUserHasOidc) {
      if (!value) {
        this.form.controls['password'].disable();
        this.form.controls['nickName'].disable();
      } else {
        this.form.controls['password'].enable();
        this.form.controls['nickName'].enable();
      }
    }
  }
  public changeGoogleAuthType(value: boolean) {
    this.googleAuthType = value;
  }
  public isCheckedAuth() {
    this.userIdentityTypes.forEach((e) => {
      if (e.identityType === AuthenticationType.Oidc) {
        this.gawOidcAuthType = e.state === UserOrganizationState.Active ? true : false;
      }
      if (e.identityType === AuthenticationType.Google) {
        this.googleAuthType = e.state === UserOrganizationState.Active ? true : false;
      }
    });
  }

  public async loadAuthTypes(): Promise<void> {
    this.failedSettingsLoading = false;
    this.settingsIsLoading = true;
    try {
      this.form.controls['password'].disable();
      this.form.controls['nickName'].disable();
      const response = await this.userOrganizationClient.getUserIdentityTypes(new BaseGetByIdRequest(this.user.userId));
      this.userIdentityTypes = response.userIdentity;
      this.isCheckedAuth();
      if (this.userIdentityTypes.some((e) => e.identityType === AuthenticationType.Oidc)) {
        this.isUserHasOidc = true;
        // const response = await this.userOrganizationClient.getIdentityOidcByUserIdentityId(new BaseGetByIdRequest(this.user.userId));
        this.form.controls['nickName'].setValue(this.user.oidcIdentity);
      }

      // this.initForm();
    } catch (e) {
      this.failedSettingsLoading = true;
      this.matchError.logError(e);
    } finally {
      this.settingsIsLoading = false;
    }
  }

  checkIsValid() {
    return !(this.googleAuthType || this.gawOidcAuthType);
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      let nickName = null;
      let password = null;
      if (this.gawOidcAuthType) {
        nickName = this.form.get('nickName').value;
        password = this.form.get('password').value;
      }
      const request = new UpdateUserRequest(
        this.user.userOrganizationId,
        this.user.userId,
        this.form.get('firstName').value,
        this.form.get('lastName').value,
        this.form.get('email').value,
        this.form.get('nickName').value,
        this.form.get('password').value,
        this.gawOidcAuthType ? UserOrganizationState.Active : UserOrganizationState.Disabled,
        this.googleAuthType ? UserOrganizationState.Active : UserOrganizationState.Disabled
      );
      try {
        await this.userOrganizationClient.updateUser(request);
        this.onUpdateOrganizationUser.emit();
        this.form.reset();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
