import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { GroupRequest } from '../contracts/group/group.request';
import { BaseGetCollectionByIdRequest } from '../contracts/common/base-get-collection-by-id.request';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class GroupClient {
  constructor(private apiRequest: ApiRequest) {}
  async getGroupsForOrganization(request: BaseGetCollectionByIdRequest): Promise<BaseGetCollectionResponse<string[]>> {
    return this.apiRequest.get<BaseGetCollectionResponse<string[]>>(
      `${API_URL}${ApiRoutes.group.getGroupsForOrganization}?${request.format()}`
    );
  }

  async createGroupForOrganization(request: GroupRequest): Promise<void> {
    return this.apiRequest.post<GroupRequest, void>(request, `${API_URL}${ApiRoutes.group.create}`);
  }

  async updateGroupForOrganization(request: GroupRequest): Promise<void> {
    return this.apiRequest.post<GroupRequest, void>(request, `${API_URL}${ApiRoutes.group.update}`);
  }

  async deleteGroupForOrganization(request: GroupRequest): Promise<void> {
    return this.apiRequest.post<GroupRequest, void>(request, `${API_URL}${ApiRoutes.group.delete}`);
  }
}
