import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutoSaveContactSource } from '../../../../../../sdk/contracts/mail-account/settings/auto-save-contact.source';
import { MailAccountSettingsClient } from '../../../../../../sdk/clients/mail-account-settings.client';
import { BaseGetByIdRequest } from '../../../../../../sdk/contracts/common/base-get-by-id.request';
import { MailAccountSettingsUpdateRequest } from '../../../../../../sdk/contracts/mail-account/settings/mail-account-settings.update-request';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatchError } from 'src/app/shared/error-matcher';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OrganizationClient } from 'src/app/sdk/clients/organization.client';
import { PignorState } from 'src/app/sdk/contracts/organization/pignor-type';
import { OrganizationSettingsClient } from 'src/app/sdk/clients/organization-settings.client';
import { ClaimClient } from 'src/app/sdk/clients/claim';
import { SamlClaims } from 'src/app/sdk/contracts/mail-account/settings/saml-claims.contract';
import { RoleType } from 'src/app/sdk/contracts/role/role-type';

@Component({
  selector: 'app-mail-account-settings-dialog',
  templateUrl: './mail-account-settings-dialog.component.html',
  styleUrls: ['./mail-account-settings-dialog.component.scss'],
})
export class MailAccountSettingsDialogComponent implements OnInit {
  @Input() public mailAccountId: number;
  @Input() public organizationId: number;
  @Output() public submitEventEmitter = new EventEmitter<{ isActive: boolean }>();
  @Output() public closeEventEmitter = new EventEmitter();
  public isLoading = false;
  public errorText: string;
  public pignor: boolean;
  public threadSleepForSend: number;
  public isSamlSupport: boolean;
  public samlClaims: SamlClaims[];
  public isShowPignor: boolean;
  public isShowAdvancedSettings: boolean;
  public settingsIsLoading = false;
  public cannotLoadSettings = false;
  public autoContactSavingSources = Object.keys(AutoSaveContactSource)
    .filter((k) => !(parseInt(k, 10) >= 0))
    .map((key) => ({ id: AutoSaveContactSource[key], name: key }));
  public settings: MailAccountSettingsUpdateRequest;
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public mailAccountSamlClaimsForm: UntypedFormGroup = new UntypedFormGroup({});
  public pickerValue: string = '';
  public notificationEmails: string[] = [];
  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    private mailAccountSettingsClient: MailAccountSettingsClient,
    private organizationSettingsClient: OrganizationSettingsClient,
    private organizationClient: OrganizationClient,
    public matchError: MatchError,
    public claimClient: ClaimClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadSettings();

    this.form = new UntypedFormGroup({
      downloadCron: new UntypedFormControl(+this.settings.downloadCron[2], [
        Validators.required,
        Validators.min(1),
        Validators.max(30),
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(50),
      ]),
      validationHours: new UntypedFormControl(this.settings.validationHours, [
        Validators.pattern('^[0-9]*$'),
        Validators.required,
        Validators.min(1),
      ]),
      downloadBatchNumber: new UntypedFormControl(this.settings.downloadBatchNumber, [
        Validators.pattern('^[0-9]*$'),
        Validators.required,
        Validators.min(1),
      ]),
      validateStart: new UntypedFormControl(this.settings.validateStart, []),
      code: new UntypedFormControl(this.settings.code, [Validators.maxLength(256)]),
      pignorPath: new UntypedFormControl(this.settings.pignorPath, [Validators.maxLength(256)]),
    });

    this.mailAccountSamlClaimsForm = new UntypedFormGroup({
      userRoleClaim: new UntypedFormControl(this.samlClaims ? this.samlClaims?.find((e) => e?.role === RoleType.User)?.claimCode : '', [
        Validators.maxLength(256),
        Validators.required,
      ]),
      superuserRoleClaim: new UntypedFormControl(
        this.samlClaims ? this.samlClaims?.find((e) => e?.role === RoleType.SuperUser)?.claimCode : '',
        [Validators.maxLength(256), Validators.required]
      ),
      creatorRoleClaim: new UntypedFormControl(
        this.samlClaims ? this.samlClaims?.find((e) => e?.role === RoleType.Creator)?.claimCode : '',
        [Validators.maxLength(256), Validators.required]
      ),
      validatorRoleClaim: new UntypedFormControl(
        this.samlClaims ? this.samlClaims?.find((e) => e?.role === RoleType.Validator)?.claimCode : '',
        [Validators.maxLength(256), Validators.required]
      ),
      limitedUserRoleClaim: new UntypedFormControl(
        this.samlClaims ? this.samlClaims?.find((e) => e?.role === RoleType.LimitedUser)?.claimCode : '',
        [Validators.maxLength(256), Validators.required]
      ),
      limitedCreatorRoleClaim: new UntypedFormControl(
        this.samlClaims ? this.samlClaims?.find((e) => e?.role === RoleType.LimitedCreator)?.claimCode : '',
        [Validators.maxLength(256), Validators.required]
      ),
    });

    // this.form.get('validateStart').valueChanges.subscribe((selectedValue) => {
    //   if (selectedValue) this.settings.validation = true;
    // });
    this.setCodeValidation();
    this.setValidation();
  }

  public setCodeValidation() {
    if (this.settings.archive) {
      this.form.controls['code'].setValidators([Validators.required, Validators.maxLength(256)]);
    } else {
      this.form.controls['code'].setValidators([Validators.maxLength(256)]);
    }
    this.form.controls['code'].markAsTouched();
    this.form.controls['code'].updateValueAndValidity();
  }

  public setValidation() {
    if (this.settings.validation) {
      this.form.controls['validateStart'].setValidators([Validators.required]);
    } else {
      this.form.controls['validateStart'].setValidators([]);
    }
    this.form.controls['validateStart'].markAsTouched();
    this.form.controls['validateStart'].updateValueAndValidity();
  }

  async loadSettings(): Promise<any> {
    this.settingsIsLoading = true;
    this.cannotLoadSettings = false;
    try {
      const organizationSettingsResponse = await this.organizationSettingsClient.getByOrganization(
        new BaseGetByIdRequest(this.organizationId)
      );
      this.isSamlSupport = organizationSettingsResponse.result.samlSupport;
      if (this.isSamlSupport) {
        const responseClaim = await this.claimClient.getByIdForAccount(new BaseGetByIdRequest(this.mailAccountId));
        this.samlClaims = responseClaim.data;
      }

      const organizationResponse = await this.organizationClient.getById(new BaseGetByIdRequest(this.organizationId));
      this.isShowPignor = organizationResponse.organization.pignorState === PignorState.Active ? true : false;
      this.isShowAdvancedSettings = !!localStorage.getItem('showAdvancedSettings');
      const response = await this.mailAccountSettingsClient.getByMailAccount(new BaseGetByIdRequest(this.mailAccountId));
      this.pignor = response.result.pignorState === PignorState.Active ? true : false;
      this.threadSleepForSend = response.result.threadSleepForSend;
      this.settings = response.result;
      if (response.result.addressesForSend?.addressesForSend) {
        this.notificationEmails = response.result.addressesForSend.addressesForSend;
      }
    } catch (e) {
      this.cannotLoadSettings = true;
      this.matchError.logError(e);
    } finally {
      this.settingsIsLoading = false;
    }
  }

  public isValidTag(value: string) {
    return !!String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  isAllValid() {
    if (!this.settings.sendNotifyOfValidation) {
      return true;
    }
    return this.notificationEmails.every((e) => this.isValidTag(e)) && this.notificationEmails.length > 0;
  }

  public setNotificationEmails(emails: string[]) {
    this.notificationEmails = emails;
  }

  checkValidation() {
    if (this.settings.validation && !this.form.controls.validateStart.value) {
      this.form.patchValue({ validateStart: new Date() });
    }
    this.setValidation();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  public hasErrorSamlForm = (controlName: string, errorName: string) => {
    return this.mailAccountSamlClaimsForm.controls[controlName].hasError(errorName);
  };

  async onSubmit(): Promise<any> {
    if (this.form.valid) {
      this.isLoading = true;

      // this.settings.downloadCron = this.form.controls.downloadCron.value;
      this.settings.downloadCron = `*/${this.form.controls.downloadCron.value} * * * *`;
      this.settings.validationHours = this.form.controls.validationHours.value;
      this.settings.downloadBatchNumber = this.form.controls.downloadBatchNumber.value;
      this.settings.validateStart = this.form.controls.validateStart.value;
      this.settings.mailAccountId = this.mailAccountId;
      this.settings.code = this.form.controls.code.value || null;
      this.settings.pignorState = this.pignor ? PignorState.Active : PignorState.Disabled;
      this.settings.pignorPath = this.form.controls.pignorPath.value || null;
      this.settings.threadSleepForSend = this.threadSleepForSend;
      // this.settings.mailAccountSamlClaims = [this.form.controls['mailAccountSamlClaims'].value];

      if (this.notificationEmails.length) {
        let addressesForSend = { addressesForSend: this.notificationEmails };
        this.settings.addressesForSend = addressesForSend;
      } else {
        this.settings.addressesForSend = null;
      }
      let isAllValid = this.notificationEmails.every((e) => this.isValidTag(e));
      if (!isAllValid) {
        return;
      }

      try {
        if (this.isSamlSupport) {
          const roles = [
            RoleType.User,
            RoleType.SuperUser,
            RoleType.Creator,
            RoleType.Validator,
            RoleType.LimitedUser,
            RoleType.LimitedCreator,
          ];
          const rolesToFormControls = {
            userRoleClaim: RoleType.User,
            superuserRoleClaim: RoleType.SuperUser,
            creatorRoleClaim: RoleType.Creator,
            validatorRoleClaim: RoleType.Validator,
            limitedUserRoleClaim: RoleType.LimitedUser,
            limitedCreatorRoleClaim: RoleType.LimitedCreator,
          };
          const samlClaims = [];
          roles.forEach((element) => {
            const updateRequestSamlClaims = new SamlClaims();
            updateRequestSamlClaims.claimId = this.samlClaims?.find((e) => e.role === element)?.claimId || null;
            updateRequestSamlClaims.claimCode =
              (element === RoleType.User && this.mailAccountSamlClaimsForm.controls.userRoleClaim.value.replace(/ /g, '')) ||
              (element === RoleType.SuperUser && this.mailAccountSamlClaimsForm.controls.superuserRoleClaim.value.replace(/ /g, '')) ||
              (element === RoleType.Creator && this.mailAccountSamlClaimsForm.controls.creatorRoleClaim.value.replace(/ /g, '')) ||
              (element === RoleType.Validator && this.mailAccountSamlClaimsForm.controls.validatorRoleClaim.value.replace(/ /g, '')) ||
              (element === RoleType.LimitedUser && this.mailAccountSamlClaimsForm.controls.limitedUserRoleClaim.value.replace(/ /g, '')) ||
              (element === RoleType.LimitedCreator &&
                this.mailAccountSamlClaimsForm.controls.limitedCreatorRoleClaim.value.replace(/ /g, '')) ||
              null;
            updateRequestSamlClaims.organizationId = this.organizationId;
            updateRequestSamlClaims.mailAccountId = this.mailAccountId;
            updateRequestSamlClaims.role = element;
            samlClaims.push(updateRequestSamlClaims);
          });
          await this.claimClient.createOrUpdate({
            claims: samlClaims,
            mailAccountId: this.mailAccountId,
          });
        }
        await this.mailAccountSettingsClient.update(this.settings);
        const isActive = this.settings.active;
        this.submitEventEmitter.emit({ isActive });
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
