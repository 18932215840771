import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { FormsModule } from '@angular/forms';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { SettingsComponent } from './components/settings/settings.component';
import { ProviderComponent } from './components/settings/provider/provider.component';
import { ProviderCreateDialogComponent } from './components/settings/provider/provider-create-dialog/provider-create-dialog.component';
import { ProviderUpdateDialogComponent } from './components/settings/provider/provider-update-dialog/provider-update-dialog.component';
import { OrganizationComponent } from './components/settings/organization/organization.component';
import { OrganizationCreateDialogComponent } from './components/settings/organization/organization-create-dialog/organization-create-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MailAccountComponent } from './components/settings/organization/mail-account/mail-account.component';
import { OrganizationClient } from './sdk/clients/organization.client';
import { ProviderClient } from './sdk/clients/provider.client';
import { GlobalHttpInterceptorService } from './services/http/global-http-interceptor.service';
import { LoggingClient } from './sdk/clients/logging.client';
import { GlobalErrorHandler } from './services/errors/error-handler.service';
import { SignalRService } from './services/signalR/signalR.service';
import { JwtInterceptor } from './services/auth/jwt.interceptor';
import { RoutingState } from './services/routing/routing.state';
import { OrganizationUsersComponent } from './components/settings/organization/organization-users/organization-users.component';
import { MailAccountClient } from './sdk/clients/mail-account.client';
import { MailAccountUsersComponent } from './components/settings/organization/mail-account/mail-account-users/mail-account-users.component';
import { MailAccountUsersAddComponent } from './components/settings/organization/mail-account/mail-account-users/mail-account-users-add/mail-account-users-add.component';
import { MailAccountUsersEditComponent } from './components/settings/organization/mail-account/mail-account-users/mail-account-users-edit/mail-account-users-edit.component';
import { MailFolderComponent } from './components/mail-folder/mail-folder.component';
import { MatTreeModule } from '@angular/material/tree';
import { environment } from '../environments/environment';
import { DispatchingRuleComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-rule.component';
import { MoveToFolderActionComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-actions/move-to-folder-action/move-to-folder-action.component';
import { ReassignActionComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-actions/reassign-action/reassign-action.component';
import { EmailClient } from './sdk/clients/email-client';
import { AttachmentClient } from './sdk/clients/attachment.client';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { EmailStatePipe } from './components/email/email-state/email-state-pipe';
import { EmailTypePipe } from './components/email/email-type/email-type-pipe';
import { EmailNoteListComponent } from './components/email/email-note/email-note-list/email-note-list.component';
import { EmailMetaComponent } from './components/email/email-meta/email-meta.component';
import { EmailTagsComponent } from './components/email/email-tags/email-tags.component';
import { EmailTemplatesComponent } from './components/email/email-templates/email-templates.component';
import { EmailNoteClient } from './sdk/clients/email-note.client';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MailAccountSettingsClient } from './sdk/clients/mail-account-settings.client';
import { MailFolderSelectDialogComponent } from './components/mail-account-folders/mail-folder-select-dialog/mail-folder-select-dialog.component';
import { ReassignDialogComponent } from './components/reassign-dialog/reassign-dialog.component';
import { MailFolderService } from './services/mail-folder/mail.folder.service';
import { MailAccountService } from './services/mail-account/mail.account.service';
import { OrganizationSettingsClient } from './sdk/clients/organization-settings.client';
import { MailFolderSettingsClient } from './sdk/clients/mail-folder-settings.client';
import { ContactClient } from './sdk/clients/contact.client';
import { ContactGroupClient } from './sdk/clients/contact-group.client';
import { SelectContactGroupDialogComponent } from './components/select-contact-group-dialog/select-contact-group-dialog.component';
import { EmailExportClient } from './sdk/clients/email-export-client';
import { EmailAuditClient } from './sdk/clients/email-audit-client';
import { EmailAuditComponent } from './components/email/email-audit/email-audit.component';
import { EmailAuditEventTypePipe } from './components/email/email-audit/email-audit-event-type.pipe';
import { FileRefComponent } from './components/file-ref/file-ref.component';
import { ForwardActionComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-actions/forward-action/forward-action.component';
import { AssignGroupsActionComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-actions/assign-to-group-action/assign-to-group-action.component';
import { PermissionClient } from './sdk/clients/permission.client';
import { TextInputDialogComponent } from './components/text-input-dialog/text-input-dialog.component';
import { EmailPrintComponent } from './components/email/email-print/email-print.component';
import { MatBadgeModule } from '@angular/material/badge';
import { TagClient } from './sdk/clients/tag.client';
import { TemplateClient } from './sdk/clients/template.client';
import { EmailGroupClient } from './sdk/clients/email-group.client';
import { GroupClient } from './sdk/clients/group.client';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { TagsComponent } from './components/tags/tags.component';
import { TagsSelectComponent } from './components/tags/tags-select/tags-select.component';
import { SearchWidgetsComponent } from './components/search-widgets/search-widgets.component';
import { SearchFilterPipe } from './components/search-widgets/search-filter.pipe';
import { EnumPipe } from './shared/helpers/enums/enum-pipe';
import { EmailNotificationsDialogComponent } from './components/email/email-notifications-dialog/email-notifications-dialog.component';
import { EmailsComponent } from './components/emails/emails.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RouterModule } from '@angular/router';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { EmailsContentComponent } from './components/emails-content/emails-content.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { EmailsSideNavComponent } from './components/emails-sidenav/emails-side-nav.component';
import { MainComponent } from './components/main/main.component';
import { ImportSessionClient } from './sdk/clients/import-session.client';
import { SearchFilterButtonsComponent } from './components/search-filter-buttons/search-filter-buttons.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { Error403PageComponent } from './components/error/error403-page/error403-page.component';
import { Error401PageComponent } from './components/error/error401-page/error401-page.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import {
  MatLegacyPaginatorIntl as MatPaginatorIntl,
  MatLegacyPaginatorModule as MatPaginatorModule,
} from '@angular/material/legacy-paginator';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SomethingWentWrongComponent } from './components/error/something-went-wrong/something-went-wrong.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { LoadingButtonComponent } from './components/buttons/loading-button/loading-button.component';
import { LoadingIconButtonComponent } from './components/buttons/loading-icon-button/loading-icon-button.component';
import { NavigationTabsComponent } from './components/navigation-tabs/navigation-tabs.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { ProviderDeleteDialogComponent } from './components/settings/provider/provider-delete-dialog/provider-delete-dialog.component';
import { OrganizationGeneralSettingsComponent } from './components/settings/organization/organization-general-settings/organization-general-settings.component';
import { ErrorHintComponent } from './components/error/error-hint/error-hint.component';
import { OrganizationDeleteDialogComponent } from './components/settings/organization/organization-delete-dialog/organization-delete-dialog.component';
import { OrganizationUpdateDialogComponent } from './components/settings/organization/organization-general-settings/organization-update-dialog/organization-update-dialog.component';
import { OrganizationSettingsDialogComponent } from './components/settings/organization/organization-general-settings/organization-settings-dialog/organization-settings-dialog.component';
import { MailAccountCreateDialogComponent } from './components/settings/organization/mail-account/mail-account-create-dialog/mail-account-create-dialog.component';
import { MailAccountDeleteDialogComponent } from './components/settings/organization/mail-account/mail-account-delete-dialog/mail-account-delete-dialog.component';
import { MailAccountGeneralSettingsComponent } from './components/settings/organization/mail-account/mail-account-general-settings/mail-account-general-settings.component';
import { OrganizationUserCreateDialogComponent } from './components/settings/organization/organization-users/organization-user-create-dialog/organization-user-create-dialog.component';
import { OrganizationUserUpdateDialogComponent } from './components/settings/organization/organization-users/organization-user-update-dialog/organization-user-update-dialog.component';
import { OrganizationUserDeleteDialogComponent } from './components/settings/organization/organization-users/organization-user-delete-dialog/organization-user-delete-dialog.component';
import { MailAccountUsersDeleteDialogComponent } from './components/settings/organization/mail-account/mail-account-users/mail-account-users-delete-dialog/mail-account-users-delete-dialog.component';
import { MailAccountUpdateDialogComponent } from './components/settings/organization/mail-account/mail-account-general-settings/mail-account-update-dialog/mail-account-update-dialog.component';
import { MailAccountSettingsDialogComponent } from './components/settings/organization/mail-account/mail-account-general-settings/mail-account-settings-dialog/mail-account-settings-dialog.component';
import { MailAccountChangePasswordDialogComponent } from './components/settings/organization/mail-account/mail-account-general-settings/password-dialog/password-dialog.component';
import { DispatchingRuleDeleteDialogComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-rule-delete-dialog/dispatching-rule-delete-dialog.component';
import { DispatchingRuleCreateOrUpdateDialogComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-rule-create-or-update-dialog/dispatching-rule-create-or-update-dialog.component';
import { DispatchingFoldersComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-folders/dispatching-folders.component';
import { DispatchingConditionsComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-conditions/dispatching-conditions.component';
import { DispatchingActionsComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-actions/dispatching-actions.component';
import { DispatchingConditionComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-conditions/dispatching-condition/dispatching-condition.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { AccountUpdateDialogComponent } from './components/account-settings/account-update-dialog/account-update-dialog.component';
import { ChangePasswordDialogComponent } from './components/account-settings/change-password-dialog/change-password-dialog.component';
import { MailAccountQuickSetupDialogComponent } from './components/settings/organization/mail-account/mail-account-general-settings/mail-account-quick-setup-dialog/mail-account-quick-setup-dialog.component';
import { MailAccountTagsComponent } from './components/settings/organization/mail-account/mail-account-tags/mail-account-tags.component';
import { MailAccountTagCreateDialogComponent } from './components/settings/organization/mail-account/mail-account-tags/mail-account-tag-create-dialog/mail-account-tag-create-dialog.component';
import { MailAccountTemplateCreateDialogComponent } from './components/settings/organization/mail-account/mail-account-templates/mail-account-template-create-dialog/mail-account-template-create-dialog.component';
import { MailAccountTemplateEditDialogComponent } from './components/settings/organization/mail-account/mail-account-templates/mail-account-template-edit-dialog/mail-account-template-edit-dialog.component';
import { MailAccountTemplatesComponent } from './components/settings/organization/mail-account/mail-account-templates/mail-account-templates.component';
import { MailAccountTagDeleteDialogComponent } from './components/settings/organization/mail-account/mail-account-tags/mail-account-tag-delete-dialog/mail-account-tag-delete-dialog.component';
import { EmailTagClient } from './sdk/clients/email-tag.client';
import { PluginsClient } from './sdk/clients/plugins.client';
import { MatchError } from './shared/error-matcher';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { WebCodeContextComponent } from './components/web-code-context/web-code-context.component';
import { DefaultPageComponent } from './components/default-page/default-page.component';
import { NotFoundErrorPageComponent } from './components/error/not-found-error-page/not-found-error-page.component';
import { InternalErrorPageComponent } from './components/error/internal-error-page/internal-error-page.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { LoadingRowComponent } from './components/loading-row/loading-row.component';
import { DisableDirective } from './shared/disable-directive';
import { ErrorDialogComponent } from './components/error/error-dialog/error-dialog.component';
import { EmailCreateOrUpdateComponent } from './components/email/email-create-or-update/email-create-or-update.component';
import { MailAccountFoldersSettingsComponent } from './components/settings/organization/mail-account/mail-account-folders-settings/mail-account-folders-settings.component';
import { MailAccountFoldersComponent } from './components/mail-account-folders/mail-account-folders.component';
import { MailFolderCreateDialogComponent } from './components/mail-account-folders/mail-folder-create-dialog/mail-folder-create-dialog.component';
import { MailFolderRenameDialogComponent } from './components/mail-account-folders/mail-folder-rename-dialog/mail-folder-rename-dialog.component';
import { MailFolderDeleteDialogComponent } from './components/mail-account-folders/mail-folder-delete-dialog/mail-folder-delete-dialog.component';
import { MailFolderSettingsDialogComponent } from './components/mail-account-folders/mail-folder-settings-dialog/mail-folder-settings-dialog.component';
import { EmailDetailDialogComponent } from './components/email/email-detail-dialog/email-detail-dialog.component';
import { EmailSearchInputComponent } from './components/email-search-input/email-search-input.component';
import { EmailSearchAdvancedDialogComponent } from './components/email-search-advanced-dialog/email-search-advanced-dialog.component';
import { EmailSearchTagInputComponent } from './components/email-search-advanced-dialog/email-search-tag-input/email-search-tag-input.component';
import { EmailsContainerComponent } from './components/emails-container/emails-container.component';
import { ReportsComponent } from './components/reports/reports.component';
import { PluginsComponent } from './components/plugins/plugins.component';
import { EmailAddTagsDialogComponent } from './components/email/email-add-tags-dialog/email-add-tags-dialog.component';
import { OrganizationGroupsComponent } from './components/settings/organization/organization-groups/organization-groups.component';
import { OrganizationGroupCreateDialogComponent } from './components/settings/organization/organization-groups/organization-group-create-dialog/organization-group-create-dialog.component';
import { OrganizationGroupUpdateDialogComponent } from './components/settings/organization/organization-groups/organization-group-update-dialog/organization-group-update-dialog.component';
import { AddUsersToGroupsFormComponent } from './components/settings/organization/organization-users/add-users-to-groups-form/add-users-to-groups-form.component';
import { AddGroupsToUsersFormComponent } from './components/settings/organization/organization-groups/add-groups-to-users-form/add-groups-to-users-form.component';
import { FilterPipe } from './shared/pipes/filter.pipe';
import { ErrorsPipe } from './shared/pipes/error-code.pipe';
import { FormatSearchOrders } from './shared/pipes/format-search-orders.pipe';
import { FormatEmailIconsByFolderType } from './shared/pipes/format-email-icons-by-folder-type.pipe';
import { EmailAddGroupsDialogComponent } from './components/email/email-add-groups-dialog/email-add-groups-dialog.component';
import { EmailContactsDialogComponent } from './components/email/email-contacts-dialog/email-contacts-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AddUsersToMailAccountsFormComponent } from './components/settings/organization/organization-users/add-users-to-mail-accounts-form/add-users-to-mail-accounts-form.component';
import { AddMailAccountsToUsersFormComponent } from './components/settings/organization/mail-account/add-mail-accounts-to-users-form/add-mail-accounts-to-users-form.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MyMatPaginatorIntl } from './translation/paginator-intl';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS } from './translation/custom-date-formats';
import { ClaimClient } from './sdk/clients/claim';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { WafPageComponent } from './components/waf-page/waf-page.component';
import { TestErrorsPageComponent } from './components/error/test-errors-page/test-errors-page.component';
import { FormatMailAccountSearchOrders } from './shared/pipes/format-mail-account-search-orders.pipe';
import { FormatAccountLanguage } from './shared/pipes/format-account-language.pipe';
import { ConfirmFinalDeletionDialogComponent } from './components/final-deletion-confirm-dialog/final-deletion-confirm-dialog.component';
import { LoggingHandler } from './services/logging/logging.service';

@NgModule({
  declarations: [
    AppComponent,
    SettingsComponent,
    ProviderComponent,
    ProviderCreateDialogComponent,
    ProviderUpdateDialogComponent,
    OrganizationComponent,
    OrganizationCreateDialogComponent,
    OrganizationUpdateDialogComponent,
    LoaderComponent,
    MailAccountComponent,
    OrganizationUsersComponent,
    MailAccountUsersComponent,
    MailAccountUsersAddComponent,
    MailAccountUsersEditComponent,
    EnumPipe,
    SearchFilterPipe,
    EmailAuditEventTypePipe,
    EmailStatePipe,
    EmailTypePipe,
    MailFolderComponent,
    DispatchingRuleComponent,
    MoveToFolderActionComponent,
    ReassignActionComponent,
    EmailNoteListComponent,
    EmailMetaComponent,
    EmailTagsComponent,
    EmailTemplatesComponent,
    MailFolderSelectDialogComponent,
    ReassignDialogComponent,
    SelectContactGroupDialogComponent,
    EmailAuditComponent,
    EmailAuditEventTypePipe,
    FileRefComponent,
    ForwardActionComponent,
    AssignGroupsActionComponent,
    TextInputDialogComponent,
    EmailPrintComponent,
    TagsComponent,
    TagsSelectComponent,
    MailAccountFoldersSettingsComponent,
    SearchWidgetsComponent,
    EmailNotificationsDialogComponent,
    EmailsComponent,
    EmailsContentComponent,
    MainHeaderComponent,
    EmailsSideNavComponent,
    MainComponent,
    SearchFilterButtonsComponent,
    TestErrorsPageComponent,
    Error403PageComponent,
    Error401PageComponent,
    SearchBarComponent,
    SomethingWentWrongComponent,
    BreadcrumbComponent,
    LoadingButtonComponent,
    LoadingIconButtonComponent,
    NavigationTabsComponent,
    DrawerComponent,
    AccountSettingsComponent,
    ProviderDeleteDialogComponent,
    OrganizationGeneralSettingsComponent,
    ErrorHintComponent,
    OrganizationDeleteDialogComponent,
    OrganizationSettingsDialogComponent,
    MailAccountCreateDialogComponent,
    MailAccountDeleteDialogComponent,
    MailAccountGeneralSettingsComponent,
    OrganizationUserCreateDialogComponent,
    OrganizationUserUpdateDialogComponent,
    OrganizationUserDeleteDialogComponent,
    MailAccountUsersDeleteDialogComponent,
    MailAccountUpdateDialogComponent,
    MailAccountSettingsDialogComponent,
    MailAccountChangePasswordDialogComponent,
    DispatchingRuleDeleteDialogComponent,
    DispatchingRuleCreateOrUpdateDialogComponent,
    DispatchingFoldersComponent,
    DispatchingConditionsComponent,
    DispatchingActionsComponent,
    DispatchingConditionComponent,
    NoDataComponent,
    AccountUpdateDialogComponent,
    ChangePasswordDialogComponent,
    MailAccountTagsComponent,
    MailAccountQuickSetupDialogComponent,
    MailAccountTagCreateDialogComponent,
    MailAccountTemplateCreateDialogComponent,
    MailAccountTemplateEditDialogComponent,
    MailAccountTemplatesComponent,
    MailAccountTagDeleteDialogComponent,
    SignInComponent,
    WebCodeContextComponent,
    DefaultPageComponent,
    NotFoundErrorPageComponent,
    InternalErrorPageComponent,
    WafPageComponent,
    PaginatorComponent,
    LoadingRowComponent,
    DisableDirective,
    ErrorDialogComponent,
    EmailCreateOrUpdateComponent,
    MailAccountFoldersComponent,
    MailFolderCreateDialogComponent,
    MailFolderRenameDialogComponent,
    MailFolderDeleteDialogComponent,
    MailFolderSettingsDialogComponent,
    EmailDetailDialogComponent,
    EmailSearchInputComponent,
    EmailSearchAdvancedDialogComponent,
    EmailSearchTagInputComponent,
    EmailsContainerComponent,
    ReportsComponent,
    PluginsComponent,
    EmailAddTagsDialogComponent,
    OrganizationGroupsComponent,
    OrganizationGroupCreateDialogComponent,
    OrganizationGroupUpdateDialogComponent,
    AddUsersToGroupsFormComponent,
    AddGroupsToUsersFormComponent,
    AddUsersToMailAccountsFormComponent,
    AddMailAccountsToUsersFormComponent,
    FilterPipe,
    ErrorsPipe,
    FormatAccountLanguage,
    FormatSearchOrders,
    FormatEmailIconsByFolderType,
    EmailContactsDialogComponent,
    ConfirmDialogComponent,
    ConfirmFinalDeletionDialogComponent,
    InfoDialogComponent,
    EmailAddGroupsDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    MatSelectModule,
    MatProgressBarModule,
    MatTreeModule,
    MatChipsModule,
    MatCheckboxModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatCardModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatRadioModule,
    MatTabsModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatTooltipModule,
    CKEditorModule,
    MatSlideToggleModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.language,
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    ContactClient,
    ContactGroupClient,
    ClaimClient,
    ProviderClient,
    SignalRService,
    OrganizationClient,
    OrganizationSettingsClient,
    LoggingClient,
    PermissionClient,
    MailAccountClient,
    MailAccountSettingsClient,
    EmailAuditClient,
    EmailClient,
    AttachmentClient,
    EmailTagClient,
    PluginsClient,
    MatchError,
    GlobalErrorHandler,
    LoggingHandler,
    ErrorsPipe,
    FormatAccountLanguage,
    FormatSearchOrders,
    FormatMailAccountSearchOrders,
    FormatEmailIconsByFolderType,
    EmailExportClient,
    EmailNoteClient,
    RoutingState,
    MailFolderService,
    MailFolderSettingsClient,
    MailAccountService,
    TagClient,
    TemplateClient,
    EmailGroupClient,
    GroupClient,
    ImportSessionClient,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: localStorage.getItem('userLocale') },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MatPaginatorIntl, useClass: MyMatPaginatorIntl },
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
