<div class="drawer-content-screen flex flex-col justify-between">
  <div class="w-full h-full" *ngIf="isLoading">
    <app-loader height="100%"></app-loader>
  </div>

  <div class="w-full h-full" *ngIf="cannotLoadNotifications">
    <app-something-went-wrong
      description="Cannot load notifications"
      [showPicture]="false"
      (retryEmitter)="loadNotifications()"></app-something-went-wrong>
  </div>
  <div *ngIf="title && !isLoading && !cannotLoadNotifications" class="text-2xl mb-10 font-medium">
    {{ title | translate }}
  </div>
  <div *ngIf="!isLoading && !cannotLoadNotifications" class="notifications">
    <div class="w-full" *ngIf="notifications.length === 0">
      <app-no-data></app-no-data>
    </div>
    <div class="notification" *ngFor="let notification of notifications">
      <mat-card [class.row-error]="isRowWithError(notification)" class="cursor-pointer" (click)="emailDetails(notification)">
        <mat-card-title class="break-words">{{ notification.from }}</mat-card-title>
        <mat-card-subtitle class="break-words" *ngIf="notification.subject">{{ notification.subject }}</mat-card-subtitle>
        <mat-card-content class="break-words">
          <div class="card-table-row">
            <div class="card-table-row-header">{{ "date" | translate }}</div>
            <div class="card-table-row-value">{{ notification.sentDateTime | date: "dd/MM/yyyy HH:mm" }}</div>
          </div>
          <div class="card-table-row">
            <div class="card-table-row-header">{{ "state" | translate }}</div>
            <div class="card-table-row-value">{{ notification.type | emailType | translate }}</div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
