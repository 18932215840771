import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../api.request';
import { UserOrganizationGetUserOrganizationsResponse } from '../contracts/user-organization/user-organization.get-user-organizations.response';
import { GetAuthenticationTypesRequest } from '../contracts/authentication/get-authentication-types.request';

const API_URL = environment.feUrl;
@Injectable({
  providedIn: 'root',
})
export class FeClient {
  private isActive = false;
  private webCode = false;

  constructor(private apiRequest: ApiRequest) {}

  setIsActive(value): void {
    this.isActive = value;
  }

  getIsActive(): boolean {
    return this.isActive;
  }

  setWebCode(value): void {
    this.webCode = value;
  }

  getWebCode(): boolean {
    return this.webCode;
  }

  async getUserOrganizations(request: GetAuthenticationTypesRequest): Promise<UserOrganizationGetUserOrganizationsResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.fe.getUserOrganizations}?${request.format()}`);
  }
}
