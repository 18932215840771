import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProviderContract } from '../../../../../sdk/contracts/provider/provider.contract';
import { ProviderClient } from '../../../../../sdk/clients/provider.client';
import { MailAccountCreateRequest } from '../../../../../sdk/contracts/mail-account/mail-account.create.request';
import { MailAccountClient } from '../../../../../sdk/clients/mail-account.client';
import { ErrorCode } from '../../../../../sdk/api-errors/error-code';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-mail-account-create-dialog',
  templateUrl: './mail-account-create-dialog.component.html',
  styleUrls: ['./mail-account-create-dialog.component.scss'],
})
export class MailAccountCreateDialogComponent implements OnInit {
  @Input() public organizationId: number;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public isLoading = false;
  public isError = false;
  public errorText: string;
  public errorCode: ErrorCode | null = null;
  public handlingErrorCodes = new Map<number, string>([
    [ErrorCode.ImapCantConnectToServer, 'Cannot create mail account: failed connection to server'],
    [ErrorCode.ImapWrongPassword, 'Cannot create  mail account: wrong password'],
    [ErrorCode.MailAccountWithEmailExist, 'Cannot create  mail account: mail account with this email already exists'],
  ]);

  public form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.maxLength(256)]),
    codeSia: new UntypedFormControl('', [Validators.maxLength(255)]),
    anagId: new UntypedFormControl('', [Validators.maxLength(255)]),
    license: new UntypedFormControl('', [Validators.maxLength(255)]),
    email: new UntypedFormControl('', [Validators.required, Validators.maxLength(256), Validators.email]),
    password: new UntypedFormControl('', [Validators.required, Validators.maxLength(256)]),
    provider: new UntypedFormControl(''),
  });
  public providersIsLoading = false;
  public cannotLoadProviders = false;
  public providers: ProviderContract[] = [];

  constructor(
    private providerClient: ProviderClient,
    public matchError: MatchError,
    private mailAccountClient: MailAccountClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadProviders();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async loadProviders(): Promise<void> {
    this.providersIsLoading = true;
    this.cannotLoadProviders = false;
    try {
      const response = await this.providerClient.getAll();
      this.providers = response.providerContracts;
    } catch (e) {
      this.cannotLoadProviders = true;
      this.matchError.logError(e);
    } finally {
      this.providersIsLoading = false;
    }
  }

  async onSubmit(mailAccountRequest: MailAccountCreateRequest): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.isError = false;
      this.errorCode = null;
      try {
        mailAccountRequest.organizationId = this.organizationId;
        mailAccountRequest.providerId = this.form.get('provider').value;
        await this.mailAccountClient.create(mailAccountRequest);
        this.creationSubmit.emit();
      } catch (e) {
        this.isError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
