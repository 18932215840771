<div class="w-full h-full" *ngIf="isLoading">
  <app-loader height="100%"></app-loader>
</div>
<div class="w-full h-full" *ngIf="isError">
  <app-something-went-wrong
    [showPicture]="false"
    description="{{ 'cannotLoadData' | translate }}"
    (retryEmitter)="loadTemplates()"></app-something-went-wrong>
</div>
<div *ngIf="!isLoading && !isError" class="notes pr-2" [style.cursor]="'default'">
  <div class="mb-3">
    <app-search-bar
      [fullWidth]="true"
      [isDynamicSearch]="true"
      (searchEventEmitter)="searchTemplates()"
      (valueChangedEventEmitter)="onSearchValueChanged($event)"
      [search]="searchValue"></app-search-bar>
  </div>
  <div *ngFor="let template of searchedTemplates" class="template-card mb-3">
    <div>
      <div [style.color]="!isAnyTemplateDownloading ? 'black' : 'grey'" class="p-1.5 font-medium">
        {{ template.name }}
      </div>
      <div *ngIf="template.description" [style.color]="!isAnyTemplateDownloading ? 'black' : 'grey'" class="p-1.5">
        {{ template.description }}
      </div>
    </div>
    <div class="p-1.5 flex justify-end">
      <button
        class="page-block-button"
        color="primary"
        mat-flat-button
        (click)="!isAnyTemplateDownloading && loadTemplate(template.templateId)"
        [disabled]="isAnyTemplateDownloading">
        <app-loading-button
          text="{{ 'select' | translate }}"
          [loading]="downloadingTemplateId === template.templateId"></app-loading-button>
      </button>
    </div>
  </div>
</div>
