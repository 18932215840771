import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import { UserOrganizationClient } from '../../../../../sdk/clients/user-organization.client';
import { BaseDeleteRequest } from '../../../../../sdk/contracts/common/base-delete.request';

@Component({
  selector: 'app-organization-user-delete-dialog',
  templateUrl: './organization-user-delete-dialog.component.html',
  styleUrls: ['./organization-user-delete-dialog.component.scss'],
})
export class OrganizationUserDeleteDialogComponent implements OnInit {
  public isLoading = false;
  public hasError = false;
  public errorText: string;

  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    private userOrganizationClient: UserOrganizationClient,
    public dialogRef: MatDialogRef<OrganizationUserDeleteDialogComponent>,
    public dialog: MatDialog,
    public matchError: MatchError,
    @Inject(MAT_DIALOG_DATA) public data: { userOrganizationId: number; userName: string; organizationName: string }
  ) {}

  ngOnInit(): void {}

  onCancelBtnClicked(): void {
    this.dialogRef.close({ isDeleted: false });
  }

  async onDeleteBtnClicked(): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    const request = new BaseDeleteRequest(this.data.userOrganizationId);
    try {
      await this.userOrganizationClient.deleteUserFromOrganization(request);
      this.dialogRef.close({ isDeleted: true });
    } catch (e) {
      this.hasError = true;
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
