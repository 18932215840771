<div class="drawer-content-screen flex flex-col justify-between">
  <div class="w-full mb-2">
    <div class="text-2xl mb-10 font-medium">
      {{ "createTag" | translate }}
    </div>
    <div>
      <form [formGroup]="form" autocomplete="off" novalidate>
        <mat-form-field appearance="fill">
          <mat-label>{{ "tagLabel" | translate }}</mat-label>
          <input matInput id="tag" formControlName="tag" required />
          <mat-error *ngIf="hasError('tag', 'required')">{{ "tagIsRequired" | translate }}</mat-error>
          <mat-error *ngIf="hasError('tag', 'maxlength')">{{ "moreThan255Char" | translate }}</mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="w-full">
    <!-- <div *ngIf="unhandlingError" class="mb-3">
      <app-error-hint errorText="Cannot create tag: something went wrong"></app-error-hint>
    </div> -->
    <!-- <div *ngIf="alreadyExistsError" class="mb-3">
      <app-error-hint errorText="This tag already exists"></app-error-hint>
    </div> -->
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
