<div class="drawer-content-screen flex flex-col justify-between">
  <div *ngIf="!isLoadingGroups && !cannotLoadGroups" class="text-2xl mb-10 font-medium">
    {{ "add" | translate }} {{ users.length > 1 ? ("users" | translate) : ("user" | translate) }} {{ "toGroups" | translate }}
  </div>
  <div class="h-full w-full mb-2 overflow-auto">
    <div class="flex flex-col justify-center h-full" *ngIf="cannotLoadGroups">
      <app-something-went-wrong
        description="{{ 'cannotLoadGroups' | translate }}. {{ 'wentWrong' | translate }}"
        (retryEmitter)="fetchGroups()"
        [showPicture]="false"></app-something-went-wrong>
    </div>

    <div *ngIf="!isLoadingGroups && !cannotLoadGroups" class="filters">
      <mat-form-field appearance="fill" class="filters-search">
        <mat-label class="filters-search-label">{{ "search" | translate }}</mat-label>
        <input matInput [(ngModel)]="search" class="filters-search-input" />
        <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="search = ''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="fill" class="filters-select">
        <mat-label>{{ "filter" | translate }}</mat-label>
        <mat-select [formControl]="filters" [value]="filterList[0].value" (selectionChange)="onFilterSelectionChange()">
          <mat-option *ngFor="let filter of filterList" [value]="filter.value">{{ filter.label | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-loader class="flex flex-col justify-center h-full" *ngIf="isLoadingGroups"></app-loader>
    <div *ngIf="!isLoadingGroups && !cannotLoadGroups">
      <mat-checkbox
        color="primary"
        class="mb-2"
        [checked]="hasSelected() && isAllSelected()"
        [indeterminate]="hasSelected() && !isAllSelected()"
        (change)="$event ? masterToggle() : null">
        {{ "selectAll" | translate }}
      </mat-checkbox>
      <ul>
        <li *ngFor="let group of filteredGroups | filter: search:'group'">
          <mat-checkbox color="primary" class="mb-1" [(ngModel)]="group._checked">
            {{ group.group }}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="!isLoadingGroups && !cannotLoadGroups" class="w-full">
    <!-- <div *ngIf="cannotLoadGroups" class="mb-3">
      <app-error-hint [errorText]="errorText"></app-error-hint>
    </div> -->
    <div class="flex justify-end">
      <!-- <button (click)="onClose()" mat-flat-button>{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
