<div class="h-full flex flex-col justify-between">
  <div *ngIf="cannotLoadAvailableData" class="flex flex-col justify-center h-full">
    <app-something-went-wrong
      description="{{ 'cannotLoadData' | translate }}"
      [showPicture]="false"
      (retryEmitter)="ngOnInit()"></app-something-went-wrong>
  </div>
  <app-loader class="flex flex-col justify-center h-full" *ngIf="loadingAvailableData"></app-loader>

  <div *ngIf="!loadingAvailableData && !cannotLoadAvailableData">
    <div class="text-2xl mb-10 font-medium">{{ "createFolder" | translate }}</div>
    <div class="w-full mb-2 mt-5">
      <div>
        <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit(form.value)">
          <mat-form-field appearance="fill">
            <mat-label>{{ "name" | translate }}</mat-label>
            <input matInput id="name" formControlName="name" />
            <mat-error *ngIf="hasError('name', 'required')">{{ "nameIsRequired" | translate }}</mat-error>
            <mat-error *ngIf="hasError('name', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="(this.parentMailFolderType === 2 || this.parentMailFolderType === 3) && isSettings" appearance="fill">
            <mat-label>{{ "providerFolder" | translate }}</mat-label>
            <mat-select [(value)]="selectedProviderFolder">
              <mat-option *ngFor="let folder of providerFolders" [value]="folder">
                {{ folder }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <div class="w-full">
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit(form.value)">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
