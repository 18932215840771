import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TemplateClient } from '../../../../../../sdk/clients/template.client';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UpdateTemplateRequest } from '../../../../../../sdk/contracts/template/update-template.request';
import { TemplateGetByIdContract } from '../../../../../../sdk/contracts/template/template-get-by-id-contract.contract';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '../../../../../../../ckeditor';
import { ConfirmDialogComponent } from '../../../../../confirm-dialog/confirm-dialog.component';
import { BaseGetByIdRequest } from 'src/app/sdk/contracts/common/base-get-by-id.request';
import '../../../../../../translation/ckeditor-translation/it';
import '../../../../../../translation/ckeditor-translation/en-gb';

@Component({
  selector: 'app-mail-account-template-edit-dialog',
  templateUrl: './mail-account-template-edit-dialog.component.html',
  styleUrls: ['./mail-account-template-edit-dialog.component.scss'],
})
export class MailAccountTemplateEditDialogComponent implements OnInit {
  @Input() mailAccountId: number;
  @Input() templateId: number;
  @Output() submitEventEmitter = new EventEmitter();
  @Output() closeEventEmitter = new EventEmitter();

  @ViewChild('bodyText') bodyText: CKEditorComponent;
  public config = {
    language: localStorage.getItem('userLocale'),
  };
  public template: TemplateGetByIdContract;
  public isLoading = false;
  public isError = false;
  public errorText: string;
  public editor = ClassicEditor;
  public body = '';
  public isSendingRequest: boolean;

  public form = new UntypedFormGroup({
    templateName: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
    templateDesc: new UntypedFormControl('', [Validators.maxLength(255)]),
    templateSubject: new UntypedFormControl('', [Validators.maxLength(255)]),
  });

  constructor(private templateClient: TemplateClient, public dialog: MatDialog, public matchError: MatchError) {}

  ngOnInit(): void {
    this.loadTemplate();
  }

  public async loadTemplate(): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    const request = new BaseGetByIdRequest(this.templateId);
    try {
      const response = await this.templateClient.getById(request);
      this.template = response.template;
      this.form.get('templateName').setValue(this.template.name);
      this.form.get('templateDesc').setValue(this.template.description);
      this.form.get('templateSubject').setValue(this.template.subject);
      this.bodyText.editorInstance.setData(this.template.body);
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async onClickReset() {
    this.form.get('templateName')?.setValue('');
    this.form.get('templateDesc')?.setValue('');
    this.form.get('templateSubject')?.setValue('');
    this.bodyText.editorInstance.setData('');
  }

  public async onSubmit(value): Promise<void> {
    if (this.form.valid) {
      this.isSendingRequest = true;
      const request = new UpdateTemplateRequest(
        this.templateId,
        this.mailAccountId,
        this.form.get('templateName').value,
        this.form.get('templateDesc').value,
        this.form.get('templateSubject').value,
        this.bodyText.editorInstance.getData()
      );
      try {
        await this.templateClient.update(request);
        this.submitEventEmitter.emit();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isSendingRequest = false;
      }
    }
  }

  public openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { name: 'reset' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onClickReset();
      }
    });
  }

  public cancel(): void {
    this.closeEventEmitter.emit();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };
}
