export class MailAccountUpdateRequest {
  mailAccountId: number;
  name: string;
  // code: string;
  license: string;
  codeSia: string;
  anagId: string;

  constructor(mailAccountId: number, name: string, codeSia: string, license: string, anagId: string) {
    this.mailAccountId = mailAccountId;
    this.name = name;
    // this.code = code;
    this.codeSia = codeSia;
    this.license = license;
    this.anagId = anagId;
  }
}
