<mat-drawer-container class="fill" [hasBackdrop]="hasBackdrop">
  <mat-drawer class="fill" [autoFocus]="false">
    <app-emails-sidenav></app-emails-sidenav>
  </mat-drawer>
  <mat-drawer-content>
    <div class="fill">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
