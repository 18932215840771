<div class="h-screen w-screen flex justify-center flex-wrap flex-col content-center p-5">
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Errors</mat-label>
      <mat-select [(ngModel)]="selected">
        <mat-option *ngFor="let error of errors" [value]="error">
          {{ error }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <button (click)="emitError()" mat-raised-button color="primary">Emit error</button>
  </div>
</div>
