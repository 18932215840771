import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { DefaultPageComponent } from './components/default-page/default-page.component';
import { EmailPrintComponent } from './components/email/email-print/email-print.component';
import { EmailsContainerComponent } from './components/emails-container/emails-container.component';
import { EmailsSideNavComponent } from './components/emails-sidenav/emails-side-nav.component';
import { EmailsComponent } from './components/emails/emails.component';
import { Error401PageComponent } from './components/error/error401-page/error401-page.component';
import { Error403PageComponent } from './components/error/error403-page/error403-page.component';
import { ErrorNotExistedPageComponent } from './components/error/errorNotExisted-page/errorNotExisted-page.component';
import { InternalErrorPageComponent } from './components/error/internal-error-page/internal-error-page.component';
import { NotFoundErrorPageComponent } from './components/error/not-found-error-page/not-found-error-page.component';
import { TestErrorsPageComponent } from './components/error/test-errors-page/test-errors-page.component';
import { MainComponent } from './components/main/main.component';
import { PluginsComponent } from './components/plugins/plugins.component';
import { ReportsComponent } from './components/reports/reports.component';
import { DispatchingRuleComponent } from './components/settings/organization/mail-account/dispatching-rule/dispatching-rule.component';
import { MailAccountFoldersSettingsComponent } from './components/settings/organization/mail-account/mail-account-folders-settings/mail-account-folders-settings.component';
import { MailAccountGeneralSettingsComponent } from './components/settings/organization/mail-account/mail-account-general-settings/mail-account-general-settings.component';
import { MailAccountTagsComponent } from './components/settings/organization/mail-account/mail-account-tags/mail-account-tags.component';
import { MailAccountTemplatesComponent } from './components/settings/organization/mail-account/mail-account-templates/mail-account-templates.component';
import { MailAccountUsersComponent } from './components/settings/organization/mail-account/mail-account-users/mail-account-users.component';
import { MailAccountComponent } from './components/settings/organization/mail-account/mail-account.component';
import { OrganizationGeneralSettingsComponent } from './components/settings/organization/organization-general-settings/organization-general-settings.component';
import { OrganizationGroupsComponent } from './components/settings/organization/organization-groups/organization-groups.component';
import { OrganizationUsersComponent } from './components/settings/organization/organization-users/organization-users.component';
import { OrganizationComponent } from './components/settings/organization/organization.component';
import { ProviderComponent } from './components/settings/provider/provider.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { WafPageComponent } from './components/waf-page/waf-page.component';
import { WebCodeContextComponent } from './components/web-code-context/web-code-context.component';
import { AuthGuard } from './services/auth/auth.guard';
import { RootAuthGuard } from './services/auth/root.auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: DefaultPageComponent,
  },
  {
    path: ':webCode/sign-in',
    component: SignInComponent,
  },
  {
    path: ':webCode/403',
    component: Error403PageComponent,
  },
  {
    path: ':webCode/test-errors-page',
    component: TestErrorsPageComponent,
  },
  {
    path: ':webCode/401',
    component: Error401PageComponent,
  },
  {
    path: ':webCode/not-exists',
    component: ErrorNotExistedPageComponent,
  },
  {
    path: ':webCode/500',
    component: InternalErrorPageComponent,
  },
  {
    path: 'requesterror',
    component: WafPageComponent,
  },
  {
    path: ':webCode',
    component: WebCodeContextComponent,
    children: [
      {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: EmailsComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: '',
                component: SettingsComponent,
                canActivate: [AuthGuard],
                children: [
                  {
                    path: 'settings/organizations',
                    component: OrganizationComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/providers',
                    component: ProviderComponent,
                    canActivate: [RootAuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/general',
                    component: OrganizationGeneralSettingsComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/mail-accounts',
                    component: MailAccountComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/users',
                    component: OrganizationUsersComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/groups',
                    component: OrganizationGroupsComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/mail-account/:mailAccountId/general',
                    component: MailAccountGeneralSettingsComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/mail-account/:mailAccountId/users',
                    component: MailAccountUsersComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/mail-account/:mailAccountId/dispatching-rules',
                    component: DispatchingRuleComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/mail-account/:mailAccountId/tags',
                    component: MailAccountTagsComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/mail-account/:mailAccountId/templates',
                    component: MailAccountTemplatesComponent,
                    canActivate: [AuthGuard],
                  },
                  {
                    path: 'settings/organization/:organizationId/mail-account/:mailAccountId/folders',
                    component: MailAccountFoldersSettingsComponent,
                    canActivate: [AuthGuard],
                  },
                ],
              },
              {
                path: 'emails',
                component: EmailsContainerComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'reports',
                component: ReportsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'plugins',
                component: PluginsComponent,
                canActivate: [AuthGuard],
              },
              {
                path: 'account-settings',
                component: AccountSettingsComponent,
                canActivate: [AuthGuard],
              },
              // {
              //   path: 'organization/detail/:id/mail-accounts',
              //   component: MailAccountComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'organization/import-sessions/:id',
              //   component: ImportSessionComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'organization/import-sessions/:id/create',
              //   component: ImportCreateComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'organization/import-sessions/:id1/details/:id2',
              //   component: ImportSessionDetailComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'mail-account/:mailAccountId/dashboard',
              //   component: MailAccountDashboardComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'mail-account/:mailAccountId/emails-dashboard',
              //   component: EmailsContentComponent,
              //   canActivate: [AuthGuard],
              // },

              // {
              //   path: 'mail-account/:mailAccountId/mail-folders/search',
              //   component: MailFolderComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'mail-account/:mailAccountId/contacts',
              //   component: ContactListComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'mail-account/:mailAccountId/mail-folder/:mailFolderId/emails-content/:emailId/audit',
              //   component: EmailAuditComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'mail-account/:mailAccountId/mail-folders/create-massive-email',
              //   component: CreateMassiveEmailComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'organization/detail/:organizationId/mailAccounts/:mailAccountId/massive',
              //   component: MassiveSendSessionsComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'organization/detail/:organizationId/mail-account/:mailAccountId/massive/:massiveSendSessionId/detail',
              //   component: MassiveSendSessionDetailComponent,
              //   canActivate: [AuthGuard],
              // },
              // {
              //   path: 'mail-account/:mailAccountId/search-widgets',
              //   component: SearchWidgetsComponent,
              //   canActivate: [AuthGuard],
              // },
            ],
          },
        ],
      },
      {
        path: 'mail-accounts',
        component: EmailsSideNavComponent,
        canActivate: [AuthGuard],
      },
      // {
      //   path: 'mail-account/:mailAccountId/contacts/create',
      //   component: CreateContactComponent,
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'mail-accounts/:mailAccountId/contact/:contactId/edit',
      //   component: EditContactComponent,
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'mail-account/:mailAccountId/contact-groups',
      //   component: ContactGroupListComponent,
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'mail-account/:mailAccountId/contactgroups/create',
      //   component: CreateContactGroupComponent,
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'mail-account/:mailAccountId/contact-group/:contactGroupId/edit',
      //   component: EditContactGroupComponent,
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: 'mail-account/:mailAccountId/contact-group/:contactGroupId/detail',
      //   component: ContactGroupDetailComponent,
      //   canActivate: [AuthGuard],
      // },
      {
        path: 'mail-account/:mailAccountId/mail-folder/:mailFolderId/emails-content/:emailId/print',
        component: EmailPrintComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundErrorPageComponent,
  },
  // {
  //   path: 'googleoauthcallback',
  //   component: GoogleOauthCallbackComponent
  // },
  // {
  //   path: 'adfsauthcallback',
  //   component: AdfsAuthCallbackComponent
  // },
  // {
  //   path: 'restorepassword',
  //   component: RestorePasswordComponent
  // },
  // {
  //   path: 'changeexpiredpassword/:userIdentityId',
  //   component: ChangeExpiredPasswordComponent
  // },
  // {
  //   path: 'login',
  //   component: LoginComponent
  // },
  // {
  //   path: 'login/:userIdentityId/:code',
  //   component: LoginComponent
  // },
  // {
  //   path: 'register',
  //   component: RegisterComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
