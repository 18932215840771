import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { OrganizationStatisticContract } from '../contracts/statistic/organization-statistic-contract';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class StatisticClient {
  constructor(private http: HttpClient, private apiRequest: ApiRequest) {}

  async organization(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<OrganizationStatisticContract>> {
    return this.apiRequest.get<BaseGetByIdResponse<OrganizationStatisticContract>>(
      `${API_URL}${ApiRoutes.statistic.organization}?${request.format()}`
    );
  }
}
