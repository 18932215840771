<app-drawer newTitle="Advanced search" [isOpen]="advancedSearchIsOpen" (closeEventEmitter)="closeAdvancedSearch()">
  <app-email-search-advanced-dialog *ngIf="advancedSearchIsOpen" (closeEventEmitter)="closeAdvancedSearch()">
  </app-email-search-advanced-dialog>
</app-drawer>

<div class="flex items-center gap-3">
  <div class="logo tooltip">
    <img class="w-[200px]" src="../../../assets/logo.svg" alt="Logo" />
    <span class="tooltiptext">
      {{ "pathToDigitalTransformation" | translate }}
      <div style="display: block">
        <a style="color: #a1ccfe; border-bottom: 1px solid #a1ccfe" target="_blank" href="https://www.credemtel.it">www.credemtel.it</a>
      </div>
    </span>
  </div>

  <div [class]="'search-main bg-white flex text-gray-600 p-0.5 border border-gray-300 rounded-md' + widthClass">
    <div class="flex-none">
      <button
        [disabled]="!isAnyFolder"
        mat-icon-button
        type="submit"
        class="h-full flex flex-wrap content-center px-2"
        (click)="onSearchBtnClicked()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
    <div class="flex items-center search-flex">
      <input
        #topInput
        style="background: white"
        class="w-full h-8 text-sm focus:outline-none focus:ring-0 pr-2"
        type="search"
        [disabled]="!isAnyFolder"
        name="search"
        placeholder="{{ 'search' | translate }}"
        [value]="search"
        (change)="onValueChanged($event)"
        (keyup.enter)="onSearchBtnClicked()" />
      <!-- <span (click)="onXClicked()">x</span> -->
      <button matTooltip="{{ 'resetAllFilters' | translate }}" [disabled]="!isAnyFolder" mat-icon-button (click)="onXClicked()">
        <mat-icon class="search-x">close</mat-icon>
      </button>
    </div>
    <div class="flex-none border-l border-gray-300">
      <button
        mat-icon-button
        [disabled]="!isAnyFolder"
        type="submit"
        class="h-full flex flex-wrap content-center px-2"
        (click)="openAdvancedSearch()">
        <mat-icon [color]="!isDefaultSearchParams && isAnyFolder ? 'primary' : ''">tune</mat-icon>
      </button>
    </div>
  </div>

  <mat-slide-toggle
    [disabled]="!isAnyFolder"
    [checked]="!hideNotifications"
    (change)="onHideNotificationsChanged(!$event.checked)"
    class="mr-3"
    color="primary"
    matTooltip="{{ 'showNotificationSearchInput' | translate }}"></mat-slide-toggle>
</div>
