<div class="drawer-content-screen flex flex-col justify-between">
  <div class="w-full mb-2">
    <div class="text-2xl mb-10 font-medium">{{ "addGroupToCompany" | translate }}</div>

    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{ "name" | translate }}: </mat-label>
        <input matInput id="name" formControlName="name" required />
        <mat-error *ngIf="form.get('name').hasError('required')">{{ "nameIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="form.get('name').hasError('maxlength')">{{ "moreThan255Char" | translate }}</mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="w-full">
    <!-- <div *ngIf="hasError" class="mb-3">
      <app-error-hint errorText="Cannot create group: something went wrong"></app-error-hint>
    </div> -->
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button>{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="save()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
