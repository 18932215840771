<h1 mat-dialog-title>{{ "confirmMailAccountDeletion" | translate }}</h1>
<div mat-dialog-content>
  <p>
    {{ "wantToDelete" | translate }} <span class="font-bold italic">{{ data.mailAccountName }}</span> {{ "mailAccount" | translate }}?
  </p>
</div>
<div mat-dialog-actions>
  <div class="w-full flex justify-between">
    <button mat-button (click)="onCancelBtnClicked()">{{ "cancel" | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onDeleteBtnClicked()">
      <app-loading-button text="{{ 'delete' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</div>
