<div class="relative">
  <h1 mat-dialog-title>{{ data.title ? (data.title | translate) : ("moveToFolderTitle" | translate) }}</h1>
  <button tabindex="-1" style="position: absolute; top: -20px; right: -17px" mat-icon-button (click)="cancel()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div *ngIf="isLoading">
    <app-loader height="100%"></app-loader>
  </div>

  <div *ngIf="cannotLoad">
    <app-something-went-wrong [showPicture]="false" description="{{ 'cannotLoadDataError' | translate }}" (retryEmitter)="loadFolders()">
    </app-something-went-wrong>
  </div>

  <div *ngIf="!isLoading && !cannotLoad">
    <mat-form-field *ngIf="selectedFolderId" appearance="fill">
      <mat-label>{{ "folder" | translate }}</mat-label>
      <mat-select [(value)]="selectedFolderId">
        <mat-option *ngFor="let folder of folders" [value]="folder.mailFolderId">
          {{ folder.creationType === 1 ? ("folderType" + folder.folderType | translate) : folder.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-error-hint *ngIf="cannotSave" errorText="{{ 'cannotMoveErrorPopupSubtitle' | translate }}" [centerAlign]="false"></app-error-hint>
  </div>
</div>
<div mat-dialog-actions>
  <div class="w-full flex justify-end">
    <button mat-button mat-flat-button color="primary" (click)="confirm()" [disabled]="moveInProgress || isLoading || cannotLoad">
      <app-loading-button text="{{ 'move' | translate }}" [loading]="moveInProgress"></app-loading-button>
    </button>
  </div>
</div>
