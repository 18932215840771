import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ProviderContract } from '../../../../sdk/contracts/provider/provider.contract';
import { Router, ActivatedRoute } from '@angular/router';
import { ProviderClient } from '../../../../sdk/clients/provider.client';
import { ProviderConfiguration } from '../../../../sdk/contracts/provider/provider.configuration';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-provider-update-dialog',
  templateUrl: './provider-update-dialog.component.html',
  styleUrls: ['./provider-update-dialog.component.scss'],
})
export class ProviderUpdateDialogComponent implements OnInit {
  @Input() provider: ProviderContract;
  @Output() public updatingSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public isLoading = false;
  public isError = false;

  constructor(
    private providerClient: ProviderClient,
    protected router: Router,
    public dialog: MatDialog,
    public matchError: MatchError,
    protected route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.provider.name, [Validators.required, Validators.maxLength(128)]),
      smtp: new UntypedFormControl(this.provider.smtp, [Validators.required, Validators.maxLength(256)]),
      smtpPort: new UntypedFormControl(this.provider.smtpPort, [
        Validators.pattern('^[0-9]*$'),
        Validators.required,
        Validators.min(1),
        Validators.max(65535),
      ]),
      imap: new UntypedFormControl(this.provider.imap, [Validators.required, Validators.maxLength(256)]),
      imapPort: new UntypedFormControl(this.provider.imapPort, [
        Validators.pattern('^[0-9]*$'),
        Validators.required,
        Validators.min(1),
        Validators.max(65535),
      ]),
      code: new UntypedFormControl(this.provider.code, [Validators.maxLength(512)]),
      inboxName: new UntypedFormControl(this.provider.configuration?.inbox, [Validators.maxLength(512)]),
      outboxName: new UntypedFormControl(this.provider.configuration?.sent, [Validators.maxLength(512)]),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(providerContract: ProviderContract): Promise<void> {
    this.isError = false;
    this.isLoading = true;
    if (this.form.valid) {
      try {
        providerContract.providerId = this.provider.providerId;
        if (this.form.controls.inboxName.value || this.form.controls.outboxName.value) {
          providerContract.configuration = new ProviderConfiguration(
            this.form.controls.inboxName.value,
            this.form.controls.outboxName.value
          );
        }
        await this.providerClient.update(providerContract);
        this.updatingSubmit.emit();
      } catch (e) {
        this.isError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
