import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserMailAccountContract } from '../../../../../../sdk/contracts/user-mail-account/user-mail-account.contract';
import { UserMailAccountClient } from '../../../../../../sdk/clients/user-mail-account.client';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { RoleType } from '../../../../../../sdk/contracts/role/role-type';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-mail-account-users-edit',
  templateUrl: './mail-account-users-edit.component.html',
  styleUrls: ['./mail-account-users-edit.component.scss'],
})
export class MailAccountUsersEditComponent implements OnInit {
  @Input() userMailAccount: UserMailAccountContract;
  @Output() public submitEventEmitter = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public errorText: string;
  public isLoading = false;
  public roleTypes = RoleType;
  public selectedRole: RoleType;
  public availableRoles: RoleType[] = [
    RoleType.User,
    RoleType.SuperUser,
    RoleType.Creator,
    RoleType.Validator,
    RoleType.LimitedUser,
    RoleType.LimitedCreator,
  ];
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public handlingErrorCodes = new Map<number, string>([]);

  constructor(private userMailAccountClient: UserMailAccountClient, public matchError: MatchError, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.userMailAccount.userFirstName + ' ' + this.userMailAccount.userLastName),
    });
    this.selectedRole = this.userMailAccount.roleId;
  }

  async onSubmit(): Promise<void> {
    if (this.selectedRole) {
      this.isLoading = true;
      try {
        const request = {
          userMailAccountId: this.userMailAccount.userMailAccountId,
          roleId: this.selectedRole,
        };
        await this.userMailAccountClient.assignRole(request);
        this.submitEventEmitter.emit();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
