import { Component, Inject, OnInit } from '@angular/core';
import { MailFolderContract } from '../../sdk/contracts/mail-folder/mail-folder.contract';
import { MailFolderService } from '../../services/mail-folder/mail.folder.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MailFolderSelectDialogComponent } from '../mail-account-folders/mail-folder-select-dialog/mail-folder-select-dialog.component';
import { MailAccountGetByOrganizationIdContract } from '../../sdk/contracts/mail-account/mail-account.get-by-organization-id.contract';
import { MailAccountService } from '../../services/mail-account/mail.account.service';
import { EmailClient } from '../../sdk/clients/email-client';
import { EmailReassignRequest } from '../../sdk/contracts/Email/email.reassign.request';
import { MailFolderType } from 'src/app/sdk/contracts/mail-folder/mail-folder.type';
import { MatchError } from 'src/app/shared/error-matcher';
import { EventsService } from 'src/app/services/events/events.service';

@Component({
  selector: 'app-reassign-dialog',
  templateUrl: './reassign-dialog.component.html',
  styleUrls: ['./reassign-dialog.component.scss'],
})
export class ReassignDialogComponent implements OnInit {
  public isLoading = true;
  public isLoadingFolders = true;
  public cannotLoad = false;
  public saving = false;
  public cannotSave = false;

  public folders: MailFolderContract[] = [];
  public accounts: MailAccountGetByOrganizationIdContract[] = [];
  public selectedFolderId: number;
  public selectedMailAccountId: number;

  constructor(
    public matchError: MatchError,
    private eventsService: EventsService,
    private mailFolderService: MailFolderService,
    private mailAccountService: MailAccountService,
    public dialogRef: MatDialogRef<MailFolderSelectDialogComponent>,
    private emailClient: EmailClient,
    @Inject(MAT_DIALOG_DATA) public data: { mailAccountId: number; ids: number[] }
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<void> {
    this.cannotLoad = false;
    try {
      await this.loadAccounts(this.data.mailAccountId);
      if (this.accounts.length > 0) {
        await this.loadFolders(this.accounts[0].mailAccountId);
      }
    } catch (e) {
      this.cannotLoad = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async loadFolders(mailAccountId: number): Promise<void> {
    try {
      this.isLoadingFolders = true;
      this.folders = await this.mailFolderService.getFoldersForAccountReassign(mailAccountId);
      if (this.folders.length > 0) {
        this.selectedFolderId = this.folders.find((e) => (
          e.folderType === MailFolderType.Inbox && e.creationType === 1)
        ).mailFolderId;
      }
    } catch (error) {
      this.matchError.errorHandler(error);
      this.cannotLoad = true;
    } finally {
      this.isLoadingFolders = false;
    }
  }

  async loadAccounts(mailAccountId: number): Promise<void> {
    this.accounts = await this.mailAccountService.getAccountsForAccountOrganization(mailAccountId);

    if (this.accounts.length > 0) {
      this.selectedMailAccountId = this.accounts[0].mailAccountId;
    }
  }

  cancel(): void {
    this.dialogRef.close({ confirmed: false });
  }

  async onAccountChange(): Promise<void> {
    if (this.selectedMailAccountId) {
      await this.loadFolders(this.selectedMailAccountId);
    }
  }

  async confirm(): Promise<void> {
    this.saving = true;
    this.cannotSave = false;
    try {
      await this.emailClient.reassign(new EmailReassignRequest(this.data.ids, this.selectedMailAccountId, this.selectedFolderId));
      this.eventsService.reassign([this.data.mailAccountId, this.selectedMailAccountId]);
      this.dialogRef.close({ confirmed: true });
    } catch (e) {
      this.cannotSave = true;
      this.matchError.logError(e);
    } finally {
      this.saving = false;
    }
  }
}
