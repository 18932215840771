<ng-container>
  <div *ngIf="loading" class="top-0 left-0 w-full h-full flex justify-center flex-wrap content-center write-loader absolute">
    <mat-spinner diameter="20"></mat-spinner>
  </div>
  <div class="inline" [ngStyle]="{ color: loading ? 'transparent' : '' }">
    {{ text }}
  </div>
</ng-container>

<!-- <div style="text-align:center">
  <button mat-raised-button color="primary" [class.spinner]="loading" [disabled]="loading">{{text}}</button>
</div> -->
