<h1 mat-dialog-title>{{ "confirmFolderDeletion" | translate }}</h1>
<div mat-dialog-content>
  <p>
    {{ "folderDeletion" | translate }} <span class="font-bold italic">{{ data.folderName }}</span> ?
  </p>
  <app-error-hint
    *ngIf="isUnhandlingError"
    errorText="{{ 'cannotDeleteFolder' | translate }}: {{ 'somethingWentWrongErrorHintTitle' | translate }}"
    [centerAlign]="false"></app-error-hint>
  <app-error-hint
    *ngIf="hasSubFoldersError"
    errorText="{{ 'cannotDeleteFolder' | translate }}: {{ 'folderHasSubfolders' | translate }}"
    [centerAlign]="false"></app-error-hint>
</div>
<div mat-dialog-actions>
  <div class="w-full flex justify-between">
    <button mat-button (click)="onCancelBtnClicked()">{{ "no" | translate }}</button>
    <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onDeleteBtnClicked()">
      <app-loading-button text="{{ 'yes' | translate }}" [loading]="isLoading"></app-loading-button>
    </button>
  </div>
</div>
