<app-main-header></app-main-header>
<mat-sidenav-container>
  <mat-sidenav #sidenav position="end">
    <div *ngIf="isError" class="h-full">
      <app-something-went-wrong description="{{ 'cannotLoadData' | translate }}" (retryEmitter)="loadData()"></app-something-went-wrong>
    </div>
    <app-loader *ngIf="isLoading"></app-loader>
    <ng-container *ngIf="!isLoading && !isError">
      <div class="user-info-block">
        <div class="flex flex-col">
          <span *ngIf="userOrganisaions.length === 1" class="font-medium tracking-wider mb-1 text-lg">
            {{ userOrganisaions[0].name }}
          </span>
          <mat-form-field
            *ngIf="userOrganisaions.length > 1"
            appearance="none"
            class="font-medium tracking-wider mb-1 text-lg main-header-select">
            <mat-select (selectionChange)="onChangeOrganization($event)" [(value)]="webCode">
              <mat-option *ngFor="let userOrganisaion of userOrganisaions" [value]="userOrganisaion.webCode">
                {{ userOrganisaion.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <span class="font-medium tracking-wider mb-1 text-lg">
            {{ user.firstName + " " + user.lastName }}
          </span>

          <span class="additional-user-info">
            {{ user.email }}
          </span>
          <span class="additional-user-info">
            <span *ngIf="organizationName && userRole"></span>
            <span *ngIf="userRole">
              {{ userRole }}
            </span>
          </span>
        </div>
      </div>
      <mat-nav-list>
        <a
          *ngIf="userRole !== 'Root'"
          [class.current-select]="isCurrentSelect('emails')"
          mat-list-item
          [routerLink]="'/' + webCode + '/emails'"
          (click)="onEmailsClick()"
          >{{ "emails" | translate }}</a
        >
        <a
          *ngIf="settingRoute"
          [class.current-select]="isCurrentSelect('settings')"
          mat-list-item
          [routerLink]="settingRoute"
          (click)="sidenav.toggle()"
          >{{ "settings" | translate }}</a
        >
        <a
          *ngIf="userRole !== 'Root'"
          [class.current-select]="isCurrentSelect('reports')"
          mat-list-item
          [routerLink]="'/' + webCode + '/reports'"
          (click)="sidenav.toggle()"
          >{{ "reports" | translate }}</a
        >
        <a
          *ngIf="userRole !== 'Root' && pluginsPermission()"
          [class.current-select]="isCurrentSelect('plugins')"
          mat-list-item
          [routerLink]="'/' + webCode + '/plugins'"
          (click)="sidenav.toggle()"
          >{{ "plugins" | translate }}</a>
        <a
          [class.current-select]="isCurrentSelect('account-settings')"
          mat-list-item
          [routerLink]="accountSettingsRoute"
          (click)="sidenav.toggle()"
          >{{ "profile" | translate }}</a
        >
        <a mat-list-item (click)="logout(); sidenav.toggle()">{{ "logout" | translate }}</a>
        <!-- <a *ngIf="isLogged()" mat-list-item (click)="logoutAll(); sidenav.toggle()">Logout All</a> -->
        <!-- <a mat-list-item (click)="sidenav.toggle()" href="">Close</a> -->
      </mat-nav-list>
      <div *ngIf="userOrganisaions && userOrganisaions.length !== 0" class="space-info">
        <div class="space-info__progress-space" [class.low-space-main]="spaceInfo.percentage>spaceInfo.alertPercentage">
          <div class="progress-space__content" [class.low-space-inner]="spaceInfo.percentage>spaceInfo.alertPercentage" [ngStyle]="{ 'width': spaceInfo.percentage + '%' }">
          </div>
          <span *ngIf="spaceInfo.percentage<100" class="progress-space__inner-text">{{spaceInfo.percentage}}%</span>
          <span *ngIf="spaceInfo.percentage>=100" class="progress-space__warning-text">{{"exceededSpaceOf"| translate}} {{spaceInfo.maxAvailableSpaceUsed}} GB</span>
        </div>
        <div style="display: flex; justify-content: space-between;">
          {{"totalSpaceOccupies" | translate}}
          <div class="space-info__text" [class.red]="spaceInfo.percentage>spaceInfo.alertPercentage">
            {{spaceInfo.spaceUsed}} GB
          </div>
        </div>
        <div *ngIf="spaceInfo.percentage>=100" class="progress-space__hint red">
          ({{"spaceHasBeenExceeded"| translate}})
        </div>
        <div *ngIf="spaceInfo.percentage>spaceInfo.alertPercentage && spaceInfo.percentage<100" class="progress-space__hint red">
          ({{"spaceIsAboutEnd"| translate}})
        </div>
      </div>
    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
