import { AttachmentContract } from '../Attachment/Attachment.contract';
import { EmailBodyContentType } from './email-body-content-type';
import { EmailState } from './email-state';

export class EmailUpdateRequest {
  emailId: number;
  mailFolderId: number;
  from: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  parentEmailId: number;
  bodyContentType: EmailBodyContentType;
  deleted: boolean;
  isSpam: boolean;
  attachmentFiles: AttachmentContract[];
  tags: string[];
  state: EmailState;
  splitSend: boolean;
}
