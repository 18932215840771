import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MailFolderUpdateRequest } from '../../../sdk/contracts/mail-folder/mail-folder.update.request';
import { MailFolderClient } from '../../../sdk/clients/mail-folder.client';
import { RemoteClient } from '../../../sdk/clients/remote.client';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { MatchError } from 'src/app/shared/error-matcher';
import { MailCreationType } from 'src/app/sdk/contracts/mail-folder/mail-folder-creation.type';

@Component({
  selector: 'app-mail-folder-rename-dialog',
  templateUrl: './mail-folder-rename-dialog.component.html',
  styleUrls: ['./mail-folder-rename-dialog.component.scss'],
})
export class MailFolderRenameDialogComponent implements OnInit {
  @Input() mailFolderId: number;
  @Input() mailFolderCreationType: MailCreationType;
  @Input() mailFolderName: string;
  @Input() providerFolderName: string | null = null;
  @Input() parentMailFolderType: number;
  @Input() mailAccountId: number;
  @Input() isSettings: boolean;

  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();

  public form: UntypedFormGroup;
  public isLoading = false;
  public isError = false;
  public errorText: string;
  public mailCreationType = MailCreationType;
  public loadingAvailableData = false;
  public cannotLoadAvailableData = false;
  public providerFolders: any[] = null;
  public selectedProviderFolder: string | null = null;
  public handlingErrorCodes = new Map<number, string>([]);

  constructor(private mailFolderClient: MailFolderClient, public matchError: MatchError, private remoteClient: RemoteClient) {}

  async ngOnInit(): Promise<any> {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.mailFolderName, [Validators.required, Validators.maxLength(256)]),
    });

    if ((this.parentMailFolderType === 2 || this.parentMailFolderType === 3) && this.isSettings) {
      await this.loadData();
    }
  }

  async loadData(): Promise<any> {
    this.loadingAvailableData = true;
    this.cannotLoadAvailableData = false;
    try {
      const response = await this.remoteClient.fetchRemoteFolders(new BaseGetByIdRequest(this.mailAccountId));
      this.providerFolders = response.data.map((e) => e.name);
      this.providerFolders = this.providerFolders.map((e) => (e = { name: e, value: e }));
      this.providerFolders.unshift({ name: '--Clear--' });
    } catch (e) {
      this.cannotLoadAvailableData = true;
      this.matchError.logError(e);
    } finally {
      this.loadingAvailableData = false;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(formValue: any): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.isError = false;
      const request = new MailFolderUpdateRequest();
      request.name = formValue.name;
      request.mailFolderId = this.mailFolderId;
      request.providerFolderName = this.providerFolderName;
      try {
        await this.mailFolderClient.update(request);
        this.creationSubmit.emit();
      } catch (e) {
        this.isError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
  public cancel(): void {
    this.closeEventEmitter.emit();
  }
}
