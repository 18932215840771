import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserContract } from '../../../../../../sdk/contracts/user/user.contract';
import { UserMailAccountClient } from '../../../../../../sdk/clients/user-mail-account.client';
import { UntypedFormGroup } from '@angular/forms';
import { BaseGetByIdRequest } from '../../../../../../sdk/contracts/common/base-get-by-id.request';
import { RoleType } from '../../../../../../sdk/contracts/role/role-type';
import { UserMailAccountCreateRequest } from '../../../../../../sdk/contracts/user-mail-account/user-mail-account.create.request';
import { ErrorCode } from '../../../../../../sdk/api-errors/error-code';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import { PermissionService } from 'src/app/services/permission/permission.service';

@Component({
  selector: 'app-mail-account-users-add',
  templateUrl: './mail-account-users-add.component.html',
  styleUrls: ['./mail-account-users-add.component.scss'],
})
export class MailAccountUsersAddComponent implements OnInit {
  @Input() public organizationId: number;
  @Input() public mailAccountId: number;
  @Output() public creationSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();

  public availableRoles: RoleType[] = [
    RoleType.User,
    RoleType.SuperUser,
    RoleType.Creator,
    RoleType.Validator,
    RoleType.LimitedUser,
    RoleType.LimitedCreator,
  ];
  public availableUsers: UserContract[] = [];
  public selectedUser: UserContract;
  public selectedRole: RoleType = RoleType.User;
  public roleTypes = RoleType;

  public cannotLoadAvailableOrganizationUsers = false;
  public availableOrganizationUsersIsLoading = false;

  public handlingErrorCodes = new Map<number, string>([
    [ErrorCode.UserAlreadyBeenAddedToMailAccount, 'Cannot add user to mail account: user already has been added'],
  ]);

  public errorText: string;
  public userAlreadyAddedError = false;
  public isLoading = false;
  public form: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private userMailAccountClient: UserMailAccountClient,
    private permissionService: PermissionService,
    public matchError: MatchError,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAvailableOrganizationUsers();
  }

  public async loadAvailableOrganizationUsers(): Promise<void> {
    this.availableOrganizationUsersIsLoading = true;
    this.cannotLoadAvailableOrganizationUsers = false;
    try {
      const response = await this.userMailAccountClient.getAvailableUsers(new BaseGetByIdRequest(this.mailAccountId));
      this.availableUsers = response.data.filter((e) => this.permissionService.hasPermissionToSeeUserByUserEmail(e.email));
    } catch (e) {
      this.cannotLoadAvailableOrganizationUsers = true;
      this.matchError.logError(e);
    } finally {
      this.availableOrganizationUsersIsLoading = false;
    }
  }

  public formIsValid(): boolean {
    return !!this.selectedRole && !!this.selectedUser;
  }

  async onSubmit(): Promise<void> {
    if (this.selectedRole && this.selectedUser) {
      this.isLoading = true;
      this.userAlreadyAddedError = false;
      try {
        const addRequest: UserMailAccountCreateRequest = {
          mailAccountId: this.mailAccountId,
          userId: this.selectedUser.userId,
          roleId: this.selectedRole,
        };
        await this.userMailAccountClient.addMailAccountUser(addRequest);
        this.creationSubmit.emit({ userId: this.selectedUser.userId });
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
