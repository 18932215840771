import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { UserMailAccountContract } from '../../sdk/contracts/user-mail-account/user-mail-account.contract';
import { MailAccountClient } from '../../sdk/clients/mail-account.client';
import { ActivatedRoute } from '@angular/router';
import { BaseGetByFileIdRequest } from '../../sdk/contracts/common/base-get-by-file-id.request';
import { FileClient } from '../../sdk/clients/file.client';
import { TempFileClient } from '../../sdk/clients/temp-file.client';
import { ReportCreateRequest, ReportResponse } from '../../sdk/contracts/report/report.create.request';
import { ReportClient } from '../../sdk/clients/report.client';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserMailAccountClient } from '../../sdk/clients/user-mail-account.client';
import { MatchError } from 'src/app/shared/error-matcher';

interface reportType {
  name: string;
  value: number;
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  public form: UntypedFormGroup = new UntypedFormGroup({});
  @ViewChild('select') select: MatSelect;
  @ViewChild('allSelected') private allSelected: MatOption;

  public isSendingRequest = false;
  public isLoading = true;
  public cannotLoadData = false;
  public accounts: UserMailAccountContract[] = [];
  public webCode: string;
  public reportTypes: reportType[] = [
    {
      name: 'reportType6',
      value: 6,
    },
    {
      name: 'reportType7',
      value: 7,
    },
    {
      name: 'reportType1',
      value: 1,
    },
    {
      name: 'reportType2',
      value: 2,
    },
    {
      name: 'reportType3',
      value: 3,
    },
    {
      name: 'reportType4',
      value: 4,
    },
    {
      name: 'reportType5',
      value: 5,
    },
  ];
  public reportObject: ReportCreateRequest;
  public popupIsOpen = false;

  constructor(
    private mailAccountClient: MailAccountClient,
    private route: ActivatedRoute,
    private userMailAccountClient: UserMailAccountClient,
    private fileClient: FileClient,
    private tempFileClient: TempFileClient,
    private reportClient: ReportClient,
    private matchError: MatchError,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.form = new UntypedFormGroup({
      range: new UntypedFormGroup({
        start: new UntypedFormControl(''),
        end: new UntypedFormControl(''),
      }),
      reportType: new UntypedFormControl('', Validators.required),
      mailsControl: new UntypedFormControl('', Validators.required),
    });
    await this.loadData();
  }

  async loadData(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadData = false;
    try {
      const response = await this.userMailAccountClient.getLoggedUserAccounts();

      this.accounts = response.userMailAccounts;
    } catch (e) {
      this.cannotLoadData = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async getAttachmentFile(fileObj: ReportResponse): Promise<void> {
    const request = new BaseGetByFileIdRequest(fileObj.result.fileId);
    await this.tempFileClient.download(request);
  }

  async createReportRequest(): Promise<void> {
    this.isSendingRequest = true;
    try {
      const response = await this.reportClient.create(this.reportObject);
      await this.getAttachmentFile(response);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isSendingRequest = false;
    }
  }

  onSubmit(value) {
    const mailIds = [];

    // Getting selected email ids
    console.log(value.mailsControl);
    value.mailsControl.forEach((item) => {
      if (item !== 0) {
        const currEmail = this.accounts.find((acc) => acc.mailAccountEmail === item);
        mailIds.push(currEmail.mailAccountId);
      }
    });

    this.reportObject = {
      reportParameters: {
        mailAccountIds: mailIds,
        reportType: value.reportType,
        mailDateTimeEnd: value.range.end ? value.range.end.toISOString() : null,
        mailDateTimeStart: value.range.start ? value.range.start.toISOString() : null,
      },
      async: false,
    };

    this.createReportRequest();
  }
  togglePerOne(all) {
    if (this.allSelected.selected) {
      this.allSelected.deselect();
      return false;
    }
    if (this.form.controls.mailsControl.value.length == this.accounts.length) this.allSelected.select();
  }
  toggleAllSelection() {
    if (this.allSelected.selected) {
      this.form.controls.mailsControl.patchValue([...this.accounts.map((item) => item.mailAccountEmail), 0]);
    } else {
      this.form.controls.mailsControl.patchValue([]);
    }
  }
}
