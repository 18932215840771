<mat-form-field class="tags-chip-list" appearance="fill">
  <mat-label>{{ "tags" | translate }}</mat-label>
  <mat-chip-list #chipList aria-label="Tags selection">
    <mat-chip *ngFor="let tag of selectedTags" [selectable]="true" [removable]="true" (removed)="removeTag(tag)">
      {{ tag }}
      <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="{{'newTag' | translate}}"
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addTag($event)" />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedTag($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
