import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MailAccountGetByOrganizationIdContract } from 'src/app/sdk/contracts/mail-account/mail-account.get-by-organization-id.contract';
import { DispatchingRule } from '../../../../../../sdk/contracts/dispatching/dispatching.rule';
import { MailFolderContract } from '../../../../../../sdk/contracts/mail-folder/mail-folder.contract';
import { Group } from '../../../../../../sdk/contracts/group/group.contract';
import { CheckboxItem } from '../../../../../../shared/checkbox.item';

@Component({
  selector: 'app-dispatching-actions',
  templateUrl: './dispatching-actions.component.html',
  styleUrls: ['./dispatching-actions.component.scss'],
})
export class DispatchingActionsComponent implements OnInit {
  @Input() dispatchingRule: DispatchingRule;
  @Input() availableActions: string[];
  @Input() existingActionTypes: string[];
  @Input() mailAccounts: MailAccountGetByOrganizationIdContract[];
  @Input() availableLocalFolders: MailFolderContract[];
  @Input() checkBoxGroups: CheckboxItem<Group>[] = [];
  @Input() isShowAddIcon: boolean;
  @Input() differentActionsIsValid: boolean;
  @Output() deleteSubmitEventEmitter = new EventEmitter<number>();
  @Output() createSubmitEventEmitter = new EventEmitter();
  @Output() changeSubmitEventEmitter = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public onDelete(index: number): void {
    this.deleteSubmitEventEmitter.emit(index);
  }

  public get hasActions(): boolean {
    return this.dispatchingRule?.dispatchingRule?.actions?.length > 0;
  }

  public filteredAvailableActions(actionName: string) {
    const actions = this.availableActions.filter((e) => !this.dispatchingRule.dispatchingRule.actions.some((x) => x.name === e));
    return !actions.some((e) => e === actionName);
  }

  public onCreate(): void {
    this.createSubmitEventEmitter.emit();
  }

  public onActionTypeChange(actionName: string, index: number): void {
    this.changeSubmitEventEmitter.emit();
    this.dispatchingRule.dispatchingRule.actions[index].args = [];
  }
}
