import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { GetAuthenticationTypesRequest } from '../../sdk/contracts/authentication/get-authentication-types.request';
import { AuthenticationClient } from '../../sdk/clients/authentication.client';
import { PermissionService } from '../../services/permission/permission.service';
import { PermissionType } from '../../sdk/contracts/permission/permission-type';
import { ApiRequest } from '../../sdk/api.request';
import { environment } from '../../../environments/environment';
import { MatchError } from 'src/app/shared/error-matcher';
import { FeClient } from 'src/app/sdk/clients/fe.client';
import { LoggingHandler } from '../../services/logging/logging.service';

@Component({
  selector: 'app-web-code-context',
  templateUrl: './web-code-context.component.html',
  styleUrls: ['./web-code-context.component.scss'],
})
export class WebCodeContextComponent implements OnInit, OnDestroy {
  public isLogged;
  public isError = false;
  public isUserActive = true;
  public userActiveTimer;
  public webCode;
  public tokenRefreshInterval;
  clientId;
  constructor(
    public matchError: MatchError,
    private route: ActivatedRoute,
    private router: Router,
    private apiRequest: ApiRequest,
    private loggingHandler: LoggingHandler,
    private authenticationService: AuthenticationService,
    private feClient: FeClient,
    private authenticationClient: AuthenticationClient,
    private permissionService: PermissionService
  ) {}

  ngOnDestroy(): void {
    clearInterval(this.tokenRefreshInterval);
  }

  async ngOnInit(): Promise<void> {
    const requestedWebCode = this.route.snapshot.paramMap.get('webCode');
    this.webCode = requestedWebCode;
    this.isLogged = false;
    try {
      const request = new GetAuthenticationTypesRequest(this.webCode);
      const organizations = (await this.feClient.getUserOrganizations(request))?.organizations;
      const isExistedWebcode = organizations.map((e) => e.webCode).includes(requestedWebCode);

      if (organizations) {
        let currentOrgs = JSON.parse(localStorage.getItem('organizations')) || [];
        if (!Array.isArray(currentOrgs)) {
          currentOrgs = [];
        }
        organizations.forEach((item) => {
          if (this.webCode === item.webCode) {
            currentOrgs = currentOrgs.filter((e) => e.webCode !== item.webCode);
            currentOrgs.unshift({
              name: item.name,
              webCode: item.webCode,
            });
          }
        });
        currentOrgs = currentOrgs.slice(0, 3);
        localStorage.setItem(`organizations`, JSON.stringify(currentOrgs));
      }

      if (!isExistedWebcode) {
        if (await this.permissionService.hasPermissionOver(null, null, PermissionType.CanEverything)) {
          if (requestedWebCode === 'admin') {
            this.isLogged = true;
          } else {
            this.router.navigateByUrl(`/${requestedWebCode}/403`);
          }
        } else {
          this.router.navigateByUrl(`/${requestedWebCode}/403`);
        }
      } else {
        this.isLogged = true;
      }

      if (this.isLogged) {
        this.feClient.setIsActive(true);
        this.feClient.setWebCode(this.webCode);
      }
      let clientId = localStorage.getItem('clientId');
      clientId = clientId ? JSON.parse(clientId) : '';
      if (!clientId) {
        clientId = this.makeId(20);
        localStorage.setItem(`clientId`, JSON.stringify(clientId));
      }
      this.clientId = clientId;
      this.userActivityTimer();
      this.tokenRenew();
    } catch (e) {
      if (e.Status === 500) {
        await this.router.navigateByUrl(`/${requestedWebCode}/500`);
      }
      this.matchError.logError(e);
    }
    console.log(`CLIENTID=${this.clientId}`);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(): void {
    this.userActivityTimer();
  }

  @HostListener('window:click', ['$event'])
  clickout(): void {
    this.userActivityTimer();
  }

  public makeId(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  public userActivityTimer(): void {
    this.isUserActive = true;
    clearTimeout(this.userActiveTimer);
    this.userActiveTimer = setTimeout(() => {
      this.isUserActive = false;
    }, environment.userActivityTimeoutMs);
  }

  async logout(): Promise<void> {
    await this.authenticationService.logout();
    console.log(`User is logged out (inactive anymore ${environment.userActivityTimeoutMs}ms)`);
    document.location.href = `${environment.feUrl}signout?webCode=${this.webCode}`;
  }

  public tokenRenew(): void {
    this.tokenRefreshInterval = setInterval(() => {
      if (this.isUserActive) {
        this.loggingHandler.create('information', 'renew token');
        this.apiRequest.get(environment.renewUri);
      } else {
        this.logout();
      }
    }, environment.userRenewTokenIntervalMs);
  }
}
