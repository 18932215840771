<div *ngIf="isLoading" class="w-full h-full">
  <app-loader height="100%"></app-loader>
</div>

<div *ngIf="cannotLoadData">
  <app-something-went-wrong
    description="{{ 'cannotLoadData' | translate }}"
    [showPicture]="false"
    (retryEmitter)="loadData()"></app-something-went-wrong>
</div>

<div *ngIf="!isLoading && !cannotLoadData" class="block p-[15px] w-full h-full">
  <form [formGroup]="form" autocomplete="off" class="w-full mt-6 relative flex flex-col" style="height: calc(100% - 30px)" novalidate>
    <h1>{{ "massiveSending" | translate }}</h1>
    <div class="mt-4">
      <mat-form-field appearance="fill" class="w-full">
        <mat-label>{{ "from" | translate }}</mat-label>
        <mat-select formControlName="mailsControl" #select>
          <mat-option (click)="optionClick()" *ngFor="let account of accounts" [value]="account.mailAccountEmail">
            {{ account.mailAccountEmail }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.mailsControl.hasError('required')">{{ "chooseAnAccount" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>{{ "subject" | translate }}</mat-label>
      <input matInput formControlName="subject" />
      <mat-error *ngIf="form.controls['subject'].hasError('subject', 'maxlength')">You have more than 2048 characters</mat-error>
    </mat-form-field>
    <div *ngIf="attachmentFiles.length > 0" class="flex relative">
      <span class="absolute custom-top">{{ "attach" | translate }}</span>
      <div class="subject attachments" type="text">
        <div class="flex flex-wrap">
          <div class="file" *ngFor="let file of attachmentFiles">
            <div [style.cursor]="'default'" class="main__attachment mr-1">
              <file-icon style="width: 16px" set="classic" style="font-size: '14px'" [type]="getFileExtension(file.fileName)"></file-icon>
            </div>
            <span style="line-height: 1; padding: 0 3px; overflow-wrap: anywhere">
              {{ file.fileName }}
            </span>
            <div class="flex justify-end items-center pb-[3px]">
              <mat-icon
                class="cursor-pointer"
                style="transform: scale(0.65); color: red"
                (click)="!isSendingRequest && deleteAttachment(file)"
                mat-icon-button
                >close</mat-icon
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="editor-create page-block h-full mb-3 canvas min-h-[200px]" style="width: calc(100% - 1px); overflow: hidden">
      <ckeditor #bodyText [editor]="editor" [config]="config" data="{{ body }}"></ckeditor>
    </div>
    <mat-error *ngIf="!isBodyValid()">{{ "PleaseFillInTheBodyOfTheEmail" | translate }}</mat-error>
    <div class="flex justify-between">
      <div>
        <button
          style="margin-right: 10px"
          mat-flat-button
          color="primary"
          [disabled]="isSendingRequest || isLoading || !form.valid || !attachmentFiles.length || !isBodyValid()"
          (click)="onSubmit(form.value)">
          <app-loading-button text="{{ 'send' | translate }}" [loading]="isSendingRequest"></app-loading-button>
        </button>
        <input #uploader [hidden]="true" type="file" multiple (change)="addAttachmentFileFromButton($event)" />
        <button mat-flat-button color="primary" [disabled]="isSendingRequest" (click)="uploader.click()">
          {{ "attachFiles" | translate }}
        </button>
      </div>
      <button [disabled]="isSendingRequest" mat-flat-button color="primary" (click)="openConfirmDialog()">
        {{ "reset" | translate }}
      </button>
    </div>
  </form>
</div>
