import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MailFolderClient } from '../../../sdk/clients/mail-folder.client';
import { ApiException } from '../../../sdk/api-errors/api.exception';
import { ErrorCode } from '../../../sdk/api-errors/error-code';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-mail-folder-delete-dialog',
  templateUrl: './mail-folder-delete-dialog.component.html',
  styleUrls: ['./mail-folder-delete-dialog.component.scss'],
})
export class MailFolderDeleteDialogComponent implements OnInit {
  public isLoading = false;
  public isUnhandlingError = false;
  public hasSubFoldersError = false;

  constructor(
    public matchError: MatchError,
    public dialogRef: MatDialogRef<MailFolderDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { mailFolderId: number; folderName: string },
    private mailFolderClient: MailFolderClient
  ) {}

  ngOnInit(): void {}

  public onCancelBtnClicked(): void {
    this.dialogRef.close({ isDeleted: false });
  }

  public async onDeleteBtnClicked(): Promise<void> {
    this.isLoading = true;
    this.isUnhandlingError = false;
    this.hasSubFoldersError = false;
    try {
      await this.mailFolderClient.delete({ id: this.data.mailFolderId });
      this.dialogRef.close({ isDeleted: true });
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
