import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmailValidationState } from '../../sdk/contracts/Email/email.validation-state';
import { FoldersTypeCheck } from '../../services/folders-utility/folders-type-check.service';
import { Subscription } from 'rxjs';
import { SearchContextStorageService } from '../../services/search/search-context-storage.service';

@Component({
  selector: 'app-emails-container',
  templateUrl: './emails-container.component.html',
  styleUrls: ['./emails-container.component.scss'],
})
export class EmailsContainerComponent implements OnInit, OnDestroy {
  private subs: Subscription;
  public all: EmailValidationState[];
  public validated: EmailValidationState[];
  public notValidated: EmailValidationState[];
  public isInbox = true;
  public isAnyFolders = true;
  public isAllFoldersSelected = false;
  public currentFolderName = 'Inbox';
  public currentFolder;
  public selectedTabIndex = 0;
  private searchContextSubscription: Subscription;
  public openedTabs = [];
  public tabIndex = 0;

  constructor(private readonly foldersTypeCheck: FoldersTypeCheck, private searchContextStorageService: SearchContextStorageService) {}

  ngOnInit(): void {
    this.all = [EmailValidationState.New, EmailValidationState.Validated, EmailValidationState.NotValidated];
    this.validated = [EmailValidationState.New, EmailValidationState.Validated];
    this.notValidated = [EmailValidationState.NotValidated];
    this.foldersTypeCheck.isInbox$.subscribe((isInbox) => {
      this.tabIndex = 0;
      this.selectedTabIndex = 0;
      this.isInbox = isInbox;
      this.openedTabs = [];
    });
    this.foldersTypeCheck.isAnyFolder$.subscribe((isAnyFolder) => {
      this.isAnyFolders = isAnyFolder;
    });
    this.isAnyFolders = this.foldersTypeCheck.isAnyFolder;
    this.searchContextSubscription = this.searchContextStorageService.searchContext$.subscribe(async (searchContext) => {
      const selectedMailFolderIds = searchContext.selectedMailFolderIds;
      const selectedMailAccount = this.searchContextStorageService?.userFolders?.find((f) => f.mailFolderId === selectedMailFolderIds[0]);
      const selectedMailAccountId = selectedMailAccount?.mailAccountId;
      this.currentFolderName = selectedMailAccount?.mailFolderName;
      this.currentFolder = selectedMailAccount;
      const allSelectedFolders = this.searchContextStorageService?.userFolders?.filter((e) => e.mailAccountId === selectedMailAccountId);
      this.isAllFoldersSelected = selectedMailFolderIds?.length === allSelectedFolders?.length;
    });
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
    this.searchContextSubscription?.unsubscribe();
  }

  changeTab(event): void {
    this.tabIndex = event.index;
  }
}
