import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { UserGroupRequest } from '../contracts/user-group/user-group.request';
import { UserGroupsResponse } from '../contracts/user-group/user-group.response';
import { ApiRoutes } from '../routes/api.routes';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class UserGroupClient {
  constructor(private apiClient: ApiRequest) {}

  public async setUsersGroups(request: UserGroupRequest) {
    return this.apiClient.post<UserGroupRequest, any>(request, `${API_URL}${ApiRoutes.userGroup.setUsersGroups}`);
  }

  public async setGroupsUsers(request: UserGroupRequest) {
    return this.apiClient.post<UserGroupRequest, any>(request, `${API_URL}${ApiRoutes.userGroup.setGroupsUsers}`);
  }

  public async getUserGroups(request: BaseGetByIdRequest) {
    return this.apiClient.get<UserGroupsResponse>(`${API_URL}${ApiRoutes.userGroup.getUserGroups}?${request.format()}`);
  }

  public async getGroupUsers(request: BaseGetByIdRequest) {
    return this.apiClient.get<UserGroupsResponse>(`${API_URL}${ApiRoutes.userGroup.getGroupUsers}?${request.format()}`);
  }
}
