<div>
  <div mat-dialog-actions style="margin-bottom: 12px" class="flex justify-between relative">
    <h1 style="margin: 0" mat-dialog-title>{{ "contacts" | translate }}</h1>
    <button tabIndex="-1" class="absolute top-[-28px] right-[-10px]" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div>
    <div class="relative">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-icon class="absolute right-0 top-[-7px]">search</mat-icon>
        <mat-label>{{ "search" | translate }}</mat-label>
        <input
          type="text"
          #input
          placeholder="{{ 'searchContacts' | translate }}"
          aria-label="Search contacts"
          matInput
          [formControl]="myControl" />
      </mat-form-field>
    </div>
    <div class="flex justify-end my-2">
      <app-paginator
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (pageSizeChange)="pageSizeChange($event)"
        [hasPreviousPage]="canNavigateBack()"
        [hasNextPage]="canNavigateForward()"
        (goToPreviousPage)="navigateBack()"
        (goToNextPage)="navigateForward()">
      </app-paginator>
    </div>
    <div class="table-responsive" *ngIf="isLoading">
      <app-loader></app-loader>
    </div>
    <div *ngIf="!isLoading && isError" class="h-full w-full">
      <app-something-went-wrong
        description="{{ 'cannotLoadData' | translate }}"
        [showPicture]="false"
        (retryEmitter)="ngOnInit()"></app-something-went-wrong>
    </div>
    <div *ngIf="!isLoading && !isError" class="table-responsive">
      <table mat-table [dataSource]="dataSource" class="page-block">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef style="width: 36px">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              color="primary"
              [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row" style="width: 36px">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              color="primary">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th class="flex-1" mat-header-cell *matHeaderCellDef>{{ "email" | translate }}</th>
          <td class="flex-1" mat-cell style="cursor: pointer" *matCellDef="let contact" (click)="addContact(contact.email)">
            {{ contact.email }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th style="width: 60px" mat-header-cell *matHeaderCellDef>{{ "delete" | translate }}</th>
          <td style="width: 60px" mat-cell *matCellDef="let contact" class="action-link">
            <button mat-icon-button (click)="deleteContact(contact)">
              <mat-icon>close</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <div class="h-[36px] flex justify-between mt-6">
      <button [disabled]="selection.isEmpty()" color="primary" mat-raised-button (click)="openConfirmDialog()">
        {{ "delete" | translate }}
      </button>
      <button [disabled]="selection.isEmpty()" color="primary" mat-raised-button (click)="addSelectedContacts()">
        {{ "select" | translate }}
      </button>
    </div>
  </div>
</div>
