<div class="relative">
  <button
    [disabled]="isLoading"
    class="absolute top-[-12px] right-[-12px]"
    (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>
  <h1 mat-dialog-title>{{ "permanentlyDelete" | translate }}</h1>
  <div mat-dialog-content>
    <p>
      <b>{{ "attention" | translate }}:</b>
      {{ "selectedMessagePermanentlyDeleted" | translate }}
      <b>{{ "trashUpperCase" | translate }}</b>
      {{ "andNoPossibleRecover" | translate }}
    </p>
  </div>
  <div mat-dialog-actions>
    <div class="w-full flex justify-between">
      <button mat-flat-button [disabled]="isLoading" color="primary" (click)="onOkClick()">
        <app-loading-button text="{{ 'yes' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
      <button mat-flat-button [disabled]="isLoading" color="primary" (click)="onNoClick()">
        {{ 'no' | translate }}
      </button>
      <!-- <button mat-flat-button color="primary" (click)="onOkClick()">{{ "yes" | translate }}</button>
      <button mat-flat-button color="primary" (click)="onNoClick()">{{ "no" | translate }}</button> -->
    </div>
  </div>
</div>
