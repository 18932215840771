<div class="w-screen h-screen p-5 flex flex-wrap content-center justify-center text-center">
  <div class="flex flex-col items-center">
    <img class="w-[200px]" src="../../../assets/logo.svg" alt="Logo" />
    <div class="menu" *ngIf="links">
      <ul>
        <li *ngFor="let link of links">
          <a mat-button href="{{ currentUrl + link.webCode }}"> {{ link.name }} </a>
        </li>
      </ul>
    </div>
  </div>
</div>
