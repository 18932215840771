import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MyMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate.get(['nextPage', 'previousPage']).subscribe((translation) => {
      this.nextPageLabel = translation['nextPage'];
      this.previousPageLabel = translation['previousPage'];
      this.changes.next();
    });
  }
}
