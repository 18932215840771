<div class="h-full">
  <div *ngIf="isAuditLoading || isAuditLoadingError" class="flex justify-between h-full">
    <div *ngIf="isAuditLoadingError" class="h-full w-full">
      <app-something-went-wrong description="{{ 'cannotLoadData' | translate }}" [showPicture]="false"
        (retryEmitter)="getEvents()"></app-something-went-wrong>
    </div>
    <div class="canvas h-full w-full" *ngIf="isAuditLoading">
      <app-loader height="100%"></app-loader>
    </div>
  </div>
  <div *ngIf="!isAuditLoading && !isAuditLoadingError" class="w-full h-full flex flex-col">
    <div *ngIf="title" class="text-2xl mb-10 font-medium">{{ title | translate }}</div>
    <div class="notes h-full">
      <div *ngFor="let event of events" class="note">
        <div [class.audit-error]="isAuditWithError(event)" class="note-header">
          <div class="p-1.5 flex flex-col text-gray-600">
            <div class="text-sm font-medium">{{ event.type | emailAuditEventType | translate }}</div>
            <!-- <div class="text-xs">{{ event.creationDateTime | date: "MMM d, y, h:mm a" }}</div> -->
            <div class="text-xs">{{ event.creationDateTime | date : "dd/MM/yyyy HH:mm:ss" }}</div>
          </div>
        </div>
        <div [class.audit-error]="isAuditWithError(event)" *ngIf="event.metadata"
          style="border-bottom: none; max-height: 120px; overflow: auto; white-space: pre-line" class="audit-border p-1.5">
          {{ event.metadata }}
        </div>
        <div [class.audit-error]="isAuditWithError(event)" class="audit-border p-1.5">
          {{ event.userFirstName + " " + event.userLastName }}
        </div>
      </div>
    </div>
  </div>
</div>
