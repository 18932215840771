<div class="flex justify-between h-full">
  <div *ngIf="isError" class="h-full w-full">
    <app-something-went-wrong
      description="{{ 'cannotLoadData' | translate }}"
      [showPicture]="false"
      (retryEmitter)="ngOnInit()"></app-something-went-wrong>
  </div>
  <div class="canvas" *ngIf="isLoading">
    <app-loader height="100%"></app-loader>
  </div>
</div>
<div *ngIf="!isLoading && !isError">
  <div class="relative">
    <h1 mat-dialog-title>{{ "addTags" | translate }}</h1>
    <button tabindex="-1" style="position: absolute; top: -20px; right: -17px" mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <!-- <p>{{'addTagsToEmail' | translate}}</p> -->
    <div class="p-2 tags-dialog">
      <app-tags
        [needSaveTags]="false"
        (setTags)="setTags($event)"
        (setIsSave)="setIsSave()"
        [emailId]="data.ids"
        [existedTags]="existedTags"
        [thisTags]="thisTags"></app-tags>
    </div>
  </div>
</div>
<div mat-dialog-actions class="flex justify-end">
  <button mat-flat-button [disabled]="isSavingTags" color="primary" (click)="saveTags()">
    <app-loading-button text="{{ 'save' | translate }}" [loading]="isSavingTags"></app-loading-button>
  </button>
</div>
