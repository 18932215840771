<div class="condtion-wrapper">
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="font-medium text-lg">{{ "conditions" | translate }}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="overflow-auto">
        <app-dispatching-condition
          *ngFor="let condition of showingConditions"
          [header]="condition.header"
          [paramName]="condition.paramName"
          [emailCondition]="getDispatchingConditions(condition.paramName)">
        </app-dispatching-condition>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
