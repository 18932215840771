<div class="drawer-content-screen flex flex-col justify-between">
  <div *ngIf="!isLoadingData" class="text-2xl mb-10 font-medium">
    {{ "quickSetupOfMailAccount" | translate }}
  </div>
  <div *ngIf="(!isLoadingData && !cannotLoadData && !canSetup) || activated" class="w-full h-full flex items-center justify-center">
    <div *ngIf="!canSetup" class="mb-3">
      <app-error-hint errorText="{{ 'cannotSetupAccountHasCustomFoldersOrDispatchingRulesErrorHint' | translate }}"></app-error-hint>
      <button style="display: block; margin: 0.5rem auto" color="primary" (click)="cancel()" mat-flat-button>{{ "ok" | translate }}</button>
    </div>
    <div *ngIf="activated" class="mb-3">
      <div class="text-green-600 text-sm flex mx-auto">
        <mat-icon class="mr-1 mb-2">check_circle</mat-icon>
        <span class="flex flex-wrap content-center">{{ "mailAccountWasSuccessfullyActivated" | translate }}</span>
      </div>
      <button style="display: block; margin: 0.5rem auto" color="primary" (click)="cancel()" mat-flat-button>{{ "ok" | translate }}</button>
    </div>
  </div>
  <div class="h-full w-full" *ngIf="cannotLoadData">
    <app-something-went-wrong
      [title]="errorText | translate"
      description="{{ 'cannotLoadData' | translate }}"
      (retryEmitter)="loadData()"
      [showPicture]="false"></app-something-went-wrong>
  </div>
  <div class="h-full w-full" *ngIf="isLoadingData">
    <app-loader height="100%"></app-loader>
  </div>
  <div class="h-full w-full flex-grow overflow-y-auto" *ngIf="!isLoadingData && !cannotLoadData && canSetup && !activated">
    <div class="mb-5">
      <mat-checkbox color="primary" [ngModelOptions]="{ standalone: true }" [(ngModel)]="startMailAccount">{{
        "startMailAccount" | translate
      }}</mat-checkbox>
    </div>
    <div class="font-medium text-lg mb-5">{{ "selectFoldersForSync" | translate }}</div>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="folder-tree">
      <mat-tree-node *matTreeNodeDef="let item" matTreeNodeToggle>
        <div class="mat-tree-node">
          <button mat-icon-button disabled></button>

          <mat-checkbox class="checklist-leaf-node" color="primary" [checked]="item.sync" (change)="selectFolder(item)">{{
            item.shortName
          }}</mat-checkbox>
        </div>
      </mat-tree-node>
      <mat-nested-tree-node *matTreeNodeDef="let item; when: hasChild">
        <div>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + item.shortName">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(item) ? "remove" : "add" }}
              </mat-icon>
            </button>
            <mat-checkbox class="checklist-leaf-node" [checked]="item.sync" color="primary" (change)="selectFolder(item)">{{
              item.shortName
            }}</mat-checkbox>
          </div>
          <div [class.folder-tree-invisible]="!treeControl.isExpanded(item)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </div>
      </mat-nested-tree-node>
    </mat-tree>
    <div class="mt-4">
      <div style="color: rgb(238, 178, 99)">{{ "someRemoteFoldersCanBeNotVisibleInQuickSetup" | translate }}</div>
    </div>
  </div>
  <div *ngIf="!isLoadingData && !cannotLoadData && canSetup && !activated" class="w-full">
    <div *ngIf="isError" class="mb-3">
      <app-error-hint errorText="{{ 'cannotSetupOfMailAccountErrorHint' | translate }}"></app-error-hint>
    </div>
    <div class="flex justify-end" *ngIf="!activated">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !canSetup" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
