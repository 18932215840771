import { Component, OnDestroy, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TreeItem } from '../../sdk/contracts/tree.item';
import { MailFolderContract } from '../../sdk/contracts/mail-folder/mail-folder.contract';
import { RoutingState } from '../../services/routing/routing.state';
import { MailFolderClient } from '../../sdk/clients/mail-folder.client';
import { ApiException } from '../../sdk/api-errors/api.exception';
import { NestedTreeControl } from '@angular/cdk/tree';
import { EventsService } from '../../services/events/events.service';
import { FoldersTypeCheck } from '../../services/folders-utility/folders-type-check.service';
import { Observable, Subscription } from 'rxjs';
import { MailAccountGetUnreadCountRequest } from '../../sdk/contracts/mail-folder/mail-folder.get-unreadcount.request';
import { MailFolderGetUnreadCountRequest } from '../../sdk/contracts/mail-folder/mail-folder.get-folder-unreadcount.request';
import { MailFolderType } from '../../sdk/contracts/mail-folder/mail-folder.type';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MailFolderContractWithFullNameForResponse } from '../../sdk/contracts/mail-folder/mail-folder-contract-with-full-name.response';
import { MailFolderDeleteDialogComponent } from './mail-folder-delete-dialog/mail-folder-delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SearchContextStorageService } from '../../services/search/search-context-storage.service';
import { SearchContext } from '../../services/search/search-context';
import { MobileObserverService } from '../../services/adaptive/mobile-observer.service';
import { MatchError } from 'src/app/shared/error-matcher';
import { MailAccountClient } from 'src/app/sdk/clients/mail-account.client';

@Component({
  selector: 'app-mail-account-folders',
  templateUrl: './mail-account-folders.component.html',
  styleUrls: ['./mail-account-folders.component.scss'],
})
export class MailAccountFoldersComponent implements OnInit, OnDestroy {
  @Input() mailAccountId: number;
  @Input() defaultMailAccountId: number;
  @Input() reloadEvent: Observable<void>;
  @Output() public getBadgesForMailAccounts = new EventEmitter();

  public searchContext: SearchContext;

  public showLoader = false;
  public loadError = false;
  public webCode: string;
  public useMobileView = false;

  public tree: TreeItem<number, MailFolderContract>[] = null;
  public dataSource = new MatTableDataSource<TreeItem<number, MailFolderContract>>(this.tree);
  // public dataSource

  public apiError: string;
  treeControl = new NestedTreeControl<TreeItem<number, MailFolderContract>>((node) => node.children);
  // treeControl = new NestedTreeControl<any>((node) => node.key);
  public selectedItem: TreeItem<number, MailFolderContract>;
  public selectedFolderId: number;

  public folderCounts = [];
  public tempFolderCounts = {};
  public mailFolderTypes = MailFolderType;

  menuTopLeftPosition = { x: '0', y: '0' };
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

  public parentMailFolderIdForCreation: number | null;
  public createDrawerIsOpen = false;
  public renameDrawerIsOpen = false;
  public editingFolder: MailFolderContractWithFullNameForResponse | null;
  private unreadRefreshInterval;
  private useMobileViewSubscription: Subscription;
  public hideNotification = true;
  public currentMailAccountId: number;
  public parentMailFolderTypeForCreation: number;

  constructor(
    public matchError: MatchError,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private routingState: RoutingState,
    private mailFolderClient: MailFolderClient,
    private mailAccountClient: MailAccountClient,
    private eventsService: EventsService,
    public dialog: MatDialog,
    private searchContextStorageService: SearchContextStorageService,
    private readonly foldersTypeCheck: FoldersTypeCheck,
    private mobileObserverService: MobileObserverService
  ) {}

  async ngOnInit(): Promise<any> {
    this.useMobileViewSubscription = this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));
    this.currentMailAccountId = this.defaultMailAccountId;
    this.webCode = this.activateRoute.parent.snapshot.paramMap.get('webCode');
    await this.load(false);

    this.searchContextStorageService.currentMailAccountId$.subscribe(async (currentMailAccountId) => {
      if (this.mailAccountId === currentMailAccountId) {
        this.refreshUnread();
      }
      this.currentMailAccountId = currentMailAccountId;
    });

    this.eventsService.unreadCountEvent.subscribe(() => {
      const isBadgesUpdate = this.hideNotification !== this.eventsService.unreadCountValue;
      this.hideNotification = !this.eventsService.unreadCountValue;
      if (this.currentMailAccountId === this.mailAccountId) {
        if (this.eventsService.unreadCountValue) {
          this.refreshUnread(isBadgesUpdate);
        } else {
          this.refreshUnread(isBadgesUpdate);
        }
      }
    });

    this.eventsService.intervalUpdateBadges.subscribe(() => {
      if (this.currentMailAccountId === this.mailAccountId) {
        this.refreshUnread(false);
      }
    });

    this.eventsService.unreadCountFolderEvent.subscribe(() => {
      if (this.currentMailAccountId === this.mailAccountId) {
        if (this.eventsService.unreadCountValue) {
          this.refreshUnreadForFolder(this.eventsService.unreadCountFolderId);
        } else {
          this.refreshUnreadForFolder(this.eventsService.unreadCountFolderId);
        }
      }
    });
  }

  public openedFoldersIds = [];
  public isOpenedFolder(folderId: number): boolean {
    return !this.openedFoldersIds.includes(folderId);
  }

  expandCollapseTree(item): void {
    const isOpened = this.openedFoldersIds.includes(item.data.mailFolderId);
    if (!isOpened) {
      this.openedFoldersIds.push(item.data.mailFolderId);
    } else {
      this.openedFoldersIds = this.openedFoldersIds.filter((e) => e !== item.data.mailFolderId);
    }
  }

  async load(refreshUnread = true): Promise<any> {
    try {
      this.getMailAccountFolders();
      if (this.currentMailAccountId === this.mailAccountId) {
        await this.refreshUnread(refreshUnread);
      }
    } catch (e) {
      if (e instanceof ApiException) {
        this.apiError = e.format();
      } else {
        this.apiError = e.message;
      }
      this.matchError.logError(e);
    }
  }

  public getMailAccountFolders(): void {
    this.tree = this.searchContextStorageService.tree.filter((e) => e.key === this.mailAccountId)[0].children;
    this.dataSource.data = this.tree;
  }

  hasChild = (_: number, node: TreeItem<number, MailFolderContract>) => !!node.children && node.children.length > 0;

  async selectFolder(folderItem: any): Promise<void> {
    this.selectedItem = folderItem;
    this.selectedFolderId = folderItem.data.mailFolderId;
    this.foldersTypeCheck.isInboxFolderOrSubfolder(this.dataSource, this.selectedItem);
    this.searchContextStorageService.selectMailFolders([folderItem.data.mailFolderId], true, this.hideNotification);
    if (this.useMobileView) {
      this.eventsService.closeLeftMenu();
    }
    await this.router.navigate([this.webCode, `emails`]);
    this.refreshUnreadForFolder(folderItem.data.mailFolderId, folderItem.data.folderType === MailFolderType.Inbox);
  }

  ngOnDestroy(): void {
    this.useMobileViewSubscription?.unsubscribe();
    clearInterval(this.unreadRefreshInterval);
  }

  public getCountForFolder(folderId: number): number | null {
    return this.folderCounts.find((e) => e.mailFolderId === folderId)?.count || null;
  }

  private async refreshUnread(updateBadges = true): Promise<any> {
    if (updateBadges) {
      this.getBadgesForMailAccounts.emit([this.mailAccountId]);
    }
    const counts = await this.mailFolderClient.getUnreadCount(
      new MailAccountGetUnreadCountRequest(this.mailAccountId, this.hideNotification)
    );
    this.folderCounts = counts?.data;
  }

  private async refreshUnreadForFolder(folderId: number, updateBadges = true): Promise<any> {
    if (updateBadges) {
      this.getBadgesForMailAccounts.emit([this.mailAccountId]);
    }
    try {
      const counts = await this.mailFolderClient.getFolderUnreadCount(new MailFolderGetUnreadCountRequest(folderId, this.hideNotification));
      if (counts === null) {
        this.folderCounts = this.folderCounts.filter((e) => e.mailFolderId !== folderId);
      } else {
        this.folderCounts = this.folderCounts.filter((e) => e.mailFolderId !== counts.mailFolderId);
        this.folderCounts.push(counts);
      }
    } catch (e) {
      this.matchError.logError(e);
    }
  }

  onRightClick(event: MouseEvent, item): void {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item };
    // we open the menu
    if (item.folderType !== MailFolderType.Inbox && item.folderType !== MailFolderType.Archive && item.folderType !== MailFolderType.Sent) {
      return null;
    } else {
      this.matMenuTrigger.openMenu();
    }
  }

  public createFolder(item: MailFolderContract): void {
    this.parentMailFolderTypeForCreation = item.folderType;
    this.parentMailFolderIdForCreation = item.mailFolderId;
    this.createDrawerIsOpen = true;
  }

  public onCloseCreateModalForm(): void {
    this.createDrawerIsOpen = false;
    this.parentMailFolderIdForCreation = null;
  }

  public async onSubmitCreation(): Promise<void> {
    this.onCloseCreateModalForm();
    await this.searchContextStorageService.refreshData();
    await this.load();
  }

  public renameFolder(folder: MailFolderContractWithFullNameForResponse): void {
    this.parentMailFolderTypeForCreation = folder.folderType;
    this.editingFolder = folder;
    this.renameDrawerIsOpen = true;
  }

  public onCloseRenameModalForm(): void {
    this.renameDrawerIsOpen = false;
    this.editingFolder = null;
  }

  public async onSubmitRename(): Promise<void> {
    this.onCloseRenameModalForm();
    await this.searchContextStorageService.refreshData();
    await this.load();
  }

  public deleteFolder(folder: MailFolderContractWithFullNameForResponse): void {
    const dialogRef = this.dialog.open(MailFolderDeleteDialogComponent, {
      width: '450px',
      data: { mailFolderId: folder.mailFolderId, folderName: folder.name },
    });

    dialogRef.afterClosed().subscribe(async (x) => {
      if (x?.isDeleted) {
        await this.searchContextStorageService.refreshData();
        await this.load();
      }
    });
  }
}
