import { BaseGetRequest } from '../../common/base-get.request';

export class MailFolderSettingsGetByMailFolderRequest extends BaseGetRequest {
  constructor(mailAccountId: number, mailFolderId: number) {
    super();
    this.mailAccountId = mailAccountId;
    this.mailFolderId = mailFolderId;
  }

  mailAccountId: number;
  mailFolderId: number;
}
