<div class="w-full h-full" *ngIf="isLoading">
  <app-loader height="100%"></app-loader>
</div>

<div class="w-full h-full" *ngIf="cannotLoadMeta">
  <app-something-went-wrong
    [showPicture]="false"
    description="Cannot load notes"
    (retryEmitter)="getEmailDetail()"></app-something-went-wrong>
</div>

<div class="w-full h-full flex flex-col notes" *ngIf="!isLoading && !cannotLoadMeta">
  <div *ngIf="title" class="text-2xl mb-10 font-medium">{{ title | translate }}</div>
  <div class="flex-none pl-1 pr-2 note mb-3" style="overflow-wrap: anywhere">
    {{ meta }}
  </div>
</div>
