import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailNoteClient } from '../../../../sdk/clients/email-note.client';
import { EmailNoteContract } from '../../../../sdk/contracts/email-note/email-note.contract';
import { BaseGetByIdRequest } from '../../../../sdk/contracts/common/base-get-by-id.request';
import { BaseDeleteRequest } from '../../../../sdk/contracts/common/base-delete.request';
import { EmailNoteCreateRequest } from '../../../../sdk/contracts/email-note/email-note.create-request';
import { EmailNoteUpdateRequest } from '../../../../sdk/contracts/email-note/email-note.update-request';
import { UserClient } from '../../../../sdk/clients/user.client';
import { UserContract } from '../../../../sdk/contracts/user/user.contract';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-email-note-list',
  templateUrl: './email-note-list.component.html',
  styleUrls: ['./email-note-list.component.scss'],
})
export class EmailNoteListComponent implements OnInit {
  @Input() emailId: number;
  @Input() title: string;
  @Output() refreshNotes = new EventEmitter();
  public isLoading = false;
  public cannotLoadNotes = false;
  public notes: EmailNoteContract[] = [];
  public editingNoteIds: number[] = [];
  public editInProgressNoteIds: number[] = [];
  public deleteInProgressNoteIds: number[] = [];
  public user: UserContract;

  constructor(
    private emailNoteClient: EmailNoteClient,
    private route: ActivatedRoute,
    private userClient: UserClient,
    private matchError: MatchError,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAvailableData();
  }

  async loadAvailableData(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadNotes = false;
    try {
      const response = await this.emailNoteClient.getForEmail(new BaseGetByIdRequest(this.emailId));
      this.notes = response.data;
      const responseUser = await this.userClient.getUser();
      this.user = responseUser.result;
    } catch (e) {
      this.cannotLoadNotes = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  editNote(noteId: number): void {
    this.editingNoteIds.push(noteId);
  }

  noteIsBeingEdited(noteId: number): boolean {
    return this.editingNoteIds.includes(noteId);
  }

  async updateNote(note: EmailNoteContract): Promise<void> {
    this.editInProgressNoteIds.push(note.emailNoteId);
    note.updating = false;
    const request = new EmailNoteUpdateRequest();
    request.emailNoteId = note.emailNoteId;
    request.note = note.note;
    try {
      await this.emailNoteClient.update(request);
      this.editingNoteIds = this.editingNoteIds.filter((id) => id !== note.emailNoteId);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.editInProgressNoteIds = this.editInProgressNoteIds.filter((id) => id !== note.emailNoteId);
    }
  }

  noteIsUpdating(noteId: number): boolean {
    return this.editInProgressNoteIds.some((id) => id === noteId);
  }

  async deleteNote(note: EmailNoteContract): Promise<void> {
    this.deleteInProgressNoteIds.push(note.emailNoteId);
    try {
      await this.emailNoteClient.delete(new BaseDeleteRequest(note.emailNoteId));
      this.notes = this.notes.filter((n) => n.emailNoteId !== note.emailNoteId);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.deleteInProgressNoteIds = this.deleteInProgressNoteIds.filter((id) => id !== note.emailNoteId);
    }
  }

  noteIsDeleting(noteId: number): boolean {
    return this.deleteInProgressNoteIds.some((id) => id === noteId);
  }

  async createNewNote(value: string): Promise<void> {
    if (value.length === 0) return;
    const request = new EmailNoteCreateRequest();
    request.emailId = this.emailId;
    request.note = value;
    try {
      await this.emailNoteClient.create(request);
      this.refreshNotes.emit();
      await this.loadAvailableData();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  canManageNote(noteUserId: number): boolean {
    return this.user.userId === noteUserId;
  }
}
