<div class="drawer-content-screen flex flex-col justify-between">
  <div class="w-full mb-2">
    <div class="text-2xl mb-10 font-medium">
      {{ "updateUser" | translate }}
    </div>
    <div>
      <form [formGroup]="form" autocomplete="off" novalidate>
        <mat-form-field appearance="fill">
          <mat-label>{{ "firstName" | translate }}</mat-label>
          <input matInput id="firstName" formControlName="firstName" required />
          <mat-error *ngIf="hasError('firstName', 'required')">{{ "nameIsRequired" | translate }}</mat-error>
          <mat-error *ngIf="hasError('firstName', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "lastName" | translate }}</mat-label>
          <input matInput id="lastName" formControlName="lastName" required />
          <mat-error *ngIf="hasError('lastName', 'required')">{{ "nameIsRequired" | translate }}</mat-error>
          <mat-error *ngIf="hasError('lastName', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field matTooltip="{{ 'emailCannotBeUpdatedUpdateUser' | translate }}" appearance="fill">
          <mat-label>{{ "emailAccountSettings" | translate }}</mat-label>
          <input matInput id="email" formControlName="email" required readonly />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "language" | translate }}</mat-label>
          <!-- <input matInput id="locale" formControlName="locale" required /> -->
          <mat-select id="locale" formControlName="locale">
            <ng-container *ngFor="let item of locales">
              <mat-option *ngIf="item.id !== 'fr' && item.id !== 'de'" [value]="item.id">{{item.language}}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="w-full">
    <!-- <div *ngIf="isError" class="mb-3">
      <app-error-hint errorText={{"cannotUpdateUserSomethingWentWrong" | translate}}></app-error-hint>
    </div> -->
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
