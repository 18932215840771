export interface FilterFields {
  search: null | string;
  ProviderId: null | number;
  isActive: null | boolean;
  isArchive: null | boolean;
  searchOrder: number;
  orderDirection: number;
}

export const defaultFilterFields: FilterFields = {
  search: null,
  ProviderId: null,
  isActive: null,
  isArchive: null,
  searchOrder: 1,
  orderDirection: 1,
};
