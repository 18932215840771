import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EmailCondition } from '../../../../../../sdk/contracts/dispatching/email.condition';
import { ExpressionOperation } from '../../../../../../sdk/contracts/dispatching/expression.operation';
import { DispatchingRule } from '../../../../../../sdk/contracts/dispatching/dispatching.rule';

interface ShowingCondition {
  header: string;
  paramName: string;
}

@Component({
  selector: 'app-dispatching-conditions',
  templateUrl: './dispatching-conditions.component.html',
  styleUrls: ['./dispatching-conditions.component.scss'],
})
export class DispatchingConditionsComponent implements OnInit {
  @Input() applyToEmails: boolean;
  @Input() applyToNotifications: boolean;
  @Input() emailConditions: EmailCondition[];
  @Input() dispatchingRule: DispatchingRule;
  @Output() changeEmailClassesEventEmitter = new EventEmitter<{ applyToEmails: boolean; applyToNotifications: boolean }>();
  public showingConditions: ShowingCondition[] = [
    { header: 'subject', paramName: 'subject' },
    { header: 'from', paramName: 'from' },
    { header: 'to', paramName: 'to' },
    { header: 'cc', paramName: 'cc' },
    { header: 'bcc', paramName: 'bcc' },
    { header: 'body', paramName: 'body' },
  ];
  panelOpenState: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  public getDispatchingConditions(paramName): EmailCondition | undefined {
    const index = this.emailConditions.findIndex((c) => c.paramName === paramName);
    if (index === -1) {
      this.emailConditions.push({
        paramName,
        operation: ExpressionOperation.Like,
        patterns: [],
      });
      return this.emailConditions[this.emailConditions.length - 1];
    }
    return this.emailConditions[index];
  }

  public onChangeEmailClasses(): void {
    this.changeEmailClassesEventEmitter.emit({
      applyToEmails: this.applyToEmails,
      applyToNotifications: this.applyToNotifications,
    });
  }

  public onPatternChange({ index, pattern }: any, paramName: string): void {
    const conditionIndex = this.emailConditions.findIndex((c) => c.paramName === paramName);
    this.emailConditions[conditionIndex].patterns[index] = pattern;
  }
}
