<div class="drawer-content-screen flex flex-col justify-between">
  <div *ngIf="cannotLoadParentOrganizations" class="flex flex-col justify-center h-full">
    <app-something-went-wrong
      [showPicture]="false"
      description="{{ 'cannotLoadData' | translate }}"
      (retryEmitter)="ngOnInit()"></app-something-went-wrong>
  </div>
  <div *ngIf="loadingParentOrganizations" class="flex flex-col justify-center h-full">
    <app-loader></app-loader>
  </div>
  <div *ngIf="!loadingParentOrganizations && !cannotLoadParentOrganizations" class="text-2xl mb-10 font-medium">
    {{ "updateCompany" | translate }}
  </div>
  <div class="h-full w-full flex-grow overflow-y-auto" *ngIf="!loadingParentOrganizations && !cannotLoadParentOrganizations">
    <form [formGroup]="form" autocomplete="off" novalidate>
      <mat-form-field appearance="fill">
        <mat-label>{{ "code" | translate }}</mat-label>
        <input matInput id="code" formControlName="code" required />
        <mat-error *ngIf="hasError('code', 'required')">{{ "codeIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('code', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "name" | translate }}</mat-label>
        <input matInput id="name" formControlName="name" required />
        <mat-error *ngIf="hasError('name', 'required')">{{ "nameIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('name', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "webCode" | translate }}</mat-label>
        <input matInput id="webCode" formControlName="webCode" required placeholder="company or company-name" />
        <mat-error *ngIf="hasError('webCode', 'required')">{{ "webCodeIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('webCode', 'maxlength')">{{ "moreThan32Char" | translate }}</mat-error>
        <mat-error *ngIf="hasError('webCode', 'pattern')">{{ "mustContainsOnlyLowercaseLetters" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "licenseId" | translate }}</mat-label>
        <input matInput id="licenseId" formControlName="licenseId" required />
        <mat-error *ngIf="hasError('licenseId', 'required')">{{ "licenseIdIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('licenseId', 'maxlength')">{{ "moreThan128Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "codeSia" | translate }}</mat-label>
        <input matInput id="codeSia" formControlName="codeSia" required />
        <mat-error *ngIf="hasError('codeSia', 'required')">{{ "codeSiaIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('codeSia', 'maxlength')">{{ "moreThan15Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "anagId" | translate }}</mat-label>
        <input matInput id="anagId" formControlName="anagId" required />
        <mat-error *ngIf="hasError('anagId', 'required')">{{ "anagIdIsRequired" | translate }}</mat-error>
        <!-- <mat-error *ngIf="hasError('anagId', 'maxlength')">{{ "moreThanTenChar" | translate }}</mat-error>
        <mat-error *ngIf="hasError('anagId', 'minlength')">{{ "lessThanTenChar" | translate }}</mat-error>
        <mat-error *ngIf="hasError('anagId', 'pattern')">{{ "mustBeInt" | translate }}</mat-error> -->
      </mat-form-field>
      <div class="flex flex-col">
        <div class="font-medium text-lg mb-3 mt-6">{{ "pignor" | translate }}</div>

        <mat-checkbox color="primary" [(ngModel)]="pignor" [ngModelOptions]="{ standalone: true }">{{ "pignor" | translate }}</mat-checkbox>
      </div>
      <!--        <mat-form-field appearance="fill">-->
      <!--          <mat-label>Organization type</mat-label>-->
      <!--          <mat-select id="type" formControlName="type" required>-->
      <!--            <mat-option *ngFor="let type of availableOrganizationTypes" [value]="type">-->
      <!--              {{organizationTypes[type]}}-->
      <!--            </mat-option>-->
      <!--          </mat-select>-->
      <!--        </mat-form-field>-->
      <!--        <mat-form-field appearance="fill" *ngIf="form.get('type').value === organizationTypes.Company">-->
      <!--          <mat-label>Parent organization</mat-label>-->
      <!--          <mat-select [(ngModel)]="parentOrganization" [ngModelOptions]="{standalone: true}">-->
      <!--            <mat-option>None</mat-option>-->
      <!--            <mat-option *ngFor="let parentOrg of availableParentOrganizations" [value]="parentOrg">-->
      <!--              {{parentOrg.name}}-->
      <!--            </mat-option>-->
      <!--          </mat-select>-->
      <!--        </mat-form-field>-->

      <div class="flex flex-col">
        <div class="font-medium text-lg mb-3 mt-6">{{ "authSettings" | translate }}</div>

        <mat-checkbox [disabled]="samlSupport" color="primary" [(ngModel)]="oidcSupport" [ngModelOptions]="{ standalone: true }">{{
          "GAW" | translate
        }}</mat-checkbox>
        <!-- <mat-checkbox color="primary" [disabled]="true" [(ngModel)]="adfsSupport" [ngModelOptions]="{standalone: true}" [disableRipple]="true">Credem ADFS</mat-checkbox> -->
        <mat-checkbox [disabled]="samlSupport" color="primary" [(ngModel)]="googleOAuthSupport" [ngModelOptions]="{ standalone: true }">{{
          "google" | translate
        }}</mat-checkbox>
        <div *ngIf="true">
          <mat-checkbox
            color="primary"
            (change)="onSamlChange($event.checked)"
            [(ngModel)]="samlSupport"
            [ngModelOptions]="{ standalone: true }"
            [disableRipple]="true"
            >{{ "saml" | translate }}</mat-checkbox
          >

          <div>
            <mat-form-field appearance="fill">
              <mat-label>{{ "samlRtaClaim" | translate }}</mat-label>
              <input matInput id="samlRtaClaim" formControlName="samlRtaClaim" />
              <mat-error *ngIf="hasError('samlRtaClaim', 'required')">{{ "samlRtaClaimIsRequired" | translate }}</mat-error>
              <mat-error *ngIf="hasError('samlRtaClaim', 'maxlength')">{{ "moreThan265Char" | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="!authTypeIsSelected" class="mt-2">
          <app-error-hint
            errorText="{{ 'selectAtLeastOneTypeOfAuthenticationErrorHint' | translate }}"
            [centerAlign]="false"></app-error-hint>
        </div>
      </div>

      <div class="space-alert">
        <div class="font-medium text-lg mb-3 mt-6" >{{ "spacePaymentPlan" | translate }}</div>
        <div class="space-alert__body">
          <div class="space-alert__line">
            <span> {{ "totalSpaceOccupiesPerCompany" | translate }}</span>
            <div class="space-alert__plan-cell space-alert__cell">
              <mat-form-field>
                <mat-label>{{ "spacePaymentPlan" | translate }}</mat-label>
                <mat-select formControlName="spaceUsedPaymentPlanType">
                  <ng-container *ngFor="let item of spaceUsedPaymentPlanType">
                    <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="space-alert__subtext"> 
            {{"thresholdForExtraGB" | translate}} 
          </div>
        </div> 
      </div>
    </form>
    <!-- <form *ngIf="samlSupport" [formGroup]="mailAccountSamlClaimsForm" autocomplete="off" novalidate>
      <div *ngIf="true" class="flex flex-col mb-3 mt-10">
        <div class="font-medium text-lg my-3">{{ "mailAccountSamlClaims" | translate }}</div>
        <mat-form-field appearance="fill">
          <mat-label>{{ "claimId" | translate }}</mat-label>
          <input type="number" matInput id="claimId" formControlName="claimId" required />
          <mat-error *ngIf="hasErrorSamlForm('claimId', 'min')">{{ "greaterThan0" | translate }}</mat-error>
          <mat-error *ngIf="hasErrorSamlForm('claimId', 'pattern')">{{ "mustBeInt" | translate }}</mat-error>
          <mat-error *ngIf="hasErrorSamlForm('claimId', 'required')">{{ "required" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "claimCode" | translate }}</mat-label>
          <input matInput id="claimCode" formControlName="claimCode" required />
          <mat-error *ngIf="hasErrorSamlForm('claimCode', 'required')">{{ "required" | translate }}</mat-error>
          <mat-error *ngIf="hasErrorSamlForm('claimCode', 'maxlength')">{{ "moreThan265Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "organizationId" | translate }}</mat-label>
          <input type="number" matInput id="organizationId" formControlName="organizationId" required />
          <mat-error *ngIf="hasErrorSamlForm('organizationId', 'required')">{{ "required" | translate }}</mat-error>
          <mat-error *ngIf="hasErrorSamlForm('organizationId', 'min')">{{ "greaterThan0" | translate }}</mat-error>
          <mat-error *ngIf="hasErrorSamlForm('organizationId', 'pattern')">{{ "mustBeInt" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "mailAccountId" | translate }}</mat-label>
          <input type="number" matInput id="mailAccountId" formControlName="mailAccountId" required />
          <mat-error *ngIf="hasErrorSamlForm('mailAccountId', 'required')">{{ "required" | translate }}</mat-error>
          <mat-error *ngIf="hasErrorSamlForm('mailAccountId', 'min')">{{ "greaterThan0" | translate }}</mat-error>
          <mat-error *ngIf="hasErrorSamlForm('mailAccountId', 'pattern')">{{ "mustBeInt" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "role" | translate }}</mat-label>
          <input type="number" matInput id="role" formControlName="role" required />
          <mat-error *ngIf="hasErrorSamlForm('role', 'required')">{{ "required" | translate }}</mat-error>
          <mat-error *ngIf="hasErrorSamlForm('role', 'min')">{{ "greaterThan0" | translate }}</mat-error>
          <mat-error *ngIf="hasErrorSamlForm('role', 'pattern')">{{ "mustBeInt" | translate }}</mat-error>
        </mat-form-field>
      </div>
    </form> -->
  </div>
  <div class="w-full" *ngIf="!loadingParentOrganizations && !cannotLoadParentOrganizations">
    <!-- <div *ngIf="isError" class="mb-3">
      <app-error-hint [errorText]="errorText"></app-error-hint>
    </div> -->
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid || !authTypeIsSelected" (click)="onSubmit(form.value)">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
