<div class="breadcrumbs-container">
  <app-breadcrumb [items]="breadcrumbItems"></app-breadcrumb>
</div>

<div>
  <app-navigation-tabs [tabItems]="tabItems"></app-navigation-tabs>
</div>

<app-drawer [isOpen]="createDrawerIsOpen" (closeEventEmitter)="onCloseCreateModalForm()">
  <app-mail-account-template-create-dialog
    *ngIf="createDrawerIsOpen"
    (closeEventEmitter)="onCloseCreateModalForm()"
    (submitEventEmitter)="onCreateTemplate()"
    [mailAccountId]="mailAccountId">
  </app-mail-account-template-create-dialog>
</app-drawer>

<app-drawer [isOpen]="editDrawerIsOpen" (closeEventEmitter)="onCloseEditModalForm()">
  <app-mail-account-template-edit-dialog
    *ngIf="editDrawerIsOpen"
    (closeEventEmitter)="onCloseEditModalForm()"
    (submitEventEmitter)="onEditTemplate()"
    [mailAccountId]="mailAccountId"
    [templateId]="editingTemplateId">
  </app-mail-account-template-edit-dialog>
</app-drawer>

<ng-container>
  <div class="page-content">
    <div class="search-panel flex-wrap">
      <div class="button-group">
        <button mat-raised-button color="primary" class="page-block-button" aria-label="Add template" (click)="openCreateModelForm()">
          <mat-icon style="font-size: 20px">add</mat-icon>{{ "newTemplate" | translate }}
        </button>
      </div>

      <div class="mobile-view search-bar">
        <app-search-bar
          [fullWidth]="true"
          (searchEventEmitter)="loadTemplates(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
      <div class="not-mobile-view search-bar">
        <app-search-bar
          [fullWidth]="false"
          (searchEventEmitter)="loadTemplates(true)"
          (valueChangedEventEmitter)="onSearchValueChanged($event)"
          [search]="searchValue"></app-search-bar>
      </div>
    </div>

    <div *ngIf="!navigationIsLoading && !isError">
      <div *ngIf="useMobileView">
        <div class="page-block">
          <mat-paginator
            [pageSize]="pageSize"
            [pageSizeOptions]="[25, 50, 100]"
            [length]="totalSize"
            [pageIndex]="pageIndex"
            (page)="handlePage($event)">
          </mat-paginator>
        </div>
        <div *ngIf="!isLoading && !isError">
          <div class="mt-4 page-block" *ngFor="let template of templates">
            <div class="card"></div>
            <mat-card class="mobile-row">
              <mat-card-content (click)="openEditModelForm(template)" class="flex flex-1">
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "templateName" | translate }}:</div>
                  <div class="card-table-row-value">{{ template.name }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "templateDesc" | translate }}:</div>
                  <div class="card-table-row-value">{{ template.description }}</div>
                </div>
                <div class="card-table-row">
                  <div class="card-table-row-header">{{ "modifyDate" | translate }}:</div>
                  <div class="card-table-row-value">{{ template.modifyDate | date : "dd/MM/yyyy HH:mm:ss" }}</div>
                </div>
              </mat-card-content>
              <div style="width: 40px; margin-left: 10px">
                <button mat-icon-button aria-label="Edit" matTooltip="{{ 'edit' | translate }}" (click)="openEditModelForm(template)">
                  <mat-icon>edit_outline</mat-icon>
                </button>
                <button
                  mat-icon-button
                  matTooltip="{{ 'delete' | translate }}"
                  color="primary"
                  aria-label="Delete"
                  (click)="openConfirmDialog(template.templateId, template.name)">
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </mat-card>
          </div>
        </div>
        <app-no-data *ngIf="templates.length === 0 && !isLoading && !navigationIsLoading && !isError"></app-no-data>
      </div>

      <div class="page-block table-container" *ngIf="!useMobileView">
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[25, 50, 100]"
          [length]="totalSize"
          [pageIndex]="pageIndex"
          (page)="handlePage($event)">
        </mat-paginator>

        <table *ngIf="!isLoading && !isError" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ "templateName" | translate }}</th>
            <td mat-cell *matCellDef="let template">{{ template.name }}</td>
          </ng-container>
          <ng-container matColumnDef="desc">
            <th mat-header-cell *matHeaderCellDef>{{ "templateDesc" | translate }}</th>
            <td mat-cell *matCellDef="let template">{{ template.description }}</td>
          </ng-container>
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>{{ "modifyDate" | translate }}</th>
            <td mat-cell *matCellDef="let template">{{ template.modifyDate | date : "dd/MM/yyyy HH:mm:ss" }}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ "actions" | translate }}</th>
            <td mat-cell *matCellDef="let template">
              <button
                mat-icon-button
                aria-label="{{ 'modifyTemplate' | translate }}"
                matTooltip="{{ 'modifyTemplate' | translate }}"
                (click)="openEditModelForm(template)">
                <mat-icon>edit_outline</mat-icon>
              </button>
              <button
                mat-icon-button
                aria-label="Delete"
                matTooltip="{{ 'delete' | translate }}"
                (click)="openConfirmDialog(template.templateId, template.name)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" class="cursor-default"></tr>
        </table>
        <app-no-data *ngIf="templates.length === 0 && !isLoading && !navigationIsLoading && !isError"></app-no-data>
      </div>
    </div>
    <app-loader *ngIf="isLoading || navigationIsLoading"></app-loader>

    <div *ngIf="isError && !isLoading" class="half-of-screen">
      <app-something-went-wrong
        description="{{ 'cannotLoadData' | translate }}"
        [showPicture]="false"
        (retryEmitter)="loadNavigation() && loadTemplates(true)"></app-something-went-wrong>
    </div>
  </div>
</ng-container>
