<div *ngIf="!data.isCreate">
  <h1 mat-dialog-title>{{ data.title || "confirmation" | translate }}</h1>
  <div mat-dialog-content>
    <p>
      {{ data.name | translate }} <span *ngIf="data.itemName" class="font-bold italic">{{ data.itemName }}</span>
    </p>
  </div>
  <div mat-dialog-actions>
    <div class="w-full flex justify-end">
      <!-- <button mat-button (click)="onNoClick()">{{ "no" | translate }}</button> -->
      <button mat-flat-button color="primary" (click)="onOkClick()">{{ "ok" | translate }}</button>
    </div>
  </div>
</div>
