import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProviderContract } from '../../../../../../sdk/contracts/provider/provider.contract';
import { ProviderClient } from '../../../../../../sdk/clients/provider.client';
import { MailAccountClient } from '../../../../../../sdk/clients/mail-account.client';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ErrorCode } from '../../../../../../sdk/api-errors/error-code';
import { MailAccountContract } from '../../../../../../sdk/contracts/mail-account/mail-account.contract';
import { MailAccountUpdateRequest } from '../../../../../../sdk/contracts/mail-account/mail-account.update.request';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-mail-account-update-dialog',
  templateUrl: './mail-account-update-dialog.component.html',
  styleUrls: ['./mail-account-update-dialog.component.scss'],
})
export class MailAccountUpdateDialogComponent implements OnInit {
  @Input() public mailAccount: MailAccountContract;
  @Output() public submitEventEmitter = new EventEmitter<{ name: string; codeSia: string; license: string; anagId: string }>();
  @Output() public closeEventEmitter = new EventEmitter();
  public providers: ProviderContract[] = [];
  public form = new UntypedFormGroup({});
  public isLoading = false;
  public errorText: string;
  public errorCode: ErrorCode | null = null;

  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    private providerClient: ProviderClient,
    public matchError: MatchError,
    private mailAccountClient: MailAccountClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.mailAccount.name, [Validators.required, Validators.maxLength(256)]),
      // code: new FormControl(this.mailAccount.code, [Validators.required, Validators.maxLength(256)]),
      codeSia: new UntypedFormControl(this.mailAccount.codeSia, [Validators.maxLength(255)]),
      anagId: new UntypedFormControl(this.mailAccount.anagId, [Validators.maxLength(255)]),
      license: new UntypedFormControl(this.mailAccount.license, [Validators.maxLength(255)]),
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(): Promise<void> {
    if (this.form.valid) {
      this.isLoading = true;
      this.errorCode = null;

      const name = this.form.get('name').value;
      // const code = this.form.get('code').value;
      const codeSia = this.form.get('codeSia').value;
      const anagId = this.form.get('anagId').value;
      const license = this.form.get('license').value;
      const request = new MailAccountUpdateRequest(this.mailAccount.mailAccountId, name, codeSia, license, anagId);

      try {
        await this.mailAccountClient.update(request);
        this.submitEventEmitter.emit({ name, codeSia, license, anagId });
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
