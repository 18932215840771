<div class="drawer-content-screen flex flex-col justify-between">
  <div *ngIf="!cannotLoadProviders && !providersIsLoading" class="text-2xl mb-10 font-medium">
    {{ "createNewMailAccount" | translate }}
  </div>
  <div class="h-full" *ngIf="cannotLoadProviders">
    <app-something-went-wrong
      description="Cannot load providers"
      (retryEmitter)="loadProviders()"
      [showPicture]="false"></app-something-went-wrong>
  </div>
  <div class="flex flex-col justify-center h-full" *ngIf="providersIsLoading">
    <app-loader></app-loader>
  </div>
  <div class="h-full w-full flex-grow overflow-y-auto" *ngIf="!cannotLoadProviders && !providersIsLoading">
    <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit(form.value)">
      <mat-form-field appearance="fill">
        <mat-label>{{ "alias" | translate }}</mat-label>
        <input matInput id="name" formControlName="name" required />
        <mat-error *ngIf="hasError('name', 'required')">{{ "aliasIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('name', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "license" | translate }}</mat-label>
        <input matInput id="license" formControlName="license" />
        <mat-error *ngIf="hasError('license', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "codeSia" | translate }}</mat-label>
        <input matInput id="codeSia" formControlName="codeSia" />
        <mat-error *ngIf="hasError('codeSia', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "anagId" | translate }}</mat-label>
        <input matInput id="anagId" formControlName="anagId" />
        <mat-error *ngIf="hasError('anagId', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "email" | translate }}</mat-label>
        <input matInput id="email" formControlName="email" required />
        <mat-error *ngIf="hasError('email', 'required')">{{ "emailIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('email', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
        <mat-error *ngIf="hasError('email', 'email')">{{ "incorrectEmail" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "password" | translate }}</mat-label>
        <input matInput type="password" id="password" formControlName="password" required />
        <mat-error *ngIf="hasError('password', 'required')">{{ "passwordIsRequired" | translate }}</mat-error>
        <mat-error *ngIf="hasError('password', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{ "providerLabel" | translate }}</mat-label>
        <mat-select formControlName="provider" required>
          <mat-option *ngFor="let provider of providers" [value]="provider.providerId">
            {{ provider.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div *ngIf="!cannotLoadProviders && !providersIsLoading" class="w-full">
    <div class="flex justify-end">
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit(form.value)">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
