<div class="drawer-content-screen flex flex-col justify-between">
  <div class="w-full mb-2">
    <div class="text-2xl mb-10 font-medium">
      {{ "changeMailAccountPassword" | translate }}
    </div>
    <div>
      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill">
          <mat-label>{{ "name" | translate }}</mat-label>
          <input matInput id="name" formControlName="name" readonly required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "providerLabel" | translate }}</mat-label>
          <input matInput id="providerName" formControlName="providerName" readonly required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "email" | translate }}</mat-label>
          <input matInput id="email" formControlName="email" readonly required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "newPassword" | translate }}</mat-label>
          <input type="password" matInput id="newPassword" formControlName="newPassword" required />
          <mat-error *ngIf="hasError('newPassword', 'required')">{{ "newPasswordIsRequired" | translate }}</mat-error>
          <mat-error *ngIf="hasError('newPassword', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="w-full">
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
