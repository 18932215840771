import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TemplateClient } from '../../../../../../sdk/clients/template.client';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateTemplateRequest } from '../../../../../../sdk/contracts/template/create-template.request';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '../../../../../../../ckeditor';
import { ConfirmDialogComponent } from '../../../../../confirm-dialog/confirm-dialog.component';
import '../../../../../../translation/ckeditor-translation/it';
import '../../../../../../translation/ckeditor-translation/en-gb';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mail-account-template-create-dialog',
  templateUrl: './mail-account-template-create-dialog.component.html',
  styleUrls: ['./mail-account-template-create-dialog.component.scss'],
})
export class MailAccountTemplateCreateDialogComponent implements OnInit {
  @Input() mailAccountId: number;
  @Output() submitEventEmitter = new EventEmitter();
  @Output() closeEventEmitter = new EventEmitter();

  @ViewChild('bodyText') bodyText: CKEditorComponent;
  public config = {
    language: localStorage.getItem('userLocale'),
  };
  public isLoading = false;
  public errorText: string;
  public editor = ClassicEditor;
  public body = '';
  public isSendingRequest: boolean;

  public form = new UntypedFormGroup({
    templateName: new UntypedFormControl('', [Validators.required, Validators.maxLength(255)]),
    templateDesc: new UntypedFormControl('', [Validators.maxLength(255)]),
    templateSubject: new UntypedFormControl('', [Validators.maxLength(255)]),
  });

  constructor(private templateClient: TemplateClient, public dialog: MatDialog, public matchError: MatchError) {}

  ngOnInit(): void {}

  async onClickReset() {
    this.form.get('templateName')?.setValue('');
    this.form.get('templateDesc')?.setValue('');
    this.form.get('templateSubject')?.setValue('');
    this.bodyText.editorInstance.setData('');
  }

  public isBodyHasData() {
    return !!this.bodyText.editorInstance.getData();
  }

  public async onSubmit(value): Promise<void> {
    if (this.form.valid) {
      this.isSendingRequest = true;
      const request = new CreateTemplateRequest(
        this.mailAccountId,
        this.form.get('templateName').value,
        this.form.get('templateDesc').value,
        this.form.get('templateSubject').value,
        this.bodyText.editorInstance.getData()
      );
      try {
        await this.templateClient.create(request);
        this.submitEventEmitter.emit();
      } catch (e) {
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isSendingRequest = false;
      }
    }
  }

  public openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { name: 'reset' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onClickReset();
      }
    });
  }

  public cancel(): void {
    this.closeEventEmitter.emit();
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };
}
