import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganizationContract } from '../../../../../sdk/contracts/organization/organization.contract';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OrganizationClient } from '../../../../../sdk/clients/organization.client';
import { OrganizationType } from '../../../../../sdk/contracts/organization/organization-type';
import { ErrorCode } from '../../../../../sdk/api-errors/error-code';
import { MatchError } from '../../../../../shared/error-matcher';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PignorState } from 'src/app/sdk/contracts/organization/pignor-type';
import { ClaimClient } from 'src/app/sdk/clients/claim';
import { BaseGetByIdRequest } from 'src/app/sdk/contracts/common/base-get-by-id.request';
import { SamlClaims } from 'src/app/sdk/contracts/mail-account/settings/saml-claims.contract';
import { OrganizationSettingsClient } from 'src/app/sdk/clients/organization-settings.client';
import { OrganizationSettingsUpdateRequest } from 'src/app/sdk/contracts/organization/settings/organization-settings.update.request';
import { OrganizationSettingsContract } from 'src/app/sdk/contracts/organization/settings/organization-settings.contract';
import { RoleType } from 'src/app/sdk/contracts/role/role-type';
import {
  SpaceUsedPaymentPlanSelectTypes,
  SpaceUsedPaymentPlanSelectTypesWithNull,
} from 'src/app/sdk/contracts/organization/settings/spaceUsedPaymentPlanTypes';

@Component({
  selector: 'app-organization-update-dialog',
  templateUrl: './organization-update-dialog.component.html',
  styleUrls: ['./organization-update-dialog.component.scss'],
})
export class OrganizationUpdateDialogComponent implements OnInit {
  @Input() organization: OrganizationContract;
  @Output() public updatingSubmit = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public form: UntypedFormGroup = new UntypedFormGroup({});
  // public mailAccountSamlClaimsForm: FormGroup = new FormGroup({});
  public settings: OrganizationSettingsContract;
  public isLoading = false;
  public pignor: boolean = false;
  public isError = false;
  public errorText: string | null;
  public handlingErrors = new Map([
    [
      ErrorCode.CannotChangeTypeForOrganizationWithChildren,
      'Cannot change type from group to company if organization has children companies',
    ],
    [ErrorCode.CannotSetParentForParentOrganization, 'Cannot set parent organization for company which itself is the parent'],
    [ErrorCode.WebCodeAlreadyUsed, 'Cannot update company: web code already used'],
    [ErrorCode.LicenseIdAndCodeSiaAlreadyUsed, 'Cannot update company: LicenseID and CodeSia already used'],
  ]);
  public loadingParentOrganizations = false;
  public cannotLoadParentOrganizations = false;
  public samlSupport: boolean = false;
  public samlClaims: SamlClaims;
  public oidcSupport = true;
  public adfsSupport = false;
  public googleOAuthSupport = true;
  public availableParentOrganizations: OrganizationContract[];
  public parentOrganization: OrganizationContract;
  public organizationTypes = OrganizationType;
  public availableOrganizationTypes = [OrganizationType.Company, OrganizationType.Group];
  public spaceUsedPaymentPlanType = SpaceUsedPaymentPlanSelectTypes;

  constructor(
    private organizationClient: OrganizationClient,
    public claimClient: ClaimClient,
    public matchError: MatchError,
    public organizationSettingsClient: OrganizationSettingsClient,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadAvailableParentOrganization();
    this.pignor = this.organization.pignorState === PignorState.Active ? true : false;
    this.parentOrganization = this.availableParentOrganizations.find((o) => o.organizationId === this.organization.parentOrganizationId);
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.organization.name, [Validators.required, Validators.maxLength(256)]),
      code: new UntypedFormControl(this.organization.code, [Validators.required, Validators.maxLength(256)]),
      type: new UntypedFormControl(this.organization.type, [Validators.required]),
      codeSia: new UntypedFormControl(this.organization.codeSia, [Validators.required, Validators.maxLength(15)]),
      licenseId: new UntypedFormControl(this.organization.licenseId, [Validators.required, Validators.maxLength(128)]),
      webCode: new UntypedFormControl(this.organization.webCode, [
        Validators.required,
        Validators.maxLength(32),
        Validators.pattern('^([a-z][a-z0-9_]*)(-[a-z0-9]+)*$'),
      ]),
      anagId: new UntypedFormControl(this.organization.anagId, [
        Validators.required,
        // Validators.maxLength(10),
        // Validators.minLength(10),
        // Validators.pattern('^[0-9]*$'),
      ]),
      samlRtaClaim: new UntypedFormControl(this.samlClaims?.claimCode || '', []),
      spaceUsedPaymentPlanType: new UntypedFormControl(this.organization.spaceUsedPaymentPlanType, [Validators.required]),
    });

    // this.mailAccountSamlClaimsForm = new FormGroup({
    //   claimId: new FormControl(this.samlClaims?.claimId || '', [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(1)]),
    //   claimCode: new FormControl(this.samlClaims?.claimCode || '', [Validators.maxLength(256), Validators.required]),
    //   organizationId: new FormControl(this.samlClaims?.organizationId || '', [
    //     Validators.pattern('^[0-9]*$'),
    //     Validators.required,
    //     Validators.min(1),
    //   ]),
    //   mailAccountId: new FormControl(this.samlClaims?.mailAccountId || '', [
    //     Validators.pattern('^[0-9]*$'),
    //     Validators.required,
    //     Validators.min(1),
    //   ]),
    //   role: new FormControl(this.samlClaims?.role || '', [Validators.pattern('^[0-9]*$'), Validators.required, Validators.min(1)]),
    // });

    if (this.samlSupport) {
      this.form.controls['samlRtaClaim'].setValidators([Validators.required, Validators.maxLength(256)]);
    } else {
      this.form.controls['samlRtaClaim'].setValidators([]);
      this.form.controls['samlRtaClaim'].disable();
    }
    this.form.controls['samlRtaClaim'].updateValueAndValidity();
  }

  public onSamlChange(isChecked: boolean) {
    if (isChecked) {
      this.form.controls['samlRtaClaim'].setValidators([Validators.required, Validators.maxLength(256)]);
      this.form.controls['samlRtaClaim'].enable();
      this.googleOAuthSupport = false;
      this.oidcSupport = false;
    } else {
      this.form.controls['samlRtaClaim'].disable();
      this.form.controls['samlRtaClaim'].setValidators([]);
    }
    this.form.controls['samlRtaClaim'].markAsTouched();
    this.form.controls['samlRtaClaim'].updateValueAndValidity();
  }

  public async loadAvailableParentOrganization(): Promise<void> {
    this.loadingParentOrganizations = true;
    this.cannotLoadParentOrganizations = false;
    try {
      const organizationClaimRespone = await this.claimClient.getByIdRtaClaimByOrganizationId(
        new BaseGetByIdRequest(this.organization.organizationId)
      );
      this.samlClaims = organizationClaimRespone?.result;

      const organizationSettingsResponse = await this.organizationSettingsClient.getByOrganization(
        new BaseGetByIdRequest(this.organization.organizationId)
      );
      this.settings = organizationSettingsResponse.result;
      this.samlSupport = this.settings.samlSupport;
      this.adfsSupport = this.settings.adfsSupport;
      this.googleOAuthSupport = this.settings.googleOAuthSupport;
      this.oidcSupport = this.settings.oidcSupport;

      const response = await this.organizationClient.getAvailableParentOrganizations();
      this.availableParentOrganizations = response.data.filter((o) => this.organization.organizationId !== o.organizationId);
    } catch (e) {
      this.cannotLoadParentOrganizations = true;
      this.matchError.logError(e);
    } finally {
      this.loadingParentOrganizations = false;
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  // public hasErrorSamlForm = (controlName: string, errorName: string) => {
  //   return this.mailAccountSamlClaimsForm.controls[controlName].hasError(errorName);
  // };

  async onSubmit(organizationContract: OrganizationContract): Promise<void> {
    this.isError = false;
    this.errorText = null;
    this.isLoading = true;
    if (this.form.valid) {
      try {
        organizationContract.organizationId = this.organization.organizationId;
        organizationContract.parentOrganizationId = this.parentOrganization?.organizationId;
        organizationContract.pignorState = this.pignor ? PignorState.Active : PignorState.Disabled;
        await this.organizationClient.update(organizationContract);

        const updateRequest = new OrganizationSettingsUpdateRequest();
        updateRequest.organizationId = this.organization.organizationId;
        updateRequest.samlSupport = this.samlSupport;
        updateRequest.googleOAuthSupport = this.googleOAuthSupport;
        updateRequest.adfsSupport = this.adfsSupport;
        updateRequest.oidcSupport = this.oidcSupport;
        updateRequest.spaceUsedPaymentPlanType = this.form.controls.spaceUsedPaymentPlanType.value;
        await this.organizationSettingsClient.createOrUpdate(updateRequest);
        if (this.samlSupport) {
          const updateRequestSamlClaims = new SamlClaims();
          updateRequestSamlClaims.claimId = this.samlClaims ? this.samlClaims.claimId : null;
          updateRequestSamlClaims.claimCode = this.form.controls.samlRtaClaim.value;
          updateRequestSamlClaims.organizationId = this.organization.organizationId;
          updateRequestSamlClaims.role = RoleType.Rta;
          await this.claimClient.createOrUpdateRtaClaimByOrganizationId(updateRequestSamlClaims);
        }
        this.updatingSubmit.emit(organizationContract);
      } catch (e) {
        this.isError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }

  public get authTypeIsSelected(): boolean {
    return this.oidcSupport || this.adfsSupport || this.googleOAuthSupport || this.samlSupport;
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }
}
