import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseGetByIdRequest } from '../../../../../../sdk/contracts/common/base-get-by-id.request';
import { MailAccountSimpleConfigurationClient } from '../../../../../../sdk/clients/mail-account-simple-configuration-client';
import { RemoteFolderForSimpleConfigurationContract } from '../../../../../../sdk/contracts/mail-account-simple-configuration/remote-folder-for-simple-configuration-contract';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MailAccountSimpleConfigurationRequest } from '../../../../../../sdk/contracts/mail-account-simple-configuration/mail-account-simple-configuration-request';
import { MatchError } from 'src/app/shared/error-matcher';
import { ErrorsPipe } from 'src/app/shared/pipes/error-code.pipe';

@Component({
  selector: 'app-mail-account-quick-setup-dialog',
  templateUrl: './mail-account-quick-setup-dialog.component.html',
  styleUrls: ['./mail-account-quick-setup-dialog.component.scss'],
})
export class MailAccountQuickSetupDialogComponent implements OnInit {
  @Input() mailAccountId: number;
  @Output() submitEventEmitter = new EventEmitter();
  @Output() closeEventEmitter = new EventEmitter();

  public isLoadingData = false;
  public cannotLoadData = false;
  public errorText: string;

  public isLoading = false;
  public isError = false;

  public canSetup = false;
  public remoteFolders: RemoteFolderForSimpleConfigurationContract[] = null;

  public dataSource = new MatTableDataSource<RemoteFolderForSimpleConfigurationContract>(this.remoteFolders);
  treeControl = new NestedTreeControl<RemoteFolderForSimpleConfigurationContract>((node) => node.subFolders);

  public startMailAccount = true;
  public activated = false;

  constructor(
    public matchError: MatchError,
    private mailAccountSimpleConfigurationClient: MailAccountSimpleConfigurationClient,
    private errorsPipe: ErrorsPipe
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadData();
  }

  async loadData(): Promise<any> {
    this.isLoadingData = true;
    this.cannotLoadData = false;
    try {
      const canSetupResponse = await this.mailAccountSimpleConfigurationClient.canSetup(new BaseGetByIdRequest(this.mailAccountId));
      this.canSetup = canSetupResponse.canSetup;
      if (this.canSetup) {
        this.remoteFolders = canSetupResponse.folders;
        this.dataSource.data = this.remoteFolders;
        this.treeControl.dataNodes = this.remoteFolders;
        this.treeControl.expandAll();
      }
    } catch (error) {
      this.cannotLoadData = true;
      this.errorText = this.errorsPipe.transform(error.Code) || `Error code: ${error.Code}: ${error.Description || 'something went wrong'}`;
      this.matchError.logError(error);
    } finally {
      this.isLoadingData = false;
    }
  }

  selectFolder(item: RemoteFolderForSimpleConfigurationContract): void {
    item.sync = !item.sync;
  }

  hasChild = (_: number, node: RemoteFolderForSimpleConfigurationContract) => !!node.subFolders && node.subFolders.length > 0;

  public cancel(): void {
    this.closeEventEmitter.emit();
  }

  public async onSubmit(): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    const request = new MailAccountSimpleConfigurationRequest();
    request.mailAccountId = this.mailAccountId;
    request.folders = this.remoteFolders;
    request.startMailAccount = this.startMailAccount;
    try {
      await this.mailAccountSimpleConfigurationClient.create(request);
      this.activated = true;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public ok(): void {
    this.submitEventEmitter.emit();
  }
}
