import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserMailAccountContract } from '../../../../../sdk/contracts/user-mail-account/user-mail-account.contract';
import { UserMailAccountClient } from '../../../../../sdk/clients/user-mail-account.client';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MailAccountClient } from '../../../../../sdk/clients/mail-account.client';
import { BaseGetByIdRequest } from '../../../../../sdk/contracts/common/base-get-by-id.request';
import { BreadcrumbItem } from '../../../../../shared/breadcrumb-item';
import { TabItem } from '../../../../../shared/tab-item';
import { PermissionType } from '../../../../../sdk/contracts/permission/permission-type';
import { PermissionService } from '../../../../../services/permission/permission.service';
import { OrganizationClient } from '../../../../../sdk/clients/organization.client';
import { PermissionNavTabHelper } from '../../../../../services/permission/permission-nav-tab-helper';
import { RoleType } from '../../../../../sdk/contracts/role/role-type';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { BaseCollectionSearchByIdRequest } from '../../../../../sdk/contracts/common/base-collection-search-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../../../../../sdk/contracts/common/base-get-search-count-by-id.request';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MailAccountUsersDeleteDialogComponent } from './mail-account-users-delete-dialog/mail-account-users-delete-dialog.component';
import { MobileObserverService } from '../../../../../services/adaptive/mobile-observer.service';
import { AuthenticationService } from '../../../../../services/auth/authentication.service';
import { OrganizationSettingsClient } from 'src/app/sdk/clients/organization-settings.client';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-mail-account-users',
  templateUrl: './mail-account-users.component.html',
  styleUrls: ['./mail-account-users.component.scss'],
})
export class MailAccountUsersComponent implements OnInit, AfterViewInit {
  public navigationIsLoading = false;
  public cannotLoadNavigation = false;
  public breadcrumbItems: BreadcrumbItem[] = [];
  public tabItems: TabItem[] = [];

  public organizationId: number;
  public mailAccountId: number;
  public isLoading = false;
  public isSamlSupport = true;
  public hasError = false;
  public userMailAccounts: UserMailAccountContract[] = [];
  public dataSource = new MatTableDataSource<UserMailAccountContract>(this.userMailAccounts);
  public displayedColumns: string[] = [
    'first-name',
    'last-name',
    'googleIdentity',
    'oidcIdentity',
    'adfsIdentity',
    'user-email',
    'user-mail-account-role-name',
    'actions',
  ];
  public roleTypes = RoleType;
  public searchValue = '';
  public pageSize = 25;
  public pageIndex = 0;
  public totalSize = 0;
  public addDrawerIsOpen = false;
  public editDrawerIsOpen = false;
  public editingUserMailAccount: UserMailAccountContract | null = null;
  public useMobileView = false;
  public webCode: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    public matchError: MatchError,
    protected router: Router,
    protected route: ActivatedRoute,
    private userMailAccountClient: UserMailAccountClient,
    private permissionService: PermissionService,
    private organizationClient: OrganizationClient,
    private permissionNavTabHelper: PermissionNavTabHelper,
    private mailAccountClient: MailAccountClient,
    public dialog: MatDialog,
    public organizationSettingsClient: OrganizationSettingsClient,
    private mobileObserverService: MobileObserverService,
    private authenticationService: AuthenticationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.pageSize = JSON.parse(localStorage.getItem('pageSizeSettings')) || 25;
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');
    this.organizationId = parseInt(this.route.snapshot.paramMap.get('organizationId'), 10);
    this.mailAccountId = +this.route.snapshot.paramMap.get('mailAccountId');
    await this.loadNavigation();
    await this.loadMailAccountUsers(true);
  }

  ngAfterViewInit(): void {
    this.mobileObserverService.mobileObserver().subscribe((isMobile) => (this.useMobileView = isMobile));
  }

  public async loadNavigation(): Promise<void> {
    this.navigationIsLoading = true;
    try {
      this.loadTabs();
      await this.loadBreadcrumbItems();
    } catch (e) {
      this.cannotLoadNavigation = true;
      this.matchError.logError(e);
    } finally {
      this.navigationIsLoading = false;
    }
  }

  private loadTabs(): void {
    this.tabItems = this.permissionNavTabHelper.getTabsForMailAccountPage(this.organizationId, this.mailAccountId, this.webCode);
  }

  private async loadBreadcrumbItems(): Promise<void> {
    const mailAccountName = (await this.mailAccountClient.getById(new BaseGetByIdRequest(this.mailAccountId))).mailAccount.name;
    if (await this.permissionService.hasPermissionOver(null, null, PermissionType.CanEverything)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: mailAccountName, route: null },
        { index: 3, label: 'usersBreadCrumbs', route: null },
      ];
    } else if (await this.permissionService.hasPermissionOver(this.organizationId, null, PermissionType.CanManageOrganization)) {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: mailAccountName, route: null },
        { index: 3, label: 'usersBreadCrumbs', route: null },
      ];
    } else {
      this.breadcrumbItems = [
        { index: 0, label: 'settingsBreadCrumbs', route: `/${this.webCode}/settings/organization/${this.organizationId}/general` },
        {
          index: 1,
          label: 'mailAccountsBreadCrumbs',
          route: `/${this.webCode}/settings/organization/${this.organizationId}/mail-accounts`,
        },
        { index: 2, label: mailAccountName, route: null },
        { index: 3, label: 'usersBreadCrumbs', route: null },
      ];
    }
  }

  async loadMailAccountUsers(fetchCounter): Promise<void> {
    this.isLoading = true;
    this.hasError = false;
    if (fetchCounter) {
      this.pageIndex = 0;
    }
    const nullableSearchValue = this.searchValue.length > 0 ? this.searchValue : null;
    const request = new BaseCollectionSearchByIdRequest(this.mailAccountId, this.pageIndex + 1, this.pageSize, nullableSearchValue);
    try {
      const organizationSettings = await this.organizationSettingsClient.getByOrganization(new BaseGetByIdRequest(this.organizationId));
      this.isSamlSupport = organizationSettings.result.samlSupport;
      const response = await this.userMailAccountClient.searchMailAccountUsers(request);
      this.userMailAccounts = response.data.filter((e) => this.permissionService.hasPermissionToSeeUserByUserEmail(e.userEmail));
      this.dataSource.data = this.userMailAccounts;
      if (fetchCounter) {
        const countRequest = new BaseGetSearchCountByIdRequest(this.mailAccountId, nullableSearchValue);
        const counterResponse = await this.userMailAccountClient.getSearchMailAccountUsersCount(countRequest);
        this.totalSize = counterResponse.result;
      }
    } catch (e) {
      this.hasError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public onSearchValueChanged(newSearchValue: string): void {
    this.searchValue = newSearchValue;
  }

  public async handlePage(e: any): Promise<void> {
    localStorage.setItem('pageSizeSettings', JSON.stringify(e.pageSize));
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    await this.loadMailAccountUsers(false);
  }

  public onCreateMailAccountUserBtnClicked(): void {
    this.addDrawerIsOpen = true;
  }

  onCloseAddModalForm(): void {
    this.addDrawerIsOpen = false;
  }

  async onAddUserToMailAccount({ userId }): Promise<void> {
    this.addDrawerIsOpen = false;
    this.totalSize += 1;
    await this.loadMailAccountUsers(false);
  }

  public onEditMailAccountUserBtnClicked(userMailAccount: UserMailAccountContract): void {
    this.editingUserMailAccount = userMailAccount;
    this.editDrawerIsOpen = true;
  }

  onCloseEditModalForm(): void {
    this.editingUserMailAccount = null;
    this.editDrawerIsOpen = false;
  }

  async onEditMailAccountUser(): Promise<void> {
    this.editingUserMailAccount = null;
    this.editDrawerIsOpen = false;
    await this.loadMailAccountUsers(false);
  }

  public deleteUserMailAccount(userMailAccountId: number, mailAccountName: string, userFirstName: string, userLastName: string): void {
    const dialogRef = this.dialog.open(MailAccountUsersDeleteDialogComponent, {
      width: '450px',
      data: { userMailAccountId, mailAccountName, userFirstName, userLastName },
    });

    dialogRef.afterClosed().subscribe(async (x) => {
      if (x.isDeleted) {
        this.totalSize -= 1;
        await this.loadMailAccountUsers(false);
      }
    });
  }
}
