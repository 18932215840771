import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { EmailListItemNotificationsContract } from '../../../sdk/contracts/Email/email.list-item-notifications.contract';
import { Router } from '@angular/router';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { EmailClient } from '../../../sdk/clients/email-client';
import { EmailState } from '../../../sdk/contracts/Email/email-state';
import { EmailType } from '../../../sdk/contracts/Email/email-type';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-email-notifications-dialog',
  templateUrl: './email-notifications-dialog.component.html',
  styleUrls: ['./email-notifications-dialog.component.scss'],
})
export class EmailNotificationsDialogComponent implements OnInit {
  public notifications: EmailListItemNotificationsContract[] = [];
  public isLoading = false;
  public cannotLoadNotifications = false;
  @Input() webCode: string;
  @Input() emailId: number;
  @Input() title: string;
  @Output() openEmailDetails = new EventEmitter();

  constructor(public matchError: MatchError, private router: Router, private emailClient: EmailClient) {}

  async ngOnInit(): Promise<void> {
    await this.loadNotifications();
  }

  async loadNotifications(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadNotifications = false;
    const request = new BaseGetByIdRequest(this.emailId);
    try {
      const result = await this.emailClient.getNotifications(request);
      this.notifications = result.data;
    } catch (e) {
      this.cannotLoadNotifications = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public isRowWithError(email: EmailListItemNotificationsContract) {
    const emailState = email.state;
    const emailType = email.type;
    return (
      emailState === EmailState.DeliveryError ||
      emailState === EmailState.VirusDetected ||
      emailState === EmailState.DispatchError ||
      emailState === EmailState.NotDispatched ||
      emailState === EmailState.NotAccepted ||
      emailState === EmailState.SendError ||
      emailType === EmailType.NonAcceptance ||
      emailType === EmailType.DeliveryError
    );
  }

  async emailDetails(email: EmailListItemNotificationsContract): Promise<void> {
    this.openEmailDetails.emit(email);
  }
}
