export class AddUserToOrganizationRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  nickName: string;
  organizationId: number;
  oidcAuth: boolean;
  googleAuth: boolean;

  public constructor(
    firstName: string,
    lastName: string,
    email: string,
    nickName: string,
    organizationId: number,
    password: string,
    oidcAuth: boolean,
    googleAuth: boolean
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.password = password;
    this.nickName = nickName;
    this.organizationId = organizationId;
    this.oidcAuth = oidcAuth;
    this.googleAuth = googleAuth;
  }
}
