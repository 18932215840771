import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventsService } from '../../services/events/events.service';
import { Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthenticationService } from '../../services/auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionService } from '../../services/permission/permission.service';
import { PermissionType } from '../../sdk/contracts/permission/permission-type';
import { UserOrganizationClient } from '../../sdk/clients/user-organization.client';
import { UserClient } from '../../sdk/clients/user.client';
import { UserContract } from '../../sdk/contracts/user/user.contract';
import { RoleType } from '../../sdk/contracts/role/role-type';
import { environment } from '../../../environments/environment';
import { OrganizationContract } from '../../sdk/contracts/organization/organization.contract';
import { Location } from '@angular/common';
import { FerreroWebCodes } from 'src/app/sdk/contracts/plugins/ferrero-webcodes';
import { ApiRequest } from 'src/app/sdk/api.request';
import { MatchError } from 'src/app/shared/error-matcher';
import { TranslateService } from '@ngx-translate/core';
import { ListOfPossibleLanguages } from 'src/app/shared/list-of-possible-languages';
import { SpaceUsedPaymentPlanDetailTypes } from 'src/app/sdk/contracts/organization/settings/spaceUsedPaymentPlanTypes';
import { SettingsService } from 'src/app/services/settings/settings.service';
import packageInfo from '../../../../package.json';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') public sideNav: MatSidenav;
  private rightMenuClickedSubscription: Subscription;
  public settingRoute: string | null = null;
  public webCode: string;
  public user: UserContract;
  public roleTypes = RoleType;
  public userRole: string;
  public organizationName: string;
  public isLoading = false;
  public isError = false;
  public userOrganisaions: OrganizationContract[] = [];
  public spaceInfo: {
    maxAvailableSpaceUsed: number | null;
    spaceUsed: number | null;
    percentage: number | null;
    alertPercentage: number | null;
  } = {
    maxAvailableSpaceUsed: 0,
    spaceUsed: 0,
    percentage: 0,
    alertPercentage: 90,
  };

  constructor(
    public matchError: MatchError,
    private eventsService: EventsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private permissionService: PermissionService,
    private userOrganizationClient: UserOrganizationClient,
    private route: ActivatedRoute,
    private location: Location,
    private userClient: UserClient,
    private apiRequest: ApiRequest,
    public translate: TranslateService,
    private settings: SettingsService
  ) {}

  async ngOnInit(): Promise<void> {
    console.log(`PM3 ${packageInfo.version}`);
    this.webCode = this.route.parent.snapshot.paramMap.get('webCode');

    this.rightMenuClickedSubscription = this.eventsService.rightMenuClicked.subscribe(async () => {
      if (this.sideNav) {
        await this.sideNav.toggle();
      }
    });
    const lastEndpoints = window.location.href.split('/').filter((e, i, arr) => i === arr.length - 1 || i === arr.length - 2);
    const isEmailUrl = window.location.href.split('/').filter((e, i, arr) => i === arr.length - 1);

    this.settings.settingsAlertLevel$.subscribe((value) => {
      this.spaceInfo.alertPercentage = value;
    });

    await this.loadData();
    this.eventsService.changeUserRole(this.userRole);
    if (this.userRole === 'RTA' || this.userRole === 'Root') {
      if (isEmailUrl[0] === this.webCode) {
        this.router.navigateByUrl(this.settingRoute);
      }
    } else {
      if (isEmailUrl[0] === this.webCode) {
        this.router.navigate([this.webCode, `emails`]);
        this.eventsService.openLeftMenu();
      }
    }
    if (lastEndpoints[0] === this.webCode && lastEndpoints[1] === 'emails') {
      this.eventsService.openLeftMenu();
    }

    this.settings.settingsMaxSpace$.subscribe((value) => {
      this.setSpaceInfo(value, this.spaceInfo.spaceUsed);
    });
  }

  public rightMenuClicked(): void {
    this.eventsService.rightMenuClicked.emit();
  }

  public onEmailsClick(): void {
    this.sideNav.toggle();
    if (!this.eventsService.isLeftMenuOpened) {
      this.eventsService.openLeftMenu();
    }
  }

  public onChangeOrganization($event): void {
    document.location.href = `/${$event.value}`;
  }

  public pluginsPermission(): boolean {
    const ferreroWebCodes = new FerreroWebCodes();
    return ferreroWebCodes.codes.includes(this.webCode);
  }

  public async loadData(): Promise<void> {
    this.isLoading = true;
    this.isError = false;
    try {
      const response = await this.userClient.getUser();
      this.user = response.result;
      this.setCorrectLanguage(this.user.localizationCode);
      this.translate.use(localStorage.getItem('userLocale'));
      if (await this.permissionService.hasPermissionOver(null, null, PermissionType.CanEverything)) {
        this.userRole = 'Root';
        this.settingRoute = `/${this.webCode}/settings/organizations`;
        this.organizationName = null;
        return;
      }

      const organizations = (await this.userOrganizationClient.getUserOrganizations()).organizations;
      if (!organizations || organizations.length === 0) {
        this.userRole = null;
        this.settingRoute = null;
      }

      const currentOrganization = organizations.find((e) => e.webCode === this.webCode);
      this.userOrganisaions = organizations;
      const organizationId = currentOrganization.organizationId;
      this.organizationName = currentOrganization.name;

      const space = currentOrganization.spaceUsedPaymentPlanType
        ? SpaceUsedPaymentPlanDetailTypes[currentOrganization.spaceUsedPaymentPlanType].value
        : 0;
      this.setSpaceInfo(space, currentOrganization.spaceUsed);
      this.settings.setSettingsMaxSpace(this.spaceInfo.maxAvailableSpaceUsed);
      this.spaceInfo.alertPercentage = currentOrganization.alertLevelSpaceUsed;

      if (await this.permissionService.hasPermissionOver(organizationId, null, PermissionType.CanManageOrganization)) {
        this.userRole = 'RTA';
        this.settingRoute = `/${this.webCode}/settings/organization/${organizationId}/general`;
        return;
      }

      if (await this.permissionService.hasPermissionOverAtLeastOneMailAccount(PermissionType.CanManageMailAccount)) {
        this.userRole = 'User';
        this.settingRoute = `/${this.webCode}/settings/organization/${organizationId}/mail-accounts`;
        return;
      }

      this.userRole = 'User';
      this.settingRoute = null;
    } catch (e) {
      this.isError = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  private setSpaceInfo(space, spaceUsed): void {
    this.spaceInfo.maxAvailableSpaceUsed = space;
    this.spaceInfo.spaceUsed = spaceUsed ? spaceUsed : 0;
    this.spaceInfo.percentage =
      this.spaceInfo.maxAvailableSpaceUsed > 0 ? +((this.spaceInfo.spaceUsed / this.spaceInfo.maxAvailableSpaceUsed) * 100).toFixed(2) : 0;
  }

  private setCorrectLanguage(code): void {
    if (code) {
      localStorage.setItem('userLocale', code);
    } else {
      const elem = navigator.language.split('-')[0];
      if (ListOfPossibleLanguages.indexOf(elem) !== -1) {
        localStorage.setItem('userLocale', elem);
      } else {
        localStorage.setItem('userLocale', environment.language);
      }
    }
  }

  public get accountSettingsRoute(): string {
    return `/${this.webCode}/account-settings`;
  }

  async ngOnDestroy(): Promise<any> {
    this.rightMenuClickedSubscription?.unsubscribe();
    this.settings.settingsMaxSpace$?.unsubscribe();
  }

  async logout(): Promise<void> {
    await this.authenticationService.logout();
    localStorage.removeItem('userLocale');
    document.location.href = `${environment.feUrl}signout?webCode=${this.webCode}`;
  }

  async login(): Promise<void> {
    await this.router.navigateByUrl(`/${this.webCode}/sign-in`);
  }

  public isCurrentSelect(path: string): boolean {
    const locationPath = this.location.path();
    const isCurrent = locationPath.toString().split('/');
    return isCurrent[1] === this.webCode && isCurrent[2] === path;
  }
}
