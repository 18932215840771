import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { MailAccountSettingsContract } from '../contracts/mail-account/settings/mail-account-settings.contract';
import { MailAccountSettingsUpdateRequest } from '../contracts/mail-account/settings/mail-account-settings.update-request';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';

const API_URL = environment.apiUrl;

@Injectable()
export class MailAccountSettingsClient {
  constructor(private apiRequest: ApiRequest) {}

  async getByMailAccount(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<MailAccountSettingsContract>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.mailAccountSettings.getByMailAccount}?${request.format()}`);
  }

  async update(request: MailAccountSettingsUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<MailAccountSettingsUpdateRequest, BaseUpdateResponse>(
      request,
      `${API_URL}${ApiRoutes.mailAccountSettings.update}`
    );
  }
}
