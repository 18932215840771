import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { getFileExtension } from 'src/app/shared/helpers/file-helper';
import ClassicEditor from '../../../../ckeditor';
import { EmailClient } from '../../../sdk/clients/email-client';
import { EmailTagClient } from '../../../sdk/clients/email-tag.client';
import { FileClient } from '../../../sdk/clients/file.client';
import { MailFolderClient } from '../../../sdk/clients/mail-folder.client';
import { TagClient } from '../../../sdk/clients/tag.client';
import { TempFileClient } from '../../../sdk/clients/temp-file.client';
import { AttachmentContract } from '../../../sdk/contracts/Attachment/Attachment.contract';
import { BaseGetByFileIdRequest } from '../../../sdk/contracts/common/base-get-by-file-id.request';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { BasePostCommentRequest } from '../../../sdk/contracts/common/base-post-comment.request';
import { EmailContract } from '../../../sdk/contracts/Email/email.contract';
import { EmailForwardRequest } from '../../../sdk/contracts/Email/email.forward.request';
import { EmailReplyRequest } from '../../../sdk/contracts/Email/email.reply.request';
import { EmailValidationState } from '../../../sdk/contracts/Email/email.validation-state';
import { AuthorizationService } from '../../../services/auth/authorization.service';
import { PermissionService } from '../../../services/permission/permission.service';
import { SearchContextStorageService } from '../../../services/search/search-context-storage.service';
import { SnackbarHelper } from '../../../shared/helpers/snackbar.helper';

import { MailFolderType } from '../../../sdk/contracts/mail-folder/mail-folder.type';

import { EmailAuditClient } from '../../../sdk/clients/email-audit-client';
import { BaseGetByEmailFileIdRequest } from '../../../sdk/contracts/common/base-get-by-email-file-id.request';
import { BaseGetCollectionByIdRequest } from '../../../sdk/contracts/common/base-get-collection-by-id.request';
import { EmailAuditContract } from '../../../sdk/contracts/email-audit/email-audit-contract';
import { EmailListItemContract } from '../../../sdk/contracts/Email/email.list-item.contract';

import { MatchError } from 'src/app/shared/error-matcher';
import { PermissionType } from '../../../sdk/contracts/permission/permission-type';
import { EventsService } from '../../../services/events/events.service';
import { MailFolderSelectDialogComponent } from '../../mail-account-folders/mail-folder-select-dialog/mail-folder-select-dialog.component';
import sanitizeHtml from 'sanitize-html';
import { EmailState } from 'src/app/sdk/contracts/Email/email-state';
import { AttachmentState } from 'src/app/sdk/contracts/Attachment/attachment-state';

enum EmailDetailMenuContent {
  Attachments,
  Notes,
  Notifications,
  Audit,
}

@Component({
  selector: 'app-email-detail-dialog',
  templateUrl: './email-detail-dialog.component.html',
  styleUrls: ['./email-detail-dialog.component.scss'],
})
export class EmailDetailDialogComponent implements OnInit {
  @ViewChild('tags') tagsElement: ElementRef;
  @Input() public emailId: number;
  @Input() public useMobileView = false;
  @Output() public close = new EventEmitter();
  @Output() public getInfoAboutOpenedEmail = new EventEmitter<any>();
  @Output() public setSeenOpened = new EventEmitter<any>();
  @Output() public setValidationStateForEmail = new EventEmitter<any>();
  @Output() public deleteFromData = new EventEmitter<any>();
  public isLoading = false;
  public cannotLoadEmailDetails = false;
  public emailStates = EmailState;
  public emailDetails = new EmailContract();
  public emailValidationState = EmailValidationState;
  public mailAccountId: number;
  public canSend = false;
  public canValidate = false;
  public existedTags: string[] = [];
  public thisTags: string[] = [];
  public webCode: string;
  public Editor = ClassicEditor;
  public showAdditionalInfo = false;
  public getFileExtension = getFileExtension;
  public menuIsOpened = false;
  public menuContent: EmailDetailMenuContent | null;
  public menuContentTypes = EmailDetailMenuContent;
  public routerSubscription: Subscription;
  public createOrUpdateDrawerIsOpen = false;
  public updatedEmailId: number | null;
  public menuTitle: string | null;
  public body: string;
  public currentFolderType: number;
  public approveInProgress: boolean = false;
  public rejectInProgress: boolean = false;
  public isDownloadingEML: boolean = false;
  public isDownloadingSingleEML: boolean = false;
  public validatingEmail: boolean = false;
  public isAuditLoading = false;
  public isAuditLoadingError = false;
  public isCurrentElementTags = false;
  public isDrawerClosing = false;
  public isReplying = false;
  public isReplyingAll = false;
  public isForwarding = false;
  public attachmentState = AttachmentState;

  public emailDrawerIsOpen = false;
  public openedEmail: EmailListItemContract | null;
  public events: EmailAuditContract[] | null;
  public displayedColumns: string[] = ['eventType', 'userName', 'eventDateTime', 'metadata'];
  public mailFolderType = MailFolderType;
  public downloadingFileIds: number[] = [];

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    private snackbarHelper: SnackbarHelper,
    private emailClient: EmailClient,
    private fileClient: FileClient,
    private tempFileClient: TempFileClient,
    private location: Location,
    private authorizationService: AuthorizationService,
    public dialog: MatDialog,
    public tagClient: TagClient,
    public matchError: MatchError,
    private eventsService: EventsService,
    private permissionService: PermissionService,
    private emailTagClient: EmailTagClient,
    private emailAuditClient: EmailAuditClient,
    private searchContextStorageService: SearchContextStorageService,
    private mailFolderClient: MailFolderClient
  ) {
    this.webCode = this.activatedRoute.parent.snapshot.paramMap.get('webCode');
  }

  async ngOnInit(): Promise<void> {
    await this.getEmailDetail(true);
    this.canValidate = await this.permissionService.hasPermissionOver(null, this.mailAccountId, PermissionType.CanValidateEmail);
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeCreateOrUpdateModalForm();
    }
  }

  async setValidated() {
    this.validatingEmail = true;
    try {
      await this.emailClient.setValidated({ emailsId: [this.emailId], validated: EmailValidationState.Validated });
      if (this.emailDetails.validationState === EmailValidationState.NotValidated) {
        this.deleteFromData.emit(this.emailId);
      } else {
        this.setValidationStateForEmail.emit(this.emailId);
      }
      this.emailDetails.validationState = EmailValidationState.Validated;
      // this.close.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.validatingEmail = false;
    }
  }

  // open new detail dialog
  public openEmailDetails(email: EmailListItemContract) {
    this.openedEmail = email;
    this.emailDrawerIsOpen = true;
  }

  public checkingTheActiveElement(): void {
    const activeElement = document.activeElement;
    if (this.tagsElement?.nativeElement?.contains(activeElement)) {
      this.isCurrentElementTags = true;
    } else {
      this.isCurrentElementTags = false;
    }
  }

  public async closeEmailModalForm(): Promise<any> {
    this.close.emit();
  }

  async getEmailDetail(isInit: boolean = false): Promise<void> {
    this.isLoading = true;
    this.cannotLoadEmailDetails = false;
    const request = new BaseGetByIdRequest(this.emailId);
    try {
      const result = await this.emailClient.getById(request);
      this.emailDetails = result.email;
      console.log(this.emailDetails);
      if (this.emailDetails.body) {
        // add htmlRegex to fix bug if not valid html (remove all Html code between <!-- and -->)
        const htmlRegex = new RegExp(/<!--[\s\S]*?-->/g);
        this.body = this.emailDetails.body.replace(htmlRegex, '');
        this.body = sanitizeHtml(this.body, {
          allowedTags: false,
          allowedAttributes: false,
          allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat(['data']),
        });
      } else {
        this.body = '';
      }
      this.currentFolderType = this.searchContextStorageService.userFolders.find(
        (e) => e.mailFolderId === this.emailDetails.mailFolderId
      ).folderType;
      this.getInfoAboutOpenedEmail.emit(this.emailDetails);
      const folderResponse = await this.mailFolderClient.get(new BaseGetByIdRequest(this.emailDetails.mailFolderId));
      this.mailAccountId = folderResponse.result.mailAccountId;

      let tagsResponse = await this.tagClient.getForMailAccount(new BaseGetByIdRequest(this.mailAccountId));

      this.existedTags = tagsResponse.data.map((c) => c.tag);

      tagsResponse = await this.emailTagClient.getEmailTags(new BaseGetByIdRequest(this.emailId));

      this.thisTags = tagsResponse.data.map((c) => c.tag);
      if (isInit && !this.emailDetails.seen) {
        this.setSeen(true, true);
      }
    } catch (e) {
      this.cannotLoadEmailDetails = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  haveAttachments(): boolean {
    if (this.emailDetails.attachmentFiles === undefined) {
      return false;
    }
    return this.emailDetails.attachmentFiles.length > 0;
  }

  async getAttachmentFile(file: AttachmentContract): Promise<void> {
    try {
      this.downloadingFileIds.push(file.fileId);
      const request = new BaseGetByEmailFileIdRequest(file.fileId, this.emailId);

      await this.fileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.downloadingFileIds = this.downloadingFileIds.filter((e) => e !== file.fileId);
    }
  }

  isFileDownloading(fileId: number) {
    if (!fileId) {
      return false;
    }
    return this.downloadingFileIds.includes(fileId);
  }

  async reply(): Promise<any> {
    try {
      this.isReplying = true;
      const newEmail = await this.emailClient.reply(new EmailReplyRequest(this.emailId));
      this.openCreateOrUpdateModalForm(newEmail.id, '');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isReplying = false;
    }
  }

  async replyAll(): Promise<any> {
    try {
      this.isReplyingAll = true;
      const newEmail = await this.emailClient.replyAll(new EmailReplyRequest(this.emailId));
      this.openCreateOrUpdateModalForm(newEmail.id, '');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isReplyingAll = false;
    }
  }

  async forward(): Promise<any> {
    try {
      this.isForwarding = true;
      const newEmail = await this.emailClient.forward(new EmailForwardRequest(this.emailId));
      this.openCreateOrUpdateModalForm(newEmail.id, '');
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isForwarding = false;
    }
  }

  async delete(): Promise<any> {
    try {
      await this.emailClient.delete({ emailIds: [this.emailId] });
      this.emailDetails.deleted = !this.emailDetails.deleted;
      this.close.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async restoreFromTrash(): Promise<any> {
    const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
      width: '350px',
      data: {
        mailAccountId: this.mailAccountId,
        ids: this.emailId,
        isRestore: true,
        folderType: this.currentFolderType,
        isTrash: true,
        sourceFoldersType: this.emailDetails.sourceFolderType,
        title: 'Select the folder to restore',
      },
    });

    dialogRef.afterClosed().subscribe(async (e) => {
      if (e?.confirmed) {
        try {
          await this.emailClient.restore({ emailIds: [this.emailId], mailFolderId: e.folderId });
          this.emailDetails.deleted = !this.emailDetails.deleted;
          this.close.emit();
        } catch (e) {
          this.matchError.errorHandler(e);
          this.matchError.logError(e);
        }
      }
    });
  }

  async restoreMoveFolderChoose(ids: number[], operationType: string): Promise<any> {
    if (ids.length > 0) {
      const dialogRef = this.dialog.open(MailFolderSelectDialogComponent, {
        width: '350px',
        data: {
          mailAccountId: this.mailAccountId,
          ids: ids,
          isRestore: true,
          folderType: this.currentFolderType,
          title: 'Select the folder to restore',
        },
      });
      dialogRef.afterClosed().subscribe((e) => {
        if (e?.confirmed) {
          if (operationType === 'restoreOneSpam') {
            this.setNotSpam(ids, e.folderId);
          }
        }
      });
    }
  }

  async restore(id: number[], folderId: number): Promise<any> {
    try {
      await this.emailClient.restore({ emailIds: id, mailFolderId: folderId });
      this.emailDetails.deleted = !this.emailDetails.deleted;
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async setNotSpam(id: number[], folderId: number): Promise<any> {
    try {
      await this.emailClient.setNotSpam({ emailIds: id, mailFolderId: folderId });
      this.emailDetails.isSpam = !this.emailDetails.isSpam;
      this.close.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async isSpam(): Promise<any> {
    try {
      await this.emailClient.setIsSpam({ emailIds: [this.emailId] });
      this.emailDetails.isSpam = !this.emailDetails.isSpam;
      this.close.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async setSeen(b: boolean, isFirstOpen: boolean = false): Promise<any> {
    try {
      await this.emailClient.setSeen({ emailIds: [this.emailId], seen: b });
      this.setSeenOpened.emit({ b: b, emailId: this.emailId, folderId: this.emailDetails.mailFolderId });
      this.emailDetails.seen = !this.emailDetails.seen;
      if (isFirstOpen) {
        this.emailDetails.seen = true;
      }
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  public showIcon(iconTypes: number[]) {
    return iconTypes.includes(this.currentFolderType);
  }

  async approve(): Promise<any> {
    this.approveInProgress = true;
    try {
      await this.emailClient.validationApprove(new BaseGetByIdRequest(this.emailId));
      this.approveInProgress = false;
      this.deleteFromData.emit(this.emailId);
      this.eventsService.unreadCountEvent.emit();
      this.close.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.approveInProgress = false;
      this.matchError.logError(e);
    }
  }

  async reject(): Promise<any> {
    this.rejectInProgress = true;
    try {
      await this.emailClient.validationReject(new BasePostCommentRequest(this.emailId, ''));
      this.rejectInProgress = false;
      this.deleteFromData.emit(this.emailId);
      this.eventsService.unreadCountEvent.emit();
      this.close.emit();
    } catch (e) {
      this.rejectInProgress = false;
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  print(): void {
    window.open(
      `${this.webCode}/mail-account/${this.mailAccountId}/mail-folder/${this.emailDetails.mailFolderId}/emails-content/${this.emailId}/print`,
      '_blank'
    );
  }

  public showNotifications(): void {
    this.menuContent = EmailDetailMenuContent.Notifications;
    this.menuIsOpened = true;
  }

  async audit(): Promise<void> {
    this.menuContent = EmailDetailMenuContent.Audit;
    this.menuIsOpened = true;
    await this.getEvents();
  }

  async getEvents(): Promise<any> {
    this.isAuditLoadingError = false;
    this.isAuditLoading = true;
    try {
      const response = await this.emailAuditClient.getByEmail(new BaseGetCollectionByIdRequest(this.emailId, 1, 100));
      this.events = response.data;
    } catch (e) {
      this.isAuditLoadingError = true;
      this.matchError.logError(e);
    } finally {
      this.isAuditLoading = false;
    }
  }

  public showAttachments(): void {
    this.menuContent = EmailDetailMenuContent.Attachments;
    this.menuIsOpened = true;
  }

  public showNotes(): void {
    this.menuContent = EmailDetailMenuContent.Notes;
    this.menuIsOpened = true;
  }

  public closeMenu(): void {
    this.menuIsOpened = false;
    this.menuContent = null;
  }

  public get menuHeader(): string {
    switch (this.menuContent) {
      case EmailDetailMenuContent.Attachments:
        return 'attachments';
      case EmailDetailMenuContent.Notes:
        return 'notes';
      case EmailDetailMenuContent.Notifications:
        return 'notifications';
      case EmailDetailMenuContent.Audit:
        return 'audit';
      default:
        return '';
    }
  }

  public formatTo(to: string | null): string {
    if (!to) return '';
    let formatedEmails = to.split(';').join('; ');
    return formatedEmails;
  }

  public getDate(email: EmailListItemContract) {
    let emailFolderType = this.searchContextStorageService.userFolders.find((e) => e.mailFolderId === email.mailFolderId).folderType;
    return emailFolderType === MailFolderType.Outbox ||
      emailFolderType === MailFolderType.Draft ||
      emailFolderType === MailFolderType.Validation
      ? email.stateDateTime
      : email.sentDateTime;
  }

  public openCreateOrUpdateModalForm(emailId: number, title: string): void {
    this.updatedEmailId = emailId;
    this.menuTitle = title;
    this.createOrUpdateDrawerIsOpen = true;
  }

  public closeCreateOrUpdateModalForm(): void {
    this.isDrawerClosing = true;
    setTimeout(() => {
      this.createOrUpdateDrawerIsOpen = false;
      this.isDrawerClosing = false;
      this.eventsService.onEscCloseDetailDialogEvent.emit();
      this.close.emit();
    }, 0);
  }

  public async downloadSingleEML() {
    this.isDownloadingSingleEML = true;
    try {
      let fileResponse = await this.emailClient.singleDownload({ emailIds: [this.emailId] });
      const request = new BaseGetByEmailFileIdRequest(fileResponse.fileId, this.emailId);
      await this.fileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
    } finally {
      this.isDownloadingSingleEML = false;
    }
  }

  public async downloadEML() {
    this.isDownloadingEML = true;
    try {
      let fileResponse = await this.emailClient.download({ emailIds: [this.emailId] });
      const request = new BaseGetByFileIdRequest(fileResponse.fileId);
      await this.tempFileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isDownloadingEML = false;
    }
  }

  public onEditorReady(editor): void {
    const toolbarElement = editor.ui.view.toolbar.element;
    toolbarElement.style.display = 'none';
  }
}
