<div *ngIf="!data.isCreate">
  <h1 mat-dialog-title>{{ data.title || "confirmation" | translate }}</h1>
  <div mat-dialog-content>
    <p>
      <span *ngIf="!data.isDontNeedConfirmWord">{{ "doYouConfirm" | translate }}</span>
      {{ data.name | translate }} <span *ngIf="data.itemName" class="font-bold italic">{{ data.itemName }}</span> ?
    </p>
  </div>
  <div mat-dialog-actions>
    <div class="w-full flex justify-between">
      <button mat-button (click)="onNoClick()">{{ "no" | translate }}</button>
      <button mat-flat-button color="primary" (click)="onOkClick()">{{ "yes" | translate }}</button>
    </div>
  </div>
</div>
<div *ngIf="data.isCreate">
  <app-create-contact [mailAccountId]="data.mailAccountId"></app-create-contact>
  <button mat-raised-button color="primary" (click)="onNoClick()">{{ "cancel" | translate }}</button>
</div>
