import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseDeleteResponse } from '../contracts/common/base-delete.response';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { DispatchingRule } from '../contracts/dispatching/dispatching.rule';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { DispatchingRuleCreateRequest } from '../contracts/dispatching/dispatchingrule.create.request';
import { DispatchingRuleUpdateRequest } from '../contracts/dispatching/dispatchingrule.update.request';
import { DispatchingRuleAvailableDataResponse } from '../contracts/dispatching/dispatchingruleavailabledata.reponse';
import { BaseCollectionSearchByIdRequest } from '../contracts/common/base-collection-search-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../contracts/common/base-get-search-count-by-id.request';
import { BaseValueResponse } from '../contracts/common/base-value-response';

const API_URL = environment.apiUrl;
@Injectable({
  providedIn: 'root',
})
export class DispatchingRuleClient {
  constructor(private apiRequest: ApiRequest) {}

  async getForAccount(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<DispatchingRule>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.dispatchingRule.getForAccount}?${request.format()}`);
  }

  async searchForAccount(request: BaseCollectionSearchByIdRequest): Promise<BaseGetCollectionResponse<DispatchingRule>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.dispatchingRule.searchForAccount}?${request.format()}`);
  }

  async getSearchForAccountCount(request: BaseGetSearchCountByIdRequest): Promise<BaseValueResponse<number>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.dispatchingRule.getSearchForAccountCount}?${request.format()}`);
  }

  async getAvailableDataForAccount(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<DispatchingRuleAvailableDataResponse>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.dispatchingRule.getAvailableDataForAccount}?${request.format()}`);
  }

  async get(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<DispatchingRule>> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.dispatchingRule.get}?${request.format()}`);
  }

  async create(request: DispatchingRuleCreateRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<DispatchingRuleCreateRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.dispatchingRule.create}`);
  }

  async update(request: DispatchingRuleUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<DispatchingRuleUpdateRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.dispatchingRule.update}`);
  }

  async delete(request: BaseDeleteRequest): Promise<BaseDeleteResponse> {
    return this.apiRequest.post<BaseDeleteRequest, BaseDeleteResponse>(request, `${API_URL}${ApiRoutes.dispatchingRule.delete}`);
  }
}
