import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { AuthenticationType } from '../../sdk/contracts/authentication/authentication-type';
import { GetAuthenticationTypesRequest } from '../../sdk/contracts/authentication/get-authentication-types.request';
import { environment } from '../../../environments/environment';
import { AuthenticationClient } from '../../sdk/clients/authentication.client';
import { MatchError } from '../../shared/error-matcher';
import { LoggingHandler } from '../logging/logging.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    protected router: Router,
    protected authenticationService: AuthenticationService,
    protected authenticationClient: AuthenticationClient,
    protected matchError: MatchError,
    protected loggingHandler: LoggingHandler,
  ) {}

  public async loadAuthTypes(webCode: string): Promise<AuthenticationType[]> {
    try {
      const request = new GetAuthenticationTypesRequest(webCode);
      const response = await this.authenticationClient.getAuthenticationTypes(request);
      return response.data;
    } catch (e) {
      if (e.Code === 66) {
        await this.loggingHandler.create('information', 'redirect loadAuthTypes e.Code === 66');
        await this.router.navigateByUrl(`/`);
      } else {
        await this.loggingHandler.create('information', 'redirect loadAuthTypes e.Code !== 66');
        await this.router.navigateByUrl(`/${webCode}/500`);
      }
      this.matchError.logError(e);
    }
  }

  public redirectAuth(authTypes: AuthenticationType[], webCode: string): boolean {
    if (authTypes.length === 1) {
      if (authTypes[0] === AuthenticationType.Oidc) {
        this.loggingHandler.create('information', 'redirect authTypes[0] === AuthenticationType.Oidc');
        document.location.href = `${environment.oidcSignInUri}?webCode=${webCode}`;
      }
      if (authTypes[0] === AuthenticationType.Google) {
        this.loggingHandler.create('information', 'redirect authTypes[0] === AuthenticationType.Google');
        document.location.href = `${environment.googleSignInUri}?webCode=${webCode}`;
      }
      if (authTypes[0] === AuthenticationType.Saml) {
        this.loggingHandler.create('information', 'redirect authTypes[0] === AuthenticationType.Saml');
        document.location.href = `${environment.samlSignInUri}?webCode=${webCode}`;
      }
    } else {
      this.loggingHandler.create('information', `redirect !authTypes redirectAuth ${authTypes} ${webCode} ${JSON.stringify(authTypes)}`);
      this.router.navigate([`/${webCode}/sign-in`], {
        queryParams: { otherData: JSON.stringify(authTypes) },
      });
    }
    return false;
  }

  public async authentication(webCode: string): Promise<boolean> {
    const authTypes = await this.loadAuthTypes(webCode);
    return this.redirectAuth(authTypes, webCode);
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    if (this.authenticationService.isLoggedIn()) { return true; }
    const webCode = route.params.webCode;
    if (!webCode) {
      await this.loggingHandler.create('information', 'redirect !webCode');
      await this.router.navigateByUrl(`/`);
    }
    return this.authentication(webCode);
  }
}
