import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiException } from 'src/app/sdk/api-errors/api.exception';
import { GroupClient } from 'src/app/sdk/clients/group.client';
import { MailFolderClient } from 'src/app/sdk/clients/mail-folder.client';
import { GroupRequest } from 'src/app/sdk/contracts/group/group.request';
import { MailAccountGetUnreadCountRequest } from 'src/app/sdk/contracts/mail-folder/mail-folder.get-unreadcount.request';
import { MatchError } from 'src/app/shared/error-matcher';
import { AuthenticationService } from '../../../services/auth/authentication.service';

@Component({
  selector: 'test-errors-page',
  templateUrl: './test-errors-page.component.html',
  styleUrls: ['./test-errors-page.component.scss'],
})
export class TestErrorsPageComponent implements OnInit {
  public returnUrl: string;
  public canRedirectToHomePage: boolean;
  public homeUrl: string | null;
  public errors = ['js', 'js without try catch', 'get getUnreadCount', 'post createGroupForOrganization'];
  public selected = 'get getUnreadCount';

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private authenticationService: AuthenticationService,
    private matchError: MatchError,
    private groupClient: GroupClient,
    private mailFolderClient: MailFolderClient,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const webCode = this.activatedRoute.snapshot.paramMap.get('webCode');
    if (webCode) {
      this.homeUrl = `/${webCode}`;
      this.canRedirectToHomePage = true;
    } else {
      this.canRedirectToHomePage = false;
    }
    this.returnUrl = this.router.getCurrentNavigation()?.extras?.state?.returnUrl;
  }

  async emitError() {
    if (this.selected === 'js without try catch') {
      throw new Error('js error');
    }
    try {
      if (this.selected === 'get getUnreadCount') {
        await this.mailFolderClient.getUnreadCount(new MailAccountGetUnreadCountRequest(999999999, false));
      }
      if (this.selected === 'post createGroupForOrganization') {
        await this.groupClient.createGroupForOrganization(new GroupRequest(999999999, 'test payload string'));
      }
      if (this.selected === 'js') {
        throw new Error('js error');
      }
    } catch (e) {
      this.matchError.logError(e);
      if (e instanceof ApiException) {
        console.log('api');
      } else {
        console.log('no api');
      }
    }
  }
}
