<div class="drawer-content-screen flex flex-col justify-between">
  <div *ngIf="!loadingAvailableData && !cannotLoadAvailableData" class="text-2xl mb-10 mr-10 font-medium">
    {{ isCreation ? ("createNewDispatchingRule" | translate) : ("editDispatchingRule" | translate) }}
  </div>
  <div *ngIf="cannotLoadAvailableData" class="flex flex-col justify-center h-full">
    <app-something-went-wrong
      description="{{ 'cannotLoadData' | translate }}"
      [showPicture]="false"
      (retryEmitter)="loadAvailableData()"></app-something-went-wrong>
  </div>
  <div *ngIf="loadingAvailableData" class="flex flex-col justify-center h-full">
    <app-loader height="100%"></app-loader>
  </div>
  <div class="h-full flex-1 overflow-auto" *ngIf="!loadingAvailableData && !cannotLoadAvailableData">
    <form class="flex flex-col h-full" [formGroup]="form" autocomplete="off" novalidate>
      <div class="fields mr-[2px]">
        <div>
          <div class="w-full flex flex-wrap md:flex-nowrap">
            <div class="w-full mr-0 md:w-1/2 md:mr-3">
              <mat-form-field appearance="fill">
                <mat-label>{{ "name" | translate }}</mat-label>
                <input matInput id="name" formControlName="name" [(ngModel)]="dispatchingRule.dispatchingRule.name" required />
                <mat-error *ngIf="hasError('name', 'required')">{{ "nameIsRequired" | translate }}</mat-error>
                <mat-error *ngIf="hasError('name', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
              </mat-form-field>
            </div>
            <div class="w-full md:w-1/2">
              <mat-form-field appearance="fill">
                <mat-label>{{ "priority" | translate }}</mat-label>
                <input
                  type="number"
                  matInput
                  id="priority"
                  formControlName="priority"
                  min="1"
                  max="10000"
                  [(ngModel)]="dispatchingRule.dispatchingRule.priority"
                  required />
                <mat-error *ngIf="hasError('priority', 'required')">{{ "priorityIsRequired" | translate }}</mat-error>
                <mat-error *ngIf="hasError('priority', 'min')">{{ "priorityMustMore0" | translate }}</mat-error>
                <mat-error *ngIf="hasError('priority', 'max')">{{ "priorityMustLessOrEqual100" | translate }}</mat-error>
                <mat-error *ngIf="hasError('priority', 'pattern')">{{ "mustBeInt" | translate }}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="mt-1">
            <mat-checkbox
              color="primary"
              (change)="onChangeContinueNextRules($event)"
              [(ngModel)]="dispatchingRule.dispatchingRule.options.continueNextRulesExecutionOnMatch"
              [ngModelOptions]="{ standalone: true }">
              <span>
                {{ "continueNextRulesExecutionOnMatch" | translate }}
              </span>
            </mat-checkbox>
          </div>
        </div>
        <div class="mb-3">
          <app-dispatching-folders [isShowError]="isShowError" [checkBoxFolders]="checkBoxFolders" [dispatchingRule]="dispatchingRule">
          </app-dispatching-folders>
        </div>
        <div class="mb-5">
          <app-dispatching-conditions
            [applyToEmails]="applyToEmails"
            [applyToNotifications]="applyToNotifications"
            [emailConditions]="dispatchingRule.dispatchingRule.matchExpression.conditions"
            (changeEmailClassesEventEmitter)="onChangeEmailClasses($event)">
          </app-dispatching-conditions>
        </div>
        <div class="mb-5">
          <app-dispatching-actions
            [isShowAddIcon]="this.existingActionTypes.length !== 0"
            [dispatchingRule]="dispatchingRule"
            [availableActions]="availableActions"
            [mailAccounts]="accounts"
            [differentActionsIsValid]="differentActionsIsValid()"
            [availableLocalFolders]="availableLocalFolders"
            [checkBoxGroups]="checkBoxGroups"
            [existingActionTypes]="existingActionTypes"
            (createSubmitEventEmitter)="addAction()"
            (changeSubmitEventEmitter)="existingActionTypesUpdate()"
            (deleteSubmitEventEmitter)="deleteAction($event)">
          </app-dispatching-actions>
        </div>
        <div>
          <app-error-hint
            *ngIf="!selectedAtLeastOneAction()"
            [centerAlign]="false"
            errorText="{{ 'addAtLeastOneActionErrorHint' | translate }}"></app-error-hint>
          <app-error-hint
            *ngIf="!differentActionsIsValid()"
            [centerAlign]="false"
            errorText="{{ 'addTwoDifferentActionsErrorHint' | translate }}"></app-error-hint>
        </div>
        <div *ngIf="isShowError">
          <app-error-hint
            *ngIf="hasError('name', 'required')"
            [centerAlign]="false"
            errorText="{{ 'nameIsRequired' | translate }}"></app-error-hint>
          <app-error-hint
            *ngIf="hasError('name', 'maxlength')"
            [centerAlign]="false"
            errorText="{{ 'moreThan256Char' | translate }}"></app-error-hint>
          <app-error-hint
            *ngIf="hasError('priority', 'required')"
            [centerAlign]="false"
            errorText="{{ 'priorityIsRequired' | translate }}"></app-error-hint>
          <app-error-hint
            *ngIf="hasError('priority', 'min')"
            [centerAlign]="false"
            errorText="{{ 'priorityMustMore0' | translate }}"></app-error-hint>
          <app-error-hint
            *ngIf="hasError('priority', 'max')"
            [centerAlign]="false"
            errorText="{{ 'priorityMustLessOrEqual100' | translate }}"></app-error-hint>
          <app-error-hint
            *ngIf="hasError('priority', 'pattern')"
            [centerAlign]="false"
            errorText="{{ 'mustBeInt' | translate }}"></app-error-hint>
          <app-error-hint
            *ngIf="!selectedAtLeastOneFolder()"
            [centerAlign]="false"
            errorText="{{ 'selectAtLeastOneFolder' | translate }}"></app-error-hint>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="!loadingAvailableData && !cannotLoadAvailableData" class="pt-4 w-full flex justify-end">
    <div class="flex justify-end">
      <div>
        <div class="flex justify-end">
          <button (click)="submit()" mat-flat-button color="primary" [disabled]="isLoading">
            <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
