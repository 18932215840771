import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  OnChanges,
  ViewChild,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AttachmentUploadContract } from '../../../sdk/contracts/Attachment/attachment.upload.contract';
import { EmailContract } from '../../../sdk/contracts/Email/email.contract';
import { getFileExtension } from '../../../shared/helpers/file-helper';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { BaseGetByEmailFileIdRequest } from '../../../sdk/contracts/common/base-get-by-email-file-id.request';
import { TagClient } from '../../../sdk/clients/tag.client';
import { EmailTagClient } from '../../../sdk/clients/email-tag.client';
import { MailAccountClient } from '../../../sdk/clients/mail-account.client';
import { PermissionType } from '../../../sdk/contracts/permission/permission-type';
import { PermissionService } from '../../../services/permission/permission.service';
import { environment } from '../../../../environments/environment';
import { filter } from 'rxjs/operators';
import objectHash from 'object-hash';
import { EmailUpdateRequest } from '../../../sdk/contracts/Email/email.update.request';
import { AttachmentContract } from '../../../sdk/contracts/Attachment/Attachment.contract';
import { EmailBodyContentType } from '../../../sdk/contracts/Email/email-body-content-type';
import { EmailState } from '../../../sdk/contracts/Email/email-state';
import '../../../translation/ckeditor-translation/it';
import '../../../translation/ckeditor-translation/en-gb';
import sanitizeHtml from 'sanitize-html';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

import { EmailClient } from '../../../sdk/clients/email-client';
import { SnackbarHelper } from '../../../shared/helpers/snackbar.helper';
import { angularEmailValidator } from '../../../shared/email-validators';
import { Location } from '@angular/common';
import { FileCreateRequest } from '../../../sdk/contracts/file/file.create.request';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FileClient } from '../../../sdk/clients/file.client';
import { EmailCreateRequest } from '../../../sdk/contracts/Email/email.create.request';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EmailContactsDialogComponent } from '../email-contacts-dialog/email-contacts-dialog.component';
import { BaseGetCollectionByIdRequest } from '../../../sdk/contracts/common/base-get-collection-by-id.request';
import { ContactClient } from '../../../sdk/clients/contact.client';
import { Observable } from 'rxjs';
import { ErrorDialogComponent } from '../../error/error-dialog/error-dialog.component';
import { EmailTagSaveRequest } from '../../../sdk/contracts/email-tags/email-tag-save.request';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { Clipboard } from '@angular/cdk/clipboard';
import ClassicEditor from '../../../../ckeditor';
import { EventsService } from '../../../services/events/events.service';
import { MatchError } from 'src/app/shared/error-matcher';
import { TemplateGetByIdContract } from '../../../sdk/contracts/template/template-get-by-id-contract.contract';
import { BaseCollectionSearchByIdRequest } from 'src/app/sdk/contracts/common/base-collection-search-by-id.request';
import { AttachmentState } from 'src/app/sdk/contracts/Attachment/attachment-state';
import { AttachmentClient } from 'src/app/sdk/clients/attachment.client';
import { EmailAuditClient } from 'src/app/sdk/clients/email-audit-client';
import { TranslateService } from '@ngx-translate/core';

enum EmailCreateMenuContent {
  Notes,
  Templates,
  Audit,
}

@Component({
  selector: 'app-email-create-or-update',
  templateUrl: './email-create-or-update.component.html',
  styleUrls: ['./email-create-or-update.component.scss'],
})
export class EmailCreateOrUpdateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public mailAccountId: number;
  @Input() public emailId: number | null;
  @Input() public useMobileView: boolean;
  @Input() public isDrawerClosing: boolean;
  @Output() public submitted = new EventEmitter();
  @Output() public deleteFromData = new EventEmitter<any>();
  @Output() public changeDraftData = new EventEmitter<any>();

  @ViewChild('bodyText') bodyText: CKEditorComponent;
  @ViewChild('uploader') uploader: ElementRef;

  public email: EmailContract;
  public checkAttachmentsInterval: ReturnType<typeof setInterval>;
  public isLoading = true;
  public isContactsLoadingTo = false;
  public isContactsLoadingCc = false;
  public cannotLoadData = false;
  public attachmentState = AttachmentState;
  public form: UntypedFormGroup;
  public senderAddress: string;
  public editor = ClassicEditor;
  public config = {
    language: localStorage.getItem('userLocale'),
  };
  public toList: string[] = [];
  public ccList: string[] = [];
  public initToList: string[] = [];
  public initCcList: string[] = [];
  public attachmentFiles: AttachmentUploadContract[] = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private fileId: number;
  public clickedTagValueTo: string;
  public clickedTagValueCc: string;
  public canSend = false;
  public canValidate = false;
  public existedTags: string[] = [];
  public thisTags: string[] = [];
  public webCode: string;
  public getFileExtension = getFileExtension;
  public menuIsOpened = false;
  public menuContent: EmailCreateMenuContent | null;
  public menuContentTypes = EmailCreateMenuContent;
  private autoSaveSubscription: Subscription;
  public previousHash: string;
  public body: string;
  public templateSubject: string;
  public templateBody: string;
  public showCc = false;
  public submitInProgress = false;
  public saveInProgress = false;
  public cannotSaveData = false;
  public contactsListTo: string[] = [];
  public contactsListCc: string[] = [];
  public initToContacts: string[] = [];
  public initCcContacts: string[] = [];
  public contactCtrl = new UntypedFormControl();
  public filteredContacts: Observable<string[]>;
  public isNotesChanged = false;
  public downloadingFileIds: number[] = [];
  public isSplitEmail = false;
  public isSaveOnClose = true;
  public isNeedToUpdate = true;
  public newTags = [];
  public currentTagsElement: number;

  constructor(
    private tagClient: TagClient,
    private emailTagClient: EmailTagClient,
    private mailAccountClient: MailAccountClient,
    private permissionService: PermissionService,
    private emailClient: EmailClient,
    private attachmentClient: AttachmentClient,
    private translate: TranslateService,
    private snackbarHelper: SnackbarHelper,
    private emailAuditClient: EmailAuditClient,
    private location: Location,
    public dialog: MatDialog,
    private clipboard: Clipboard,
    public matchError: MatchError,
    private eventsService: EventsService,
    public contactClient: ContactClient,
    private fileClient: FileClient
  ) {}

  async ngOnInit(): Promise<void> {
    this.canSend = await this.permissionService.hasPermissionOver(null, this.mailAccountId, PermissionType.CanSendEmail);
    this.canValidate = await this.permissionService.hasPermissionOver(null, this.mailAccountId, PermissionType.CanValidateEmail);

    try {
      await this.loadData();
      if (this.email.cc && this.email.cc.length > 0) {
        this.showCc = true;
      }
      if (this.useMobileView) {
        this.showCc = true;
      }
      this.isSplitEmail = this.email.splitSend;
      this.form = new UntypedFormGroup({
        to: new UntypedFormControl(''),
        cc: new UntypedFormControl(''),
        bcc: new UntypedFormControl(''),
        subject: new UntypedFormControl(this.email.subject, [Validators.maxLength(2048)]),
      });

      this.previousHash = this.calculateDraftHash();
      this.autoSaveSubscription = interval(environment.autoSaveIntervalMs)
        .pipe(
          filter(() => {
            if (this.isLoading || this.cannotLoadData) { return false; }
            const newHash = this.calculateDraftHash();
            const hashesAreNotEqual = this.previousHash !== newHash;
            if (hashesAreNotEqual) { this.previousHash = newHash; }
            return hashesAreNotEqual;
          })
        )
        .subscribe(() => {
          this.updateDraft(true).then();
        });
      this.checkAttachmentsInterval = setInterval(() => {
        this.checkAttachments(this.emailId);
      }, 1000 * 10);
    } catch (e) {
      this.matchError.logError(e);
    }
  }

  ngOnDestroy(): void {
    this.isNeedToUpdate = false;
    this.autoSaveSubscription?.unsubscribe();
    clearInterval(this.checkAttachmentsInterval);
  }

  async ngOnChanges(): Promise<void> {
    if (this.isDrawerClosing && this.isSaveOnClose) {
      this.saveInProgress = true;
      await this.updateDraft(true);
      this.saveInProgress = false;
      this.submitted.emit();
    }
  }

  setCurrentTagsElement(value: number = null): void {
    if (value) {
      this.currentTagsElement = value;
      return;
    }
    const activeEle = document.activeElement.parentElement.parentElement;
    const tagsTags = document.getElementById('tags-tags');
    const tagsCc = document.getElementById('tags-cc');
    const tagsTo = document.getElementById('tags-to');
    if (tagsTo.contains(activeEle)) {
      this.currentTagsElement = 1;
    } else if (tagsCc?.contains(activeEle)) {
      this.currentTagsElement = 2;
    } else if (tagsTags.contains(activeEle)) {
      this.currentTagsElement = 3;
    } else {
      this.currentTagsElement = null;
    }
  }

  private async getContactsTo(value): Promise<any> {
    this.isContactsLoadingTo = true;
    let response;
    try {
      if (!value) {
        response = await this.contactClient.getForAccount(new BaseGetCollectionByIdRequest(this.mailAccountId, 1, 50));
      } else {
        response = await this.contactClient.searchForAccount(new BaseCollectionSearchByIdRequest(this.mailAccountId, 1, 50, value));
      }
      this.contactsListTo = response.data.map((e) => e.email);
    } catch (e) {
      this.matchError.logError(e); // this.matchError.errorHandler(e);
    } finally {
      this.isContactsLoadingTo = false;
    }
  }

  private async getContactsCc(value): Promise<any> {
    this.isContactsLoadingCc = true;
    let response;
    try {
      if (!value) {
        response = await this.contactClient.getForAccount(new BaseGetCollectionByIdRequest(this.mailAccountId, 1, 50));
      } else {
        response = await this.contactClient.searchForAccount(new BaseCollectionSearchByIdRequest(this.mailAccountId, 1, 50, value));
      }
      this.contactsListCc = response.data.map((e) => e.email);
    } catch (e) {
      this.matchError.logError(e); // this.matchError.errorHandler(e);
    } finally {
      this.isContactsLoadingCc = false;
    }
  }

  public splitEmailToggle() {
    this.isSplitEmail = !this.isSplitEmail;
  }

  public onHideNotificationsChanged($event: boolean): void {
    this.isSplitEmail = $event;
  }

  public openErrorDialog(title: string, description: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px',
      data: { title, description },
    });
  }

  public openContactsDialog(isCc = false): void {
    const dialogRef = this.dialog.open(EmailContactsDialogComponent, {
      width: '600px',
      data: { mailAccountId: this.mailAccountId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.contactCtrl.enable();
      if (result && !isCc) {
        this.toList = this.toList.concat(result);
        const notValidEmails = [];
        this.toList.forEach((e, i, arr) => (arr.indexOf(e) === i ? null : notValidEmails.push(e)));
        this.toList = this.toList.filter((e, i, arr) => arr.indexOf(e) === i);
        this.initToList = this.toList;
        if (notValidEmails.length) {
          this.openErrorDialog('These emails already have been added', `${notValidEmails.join('; ')}`);
        }
      }
      if (result && isCc) {
        this.ccList = this.ccList.concat(result);
        const notValidEmails = [];
        this.ccList.forEach((e, i, arr) => (arr.indexOf(e) === i ? null : notValidEmails.push(e)));
        this.ccList = this.ccList.filter((e, i, arr) => arr.indexOf(e) === i);
        this.initCcList = this.ccList;
        if (notValidEmails.length) {
          this.openErrorDialog('These emails already have been added', `${notValidEmails.join('; ')}`);
        }
      }
    });
  }

  private async createDraft(): Promise<number> {
    const createRequest = new EmailCreateRequest();
    createRequest.attachmentFiles = new Array<AttachmentContract>();
    createRequest.from = null;
    createRequest.to = null;
    createRequest.cc = null;
    createRequest.subject = null;
    createRequest.body = null;
    createRequest.bodyContentType = EmailBodyContentType.Html;
    createRequest.mailAccountId = this.mailAccountId;
    createRequest.tags = [];
    createRequest.notes = [];
    createRequest.splitSend = false;

    const response = await this.emailClient.createDraft(createRequest);
    return response.id;
  }

  public templateSelected(value: TemplateGetByIdContract) {
    this.templateSubject = value.subject;
    this.templateBody = value.body;
    this.templateBody = sanitizeHtml(this.templateBody, {
      allowedTags: false,
      allowedAttributes: false,
      allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat(['data']),
    });
    if (this.form.get('subject').value || this.bodyText.editorInstance.getData()) {
      this.openConfirmDialog('bodyAndSubjectChangeConfirmation');
    } else {
      this.changeData();
    }
  }

  public changeData() {
    if (this.body?.length && this.body.slice(0, 8) === '<br />--') {
      this.templateBody = this.templateBody + this.body;
    } else {
      if (this.templateSubject) {
        this.form.get('subject').setValue(this.templateSubject);
      } else {
        this.form.get('subject').setValue('');
      }
    }

    this.bodyText.editorInstance.setData(this.templateBody);
    this.templateSubject = '';
    this.templateBody = '';
    if (this.useMobileView) {
      this.menuIsOpened = false;
    }
  }

  public async checkAttachments(emailId: number): Promise<void> {
    const needToCheck = this.attachmentFiles.some(
      (a) => a.fileState === AttachmentState.CheckInProgress || a.fileState === AttachmentState.Uploaded
    );
    if (!needToCheck) {
      return;
    }
    try {
      const attachmentsResponse = await this.attachmentClient.getById(new BaseGetByIdRequest(emailId));
      const attachmentsDifference: AttachmentContract[] = [];
      const emailFileIds = attachmentsResponse.data.map((a) => a.fileId);
      this.attachmentFiles.forEach((a) => {
        const included = emailFileIds.includes(a.fileId);
        if (!included) {
          attachmentsDifference.push(a);
        }
      });

      if (attachmentsDifference.length) {
        const auditResponse = await this.emailAuditClient.getByEmail(new BaseGetCollectionByIdRequest(this.emailId, 1, 100));
        const auditMetadatas = auditResponse.data.map((e) => e.metadata);
        const attachmentsDifferenceInMetadata = attachmentsDifference.filter((e) => auditMetadatas.some((m) => m === e.fileName));
        console.log('auditResponse', auditMetadatas);
        if (attachmentsDifferenceInMetadata.length) {
          console.log('files containing viruses have been deleted:', attachmentsDifference, auditResponse);
          this.attachmentFiles = this.attachmentFiles.filter((a) => !attachmentsDifferenceInMetadata.some((m) => m.fileId === a.fileId));
          const files = attachmentsDifferenceInMetadata.map((e) => e.fileName).join(', ');
          console.log(files);
          this.openErrorDialog(
            'error',
            this.translate.instant('virusesDetectedFirst') + ' "' + files + '" ' + this.translate.instant('virusesDetectedSecond')
          );
        }
      }

      const findAttachmentFromResponse = (fileId: number) => {
        return attachmentsResponse.data.find((a) => a.fileId === fileId);
      };

      this.attachmentFiles = this.attachmentFiles.map((a) => {
        const attachmentFromResponse = findAttachmentFromResponse(a.fileId);
        if (attachmentFromResponse) {
          return { ...a, fileState: attachmentFromResponse.fileState };
        } else {
          return a;
        }
      });
    } catch (error) {}
  }

  async audit(): Promise<void> {
    this.menuContent = this.menuContentTypes.Audit;
    this.menuIsOpened = true;
  }

  private async getEmail(emailId: number): Promise<void> {
    const emailResponse = await this.emailClient.getById(new BaseGetByIdRequest(emailId));
    this.email = emailResponse.email;
  }

  public async loadData(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadData = false;
    try {
      if (this.emailId) {
        await this.getEmail(this.emailId);
      } else {
        const createdEmailId = await this.createDraft();
        this.emailId = createdEmailId;
        await this.getEmail(createdEmailId);
      }

      const tagsResponse = await this.tagClient.getForMailAccount(new BaseGetByIdRequest(this.mailAccountId));
      this.existedTags = tagsResponse.data.map((c) => c.tag);

      const contactsResponse = await this.contactClient.getForAccount(new BaseGetCollectionByIdRequest(this.mailAccountId, 1, 50));
      this.contactsListTo = contactsResponse.data.map((c) => c.email);
      this.contactsListCc = contactsResponse.data.map((c) => c.email);
      this.initToContacts = this.contactsListTo;
      this.initCcContacts = this.contactsListCc;
      const emailTagsResponse = await this.emailTagClient.getEmailTags(new BaseGetByIdRequest(this.email.emailId));

      this.thisTags = emailTagsResponse.data.map((c) => c.tag);
      this.newTags = this.thisTags;

      if (this.email.to) {
        this.toList = this.email.to.split(';');
        this.initToList = this.email.to.split(';');
      }
      if (this.email.cc) {
        this.ccList = this.email.cc.split(';');
        this.initCcList = this.email.cc.split(';');
      }

      this.senderAddress = this.email.from;
      if (this.email.body) {
        // add htmlRegex to fix bug if not valid html (remove all Html code between <!-- and -->)
        const htmlRegex = new RegExp(/<!--[\s\S]*?-->/g);
        this.body = this.email.body.replace(htmlRegex, '');
        this.body = sanitizeHtml(this.body, {
          allowedTags: false,
          allowedAttributes: false,
          allowedSchemes: sanitizeHtml.defaults.allowedSchemes.concat(['data']),
        });
      } else {
        this.body = '';
      }

      this.attachmentFiles = this.email.attachmentFiles.map((a) => {
        const attach = new AttachmentUploadContract();
        attach.progress = 100;
        attach.attachmentId = a.attachmentId;
        attach.fileId = a.fileId;
        attach.fileName = a.fileName;
        attach.mimeType = a.mimeType;
        attach.size = a.size;
        attach.fileState = a.fileState;
        return attach;
      });

      const response = await this.mailAccountClient.getById(new BaseGetByIdRequest(this.mailAccountId));
      this.senderAddress = response.mailAccount.email;
    } catch (e) {
      this.matchError.logError(e);
      this.cannotLoadData = true;
    } finally {
      this.isLoading = false;
    }
  }

  calculateDraftHash(): string {
    const editorBody = this.bodyText?.editorInstance?.getData();

    const obj = {
      to: this.toList,
      cc: this.ccList,
      subject: this.form.get('subject')?.value,
      body: editorBody || '',
      tags: this.thisTags,
      attachments: this.attachmentFiles.map((f) => f.fileId),
    };

    return objectHash(obj);
  }

  async onClickReset() {
    this.attachmentFiles = [];
    this.toList = [];
    this.ccList = [];
    this.newTags = [];
    this.thisTags = [];
    this.form.get('subject')?.setValue('');
    this.bodyText.editorInstance.setData('');
    await this.emailTagClient.saveTags(new EmailTagSaveRequest([this.emailId], this.thisTags));
    await this.updateDraft(true);
  }

  public openConfirmDialog(name: string, title?: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      data: { name, isDontNeedConfirmWord: true, title },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (name === 'resetSubtitle') {
          this.onClickReset();
        } else {
          this.changeData();
        }
      }
    });
  }

  fileIsUploading(): boolean {
    return this.attachmentFiles.filter((x) => x.progress < 100).length !== 0;
  }

  public setTags(tags) {
    this.newTags = tags;
    //
  }

  async updateDraft(autoSaving: boolean, setStateDraft = false): Promise<void> {
    // if (this.fileIsUploading()) return;
    if ((this.submitInProgress && autoSaving) || this.isLoading) {
      return;
    }
    if (autoSaving) { console.info('%c Email autosaving... ', 'background: #000000; color: #00bfff'); }

    try {
      const updateRequest = new EmailUpdateRequest();
      updateRequest.attachmentFiles = new Array<AttachmentContract>();
      updateRequest.from = this.senderAddress;
      updateRequest.to = this.toList.join(';');
      updateRequest.cc = this.ccList.join(';');
      updateRequest.subject = this.form.controls.subject.value;
      updateRequest.body = this.bodyText.editorInstance?.getData();
      updateRequest.bodyContentType = EmailBodyContentType.Html;
      updateRequest.emailId = this.email.emailId;
      updateRequest.tags = this.newTags;
      if (
        !updateRequest.to &&
        !updateRequest.cc &&
        !updateRequest.attachmentFiles.length &&
        !updateRequest.subject &&
        !updateRequest.body &&
        !updateRequest.tags.length &&
        !setStateDraft
      ) {
        if (this.email.state === EmailState.Draft) {
          updateRequest.state = EmailState.Draft;
        } else {
          if (this.email.state !== EmailState.VirusDetected) {
            updateRequest.state = EmailState.PreDraft;
          } else {
            updateRequest.state = EmailState.Draft;
          }
        }
      } else {
        updateRequest.state = EmailState.Draft;
      }
      this.email.state = updateRequest.state;
      updateRequest.splitSend = this.isSplitEmail;
      const attachments = this.attachmentFiles;
      attachments
        .filter((e) => e?.fileId)
        .forEach((file) => {
          const emailAttachment = new AttachmentContract();
          emailAttachment.fileId = file.fileId;
          emailAttachment.fileName = file.fileName;
          emailAttachment.mimeType = file.mimeType;
          emailAttachment.size = file.size;
          updateRequest.attachmentFiles.push(emailAttachment);
        });
      await this.emailClient.update(updateRequest);
      const emailId = this.emailId;
      const isNotesChanged = this.isNotesChanged;
      this.changeDraftData.emit({ emailId, updateRequest, isNotesChanged });
      if (autoSaving) { console.info('%c Success autosaving ', 'background: #000000; color: #00ffbf'); }
    } catch (e) {
      this.matchError.errorHandler(e);
      if (autoSaving) {
        // this.snackbarHelper.openSnackBar('Cannot autosave email');
        console.info('%c Failed autosaving ', 'background: #000000; color: #ff4000');
      } else {
        throw e;
      }
      this.matchError.logError(e);
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  }

  public validateForm(): boolean {
    if (!this.form) { return false; }
    return this.form.valid && this.validateTo() && this.validateCc();
  }

  private validateTo(): boolean {
    if (this.toList.length === 0) { return false; }

    for (const to of this.toList) {
      if (!this.validateEmail(to)) { return false; }
    }
    return true;
  }

  validateEmail(email: string): boolean {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  private validateCc(): boolean {
    for (const cc of this.ccList) {
      if (!this.validateEmail(cc)) { return false; }
    }
    return true;
  }

  getEmailChipColor(email: string): any {
    if (angularEmailValidator(email)) {
      return 'primary';
    } else {
      return 'warn';
    }
  }

  isFileDownloading(fileId) {
    if (!fileId) {
      return false;
    }
    return this.downloadingFileIds.includes(fileId);
  }

  async getAttachmentFile(file: AttachmentUploadContract): Promise<void> {
    try {
      this.downloadingFileIds.push(file.fileId);
      const request = new BaseGetByEmailFileIdRequest(file.fileId, this.emailId);

      await this.fileClient.download(request);
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.downloadingFileIds = this.downloadingFileIds.filter((e) => e !== file.fileId);
    }
  }

  public isEveryFileUploaded() {
    return this.attachmentFiles.every((e) => e?.fileId);
  }

  addAttachmentFile(files: File[]): void {
    for (const selectedFile of files) {
      const uploadFile = new AttachmentUploadContract();
      uploadFile.fileName = selectedFile.name;
      uploadFile.size = selectedFile.size;
      uploadFile.mimeType = selectedFile.type;
      uploadFile.progress = 0;
      uploadFile.fileState = this.attachmentState.CheckInProgress;
      if (!uploadFile.mimeType) {
        uploadFile.mimeType = 'application/octet-stream';
      }

      if (this.attachmentFiles.length > 0) {
        const existAttachment = this.attachmentFiles.filter((x) => x.fileName === selectedFile.name);
        if (existAttachment.length === 0) {
          const newAttachments = this.attachmentFiles.filter((x) => x.fileName !== selectedFile.name);
          newAttachments.push(uploadFile);
          this.attachmentFiles = newAttachments;
        }
      } else {
        const newAttachments = this.attachmentFiles.filter((x) => x.fileName !== selectedFile.name);
        newAttachments.push(uploadFile);
        this.attachmentFiles = newAttachments;
      }

      const notUploadFiles = this.attachmentFiles.filter((x) => x.fileName === selectedFile.name && x.progress === 0);
      if (notUploadFiles.length !== 0) {
        const reader = new FileReader();
        reader.onload = () => {
          const content = (reader.result as string).split(',')[1];
          this.uploadFile(selectedFile.name, uploadFile.mimeType, content);
        };

        reader.readAsDataURL(selectedFile);
      }
    }
  }

  addAttachmentFileFromButton(event: any): void {
    this.addAttachmentFile(event.target.files);
    this.uploader.nativeElement.value = '';
  }

  uploadFile(fileName: string, mimeType: string, content: string): void {
    let subscription: Subscription;
    const request = new FileCreateRequest(fileName, mimeType, content, this.emailId);
    const cancelRequestIfCloseComponent = setInterval(() => {
      if (!this.isNeedToUpdate) {
        subscription?.unsubscribe();
        clearInterval(cancelRequestIfCloseComponent);
      }
    }, 400);
    subscription = this.fileClient.upload(request).subscribe(
      (event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.attachmentFiles.forEach((attachment) => {
            if (attachment.fileName === fileName) {
              attachment.progress = Math.round((100 * event.loaded) / event.total);
            }
          });
        } else if (event instanceof HttpResponse) {
          if (event.status === 200) {
            this.fileId = event.body.fileId;
            this.attachmentFiles.forEach((attachment) => {
              if (attachment.fileName === fileName) {
                attachment.fileId = event.body.fileId;
              }
            });
            if (this.isNeedToUpdate) {
              this.updateDraft(false);
            }
          } else {
            if (this.isNeedToUpdate) {
              this.attachmentFiles = this.attachmentFiles.filter((item) => item.fileName !== fileName);
              this.openErrorDialog('error', `An error occurred while uploading the file: ${fileName}.`);
            }
            // this.snackbarHelper.openSnackBar(`An error occurred while downloading the file: ${fileName}.`);
          }
        }
      },
      () => {
        if (this.isNeedToUpdate) {
          this.openErrorDialog('error', `An error occurred while uploading the file: ${fileName}.`);
          // this.snackbarHelper.openSnackBar(`An error occurred while downloading the file: ${fileName}.`);
          this.attachmentFiles = this.attachmentFiles.filter((item) => item.fileName !== fileName);
        }
      }
    );
  }

  async deleteAttachment(file: AttachmentUploadContract): Promise<void> {
    const index = this.attachmentFiles.indexOf(file);
    this.attachmentFiles.splice(index, 1);
  }

  public showNotes(): void {
    this.menuContent = EmailCreateMenuContent.Notes;
    this.menuIsOpened = true;
  }

  public showTemplates(): void {
    this.menuContent = EmailCreateMenuContent.Templates;
    this.menuIsOpened = true;
  }

  public refreshNotes() {
    this.isNotesChanged = true;
  }

  public closeMenu(): void {
    this.menuIsOpened = false;
    this.menuContent = null;
  }

  public get menuHeader(): string {
    switch (this.menuContent) {
      case EmailCreateMenuContent.Notes:
        return 'notes';
      case EmailCreateMenuContent.Templates:
        return 'templates';
      case EmailCreateMenuContent.Audit:
        return 'audit';
      default:
        return '';
    }
  }

  async onSubmit(send: boolean): Promise<void> {
    if (send && !this.validateForm()) { return; }

    this.submitInProgress = true;
    this.cannotSaveData = false;

    try {
      await this.updateDraft(false, true);
      if (send) {
        if (this.canSend) {
          await this.emailClient.markForSend(new BaseGetByIdRequest(this.email.emailId));
        } else {
          await this.emailClient.readyToValidate(new BaseGetByIdRequest(this.email.emailId));
        }
        this.isSaveOnClose = false;
        this.deleteFromData.emit(this.email.emailId);
      }
      this.eventsService.unreadCountEvent.emit();
      this.submitted.emit();
    } catch (e) {
      this.cannotSaveData = true;
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.submitInProgress = false;
    }
  }

  async delete(): Promise<any> {
    try {
      await this.emailClient.delete({ emailIds: [this.emailId] });
      this.submitted.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    }
  }

  async saveAsDraft(): Promise<any> {
    this.saveInProgress = true;
    try {
      await this.onSubmit(false);
    } catch (e) {
      this.matchError.logError(e);
    } finally {
      this.isSaveOnClose = false;
      this.saveInProgress = false;
    }
  }

  public changeToList(value: string[]) {
    this.toList = value;
  }

  public changeCcList(value: string[]) {
    this.ccList = value;
  }
}
