export class CreateTemplateRequest {
  mailAccountId: number;
  name: string;
  description: string;
  subject: string;
  body: string;

  constructor(mailAccountId: number, name: string, description: string, subject: string, body: string) {
    this.mailAccountId = mailAccountId;
    this.name = name;
    this.description = description;
    this.subject = subject;
    this.body = body;
  }
}
