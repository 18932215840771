<div class="drawer-content-screen flex flex-col justify-between">
  <div class="w-full mb-2">
    <div class="text-2xl mb-10 font-medium">
      {{ "updateMailAccount" | translate }}
    </div>
    <div>
      <form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="onSubmit()">
        <!-- <mat-form-field appearance="fill">
          <mat-label>Code</mat-label>
          <input matInput id="code" formControlName="code" required>
          <mat-error *ngIf="hasError('code', 'required')">Code is required</mat-error>
          <mat-error *ngIf="hasError('code', 'maxlength')">You have more than 256 characters</mat-error>
        </mat-form-field> -->
        <mat-form-field appearance="fill">
          <mat-label>{{ "license" | translate }}</mat-label>
          <input matInput id="license" formControlName="license" />
          <mat-error *ngIf="hasError('license', 'maxlength')">{{ "moreThan255Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "codeSia" | translate }}</mat-label>
          <input matInput id="codeSia" formControlName="codeSia" />
          <mat-error *ngIf="hasError('codeSia', 'maxlength')">{{ "moreThan255Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "anagId" | translate }}</mat-label>
          <input matInput id="anagId" formControlName="anagId" />
          <mat-error *ngIf="hasError('anagId', 'maxlength')">{{ "moreThan255Char" | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "name" | translate }}</mat-label>
          <input matInput id="name" formControlName="name" required />
          <mat-error *ngIf="hasError('name', 'required')">{{ "nameIsRequired" | translate }}</mat-error>
          <mat-error *ngIf="hasError('name', 'maxlength')">{{ "moreThan256Char" | translate }}</mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="w-full">
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
