import { DispatchingRuleOptions } from './dispatchingrule.options';
import { RemoteMailFolderDefinition } from './remotemailfolder.definition';
import { EmailExpression } from './email.expression';
import { DispatchingAction } from './dispatching.action';

export class DispatchingRuleObject {
  priority = 1;
  name = '';
  options: DispatchingRuleOptions = new DispatchingRuleOptions();
  folders: RemoteMailFolderDefinition[] = [];
  matchExpression: EmailExpression = new EmailExpression();
  actions: DispatchingAction[] = [];
}
