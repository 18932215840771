<div class="h-screen w-screen" *ngIf="isLoading">
  <app-loader height="100vh"></app-loader>
</div>

<!-- <div class="h-screen w-screen" *ngIf="isError">
  <app-something-went-wrong
    [description]="errorText" (retryEmitter)="loadAuthTypes()">
  </app-something-went-wrong>
</div> -->

<div class="h-screen w-screen flex flex-wrap content-center justify-center relative" *ngIf="!isLoading">
  <div class="sign-in-form -mt-20">
    <div class="text-2xl mb-5 font-medium text-center">
      {{ "login" | translate }}
    </div>
    <mat-form-field appearance="fill">
      <mat-label>{{ "authenticationType" | translate }}</mat-label>
      <mat-select [(value)]="selectedAuthType">
        <mat-option *ngFor="let authType of authTypes" [value]="authType">
          {{ getAuthTypeDescription(authType) | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="flex justify-center mt-8">
      <button mat-flat-button color="primary" (click)="onLogin()">{{ "login" | translate }}</button>
    </div>
  </div>
  <!-- <div class="absolute inset-x-0 bottom-0 h-16 w-full bg-black">
    <div class="text-white text-xl font-thin font-mono tracking-tighter h-full flex flex-wrap content-center ml-5">© PecManager</div>
  </div> -->
  <!-- <div class="absolute bottom-0 right-0">
    <img src="assets/sign-in.svg" alt="sign-in" />
  </div> -->
</div>
<!--<div *ngIf="isError" class="h-screen w-screen flex justify-center flex-wrap content-center p-5">-->
<!--  <div class="h-2/3 text-center">-->
<!--    <div class="font-medium text-3xl mb-5">{{ "companyDoesntRegistered" | translate }}</div>-->
<!--    <div class="text-gray-700 text-lg mb-5">-->
<!--      {{ "contactOurSalesDepartment" | translate }}!-->
<!--      <br />-->
<!--      &lt;!&ndash; <div *ngIf="canRedirectToHomePage || returnUrl" class="flex justify-center">-->
<!--        You can go back to&nbsp;-->
<!--        <div *ngIf="canRedirectToHomePage && returnUrl">-->
<!--          <a class="error__link" [routerLink]="returnUrl">previous</a>-->
<!--          or-->
<!--          <a class="error__link" [routerLink]="homeUrl">home</a>-->
<!--          pages-->
<!--        </div>-->
<!--        <div *ngIf="canRedirectToHomePage && !returnUrl">-->
<!--          <a class="error__link" [routerLink]="homeUrl">home</a>-->
<!--          page-->
<!--        </div>-->
<!--        <div *ngIf="!canRedirectToHomePage && returnUrl" >-->
<!--          <a class="error__link" [routerLink]="returnUrl">previous</a>-->
<!--          page-->
<!--        </div>-->
<!--      </div> &ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash; <img class="error-img" src="../../../../assets/401.svg" alt="access-denied">-->
<!--    <button (click)="signout()" mat-raised-button>logout</button> &ndash;&gt;-->
<!--  </div>-->
<!--</div>-->
