export class FileCreateRequest {
  fileName: string;
  mimeType: string;
  content: string;
  emailId: number;

  constructor(fileName: string, mimeType: string, content: string, emailId: number) {
    this.fileName = fileName;
    this.mimeType = mimeType;
    this.content = content;
    this.emailId = emailId;
  }
}
