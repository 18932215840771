import { Component, Input, OnInit } from '@angular/core';
import { BaseGetByIdRequest } from '../../../sdk/contracts/common/base-get-by-id.request';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EmailClient } from '../../../sdk/clients/email-client';
import { MatchError } from 'src/app/shared/error-matcher';

@Component({
  selector: 'app-email-meta',
  templateUrl: './email-meta.component.html',
  styleUrls: ['./email-meta.component.scss'],
})
export class EmailMetaComponent implements OnInit {
  @Input() emailId: number;
  @Input() title: string;
  public isLoading = false;
  public cannotLoadMeta = false;
  public meta: string;

  constructor(public matchError: MatchError, public dialog: MatDialog, private emailClient: EmailClient) {}

  async ngOnInit(): Promise<void> {
    await this.getEmailDetail();
  }

  async getEmailDetail(): Promise<void> {
    this.isLoading = true;
    this.cannotLoadMeta = false;
    const request = new BaseGetByIdRequest(this.emailId);
    try {
      const result = await this.emailClient.getById(request);

      this.meta = result.email.meta;
    } catch (e) {
      this.cannotLoadMeta = true;
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
