<div class="drawer-content-screen flex flex-col justify-between">
  <div class="w-full mb-2">
    <div class="text-2xl mb-10 font-medium">
      {{ "changeUserRole" | translate }}
    </div>
    <div>
      <form [formGroup]="form" autocomplete="off" novalidate>
        <mat-form-field appearance="fill">
          <mat-label>{{ "user" | translate }}</mat-label>
          <input matInput id="name" formControlName="name" readonly required />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ "role" | translate }}</mat-label>
          <mat-select [(value)]="selectedRole" required>
            <mat-option *ngFor="let roleId of availableRoles" [value]="roleId">
              {{ roleTypes[roleId] | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="w-full">
    <div class="flex justify-end">
      <!-- <button (click)="cancel()" mat-flat-button [disabled]="isLoading">{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading || !form.valid" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
