import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatchError } from 'src/app/shared/error-matcher';
import { GroupCreateRequest } from '../../../../..//sdk/contracts/group/group.requets';
import { GroupClient } from '../../../../../sdk/clients/group-settings.client';

@Component({
  selector: 'app-organization-group-create-dialog',
  templateUrl: './organization-group-create-dialog.component.html',
  styleUrls: ['./organization-group-create-dialog.component.scss'],
})
export class OrganizationGroupCreateDialogComponent implements OnInit {
  @Output() public closeEventEmitter = new EventEmitter();
  @Output() public createEventEmitter = new EventEmitter();
  @Input() public organizationId: number;

  form = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(255)]],
  });

  isLoading: boolean = false;
  errorText: string;
  hasError: boolean = false;

  constructor(private fb: UntypedFormBuilder, private groupClient: GroupClient, public matchError: MatchError) {}

  ngOnInit(): void {}

  public cancel(): void {
    this.closeEventEmitter.emit();
  }

  public async save() {
    if (this.form.valid) {
      const req: GroupCreateRequest = {
        organizationId: this.organizationId,
        group: this.form.get('name').value,
      };
      try {
        this.isLoading = true;
        await this.groupClient.create(req);
        this.createEventEmitter.emit();
        this.form.reset();
      } catch (e) {
        this.hasError = true;
        this.matchError.errorHandler(e);
        this.matchError.logError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
