import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { BaseCollectionSearchByIdRequest } from '../contracts/common/base-collection-search-by-id.request';
import { BaseGetSearchCountByIdRequest } from '../contracts/common/base-get-search-count-by-id.request';
import { GroupCreateRequest, GroupDeleteRequest, GroupUpdateRequest } from '../contracts/group/group.requets';
import { GetSearchCounter, GroupGetForOrganizationResponse } from '../contracts/group/group.responses';
import { ApiRoutes } from '../routes/api.routes';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class GroupClient {
  constructor(private apiClient: ApiRequest) {}

  public async getAllByOrganization(request: BaseCollectionSearchByIdRequest) {
    return this.apiClient.get<GroupGetForOrganizationResponse>(
      `${API_URL}${ApiRoutes.groupSettings.getForOrganization}?${request.format()}`
    );
  }

  public async create(request: GroupCreateRequest) {
    return this.apiClient.post<GroupCreateRequest, any>(request, `${API_URL}${ApiRoutes.groupSettings.create}`);
  }

  public async update(request: GroupUpdateRequest) {
    return this.apiClient.post<GroupUpdateRequest, any>(request, `${API_URL}${ApiRoutes.groupSettings.update}`);
  }

  public async delete(request: GroupDeleteRequest) {
    return this.apiClient.post<GroupDeleteRequest, any>(request, `${API_URL}${ApiRoutes.groupSettings.delete}`);
  }

  public async getSearchCounter(request: BaseGetSearchCountByIdRequest) {
    return this.apiClient.get<GetSearchCounter>(`${API_URL}${ApiRoutes.groupSettings.getSearchCounter}?${request.format()}`);
  }
}
