<app-drawer *ngIf="useMobileView" [isOpen]="menuIsOpened" (closeEventEmitter)="closeMenu()">
  <div class="w-full h-full flex flex-col relative">
    <div class="absolute top-[-15px] right-[-15px] h-12 w-12 z-20"></div>
    <div class="w-full flex mb-7 bg-white z-20">
      <div class="flex cursor-pointer items-center" (click)="closeMenu()">
        <button mat-icon-button class="menu-close-btn pr-3 mt-[2px]">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <div class="text-xl font-medium">{{ menuHeader | translate }}</div>
      </div>
    </div>

    <div class="flex-grow w-full px-1 overflow-y-auto">
      <div *ngIf="menuContent == menuContentTypes.Notes" class="h-full">
        <app-email-note-list [emailId]="emailId" (refreshNotes)="refreshNotes()"></app-email-note-list>
      </div>
      <div *ngIf="menuContent == menuContentTypes.Templates" class="h-full">
        <app-email-templates (templateSelected)="templateSelected($event)" [mailAccountId]="mailAccountId"></app-email-templates>
      </div>
      <div *ngIf="menuContent == menuContentTypes.Audit" class="h-full">
        <app-email-audit [emailId]="emailId"></app-email-audit>
      </div>
    </div>
  </div>
</app-drawer>

<div *ngIf="cannotLoadData" class="h-full">
  <app-something-went-wrong
    description="{{ 'cannotLoadData' | translate }}"
    [showPicture]="false"
    (retryEmitter)="ngOnInit()"></app-something-went-wrong>
</div>
<app-loader height="100%" *ngIf="isLoading"></app-loader>
<div *ngIf="!isLoading && !cannotLoadData" (click)="setCurrentTagsElement()" class="flex justify-between h-full">
  <div class="drawer-content-screen flex flex-col w-full h-full overflow-y-auto">
    <div class="w-full mb-2 mt-0 pr-2 flex-grow">
      <div *ngIf="!cannotLoadData && !isLoading">
        <div>
          <div>
            <div>
              <form class="create-email-form" [formGroup]="form" autocomplete="off" novalidate>
                <div class="w-full flex flex-row flex-wrap content-center justify-between">
                  <div class="flex-grow form">
                    <div class="flex relative">
                      <span class="absolute custom-top">{{ "from" | translate }}</span>
                      <div class="subject">{{ senderAddress }}</div>
                    </div>
                    <div class="flex relative">
                      <button
                        mat-icon-button
                        matTooltip="{{ 'openContacts' | translate }}"
                        class="folder-icon"
                        (click)="openContactsDialog()">
                        <mat-icon>folder_shared</mat-icon>
                      </button>
                      <div [class.show-cc-btn]="!showCc" class="folder-add-cc" (click)="showCc = true">
                        <mat-progress-spinner
                          style="width: 24px"
                          *ngIf="isContactsLoadingTo"
                          mode="indeterminate"
                          diameter="16"></mat-progress-spinner>
                        <div *ngIf="!showCc" class="cursor-pointer hover:underline">{{ "addCc" | translate }}</div>
                      </div>
                      <div
                        [ngStyle]="{ 'max-height': currentTagsElement === 1 ? '165px' : '60px' }"
                        id="tags-to"
                        style="overflow: auto"
                        class="flex relative tags-main">
                        <span class="absolute custom-top-tags">{{ "to" | translate }}</span>
                        <app-email-tags
                          style="width: 100%"
                          [placeHolder]="'to'"
                          [toList]="initToList"
                          [initContacts]="initToContacts"
                          (dynamicSearchValue)="getContactsTo($event)"
                          (changeList)="changeToList($event)"
                          (setCurrentTagsElement)="setCurrentTagsElement(1)"
                          [showCc]="showCc"
                          [existedContacts]="contactsListTo"></app-email-tags>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="showCc" class="w-full flex flex-row flex-wrap content-center justify-between">
                  <div class="flex-grow form">
                    <button
                      mat-icon-button
                      matTooltip="{{ 'openContacts' | translate }}"
                      class="folder-icon"
                      (click)="openContactsDialog(true)">
                      <mat-icon>folder_shared</mat-icon>
                    </button>
                    <div [class.show-cc-btn]="!showCc" class="folder-add-cc" (click)="showCc = true">
                      <mat-progress-spinner
                        style="width: 24px"
                        *ngIf="isContactsLoadingCc"
                        mode="indeterminate"
                        diameter="16"></mat-progress-spinner>
                    </div>
                    <div
                      style="overflow: auto"
                      [ngStyle]="{ 'max-height': currentTagsElement === 2 ? '165px' : '60px' }"
                      id="tags-cc"
                      class="flex relative tags-main">
                      <span class="absolute custom-top-tags">{{ "cc" | translate }}</span>
                      <app-email-tags
                        [placeHolder]="'cc'"
                        (dynamicSearchValue)="getContactsCc($event)"
                        (changeList)="changeCcList($event)"
                        (setCurrentTagsElement)="setCurrentTagsElement(2)"
                        style="width: 100%"
                        [toList]="initCcList"
                        [initContacts]="initCcContacts"
                        [existedContacts]="contactsListCc"></app-email-tags>
                    </div>
                  </div>
                </div>
                <div class="flex relative">
                  <span class="absolute custom-top">{{ "subject" | translate }}</span>
                  <input class="subject" type="text" formControlName="subject" (keydown.enter)="$event.preventDefault()" />
                  <mat-error *ngIf="hasError('subject', 'maxlength')">You have more than 2048 characters</mat-error>
                </div>
                <div *ngIf="attachmentFiles.length > 0" class="flex relative min-h-[40px] max-h-[200px]">
                  <span class="absolute custom-top">{{ "attach" | translate }}</span>
                  <div class="subject attachments" type="text">
                    <div class="flex flex-wrap">
                      <div
                        [matTooltip]="!file.fileId ? ('uploadFileAndScanForVirus' | translate) : null"
                        class="file"
                        *ngFor="let file of attachmentFiles"
                        [style.cursor]="file.fileId && !isFileDownloading(file.fileId) ? 'pointer' : 'default'">
                        <div class="flex justify-end items-center pb-[3px] h-[24px]">
                          <mat-icon
                            [matTooltip]="'scaningForVirus' | translate"
                            *ngIf="false &&
                              file.fileId &&
                              (file.fileState === attachmentState.CheckInProgress || file.fileState === attachmentState.Uploaded)
                            "
                            style="transform: scale(0.65); color: rgb(233, 162, 21); cursor: default"
                            mat-icon-button>
                            bolt
                          </mat-icon>
                          <mat-icon
                            [matTooltip]="'noVirusesDetected' | translate"
                            *ngIf="false && file.fileId && file.fileState === attachmentState.VirusNotDetected"
                            style="transform: scale(0.65); color: green; cursor: default"
                            mat-icon-button>
                            check
                          </mat-icon>
                        </div>

                        <!-- <div [style.cursor]="'default'" class="main__attachment">
                          <file-icon
                            style="width: 16px"
                            set="classic"
                            style="font-size: '14px'"
                            [type]="getFileExtension(file.fileName)"></file-icon>
                        </div> -->
                        <span
                          (click)="file.fileId && !isFileDownloading(file.fileId) && getAttachmentFile(file)"
                          [style.cursor]="file.fileId && !isFileDownloading(file.fileId) ? 'pointer' : 'default'"
                          [style.color]="file.fileId && !isFileDownloading(file.fileId) ? 'black' : 'grey'"
                          style="line-height: 1; padding: 0 3px; overflow-wrap: anywhere">
                          {{ file.fileName }}
                        </span>
                        <div class="flex justify-end items-center pb-[3px] h-[24px]">
                          <mat-progress-spinner
                            style="width: 24px"
                            *ngIf="isFileDownloading(file.fileId)"
                            mode="indeterminate"
                            diameter="16"></mat-progress-spinner>
                          <mat-progress-spinner
                            style="width: 24px"
                            *ngIf="!file.fileId"
                            mode="indeterminate"
                            diameter="16"></mat-progress-spinner>
                          <mat-icon
                            *ngIf="file.fileId && !isFileDownloading(file.fileId)"
                            style="transform: scale(0.65); color: red"
                            (click)="deleteAttachment(file)"
                            mat-icon-button
                            >close</mat-icon
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex relative w-full">
                  <span class="absolute custom-top-tags">{{ "tags" | translate }}</span>
                  <div
                    id="tags-tags"
                    [ngStyle]="{ 'max-height': currentTagsElement === 3 ? '165px' : '60px' }"
                    class="w-full app-tags tags-main">
                    <app-tags
                      (setTags)="setTags($event)"
                      [emailId]="[emailId]"
                      [existedTags]="existedTags"
                      [thisTags]="thisTags"></app-tags>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="editor-create page-block h-full mb-3 canvas min-h-[200px]" style="width: calc(100% - 1px); overflow: hidden">
      <ckeditor #bodyText [editor]="editor" [config]="config" data="{{ body }}"></ckeditor>
    </div>
    <div class="w-full flex-none" *ngIf="!cannotLoadData && !isLoading">
      <div class="flex justify-between overflow-hidden">
        <div class="icon-button-group overflow-x-hidden flex items-center" *ngIf="useMobileView">
          <input #uploader [hidden]="true" type="file" multiple (change)="addAttachmentFileFromButton($event)" />
          <!-- <button mat-icon-button aria-label="Attach file(s)" matTooltip="{{ 'attachFile' | translate }}" (click)="uploader.click()">
            <mat-icon>file_upload</mat-icon>
          </button> -->
          <button
            *ngIf="canSend"
            mat-icon-button
            aria-label="Send"
            matTooltip="{{ 'send' | translate }}"
            (click)="onSubmit(true)"
            [disabled]="!validateForm() || !isEveryFileUploaded()">
            <mat-icon>send</mat-icon>
          </button>
          <button
            *ngIf="!canSend"
            mat-icon-button
            aria-label="Ready to validate"
            matTooltip="{{ 'readyToValidate' | translate }}"
            (click)="onSubmit(true)"
            [disabled]="!validateForm() || !isEveryFileUploaded()">
            <mat-icon>send</mat-icon>
          </button>
          <button [disabled]="!isEveryFileUploaded()" mat-icon-button matTooltip="{{ 'save' | translate }}" (click)="saveAsDraft()">
            <mat-icon>save_outline</mat-icon>
          </button>
          <button mat-icon-button aria-label="Open templates" matTooltip="{{ 'templates' | translate }}" (click)="showTemplates()">
            <mat-icon>library_books</mat-icon>
          </button>
          <button mat-icon-button aria-label="Open notes" matTooltip="{{ 'openNotes' | translate }}" (click)="showNotes()">
            <mat-icon>note_outline</mat-icon>
          </button>
          <button mat-icon-button aria-label="Attach file(s)" matTooltip="{{ 'attachFile' | translate }}" (click)="uploader.click()">
            <mat-icon>attach_file</mat-icon>
          </button>
          <button mat-icon-button aria-label="Delete" (click)="delete()" matTooltip="{{ 'delete' | translate }}">
            <mat-icon>delete_outline</mat-icon>
          </button>

          <mat-slide-toggle
            [checked]="isSplitEmail"
            (change)="splitEmailToggle($event.checked)"
            class="ml-2"
            color="primary"
            matTooltip="{{ 'splitSend' | translate }}"></mat-slide-toggle>
        </div>

        <div class="button-group flex items-center" *ngIf="!useMobileView">
          <input #uploader [hidden]="true" type="file" multiple (change)="addAttachmentFileFromButton($event)" />
          <button
            class="page-block-button"
            color="primary"
            mat-flat-button
            *ngIf="canSend"
            (click)="onSubmit(true)"
            [disabled]="!validateForm() || submitInProgress || saveInProgress || !isEveryFileUploaded()">
            <app-loading-button text="{{ 'send' | translate }}" [loading]="submitInProgress && !saveInProgress"></app-loading-button>
          </button>
          <button
            class="page-block-button"
            color="primary"
            mat-flat-button
            *ngIf="!canSend"
            (click)="onSubmit(true)"
            [disabled]="!validateForm() || submitInProgress || saveInProgress || !isEveryFileUploaded()">
            <app-loading-button
              text="{{ 'readyToApprove' | translate }}"
              [loading]="submitInProgress && !saveInProgress"></app-loading-button>
          </button>
          <button
            class="page-block-button"
            color="primary"
            mat-flat-button
            (click)="saveAsDraft()"
            [disabled]="submitInProgress || saveInProgress || !isEveryFileUploaded()">
            <app-loading-button text="{{ 'save' | translate }}" [loading]="saveInProgress"></app-loading-button>
          </button>
          <button
            class="page-block-button"
            color="primary"
            mat-flat-button
            (click)="uploader.click()"
            [disabled]="submitInProgress || saveInProgress">
            {{ "attachFiles" | translate }}
          </button>
        </div>
        <div class="button-group flex items-center" *ngIf="!useMobileView">
          <mat-slide-toggle
            [checked]="isSplitEmail"
            (change)="splitEmailToggle($event.checked)"
            class="mr-3"
            color="primary"
            matTooltip="{{ 'splitSend' | translate }}"></mat-slide-toggle>
          <button mat-icon-button aria-label="Open templates" matTooltip="{{ 'templates' | translate }}" (click)="showTemplates()">
            <mat-icon>library_books</mat-icon>
          </button>
          <button color="primary" matTooltip="{{ 'openNotes' | translate }}" mat-icon-button (click)="showNotes()">
            <mat-icon>note_outline</mat-icon>
          </button>
          <button mat-icon-button aria-label="Delete" (click)="delete()" matTooltip="{{ 'delete' | translate }}">
            <mat-icon>delete_outline</mat-icon>
          </button>
          <button mat-icon-button aria-label="Audit" (click)="audit()" matTooltip="{{ 'audit' | translate }}">
            <mat-icon>verified_outline</mat-icon>
          </button>
          <button
            matTooltip="{{ 'resetAll' | translate }}"
            color="primary"
            mat-icon-button
            (click)="openConfirmDialog('resetSubtitle', 'resetTitle')">
            <mat-icon>restart_alt</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!useMobileView && menuIsOpened" style="max-width: 27%" class="w-full h-full relative">
    <div class="absolute top-[-15px] right-[-15px] h-12 w-12 z-20"></div>
    <div class="w-full h-full flex flex-col">
      <div class="w-full flex mb-2 bg-white z-20">
        <div class="flex cursor-pointer items-center" (click)="closeMenu()">
          <button mat-icon-button class="menu-close-btn pr-3 mt-[2px]">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <div class="text-xl font-medium">{{ menuHeader | translate }}</div>
        </div>
      </div>

      <div class="flex-grow w-full px-1 overflow-y-auto">
        <div *ngIf="menuContent == menuContentTypes.Notes" class="h-full w-full">
          <app-email-note-list [emailId]="emailId" (refreshNotes)="refreshNotes()"></app-email-note-list>
        </div>
        <div *ngIf="menuContent == menuContentTypes.Templates" class="h-full w-full">
          <app-email-templates (templateSelected)="templateSelected($event)" [mailAccountId]="mailAccountId"></app-email-templates>
        </div>
        <div *ngIf="menuContent == menuContentTypes.Audit" class="h-full w-full">
          <app-email-audit [emailId]="emailId"></app-email-audit>
        </div>
      </div>
    </div>
  </div>
</div>
