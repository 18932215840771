import { Component, Input, OnInit } from '@angular/core';
import { CheckboxItem } from '../../../../../../shared/checkbox.item';
import { RemoteFolder } from '../../../../../../sdk/contracts/remote/remote-folder.contract';
import { RemoteMailFolderDefinition } from '../../../../../../sdk/contracts/dispatching/remotemailfolder.definition';
import { DispatchingRule } from '../../../../../../sdk/contracts/dispatching/dispatching.rule';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-dispatching-folders',
  templateUrl: './dispatching-folders.component.html',
  styleUrls: ['./dispatching-folders.component.scss'],
})
export class DispatchingFoldersComponent implements OnInit {
  @Input() public checkBoxFolders: CheckboxItem<RemoteFolder>[] = [];
  @Input() public dispatchingRule: DispatchingRule;
  @Input() public isShowError: boolean;

  public form: UntypedFormGroup = new UntypedFormGroup({});
  public selected = [];
  constructor() {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      mailFolders: new UntypedFormControl('', Validators.required),
    });
    this.selected = this.dispatchingRule.dispatchingRule.folders.map((e) => e.name);
  }

  ngOnChanges() {
    if (this.isShowError) {
      this.form.controls['mailFolders'].markAsTouched();
    }
  }

  onChange(folder): void {
    let currFolder = this.checkBoxFolders.find((item) => item.label === folder.label);
    currFolder.checked = !currFolder.checked;

    this.dispatchingRule.dispatchingRule.folders = this.checkBoxFolders
      .filter((c) => c.checked)
      .map((c) => {
        const f = new RemoteMailFolderDefinition();
        f.isOutbox = false;
        f.name = c.value.name;
        return f;
      });
  }
}
