import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseCollectionSearchByIdRequest } from '../../../../../sdk/contracts/common/base-collection-search-by-id.request';
import { UserOrganizationGetOrganizationUsersContract } from '../../../../../sdk/contracts/user-organization/user-organization.get-organization-users.contract';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import { MailAccountGetByOrganizationIdContract } from 'src/app/sdk/contracts/mail-account/mail-account.get-by-organization-id.contract';
import { MailAccountClient } from 'src/app/sdk/clients/mail-account.client';
import { UserMailAccountClient } from 'src/app/sdk/clients/user-mail-account.client';
import { UserMailAccountRequest } from 'src/app/sdk/contracts/user-mail-account/user-mail-account.request';
import { UserMailAccountGetListRequest } from 'src/app/sdk/contracts/user-mail-account/user-mail-account.get-list.request';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { RoleType } from 'src/app/sdk/contracts/role/role-type';
import { BaseCollectionFilteredSearchByIdRequest } from 'src/app/sdk/contracts/common/base-collection-filtered-search-by-id.request';

interface MailAccountCheckboxes extends MailAccountGetByOrganizationIdContract {
  _checked: boolean;
  role: number;
}

interface Roles {
  name: string;
  value: RoleType;
}

@Component({
  selector: 'app-add-users-to-mail-accounts-form',
  templateUrl: './add-users-to-mail-accounts-form.component.html',
  styleUrls: ['./add-users-to-mail-accounts-form.component.scss'],
})
export class AddUsersToMailAccountsFormComponent implements OnInit {
  @Input() public users: UserOrganizationGetOrganizationUsersContract[];
  @Input() private organizationID: number;
  @Output() private close = new EventEmitter();
  @Output() private submit = new EventEmitter();

  public handlingErrorCodes = new Map<number, string>([]);

  public cannotLoadMailAccounts: boolean = false;
  public errorText: string = '';
  public isLoading: boolean = false;
  public isLoadingMailAccounts: boolean = false;

  public roles: Roles[] = [
    {
      name: 'User',
      value: RoleType.User,
    },
    {
      name: 'SuperUser',
      value: RoleType.SuperUser,
    },
    {
      name: 'Creator',
      value: RoleType.Creator,
    },
    {
      name: 'Validator',
      value: RoleType.Validator,
    },
    {
      name: 'LimitedUser',
      value: RoleType.LimitedUser,
    },
    {
      name: 'LimitedCreator',
      value: RoleType.LimitedCreator,
    },
  ];

  public search: string = '';
  public filterList = [
    { label: 'allSelect', value: -1 },
    { label: 'showOnlySelectedSelect', value: 1 },
    { label: 'showOnlyNonSelectedSelect', value: 0 },
  ];
  public filters = new UntypedFormControl(this.filterList[0].value);

  public mailAccounts: MailAccountCheckboxes[] = [];
  public filteredMailAccounts: MailAccountCheckboxes[] = [];

  public dataSource = new MatTableDataSource<MailAccountCheckboxes>(this.filteredMailAccounts);
  public displayedColumns: string[] = ['select', 'mailAccount', 'role'];

  constructor(
    private mailAccountClient: MailAccountClient,
    private userMailAccountClient: UserMailAccountClient,
    private matchError: MatchError,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.fetchMailAccounts();
  }

  public async onSubmit() {
    this.isLoading = true;
    const users = this.users.map((user) => user.userId);
    const usersRoles = this.mailAccounts
      .filter((item) => item._checked)
      .map((item) => {
        return {
          mailAccountId: item.mailAccountId,
          role: item.role,
        };
      });

    const request: UserMailAccountRequest = {
      users,
      usersRoles,
    };

    try {
      await this.userMailAccountClient.setUserMailAccounts(request);
      this.submit.emit();
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public onClose() {
    this.close.emit();
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  resetSearch() {
    this.search = '';
    this.dataSource.filter = this.search.trim().toLowerCase();
  }

  private async fetchMailAccounts() {
    this.isLoadingMailAccounts = true;
    try {
      const request = new BaseCollectionFilteredSearchByIdRequest(this.organizationID, 1, 500, {
        search: '',
        ProviderId: null,
        isActive: null,
        isArchive: null,
        searchOrder: 1,
        orderDirection: 1,
      });
      const result = await this.mailAccountClient.getSearch(request);

      if (this.users.length == 1) {
        const { userMailAccounts } = await this.getUserMailAccounts();

        this.mailAccounts = result.data.map((mailAccount) => {
          let currItem = userMailAccounts.find((item) => mailAccount.mailAccountId === item.mailAccountId);
          return {
            ...mailAccount,
            role: !!currItem ? this.roles.find((item) => item.value === currItem.roleId).value : this.roles[0].value,
            _checked: !!currItem,
          };
        });
      } else {
        this.mailAccounts = result.data.map((mailAccount) => {
          return { ...mailAccount, role: this.roles[0].value, _checked: false };
        });
      }

      this.filteredMailAccounts = this.mailAccounts;
      this.dataSource.data = this.filteredMailAccounts;
      this.cannotLoadMailAccounts = false;
    } catch (e) {
      this.cannotLoadMailAccounts = true;
      this.matchError.logError(e);
    } finally {
      this.isLoadingMailAccounts = false;
    }
  }

  private async getUserMailAccounts() {
    const req = new UserMailAccountGetListRequest(this.users[0].userId);
    const userMailAccounts = await this.userMailAccountClient.getUserAccountsById(req);
    return userMailAccounts;
  }

  isAllSelected() {
    const numSelected = this.mailAccounts.filter((mailAccount) => mailAccount._checked).length;
    const numRows = this.mailAccounts.length;
    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.mailAccounts.forEach((mailAccount) => (mailAccount._checked = false))
      : this.mailAccounts.forEach((mailAccount) => (mailAccount._checked = true));
  }

  public hasSelected() {
    let hasSelected = false;
    for (let i = 0; i < this.mailAccounts.length; i++) {
      if (this.mailAccounts[i]._checked) {
        hasSelected = true;
        break;
      }
    }
    return hasSelected;
  }

  onFilterSelectionChange() {
    if (this.filters.value < 0) {
      this.filteredMailAccounts = this.mailAccounts;
      this.dataSource.data = this.filteredMailAccounts;
    }

    if (this.filters.value == 0) {
      this.filteredMailAccounts = this.mailAccounts.filter((mailAccount) => !mailAccount._checked);
      this.dataSource.data = this.filteredMailAccounts;
    }

    if (this.filters.value > 0) {
      this.filteredMailAccounts = this.mailAccounts.filter((mailAccount) => mailAccount._checked);
      this.dataSource.data = this.filteredMailAccounts;
    }
  }
}
