<div class="drawer-content-screen flex flex-col justify-between">
  <div class="text-2xl mb-10 font-medium">
    {{ "createTemplate" | translate }}
  </div>

  <form
    [formGroup]="form"
    autocomplete="off"
    class="w-full relative flex flex-col overflow-hidden"
    style="height: calc(100% - 30px)"
    novalidate>
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>{{ "templateName" | translate }}</mat-label>
      <input matInput formControlName="templateName" />
      <mat-error *ngIf="hasError('templateName', 'maxlength')">You have more than 256 characters</mat-error>
      <mat-error *ngIf="hasError('templateName', 'required')">{{ "templateNameIsRequired" | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>{{ "templateDesc" | translate }}</mat-label>
      <input matInput formControlName="templateDesc" />
      <mat-error *ngIf="hasError('templateDesc', 'maxlength')">You have more than 256 characters</mat-error>
      <!-- <mat-error *ngIf="hasError('templateDesc', 'required')">{{ "templateDesc" | translate }} is required</mat-error> -->
    </mat-form-field>
    <mat-form-field class="w-full" appearance="fill">
      <mat-label>{{ "subject" | translate }}</mat-label>
      <input matInput formControlName="templateSubject" />
      <mat-error *ngIf="hasError('templateSubject', 'maxlength')">You have more than 256 characters</mat-error>
    </mat-form-field>
    <div class="editor-create page-block h-full mb-3 canvas min-h-[200px]" style="width: calc(100% - 1px); overflow: hidden">
      <ckeditor #bodyText [editor]="editor" [config]="config" data="{{ body }}"></ckeditor>
    </div>
    <div class="flex justify-end">
      <button
        mat-flat-button
        color="primary"
        [disabled]="isSendingRequest || isLoading || !form.valid || !isBodyHasData()"
        (click)="onSubmit(form.value)">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isSendingRequest"></app-loading-button>
      </button>
    </div>
  </form>
</div>
