import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { MailFolderGetAllResponse } from '../contracts/mail-folder/mail-folder.get-all.response';
import { MailFolderContract } from '../contracts/mail-folder/mail-folder.contract';
import { MailFolderCreateRequest } from '../contracts/mail-folder/mail-folder.create.request';
import { MailFolderCreateResponse } from '../contracts/mail-folder/mail-folder.create.response';
import { MailFolderUpdateRequest } from '../contracts/mail-folder/mail-folder.update.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { BaseGetCollectionRequest } from '../contracts/common/base-get-collection.request';
import { BaseGetByIdResponse } from '../contracts/common/base-get-by-id.response';
import { BaseGetCollectionResponse } from '../contracts/common/base-get-collection.response';
import { BaseUpdateResponse } from '../contracts/common/base-update.response';
import { BaseDeleteRequest } from '../contracts/common/base-delete.request';
import { BaseDeleteResponse } from '../contracts/common/base-delete.response';
import { TreeItem } from '../contracts/tree.item';
import { MailFolderGetDefaultRequest } from '../contracts/mail-folder/mail-folder.get-default.request';
import { MailAccountGetUnreadCountRequest } from '../contracts/mail-folder/mail-folder.get-unreadcount.request';
import { MailFolderGetUnreadCountRequest } from '../contracts/mail-folder/mail-folder.get-folder-unreadcount.request';
import { MailFolderUnreadCount } from '../contracts/mail-folder/mail-folder.unread.count';
import { MailFolderContractWithFullNameForResponse } from '../contracts/mail-folder/mail-folder-contract-with-full-name.response';
import { UserFolderContract } from '../contracts/mail-folder/user-folder.contract';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class MailFolderClient {
  constructor(private apiRequest: ApiRequest) {}

  async getAll(request: BaseGetCollectionRequest): Promise<MailFolderGetAllResponse> {
    return this.apiRequest.get<MailFolderGetAllResponse>(`${API_URL}${ApiRoutes.mailFolder.getAll}?${request.format()}`);
  }

  async get(request: BaseGetByIdRequest): Promise<BaseGetByIdResponse<MailFolderContract>> {
    return this.apiRequest.get<BaseGetByIdResponse<MailFolderContract>>(`${API_URL}${ApiRoutes.mailFolder.get}?${request.format()}`);
  }

  async getForAccount(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<TreeItem<number, MailFolderContract>>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TreeItem<number, MailFolderContract>>>(
      `${API_URL}${ApiRoutes.mailFolder.getForAccount}?${request.format()}`
    );
  }

  async getForReassign(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<TreeItem<number, MailFolderContract>>> {
    return this.apiRequest.get<BaseGetCollectionResponse<TreeItem<number, MailFolderContract>>>(
      `${API_URL}${ApiRoutes.mailFolder.getForReassign}?${request.format()}`
    );
  }

  async getForMailAccountFlat(request: BaseGetByIdRequest): Promise<BaseGetCollectionResponse<MailFolderContractWithFullNameForResponse>> {
    return this.apiRequest.get<BaseGetCollectionResponse<MailFolderContractWithFullNameForResponse>>(
      `${API_URL}${ApiRoutes.mailFolder.getForMailAccountFlat}?${request.format()}`
    );
  }

  async getDefault(request: MailFolderGetDefaultRequest): Promise<BaseGetByIdResponse<MailFolderContract>> {
    return this.apiRequest.get<BaseGetByIdResponse<MailFolderContract>>(`${API_URL}${ApiRoutes.mailFolder.getDefault}?${request.format()}`);
  }

  async create(request: MailFolderCreateRequest): Promise<MailFolderCreateResponse> {
    return this.apiRequest.post<MailFolderCreateRequest, MailFolderCreateResponse>(request, `${API_URL}${ApiRoutes.mailFolder.create}`);
  }

  async update(request: MailFolderUpdateRequest): Promise<BaseUpdateResponse> {
    return this.apiRequest.post<MailFolderUpdateRequest, BaseUpdateResponse>(request, `${API_URL}${ApiRoutes.mailFolder.update}`);
  }

  async delete(request: BaseDeleteRequest): Promise<BaseDeleteResponse> {
    return this.apiRequest.post<BaseDeleteRequest, BaseDeleteResponse>(request, `${API_URL}${ApiRoutes.mailFolder.delete}`);
  }

  async getUnreadCount(request: MailAccountGetUnreadCountRequest): Promise<BaseGetCollectionResponse<MailFolderUnreadCount>> {
    return this.apiRequest.get<BaseGetCollectionResponse<MailFolderUnreadCount>>(
      `${API_URL}${ApiRoutes.mailFolder.getUnreadCount}?${request.format()}`
    );
  }

  async getFolderUnreadCount(request: MailFolderGetUnreadCountRequest): Promise<MailFolderUnreadCount> {
    return this.apiRequest.get<MailFolderUnreadCount>(`${API_URL}${ApiRoutes.mailFolder.getFolderUnreadCount}?${request.format()}`);
  }

  async getUserFolders(): Promise<BaseGetCollectionResponse<UserFolderContract>> {
    return this.apiRequest.get<BaseGetCollectionResponse<UserFolderContract>>(`${API_URL}${ApiRoutes.mailFolder.getUserFolders}`);
  }
}
