<div *ngIf="isOpen" class="fixed h-screen w-screen z-50 flex flew-row top-0 left-0">
  <div [class.custom-blur]="showBackground" class="h-screen lg:flex-1"></div>
  <div class="h-screen bg-white p-5 flex-1 custom-on-md" [class.flex-1]="!width" [style]="width">
    <div class="flex relative">
      <button [ngClass]="isOffset ? 'offset-close-button' : 'close-button'" mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="w-full drawer">
      <ng-content></ng-content>
    </div>
  </div>
</div>
