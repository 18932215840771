<div class="drawer-content-screen flex flex-col justify-between">
  <div *ngIf="!isLoadingUsers && !cannotLoadUsers" class="text-2xl mb-10 font-medium">
    {{ mailAccounts.length > 1 ? ("addMailAccountsToUsers" | translate) : ("addMailAccountToUsers" | translate) }}
  </div>
  <div class="h-full w-full flex-grow mb-2 overflow-y-auto">
    <div class="flex flex-col justify-center h-full" *ngIf="cannotLoadUsers && !isLoadingUsers">
      <app-something-went-wrong
        description="Cannot load the users. Something went wrong"
        (retryEmitter)="fetchUsers()"
        [showPicture]="false"></app-something-went-wrong>
    </div>

    <div *ngIf="!isLoadingUsers && !cannotLoadUsers" class="filters">
      <mat-form-field appearance="fill" class="filters-search">
        <mat-label class="filters-search-label">{{ "search" | translate }}</mat-label>
        <input matInput [(ngModel)]="search" (keyup)="applyFilter($event)" class="filters-search-input" />
        <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearch()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="fill" class="filters-select">
        <mat-label>{{ "filter" | translate }}</mat-label>
        <mat-select [formControl]="filters" [value]="filterList[0].value" (selectionChange)="onFilterSelectionChange()">
          <mat-option *ngFor="let filter of filterList" [value]="filter.value">{{ filter.label | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-loader class="flex flex-col justify-center h-full" *ngIf="isLoadingUsers"></app-loader>

    <div class="mt-4 pr-2" *ngIf="!isLoadingUsers && !cannotLoadUsers">
      <table mat-table [dataSource]="dataSource" class="w-full">
        <!-- SELECT COLUMN -->
        <ng-container matColumnDef="select">
          <th class="main-header-select" mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              [checked]="hasSelected() && isAllSelected()"
              [indeterminate]="hasSelected() && !isAllSelected()"
              (change)="$event ? masterToggle() : null">
            </mat-checkbox>
          </th>
          <td class="main-header-select" mat-cell *matCellDef="let filteredUser">
            <mat-checkbox color="primary" class="mb-1" [(ngModel)]="filteredUser._checked"> </mat-checkbox>
          </td>
        </ng-container>

        <!-- USERNAME COLUMN -->
        <ng-container matColumnDef="user">
          <th class="main-header-select" mat-header-cell *matHeaderCellDef>{{ "name" | translate }}</th>
          <td class="main-header-select" mat-cell *matCellDef="let filteredUser">
            {{ filteredUser.firstName + " " + filteredUser.lastName }}
          </td>
        </ng-container>

        <!-- ROLE COLUMN -->
        <ng-container matColumnDef="role">
          <th class="main-header-select" mat-header-cell *matHeaderCellDef>{{ "role" | translate }}</th>
          <td class="main-header-select" mat-cell *matCellDef="let user">
            <mat-form-field appearance="none" class="w-full">
              <mat-select [(ngModel)]="user.role" required>
                <mat-option id="user.userId" *ngFor="let role of roles" [value]="role.value">
                  {{ role.name | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>

  <div *ngIf="!isLoadingUsers && !cannotLoadUsers" class="w-full">
    <div class="flex justify-end">
      <!-- <button (click)="onClose()" mat-flat-button>{{'cancel' | translate}}</button> -->
      <button mat-flat-button color="primary" [disabled]="isLoading" (click)="onSubmit()">
        <app-loading-button text="{{ 'save' | translate }}" [loading]="isLoading"></app-loading-button>
      </button>
    </div>
  </div>
</div>
