import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseGetByIdRequest } from '../../../../../../sdk/contracts/common/base-get-by-id.request';
import { CheckboxItem } from '../../../../../../shared/checkbox.item';
import { RemoteFolder } from '../../../../../../sdk/contracts/remote/remote-folder.contract';
import { DispatchingRuleClient } from '../../../../../../sdk/clients/dispatchingrule.client';
import { MailFolderContract } from '../../../../../../sdk/contracts/mail-folder/mail-folder.contract';
import { TreeItem } from '../../../../../../sdk/contracts/tree.item';
import { DispatchingRule } from '../../../../../../sdk/contracts/dispatching/dispatching.rule';
import { DispatchingRuleObject } from '../../../../../../sdk/contracts/dispatching/dispatchingrule.object';
import { EmailClass } from '../../../../../../sdk/contracts/dispatching/email-class';
import { DispatchingAction } from '../../../../../../sdk/contracts/dispatching/dispatching.action';
import { DispatchingRuleUpdateRequest } from '../../../../../../sdk/contracts/dispatching/dispatchingrule.update.request';
import { DispatchingRuleCreateRequest } from '../../../../../../sdk/contracts/dispatching/dispatchingrule.create.request';
import { MatchError } from 'src/app/shared/error-matcher';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MailFolderType } from 'src/app/sdk/contracts/mail-folder/mail-folder.type';
import { MailAccountService } from 'src/app/services/mail-account/mail.account.service';
import { MailAccountGetByOrganizationIdContract } from 'src/app/sdk/contracts/mail-account/mail-account.get-by-organization-id.contract';
import { Group } from '../../../../../../sdk/contracts/group/group.contract';

@Component({
  selector: 'app-dispatching-rule-create-or-update-dialog',
  templateUrl: './dispatching-rule-create-or-update-dialog.component.html',
  styleUrls: ['./dispatching-rule-create-or-update-dialog.component.scss'],
})
export class DispatchingRuleCreateOrUpdateDialogComponent implements OnInit {
  @Input() public mailAccountId: number;
  @Input() public updatingDispatchingRule: DispatchingRule | null = null;
  @Output() public submitEventEmitter = new EventEmitter();
  @Output() public closeEventEmitter = new EventEmitter();
  public loadingAvailableData = false;
  public cannotLoadAvailableData = false;
  public form = new UntypedFormGroup({});
  public availableFolders: RemoteFolder[];
  public availableActions: string[];
  public availableConditions: string[];
  public availableLocalFolders: MailFolderContract[] = [];
  public availableGroups: Group[] = [];
  public checkBoxGroups: CheckboxItem<Group>[] = [];
  public checkBoxFolders: CheckboxItem<RemoteFolder>[] = [];
  public dispatchingRule: DispatchingRule;
  public applyToEmails: boolean;
  public applyToNotifications: boolean;
  public isLoading = false;
  public errorText: string;
  public isShowError: boolean = false;
  public existingActionTypes: string[] = [];
  public condtionsBeforeSend;
  public accounts: MailAccountGetByOrganizationIdContract[] = [];

  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    private dispatchingRuleClient: DispatchingRuleClient,
    private mailAccountService: MailAccountService,
    public matchError: MatchError,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    this.initDispatchingRule();
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(this.dispatchingRule.dispatchingRule.name, [Validators.required, Validators.maxLength(256)]),
      priority: new UntypedFormControl(this.dispatchingRule.dispatchingRule.priority, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
        Validators.max(10000),
      ]),
    });
    await this.loadAvailableData();
    this.applyToEmails =
      this.dispatchingRule.dispatchingRule.matchExpression.emailClasses?.find((value) => value === EmailClass.Email) !== undefined;
    this.applyToNotifications =
      this.dispatchingRule.dispatchingRule.matchExpression.emailClasses?.find((value) => value === EmailClass.Notification) !== undefined;
  }

  public get isCreation(): boolean {
    return this.updatingDispatchingRule == null;
  }
  
  private groupChecked(groupId: number): boolean {
	let assignGroupActionExist = this.dispatchingRule.dispatchingRule.actions.find((f) => f.name === 'AssignToGroups') !== undefined;
	if(assignGroupActionExist)
		return this.dispatchingRule.dispatchingRule.actions.find((f) => f.name === 'AssignToGroups').args.find((y) => Number(y.value) === groupId) !== undefined;
	else 
		return assignGroupActionExist;
  }

  private folderChecked(name: string): boolean {
    return this.dispatchingRule.dispatchingRule.folders.find((f) => f.name === name) !== undefined;
  }

  public initDispatchingRule(): void {
    if (!this.updatingDispatchingRule) {
      this.dispatchingRule = new DispatchingRule();
      this.dispatchingRule.dispatchingRule = new DispatchingRuleObject();
    } else {
      this.dispatchingRule = JSON.parse(JSON.stringify(this.updatingDispatchingRule));
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.form.controls[controlName].hasError(errorName);
  };

  async onSubmit(): Promise<void> {
    this.submitEventEmitter.emit();
  }

  async cancel(): Promise<void> {
    this.closeEventEmitter.emit();
  }

  async loadAccounts(mailAccountId: number): Promise<void> {
    this.accounts = await this.mailAccountService.getAccountsForAccountOrganization(mailAccountId);
  }

  async loadAvailableData(): Promise<any> {
    this.loadingAvailableData = true;
    this.cannotLoadAvailableData = false;
    try {
      this.loadAccounts(this.mailAccountId);
      const data = await this.dispatchingRuleClient.getAvailableDataForAccount(new BaseGetByIdRequest(this.mailAccountId));
      this.availableFolders = data.result.availableFolders;
      this.availableGroups = data.result.availableGroups;
      this.availableActions = data.result.availableActions;
      this.existingActionTypes = [...this.availableActions];
      const actions = this.dispatchingRule.dispatchingRule.actions.map((e) => e.name);
      this.existingActionTypes = this.availableActions.filter((e) => !actions.some((x) => x === e));
      this.availableConditions = data.result.availableConditions;
      data.result.availableLocalFolders.forEach((ti) => {
        this.availableLocalFolders.push(ti.data);
        if (ti.children && ti.children.length > 0) {
          this.addChildrenFolders(ti.children, 'ᅠ');
        }
      });
      const folderTypeOrder = [
        MailFolderType.Inbox,
        MailFolderType.Sent,
        MailFolderType.Archive,
        MailFolderType.Outbox,
        MailFolderType.Validation,
        MailFolderType.Draft,
        MailFolderType.Trash,
        MailFolderType.Spam,
      ];
      this.availableLocalFolders.sort((a, b) => folderTypeOrder.indexOf(a.folderType) - folderTypeOrder.indexOf(b.folderType));
	  
	  this.availableGroups.forEach((f) => {
        const newItem = new CheckboxItem<Group>(f, f.group, this.groupChecked(f.groupId));
        this.checkBoxGroups.push(newItem);
      });

      this.availableFolders.forEach((f) => {
        const newItem = new CheckboxItem<RemoteFolder>(f, f.name, this.folderChecked(f.name));
        this.checkBoxFolders.push(newItem);
      });
    } catch (e) {
      this.cannotLoadAvailableData = true;
      this.matchError.logError(e);
    } finally {
      this.loadingAvailableData = false;
    }
  }

  private addChildrenFolders(children: TreeItem<number, MailFolderContract>[], pad: string): void {
    children.forEach((ti) => {
      ti.data.name = `${pad}${ti.data.name}`;
      this.availableLocalFolders.push(ti.data);
      if (ti.children && ti.children.length > 0) {
        this.addChildrenFolders(ti.children, `${pad}ᅠ`);
      }
    });
  }

  public addAction(): void {
    this.existingActionTypesUpdate();
    if (this.existingActionTypes.length !== 0) {
      if (!this.dispatchingRule.dispatchingRule.actions) {
        this.dispatchingRule.dispatchingRule.actions = [];
      }
      const action = new DispatchingAction();
      if (this.availableActions[0]) {
        action.name = this.existingActionTypes[0];
      }
      action.args = [];
      this.dispatchingRule.dispatchingRule.actions.push(action);
      this.existingActionTypesUpdate();
    }
  }

  public existingActionTypesUpdate() {
    const actions = this.dispatchingRule.dispatchingRule.actions.map((e) => e.name);
    this.existingActionTypes = this.availableActions.filter((e) => !actions.some((x) => x === e));
  }

  public deleteAction(index: number): void {
    this.dispatchingRule.dispatchingRule.actions.splice(index, 1);
    this.existingActionTypesUpdate();
  }

  public onChangeEmailClasses({ applyToEmails, applyToNotifications }): void {
    this.dispatchingRule.dispatchingRule.matchExpression.emailClasses = [];
    if (applyToEmails) {
      this.dispatchingRule.dispatchingRule.matchExpression.emailClasses.push(EmailClass.Email);
      this.applyToEmails = applyToEmails;
    }
    if (applyToNotifications) {
      this.dispatchingRule.dispatchingRule.matchExpression.emailClasses.push(EmailClass.Notification);
      this.applyToEmails = applyToNotifications;
    }
  }

  public dispatchingRuleIsValid(): boolean {
    return (
      this.form.valid &&
      this.selectedAtLeastOneFolder() &&
      this.conditionsAreValid() &&
      this.selectedAtLeastOneAction() &&
      this.differentActionsIsValid() &&
      this.actionsIsValid()
    );
  }

  private conditionsAreValid(): boolean {
    for (const condition of this.dispatchingRule.dispatchingRule.matchExpression.conditions) {
      for (const pattern of condition.patterns) {
        if (!this.patternIsValid(pattern)) {
          return false;
        }
      }
    }
    return true;
  }

  private patternIsValid(pattern: string): boolean {
    return pattern.length <= 256;
  }

  private selectedAtLeastOneFolder(): boolean {
    return this.dispatchingRule.dispatchingRule.folders.length !== 0;
  }

  private selectedAtLeastOneAction(): boolean {
    return this.dispatchingRule.dispatchingRule.actions.length !== 0;
  }

  private differentActionsIsValid(): boolean {
    let arr = [];

    this.dispatchingRule.dispatchingRule.actions.forEach((rule) => {
      if (this.availableActions.includes(rule.name)) {
        arr.push(rule.name);
      }
    });

    if (arr[0] === arr[1]) return false;

    return true;
  }

  private actionsIsValid(): boolean {
    for (const action of this.dispatchingRule.dispatchingRule.actions) {
      if (action.name === 'ForwardMail') {
        for (const forwardToArg of action.args) {
          let isAllValid = forwardToArg.value
            .replace(/\s+/g, '')
            .split(';')
            .every((e) => this.isValidTag(e));
          return !!isAllValid;
        }
      }
    }
    return true;
  }

  public isValidTag(value: string) {
    return !!String(value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  public async submit(): Promise<void> {
    if (!this.dispatchingRuleIsValid()) {
      this.isShowError = true;
      this.form.controls['name'].markAsTouched();
      this.form.controls['priority'].markAsTouched();
      return;
    }

    this.isLoading = true;
    try {
      if (this.isCreation) {
        await this.createDispatchingRule();
      } else {
        await this.updateDispatchingRule();
      }
      this.submitEventEmitter.emit();
    } catch (e) {
      this.dispatchingRule.dispatchingRule.matchExpression.conditions = this.condtionsBeforeSend;
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }

  public onChangeContinueNextRules(event: MatCheckboxChange) {
    this.dispatchingRule.dispatchingRule.options.continueNextRulesExecutionOnMatch = event.checked;
  }

  private async createDispatchingRule(): Promise<void> {
    const request = new DispatchingRuleCreateRequest();
    request.mailAccountId = this.mailAccountId;
    this.condtionsBeforeSend = this.dispatchingRule.dispatchingRule.matchExpression.conditions;
    this.dispatchingRule.dispatchingRule.matchExpression.conditions = this.dispatchingRule.dispatchingRule.matchExpression.conditions.map(
      (e) => {
        return { ...e, patterns: e.patterns.filter((x) => x !== '').map((e) => e.trim()) };
      }
    );
    request.dispatchingRule = this.dispatchingRule.dispatchingRule;
    await this.dispatchingRuleClient.create(request);
  }

  private async updateDispatchingRule(): Promise<void> {
    const request = new DispatchingRuleUpdateRequest();
    request.dispatchingRuleId = this.dispatchingRule.dispatchingRuleId;
    this.condtionsBeforeSend = this.dispatchingRule.dispatchingRule.matchExpression.conditions;
    this.dispatchingRule.dispatchingRule.matchExpression.conditions = this.dispatchingRule.dispatchingRule.matchExpression.conditions.map(
      (e) => {
        return { ...e, patterns: e.patterns.filter((x) => x !== '').map((e) => e.trim()) };
      }
    );

    request.dispatchingRule = this.dispatchingRule.dispatchingRule;
    await this.dispatchingRuleClient.update(request);
  }
}
