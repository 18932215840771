import { Component, OnInit } from '@angular/core';
import { UrlService } from '../../../services/url.service';

@Component({
  selector: 'app-not-found-error-page',
  templateUrl: './not-found-error-page.component.html',
  styleUrls: ['./not-found-error-page.component.scss'],
})
export class NotFoundErrorPageComponent implements OnInit {
  public previousRoute;

  constructor(private urlService: UrlService) {}

  ngOnInit(): void {
    this.urlService.previousUrl$.subscribe((previousUrl: string) => {
      this.previousRoute = previousUrl;
    });
  }
}
