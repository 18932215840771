import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatchError } from 'src/app/shared/error-matcher';
import { UserMailAccountClient } from '../../../../../../sdk/clients/user-mail-account.client';
import { BaseDeleteRequest } from '../../../../../../sdk/contracts/common/base-delete.request';

@Component({
  selector: 'app-mail-account-users-delete-dialog',
  templateUrl: './mail-account-users-delete-dialog.component.html',
  styleUrls: ['./mail-account-users-delete-dialog.component.scss'],
})
export class MailAccountUsersDeleteDialogComponent implements OnInit {
  public isLoading = false;
  public errorText: string;
  public handlingErrorCodes = new Map<number, string>([]);

  constructor(
    public dialogRef: MatDialogRef<MailAccountUsersDeleteDialogComponent>,
    public matchError: MatchError,
    @Inject(MAT_DIALOG_DATA)
    public data: { userMailAccountId: number; mailAccountName: string; userFirstName: string; userLastName: string },
    public userMailAccountClient: UserMailAccountClient,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  onCancelBtnClicked(): void {
    this.dialogRef.close({ isDeleted: false });
  }

  async onDeleteBtnClicked(): Promise<void> {
    this.isLoading = true;
    const request = new BaseDeleteRequest(this.data.userMailAccountId);
    try {
      await this.userMailAccountClient.deleteMailAccountUser(request);
      this.dialogRef.close({ isDeleted: true });
    } catch (e) {
      this.matchError.errorHandler(e);
      this.matchError.logError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
