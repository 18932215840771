import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiRoutes } from '../routes/api.routes';
import { ApiRequest } from '../api.request';
import { BaseGetByIdRequest } from '../contracts/common/base-get-by-id.request';
import { MailAccountSimpleConfigurationRequest } from '../contracts/mail-account-simple-configuration/mail-account-simple-configuration-request';
import { MailAccountSimpleConfigurationCanSetupResponse } from '../contracts/mail-account-simple-configuration/mail-account-simple-configuration-can-setup-response';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class MailAccountSimpleConfigurationClient {
  constructor(private http: HttpClient, private apiRequest: ApiRequest) {}

  async canSetup(request: BaseGetByIdRequest): Promise<MailAccountSimpleConfigurationCanSetupResponse> {
    return this.apiRequest.post<BaseGetByIdRequest, MailAccountSimpleConfigurationCanSetupResponse>(
      request,
      `${API_URL}${ApiRoutes.mailAccountSimpleConfiguration.canSetup}`
    );
  }
  async create(request: MailAccountSimpleConfigurationRequest): Promise<any> {
    return this.apiRequest.post<MailAccountSimpleConfigurationRequest, any>(
      request,
      `${API_URL}${ApiRoutes.mailAccountSimpleConfiguration.create}`
    );
  }
}
