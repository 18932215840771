import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ApiRequest } from '../api.request';
import { ApiRoutes } from '../routes/api.routes';
import { BaseCreateResponse } from '../contracts/common/base-create.response';
import { CreateImportSessionRequest } from '../contracts/import/create-import-session.request';
import { BaseGetCollectionByIdRequest } from '../contracts/common/base-get-collection-by-id.request';
import { GetImportSessionsByOrganizationIdResponse } from '../contracts/import/get-import-sessions-by-organizationid.response';
import { GetImportSessionResourceByImportSessionIdResponse } from '../contracts/import/get-import-session-resource.contract';

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ImportSessionClient {
  constructor(private apiRequest: ApiRequest) {}

  async create(request: CreateImportSessionRequest): Promise<BaseCreateResponse> {
    return this.apiRequest.post<CreateImportSessionRequest, BaseCreateResponse>(request, `${API_URL}${ApiRoutes.importSession.create}`);
  }

  async GetImportSessions(request: BaseGetCollectionByIdRequest): Promise<GetImportSessionsByOrganizationIdResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.importSession.getByOrganizationId}?${request.format()}`);
  }

  async GetImportSessionResources(request: BaseGetCollectionByIdRequest): Promise<GetImportSessionResourceByImportSessionIdResponse> {
    return this.apiRequest.get(`${API_URL}${ApiRoutes.importSessionResource.getByImportSessionId}?${request.format()}`);
  }
}
