import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  public settingsMaxSpace$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public settingsAlertLevel$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  constructor() {}

  setSettingsMaxSpace(value: number) {
    this.settingsMaxSpace$.next(value);
  }

  setSettingsAlertLevel(value: number) {
    this.settingsAlertLevel$.next(value);
  }
}
