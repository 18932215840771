import { AttachmentContract } from '../Attachment/Attachment.contract';
import { EmailBodyContentType } from '../Email/email-body-content-type';

export class EmailCreateRequest {
  from: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
  parentEmailId: number;
  bodyContentType: EmailBodyContentType;
  attachmentFiles: AttachmentContract[];
  mailAccountId: number;
  tags: string[];
  notes: string[];
  splitSend: boolean;
}
