<div>
  <div class="flex flex-wrap content-center">
    <div class="flex flex-wrap content-center font-medium text-lg mb-4">
      {{ "actions" | translate }}
    </div>
  </div>
  <div class="action-box" *ngFor="let action of dispatchingRule.dispatchingRule.actions; let i = index">
    <div class="action-container p-2">
      <div class="w-full flex">
        <mat-form-field appearance="fill" class="mat-input-without-padding">
          <mat-label>{{ "action" | translate }}</mat-label>
          <mat-select name="action" [(value)]="action.name" (valueChange)="onActionTypeChange($event, i)" required>
            <mat-option
              *ngFor="let actionName of availableActions"
              [value]="actionName"
              [disabled]="filteredAvailableActions(actionName)"
              >{{
                actionName === "MoveMailToFolder"
                  ? ("moveMailToFolder" | translate)
                  : actionName === "ForwardMail"
                  ? ("forwardMail" | translate)
                  : actionName === "ReassignMailToMailAccount"
                  ? ("reassignMailToMailAccount" | translate)				  
                  : actionName === "AssignToGroups"
                  ? ("assignToGroups" | translate)
                  : actionName
              }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="action">
        <div class="w-full flex">
          <div class="w-full">
            <app-move-to-folder-action
              *ngIf="action.name === 'MoveMailToFolder'"
              [dispatchingAction]="action"
              [availableLocalFolders]="availableLocalFolders">
            </app-move-to-folder-action>
            <app-forward-action *ngIf="action.name === 'ForwardMail'" [dispatchingAction]="action"> </app-forward-action>
            <app-reassign-action
              *ngIf="action.name === 'ReassignMailToMailAccount'"
              [dispatchingAction]="action"
              [mailAccounts]="mailAccounts">
            </app-reassign-action>
			      <app-assign-to-group-action
              *ngIf="action.name === 'AssignToGroups'"
              [dispatchingAction]="action"
              [checkBoxGroups]="checkBoxGroups">
            </app-assign-to-group-action>
          </div>
        </div>
      </div>
      <div class="action-delete">
        <button
          matTooltip="{{ 'deleteAction' | translate }}"
          mat-flat-button
          color="primary"
          aria-label="Delete action"
          (click)="onDelete(i)"
          class="flex flex-wrap content-center no-hover-effect">
          {{ "deleteAction" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="existingActionTypes.length" class="flex justify-center items-center">
    <button color="primary" matTooltip="{{ 'createAction' | translate }}" mat-flat-button aria-label="Create" (click)="onCreate()">
      {{ "addAction" | translate }}
    </button>
  </div>
</div>
