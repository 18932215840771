<div class="flex">
  <div *ngIf="pageSize" class="per-page-block">
    <mat-form-field>
      <mat-select class="text-center" [value]="pageSize" (valueChange)="onChangePageSize($event)">
        <mat-option *ngFor="let pageSizeOption of pageSizeOptions" [value]="pageSizeOption">{{ pageSizeOption }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="range-buttons">
    <div class="flex items-center">
      <div *ngIf="countTotal" style="margin-top: 6px; min-width: 100px; text-align: center; font-size: 14px">
        {{ countFrom + " - " + countTo + (" " + ("of" | translate) + " ") + countTotal }}
      </div>
      <button mat-icon-button matTooltip="{{ 'previousPage' | translate }}" (click)="previous()" [disabled]="!hasPreviousPage">
        <mat-icon>navigate_before</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="{{ 'nextPage' | translate }}"
        [class.disabled-mat-paginator-icon]="!hasNextPage"
        (click)="next()"
        [disabled]="!hasNextPage">
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
</div>
